bsc-flash-message {
    display: block;
    margin: $alert-margin;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.alert {
    background: $alert-background;
    border: $alert-border {
        left-width: $alert-border-left-width;
    }
    border-radius: $alert-border-radius;
    display: flex;
    font: {
        size: $alert-font-size;
    }
    margin: $alert-margin;
    min-height: $alert-min-height;
    position: relative;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.alert--success {
        border-left-color: $color-success;
    }

    &.alert--info {
        border-left-color: $color-info;
    }

    &.alert--warning {
        border-left-color: $color-warning;
    }

    &.alert--danger {
        border-left-color: $color-danger;
    }

    .btns-group {
        margin-top: $alert-btns-group-margin;
    }
}

.alert--modal {
    border: none {
        top: 5px solid $color-border;
    }
    flex-direction: column;
    margin: auto;

    &.alert--success {
        border-top-color: $color-success;
    }

    &.alert--info {
        border-top-color: $color-info;
    }

    &.alert--warning {
        border-top-color: $color-warning;
    }

    &.alert--danger {
        border-top-color: $color-danger;
    }
}

.alert__icon {
    align-items: flex-start;
    color: $alert-icon-color;
    display: flex;
    font: {
        size: $alert-icon-size;
        family: $font-icon;
    }
    justify-content: center;
    line-height: 1;
    padding-top: ($alert-min-height - strip-unit($alert-icon-size)) / 2;
    text-align: center;
    width: $alert-icon-width;

    .alert--modal & {
        margin: auto;
        padding-top: 25px;
    }

    .alert--success & {
        color: $color-success;

        &:before {
            content: getIconCode(alert-success);
        }
    }

    .alert--info & {
        color: $color-info;

        &:before {
            content: getIconCode(alert-info);
        }
    }

    .alert--warning & {
        color: $color-warning;

        &:before {
            content: getIconCode(alert-warning);
        }
    }

    .alert--danger & {
        color: $color-danger;

        &:before {
            content: getIconCode(alert-error);
        }
    }
}

.alert__form {
    padding: $alert-modal-content-padding;
}

.alert__content {
    align-self: center;
    flex: 1;
    line-height: $alert-line-height;
    padding: $alert-content-padding;

    .alert--modal & {
        text-align: center;
        padding: $alert-modal-content-padding;
        width: 100%;

        .btns-group {
            justify-content: center;
        }
    }

    .title {
        color: $color-default;
        display: block;
        font: {
            size: $alert-content-title-size;
        }

        & + p {
            margin-top: $alert-first-paragraph-margin;
        }

        .alert--success & {
            color: $color-success;
        }

        .alert--info & {
            color: $color-info;
        }

        .alert--warning & {
            color: $color-warning;
        }

        .alert--danger & {
            color: $color-danger;
        }
    }
}

.alert__close {
    font-size: $alert-close-size;
    position: absolute;
    top: 0;
    right: 0;
    padding: $alert-close-padding;
    width: $alert-close-width;

    @include styleFontIcon() {
        cursor: pointer;
    }
}
