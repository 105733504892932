.votes-chart-wrapper {
    padding: 25px 0;
    position: relative;

    .votes-yes-label,
    .votes-no-label {
        top: -10px;
        color: $color-tertiary;
        font: {
            size: 12px;
        }
        position: absolute;
        text-transform: uppercase;
    }

    strong {
        font: {
            size: 26px;
            weight: normal;
        }
        position: relative;
        top: 1px;
    }

    .votes-yes-label {
        left: 0;

        strong {
            color: #71db9a;
        }
    }

    .votes-no-label {
        right: 0;

        strong {
            color: #e74d63;
        }
    }

    .votes-required-label {
        bottom: 0;
        color: #545454;
        font: {
            size: 12px;
        }
        left: 0;
        position: absolute;
    }

    .votes-addressees {
        bottom: 0;
        color: $color-tertiary;
        font: {
            size: 12px;
        }
        position: absolute;
        right: 0;
    }
}

.votes-chart {
    background: $background-secondary;
    height: 50px;
    position: relative;
    border-radius: 3px;

    .votes-yes {
        height: inherit;
        left: 0;
        width: 0;
        position: absolute;
        background: #6bd692;
        //+makeGradient(#7ce5a8, #77e0a1, #6bd692)
        transition: all cubic-bezier(0.21, 0.62, 0.46, 1.01) 1s;
        border-radius: 3px 0 0 3px;
    }

    .votes-no {
        height: inherit;
        position: absolute;
        right: 0;
        width: 0;
        background: #d44051;
        transition: all cubic-bezier(0.21, 0.62, 0.46, 1.01) 1s;
        //+translateHax
        border-radius: 0 3px 3px 0;
    }

    .votes-required {
        height: inherit;
        position: absolute;
        left: 0;
        border: 2px solid #545454;
        border-radius: 3px 0 0 3px;

        &:after {
            content: '';
            position: absolute;
            bottom: -7px;
            left: 50%;
            margin-left: -4px;
            @include makeArrow(down, #545454, 5px, 4px);
        }
    }
}

@include media-query-max($breakpoint-md) {
    .votes-chart-wrapper {
        .votes-required-label {
            bottom: 5px;
        }

        .votes-addressees {
            bottom: -10px;
        }
    }
}
