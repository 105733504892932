bsc-popover:not(.hydrated) {
    display: none;
}

.popover {
    position: relative;
}

.popover__content {
    position: absolute;
    z-index: 5;
    background: $popover-background;
    border-radius: $popover-border-radius;
    box-shadow: $popover-shadow;
    width: $popover-width;
    text-align: left;
    pointer-events: all;
    opacity: 1;
    transition: opacity 0.1s linear;

    [opened] & {
        pointer-events: auto;
        opacity: 1;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 4;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 4;
    }

    .popover--left &,
    &.popover--left {
        right: calc(100% + 10px);
        top: 0;

        &::after {
            @include makeArrow(right, #fff, 6px, 6px);
            right: -5px;
            top: 15px;
        }

        &::before {
            @include makeArrow(right, rgba(#000, 0.05), 6px, 7px);
            right: -7px;
            top: 15px;
        }
    }

    .popover--right &,
    &.popover--right {
        left: calc(100% + 10px);
        top: 50%;
        transform: translateY(-50%);

        &::after {
            @include makeArrow(left, #fff, 5px, 5px);
            left: -5px;
            top: 50%;
            transform: translateY(-50%);
        }

        &::before {
            @include makeArrow(left, rgba(#000, 0.05), 6px, 7px);
            left: -7px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .popover--top &,
    &.popover--top {
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);

        &::after {
            @include makeArrow(down, #fff, 5px, 5px);
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
        }

        &::before {
            @include makeArrow(down, rgba(#000, 0.05), 6px, 7px);
            bottom: -7px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    // this is default
    .popover--bottom &,
    &.popover--bottom {
        top: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);

        &::after {
            @include makeArrow(up, #fff, 5px, 5px);
            top: -5px;
            left: 50%;
            transform: translateX(-50%);
        }

        &::before {
            @include makeArrow(up, rgba(#000, 0.05), 6px, 7px);
            top: -7px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    // this is default
    .popover--bottom-right &,
    &.popover--bottom-right {
        top: calc(100% + 10px);
        right: 0;

        &::after {
            @include makeArrow(up, #fff, 5px, 5px);
            top: -5px;
            right: 15px;
        }

        &::before {
            @include makeArrow(up, rgba(#000, 0.05), 8px, 8px);
            top: -8px;
            right: 12px;
        }
    }

    // this is default
    .popover--bottom-left &,
    &.popover--bottom-left {
        top: calc(100% + 10px);
        left: 0;

        &::after {
            @include makeArrow(up, #fff, 5px, 5px);
            top: -5px;
            left: 15px;
        }

        &::before {
            @include makeArrow(up, rgba(#000, 0.05), 8px, 8px);
            top: -8px;
            left: 12px;
        }
    }
}

.popover-header {
    background: $popover-header-background;
    border-bottom: $popover-header-border;
    font: {
        size: $popover-header-font-size;
    }
    padding: $popover-header-padding;

    &:empty {
        display: none;
    }
}

.popover-body {
    cursor: default;
    font: {
        size: $popover-body-font-size;
    }
    padding: $popover-body-padding;

    & > *:first-child {
        margin-top: 0;
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    &:empty {
        display: none;
    }
}

.popover-footer {
    background: $popover-footer-background;
    border-top: $popover-footer-border;
    font: {
        size: $popover-footer-font-size;
    }
    padding: $popover-footer-padding;

    &:empty {
        display: none;
    }
}

.popover-menu {
    font-size: 1.3rem;

    .popover-menu__header {
        font-size: 1rem;
        text-transform: uppercase;
        color: $color-tertiary;
        font-family: $font-family-secondary;
        padding: 10px 15px 5px;
    }

    ul, .popover__content & ul {
        margin: 0;
        list-style: none;
    }

    li {
        margin: 0;

        &.separator {
            height: 1px;
            background: $color-border;
            margin: 0 5px;

            &:last-child {
                display: none;
            }
        }
    }

    a {
        display: block;
        padding: 10px 15px;

        &:hover {
            background: darken(#fff, 5%);
        }
    }
}
