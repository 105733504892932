@if $use-tooltipster {
    .tooltipster-fall,
    .tooltipster-grow.tooltipster-show {
        -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
        -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
        -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
    }

    .tooltipster-base {
        display: flex;
        pointer-events: none;
        position: absolute;
    }

    .tooltipster-box {
        flex: 1 1 auto;
    }

    .tooltipster-content {
        box-sizing: border-box;
        max-height: 100%;
        max-width: 100%;
        overflow: auto;
    }

    .tooltipster-ruler {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: fixed;
        right: 0;
        top: 0;
        visibility: hidden;
    }

    .tooltipster-fade {
        opacity: 0;
        -webkit-transition-property: opacity;
        -moz-transition-property: opacity;
        -o-transition-property: opacity;
        -ms-transition-property: opacity;
        transition-property: opacity;

        &.tooltipster-show {
            opacity: 1;
        }
    }

    .tooltipster-grow {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        transform: scale(0, 0);
        -webkit-transition-property: -webkit-transform;
        -moz-transition-property: -moz-transform;
        -o-transition-property: -o-transform;
        -ms-transition-property: -ms-transform;
        transition-property: transform;
        -webkit-backface-visibility: hidden;

        &.tooltipster-show {
            -webkit-transform: scale(1, 1);
            -moz-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            transform: scale(1, 1);
            -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
            transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
        }
    }

    .tooltipster-swing {
        opacity: 0;
        -webkit-transform: rotateZ(4deg);
        -moz-transform: rotateZ(4deg);
        -o-transform: rotateZ(4deg);
        -ms-transform: rotateZ(4deg);
        transform: rotateZ(4deg);
        -webkit-transition-property: -webkit-transform, opacity;
        -moz-transition-property: -moz-transform;
        -o-transition-property: -o-transform;
        -ms-transition-property: -ms-transform;
        transition-property: transform;

        &.tooltipster-show {
            opacity: 1;
            -webkit-transform: rotateZ(0);
            -moz-transform: rotateZ(0);
            -o-transform: rotateZ(0);
            -ms-transform: rotateZ(0);
            transform: rotateZ(0);
            -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 1);
            -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
            -moz-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
            -ms-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
            -o-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
            transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
        }
    }

    .tooltipster-fall {
        -webkit-transition-property: top;
        -moz-transition-property: top;
        -o-transition-property: top;
        -ms-transition-property: top;
        transition-property: top;
        -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
        transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);

        &.tooltipster-initial {
            top: 0 !important;
        }

        &.tooltipster-dying {
            -webkit-transition-property: all;
            -moz-transition-property: all;
            -o-transition-property: all;
            -ms-transition-property: all;
            transition-property: all;
            top: 0 !important;
            opacity: 0;
        }
    }

    .tooltipster-slide {
        -webkit-transition-property: left;
        -moz-transition-property: left;
        -o-transition-property: left;
        -ms-transition-property: left;
        transition-property: left;
        -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
        -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
        -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
        -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
        transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);

        &.tooltipster-initial {
            left: (-40px) !important;
        }

        &.tooltipster-dying {
            -webkit-transition-property: all;
            -moz-transition-property: all;
            -o-transition-property: all;
            -ms-transition-property: all;
            transition-property: all;
            left: 0 !important;
            opacity: 0;
        }
    }

    @keyframes tooltipster-fading {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .tooltipster-update-fade {
        animation: tooltipster-fading 0.4s;
    }

    @keyframes tooltipster-rotating {
        25% {
            transform: rotate(-2deg);
        }

        75% {
            transform: rotate(2deg);
        }

        100% {
            transform: rotate(0);
        }
    }

    .tooltipster-update-rotate {
        animation: tooltipster-rotating 0.6s;
    }

    @keyframes tooltipster-scaling {
        50% {
            transform: scale(1.1);
        }

        100% {
            transform: scale(1);
        }
    }

    .tooltipster-update-scale {
        animation: tooltipster-scaling 0.6s;
    }

    .tooltipster-sidetip {
        .tooltipster-box {
            background: $tooltipster-background;
            border: $tooltipster-border;
            border-radius: $tooltipster-border-radius;
        }

        &.tooltipster-bottom .tooltipster-box {
            margin-top: $tooltipster-arrow-size - $tooltipster-border-size;
        }

        &.tooltipster-left .tooltipster-box {
            margin-right: $tooltipster-arrow-size - $tooltipster-border-size;
        }

        &.tooltipster-right .tooltipster-box {
            margin-left: $tooltipster-arrow-size - $tooltipster-border-size;
        }

        &.tooltipster-top .tooltipster-box {
            margin-bottom: $tooltipster-arrow-size - $tooltipster-border-size;
        }

        .tooltipster-content {
            color: $tooltipster-color;
            font-size: $tooltipster-font-size;
            line-height: $tooltipster-line-height;
            padding: $tooltipster-padding;
        }

        .tooltipster-arrow {
            overflow: hidden;
            position: absolute;
        }

        &.tooltipster-bottom .tooltipster-arrow {
            height: $tooltipster-arrow-size;
            margin-left: -1 * $tooltipster-arrow-size;
            top: 0;
            width: 2 * $tooltipster-arrow-size;
        }

        &.tooltipster-left .tooltipster-arrow {
            height: 2 * $tooltipster-arrow-size;
            margin-top: -1 * $tooltipster-arrow-size;
            right: 0;
            top: 0;
            width: $tooltipster-arrow-size;
        }

        &.tooltipster-right .tooltipster-arrow {
            height: 2 * $tooltipster-arrow-size;
            margin-top: -1 * $tooltipster-arrow-size;
            left: 0;
            top: 0;
            width: $tooltipster-arrow-size;
        }

        &.tooltipster-top .tooltipster-arrow {
            bottom: 0;
            height: $tooltipster-arrow-size;
            margin-left: -1 * $tooltipster-arrow-size;
            width: 2 * $tooltipster-arrow-size;
        }

        .tooltipster-arrow-background,
        .tooltipster-arrow-border {
            height: 0;
            position: absolute;
            width: 0;
        }

        .tooltipster-arrow-background {
            border: $tooltipster-arrow-size solid transparent;
        }

        &.tooltipster-bottom .tooltipster-arrow-background {
            border-bottom-color: $tooltipster-background;
            left: 0;
            top: $tooltipster-border-size;
        }

        &.tooltipster-left .tooltipster-arrow-background {
            border-left-color: $tooltipster-background;
            left: -1 * $tooltipster-border-size;
            top: 0;
        }

        &.tooltipster-right .tooltipster-arrow-background {
            border-right-color: $tooltipster-background;
            left: $tooltipster-border-size;
            top: 0;
        }

        &.tooltipster-top .tooltipster-arrow-background {
            border-top-color: $tooltipster-background;
            left: 0;
            top: -1 * $tooltipster-border-size;
        }

        .tooltipster-arrow-border {
            border: $tooltipster-arrow-size solid transparent;
            left: 0;
            top: 0;
        }

        &.tooltipster-bottom .tooltipster-arrow-border {
            border-bottom-color: $tooltipster-border-color;
        }

        &.tooltipster-left .tooltipster-arrow-border {
            border-left-color: $tooltipster-border-color;
        }

        &.tooltipster-right .tooltipster-arrow-border {
            border-right-color: $tooltipster-border-color;
        }

        &.tooltipster-top .tooltipster-arrow-border {
            border-top-color: $tooltipster-border-color;
        }

        .tooltipster-arrow-uncropped {
            position: relative;
        }

        &.tooltipster-bottom .tooltipster-arrow-uncropped {
            top: -1 * $tooltipster-arrow-size;
        }

        &.tooltipster-right .tooltipster-arrow-uncropped {
            left: -1 * $tooltipster-arrow-size;
        }
    }
}
