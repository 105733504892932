// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.app-header__logo {
    min-width: 167px;
    height: 53px;
    background: url('/assets/images/logo.png') no-repeat left center {
        size: contain;
    }
    margin-right: 15px;
    overflow: hidden;
    text-indent: -999px;
    align-self: center;
}

.app-header__logo--subtitle {
    min-width: 168px;
    background-image: url('/assets/images/logo-subtitle.png');
    text-indent: 0;
    padding: {
        top: 31px;
        left: 62px;
    }
    height: 53px;
    line-height: 1;
    font-size: 1.4rem;
    color: $color-brand;
    font: {
        weight: 400;
        family: $font-family-secondary;
    }
}

.apps-list {
    font: {
        size: 1.6rem;
    }
    text-align: center;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        border-top: 1px solid $color-border;
        margin: 0;

        &:first-child {
            border-top: none;
        }
    }

    a {
        padding: 15px 0;
        line-height: 2rem;
        display: block;
    }
}

sbm-mobile-menu {
    display: none;
}

@include media-query-max($mobile-breakpoint) {
    sbm-mobile-menu {
        display: block;
    }
}
