.chart-400 {
    canvas {
        height: 400px !important;
    }
}

.divider {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid $color-border;
}

.cursor-pointer {
    cursor: pointer;
}

.text-line-through {
    text-decoration: line-through;
}

.ordered-rows {
    display: flex;
    flex-direction: column;

    .ordered-rows__end {
        order: 99999;
    }
}

.video-wrapper {
    display: block;
    width: 100%;
    max-width: 600px;
    margin: 15px 0;

    .video-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        display: block;
        padding-top: 56.25%;
    }

    iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.fancy-amount {
    font-size: 10px;
    line-height: 2em;
    font-weight: 400;
    //overflow: hidden;
    //padding: 3px;

    .float-right & {
        position: relative;
        top: -4px;
    }

    &.amount-minus {
        color: $color-danger;
    }

    .value-wrapper {
        font-size: 2.4em;
    }

    .decimal-wrapper {
        font-size: 1.4em;
        line-height: 1;
        width: 2.1em;
        position: relative;
        text-align: right;
        top: -0.5em;
    }

    .currency-wrapper {
        font-size: 0.9em;
        line-height: 1;
        position: relative;
        top: 2px;
        margin-left: -1.85em;
        min-width: 17px;
        display: inline-block;
    }
}

.date-with-icon {
    padding-left: 40px;
    position: relative;

    @include styleFontIcon() {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        font-size: 2.5rem;
    }
}

.profile-intro {
    text-align: center;
    margin-bottom: 30px;

    &.profile-intro--leader {
        img {
            border-radius: 10px 10px 50% 50%;
        }
    }

    &.profile-intro--player {
        img {
            border-radius: 50%;
        }
    }

    img {
        height: 180px;
        border-radius: 5px;
        box-shadow: 0 2px 5px 2px rgba(#000, 0.25);
    }

    .profile-intro__image {
        position: relative;
        margin: auto;
        height: 180px;
        max-width: 60%;
        display: inline-block;

        .action {
            position: absolute;
            bottom: 5%;
            right: 5%;
        }
    }

    h3 {
        font: {
            size: 2.4rem;
            weight: 300;
        }

        small {
            font-size: 1.6rem;
            font-weight: 300;
            font-style: italic;
            display: block;
            color: $color-tertiary;
        }
    }

    .profile__role {
        margin-top: -5px;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: $color-tertiary;
    }
}

.user-tile {
    display: flex;
}

.user-tile__image {
    margin-right: 15px;

    .image-wrapper {
        display: block;
        position: relative;
    }

    .user-tile__age {
        position: absolute;
        bottom: 2px;
        left: 2px;
        background: $color-primary;
        color: color-contrast($color-primary);
        font-size: 1.1rem;
        padding: 3px;
        border-radius: 4px;
    }

    &,
    & img {
        width: 90px;
    }

    img {
        box-shadow: 0 1px 3px 1px rgba(#000, 0.1);
    }

    .user-tile--large & {
        width: 180px;
        margin-right: 30px;
    }

    .user-tile--leader & {
        img {
            border-radius: 10px 10px 50% 50%;
        }
    }

    .user-tile--player & {
        img {
            border-radius: 50%;
        }
    }

    .user-tile--group & {
        img {
            border-radius: 5px;
        }
    }
}

.user-tile__content {
    flex: 1;
    padding: 12px 0 0;
}

.user-tile__values {
    margin: -10px -15px;
    display: flex;
    padding-top: 10px;

    & > * {
        margin: 10px 15px;
    }
}

.user-tile__value {
    position: relative;
    padding-left: 40px;
    font-size: 1.8rem;

    @include styleFontIcon() {
        font-size: 3rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        color: $color-tertiary;
    }
}

.shortcuts {
    margin: 20px 0;

    &:last-child {
        margin-bottom: 0;
    }

    &:first-child {
        margin-top: 0;
    }
}

.shortcut-item {
    background: #fff;
    padding: 30px 15px 30px 75px;
    border: 1px solid $color-border;
    position: relative;
    font-size: 1.6rem;
    transition: background-color 0.1s linear;
    border-radius: 3px;
    display: block;

    &.active,
    &:hover {
        background: darken(#fff, 5%);
    }

    @include styleFontIcon() {
        font-size: 30px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        color: $color-primary;
    }
}

.pinned-scrollable-bar {
    padding: 8px 0;
    transition: box-shadow 0.2s linear;
    box-shadow: 0 0 0 0 rgba(#000, 0);

    &.pinned {
        background: #fff;
        position: fixed;
        top: 0;
        z-index: 10;
        box-shadow: 0 9px 5px -6px rgba(#000, 0.2);
    }
}

.package-box {
    .box-simple__content {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        height: 100%;
    }
}

.campaign {
    &.campaign--is-highlighted .card {
        border: 5px solid $color-lime;
    }

    .campaign-image {
        img {
            display: block;
            margin: 0 auto 15px;
        }
    }
}
.campaign-actions {
    display: flex;
    align-items: flex-end;

    & > * {
        flex: 1;
    }

    .campaign-actions__cta {
        text-align: right;
    }
}

@include media-query-max($breakpoint-md) {
    .pinned-scrollable-bar {
        position: static !important;
        box-shadow: none !important;
    }

    .profile-intro {
        padding-top: 20px;

        .profile-intro__image {
            height: 120px;
        }

        img {
            height: 120px;
        }

        h3 {
            font: {
                size: 1.8rem;
            }
        }
    }
}
