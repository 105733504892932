.dashboard-boxes {
    min-height: 600px;
}

.dashboard-multi-boxes {
    height: 100%;
    display: flex;
    flex-direction: column;

    .card {
        flex: 1;
    }

    .height-fill {
        flex: 1;
    }
}

.special-offer-box {
    border-color: $color-warning;
    border-width: 3px;
    border-left-width: 10px;
    font: {
        size: 16px;
        weight: 700;
    }
    position: relative;

    .alert__icon {
        color: $color-warning;
        padding-top: ($alert-min-height - 4px - strip-unit($alert-icon-size)) / 2;
    }

    a {
        color: inherit;
    }

    .special-offer__logo {
        padding: 6px 10px;
        display: flex;
        align-items: center;

        img {
            display: block;
            max-height: 50px;
            max-width: 100px;
        }
    }
}
