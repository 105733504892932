.dnd-box,
.dnd-item {
    margin: 5px 0;
    border: 1px solid $color-border;
    background: $background-secondary;
    padding: 15px 20px;
    cursor: move;

    &:not(.has-handle):not(.move-disabled),
    &.has-handle [ngxdraghandle],
    &.has-handle [ngxDragHandle] {
        cursor: move;
    }

    .dnd-content {
        user-select: none;
    }

    &:hover {
        border: 1px solid darken($color-border, 15%);
    }
}

.dnd-box {
    height: 40px;
    width: 40px;
    line-height: 20px;
    text-align: center;
    float: left;
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging {
    .dnd-box,
    .dnd-item {
        &:not(.cdk-drag-placeholder) {
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
        }
    }
}

