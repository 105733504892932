.conversation-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 30px 0;

    .author {
        font-size: 12px;
        color: $color-tertiary;
        margin-bottom: 10px;
    }

    .message {
        position: relative;
        background: $background-secondary;
        border: 1px solid $background-secondary;
        border-radius: 5px;
        line-height: 1.5;
        padding: 10px 15px;
        max-width: 90%;

        &:before {
            content: "";
            display: block;
            position: absolute;
            @include makeArrow(up, $background-secondary, 10px, 10px);
            top: -10px;
            left: 5px;
        }
    }

    &.user-message {
        align-items: flex-end;
        justify-content: flex-end;

        .message {
            background: $background-quaternary;
            border: 1px solid $background-quaternary;
            border-radius: 3px;

            &:before {
                content: "";
                display: block;
                position: absolute;
                @include makeArrow(up, $background-quaternary, 10px, 10px);
                top: -10px;
                left: auto;
                right: 5px;
            }
        }
    }
}
