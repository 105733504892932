.shepherd-button {
    @extend .btn;
    @extend .btn--small;
    @extend .btn--primary;
}

.shepherd-button-secondary {
    @extend .btn;
    @extend .btn--small;
    @extend .btn--secondary;
}

.shepherd-button.cancel-button {
    @extend .btn--outline;
}

.shepherd-footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px dashed $color-border;
    padding: 15px 0;
    margin: 0 15px;

    .shepherd-button {
        margin: 0 5px;
    }

    .shepherd-button:last-child {
        margin-right: 0;
    }
}

.shepherd-cancel-icon {
    background: transparent;
    border: none;
    color: $color-tertiary;
    font-size: 3rem;
    line-height: 2rem;
    cursor: pointer;
    font-weight: 400;
    margin: 5px;
    padding: 0;
    transition: color 0.1s linear;

    &:hover {
        color: lighten($color-tertiary, 10%);
    }
}

.shepherd-title {
    display: flex;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    flex: 1 0 auto;
    margin: 0;
    padding: 0;
}

.shepherd-header {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.shepherd-has-title .shepherd-content .shepherd-header {
    border-bottom: 1px dashed $color-border;
    margin: 0 15px;
    padding: 15px 0;
}

.shepherd-text {
    font-size: 1.4rem;
    line-height: 1.5em;
    padding: 15px;

    p {
        margin-top: 0;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.shepherd-content {
    outline: none;
    padding: 0;
}

.shepherd-element {
    background: $modal-background;
    border-radius: $modal-border-radius;
    overflow: hidden;
    width: 400px;
    opacity: 0;
    outline: none;
    transition: opacity 0.3s;
    z-index: 9999;
}

.shepherd-enabled.shepherd-element {
    opacity: 1;
}

.shepherd-element {
    box-sizing: border-box;
    box-shadow: 0 0 10px 5px rgba(#333, 0.4);

    *,
    :after,
    :before {
        box-sizing: border-box;
    }

    .shepherd-arrow {
        border: 16px solid transparent;
        content: '';
        display: block;
        height: 16px;
        pointer-events: none;
        position: absolute;
        width: 16px;
        z-index: 10000;
    }

    &.shepherd-element-attached-bottom.shepherd-element-attached-center .shepherd-arrow {
        bottom: 0;
        border-top-color: #fff;
        left: 50%;
        transform: translate(-50%, 100%);
    }

    &.shepherd-element-attached-top.shepherd-element-attached-center {
        .shepherd-arrow {
            border-bottom-color: #fff;
            left: 50%;
            top: 0;
            transform: translate(-50%, -100%);
        }

        &.shepherd-has-title .shepherd-arrow {
            border-bottom-color: #e6e6e6;
        }
    }

    &.shepherd-element-attached-middle {
        &.shepherd-element-attached-left .shepherd-arrow {
            border-right-color: #fff;
            left: 0;
            top: 50%;
            transform: translate(-100%, -50%);
        }

        &.shepherd-element-attached-right .shepherd-arrow {
            border-left-color: #fff;
            right: 0;
            top: 50%;
            transform: translate(100%, -50%);
        }
    }
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target {
    pointer-events: none;

    * {
        pointer-events: none;
    }
}

.shepherd-modal-overlay-container {
    -ms-filter: progid:dximagetransform.microsoft.gradient.alpha(Opacity=50);
    filter: alpha(opacity=50);
    fill-rule: evenodd;
    height: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
    width: 100vw;
    z-index: 9997;

    &.shepherd-modal-is-visible {
        height: 100vh;
        opacity: 0.5;
        transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;

        path {
            pointer-events: all;
        }
    }
}
