@mixin styleFontIcon() {
    [class^='#{$font-prefix}'],
    [class*=' #{$font-prefix}'] {
        @content;
    }
}

@function unicode($str) {
    @return unquote('"\\') + $str + unquote('"');
}

@function getIconCode($name) {
    $code: map_get($icons, $name);

    @return unicode($code);
}

@mixin generateIcon($name, $code) {
    .#{$font-prefix}#{$name}::before {
        content: unicode($code);
    }
}
