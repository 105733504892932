$modal-overlay-background: rgba(#000, 0.8) !default;
$modal-overlay-padding-top: 50px !default;
$modal-overlay-padding-bottom: 50px !default;

$modal-max-width: 700px !default;
$modal-background: #fff !default;
$modal-border-radius: 4px !default;
$modal-padding: 15px 0 !default;

$modal-close-right: 10px !default;
$modal-close-top: 10px !default;
$modal-close-content: 'X' !default;

$modal-title-padding: 15px 30px !default;
$modal-title-font-size: 24px !default;
$modal-title-font-weight: 700 !default;
$modal-title-line-height: 1.3 !default;

$modal-content-padding: 15px 30px !default;
