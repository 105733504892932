.targets-list {
    margin: -1 * $outer-margin;

    & > * {
        padding: $outer-margin;
    }
}

.exercises-list {
    margin: -1 * $outer-margin;

    & > * {
        padding: $outer-margin;
    }
}

.exercise-box {
    padding: 0;
    overflow: hidden;

    img {
        display: block;
    }

    .exercise-categories {
        display: block;
        color: $color-tertiary;
        font-size: 1.1rem;
        margin-top: 3px;
    }

    .exercise-details {
        padding: 15px;
    }

    .exercise-source {
        position: absolute;
        background: $background-tertiary;
        color: $color-inverse;
        padding: 5px 10px;
        line-height: 1;
        top: 0;
        right: 0;
        font-size: 1rem;
    }

    .exercise-sport {
        position: absolute;
        background: $background-secondary;
        color: $color-secondary;
        padding: 5px 10px;
        line-height: 1;
        top: 0;
        left: 0;
        font-size: 1rem;
    }
}
