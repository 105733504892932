.accordion-item {
    padding: $accordion-padding;
    border-bottom: $accordion-border;
    margin-top: $accordion-margin-top;
    transition: background-color 0.1s linear;

    &:first-child {
        margin-top: 0;
    }

    &.opened .accordion-item__header,
    .accordion-item__header:hover {
        color: $accordion-active-color;
    }

    &.opened {
        .highlight-opened & {
            background: $accordion-highlight-opened-color;
        }
        .trigger-angle {
            @include styleFontIcon() {
                display: inline-block;
                transform: rotate(-180deg);
            }
        }
    }
}

.accordion-item__header {
    transition: color 0.2s linear;
    position: relative;
    padding: $accordion-header-padding;
    cursor: pointer;

    .trigger-angle {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        user-select: none;
        font-size: $accordion-arrow-size;

        @include styleFontIcon() {
            display: block;
            width: 1em;
            height: 1em;
            font-size: 1em;
            transition: transform 0.1s linear;
        }
    }
}

.accordion-item__body {
    display: none;
    padding: $accordion-body-padding;
    font-size: $accordion-body-font-size;
}
