/// @group Cards
$card-padding: 30px !default;
/// @group Cards
$card-background: #ffffff !default;
/// @group Cards
$card-border-radius: 4px !default;
/// @group Cards
$card-box-shadow: 0px 0px 5px rgba(#000, 0.2) !default;
/// @group Cards
$card-margin-bottom: $gutter-width !default;

/// @group Cards
$card-icon-size: 3.3rem !default;
/// @group Cards
$card-icon-padding: 50px !default;
$card-options-padding: 70px !default;

/// @group Cards
$card-header-title-font-size: 2rem !default;
/// @group Cards
$card-header-title-line-height: 1.2 !default;
/// @group Cards
$card-header-subtitle-color: $color-tertiary !default;
/// @group Cards
$card-header-subtitle-font-size: 1.2rem !default;
/// @group Cards
$card-header-subtitle-line-height: 1.4rem !default;
$card-header-subtitle-margin-top: 2px !default;
/// @group Cards
$card-transparent-footer-border: 1px solid $color-border !default;
