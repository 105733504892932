.box-stats {
    margin: -1 * $box-stats-spacing;
    display: flex;
    flex-wrap: wrap;
}

.box-stats__item {
    flex: 1;
    flex-basis: $box-stats-min-width;
    margin: $box-stats-spacing;
    background: $background-secondary;
    padding: $box-stats-padding;
    text-align: center;
    position: relative;
    border-radius: $box-stats-border-radius;
    font: {
        size: $box-stats-font-size;
    }
    line-height: $box-stats-line-height;
    color: $color-tertiary;

    .box-stats--small & {
        padding: $box-stats-small-padding;
    }

    a {
        color: $color-tertiary;
    }

    .box-stats--col-3 & {
        flex-basis: calc(#{100% / 3} - #{4 * $box-stats-spacing});
    }

    .box-stats--col-4 & {
        flex-basis: calc(#{100% / 4} - #{6 * $box-stats-spacing});
    }

    .box-stats--col-5 & {
        flex-basis: calc(#{100% / 5} - #{8 * $box-stats-spacing});
    }

    @include background-colors(true);

    &.with-border {
        border: $box-stats-border;
    }

    strong,
    .value {
        display: block;
        line-height: 1;

        &.fancy-amount {
            font-size: 1.4rem;
            min-height: 4.4rem;
            padding-top: 0.6rem;
        }

        @include foreground-colors();

        .box-stats--small & {
            &.fancy-amount {
                font-size: 1.333333rem;
            }
        }
    }

    strong {
        font: {
            size: $box-stats-number-size;
            weight: $box-stats-number-weight;
        }
        margin-bottom: 0.07em;

        .box-stats--small & {
            font-size: $box-stats-small-number-size;
        }
    }

    .value {
        font: {
            size: $box-stats-value-size;
            weight: $box-stats-value-weight;
        }
        margin-bottom: 0.3em;

        .box-stats--small & {
            font-size: $box-stats-small-value-size;
        }
    }

    .corner-link {
        position: absolute;
        top: $box-stats-corner-link-top;
        right: $box-stats-corner-link-right;
        font: {
            size: $box-stats-corner-link-size;
        }
        color: $color-primary;
        z-index: 2;
    }
}

@include media-query-max-min($breakpoint-lg, $breakpoint-md) {
    .box-stats--small .box-stats__item strong {
        font-size: 2.4rem;

        &.fancy-amount {
            font-size: 1rem;
        }
    }
}
