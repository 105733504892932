@each $name, $props in $background-colors {
    .bg-#{$name} {
        background: map_get($props, base);
        color: map_get($props, contrast);
    }
}

@each $name, $props in $colors {
    .text-#{$name} {
        color: map_get($props, base);
    }
}
