$typography-blocks-default-margin: 10px 0 !default;

$typography-lists-margin: 15px 0 15px 20px !default;
$typography-lists-small-margin: 10px 0 10px 13px !default;
$typography-lists-nested-margin: 0 0 0 30px !default;
$typography-lists-element-margin: 5px 0 !default;

$typography-treeview-border: 1px solid $color-primary !default;
$typography-treeview-nested-margin: -7px 0 -7px -15px !default;
$typography-treeview-item-padding: 7px 0 7px 35px !default;
$typography-treeview-line-color: $color-primary !default;
$typography-treeview-line-width: 20px !default;
$typography-treeview-line-height: 1px !default;

$typography-blocks: (
    'h1, .heading1': (
        font-size: 4.4rem,
        font-weight: 600,
        font-family: $font-family,
        line-height: 1.136363,
        margin: 30px 0,
        text-transform: none,
    ),
    'h2, .heading2': (
        font-size: 2rem,
        font-weight: 400,
        font-family: $font-family-secondary,
        line-height: 1.2,
        text-transform: uppercase,
        margin: 20px 0,
    ),
    'h3, .heading3': (
        font-size: 1.6rem,
        font-weight: 400,
        font-family: $font-family-secondary,
        line-height: 1.25,
        text-transform: none,
        margin: 15px 0,
    ),
    'h4, .heading4': (
        font-size: 1.2rem,
        font-weight: 600,
        font-family: $font-family-secondary,
        line-height: 1.166667,
        text-transform: uppercase,
        margin: 10px 0,
    ),
    'h5, .heading5': (
        font-size: 1.4rem,
        font-weight: 400,
        font-family: $font-family-secondary,
        line-height: 1.142857,
        text-transform: uppercase,
        margin: 10px 0,
    ),
    'h6, .heading6': (
        font-size: 1.2rem,
        font-weight: 600,
        font-family: $font-family-secondary,
        line-height: 1.166667,
        text-transform: uppercase,
        margin: 10px 0,
    ),
    'p.lead': (
        font-size: 1.6rem,
        font-weight: 600,
    ),
    '.text-big': (
        font-size: 1.4rem,
        line-height: 1.384615,
    ),
    '.text-small': (
        font-size: 1.3rem,
        line-height: 1.333333,
    ),
) !default;
