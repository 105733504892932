$loader-margin: 20px auto !default;
$loader-size: 64px !default;
$loader-width: 6px !default;
$loader-color: $color-primary !default;
$loader-color-inverse: color-inverse($color-primary) !default;

$loader-small-size: 44px !default;
$loader-small-width: 5px !default;

$loader-sizes: (
    small: (
        size: 44px,
        width: 5px,
    ),
    form: (
        size: 44px,
        width: 5px,
    ),
    popover: (
        size: 41px,
        width: 5px,
    ),
    alert: (
        size: 44px,
        width: 5px,
    ),
    xsmall: (
        size: 34px,
        width: 4px,
    ),
    links: (
        size: 38px,
        width: 4px,
    ),
    icon-md: (
        size: 26.5px,
        width: 3px,
    ),
    paragraph: (
        size: 22px,
        width: 3px,
    ),
    results: (
        size: 17px,
        width: 2px,
    ),
    large: (
        size: 96px,
        width: 8px,
    ),
) !default;
