$side-navigation-width: 250px !default;
//$side-navigation-width: 120px !default;
$side-navigation-width-mobile: 60px;
$side-navigation-background: #3b444e !default;
$side-navigation-font-family: $font-family-secondary !default;
$side-navigation-font-size: 1.2rem !default;
$side-navigation-font-weight: 400 !default;
$side-navigation-link-padding-x: 20px !default;
//$side-navigation-link-padding-x: 15px !default;
$side-navigation-link-padding-y: 15px !default;
//$side-navigation-link-padding-y: 10px !default;
$side-navigation-link-line-height: 20px !default;
$side-navigation-link-border-size: 5px !default;
$side-navigation-link-border: $side-navigation-link-border-size solid transparent !default;
$side-navigation-link-color: #c1c4c8 !default;
$side-navigation-link-color-hover: #ffffff !default;
$side-navigation-link-icon-size: 20px !default;
