.box-detailed-heading {
    align-items: center;
    background: $box-detailed-heading-background;
    border-radius: $box-detailed-heading-border-radius;
    color: $box-detailed-heading-color;
    display: flex;
    flex-wrap: wrap;
    padding: $box-detailed-heading-padding;

    a {
        color: $box-detailed-heading-color;

        &:hover {
            color: darken($box-detailed-heading-color, 15%);
        }
    }

    .item {
        align-self: center;
        margin: $box-detailed-heading-item-margin;
        max-width: $box-detailed-heading-item-min-width;
        position: relative;

        &.item--with-icon {
            padding-left: $box-detailed-heading-item-icon-padding;
        }
    }

    .item--wide {
        max-width: $box-detailed-heading-item-min-width-wide;
    }

    .item__title {
        font: {
            size: $box-detailed-heading-item-title-size;
            weight: 600;
        }
        word-break: break-word;
    }

    .item__label {
        display: block;
        font: {
            size: $box-detailed-heading-item-label-size;
        }
    }

    .form-checkbox {
        margin-top: 0;

        input:checked + .custom-checkbox__layer,
        .custom-checkbox__layer {
            background: inherit;
        }
    }

    .form-radio {
        margin-top: 0;

        &:checked + .custom-radio__layer,
        .custom-radio__layer {
            background: inherit;
        }
    }

    @include styleFontIcon() {
        color: $color-border;
        font: {
            size: $box-detailed-heading-icon-size;
        }
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}
