/* position */
.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-left {
    top: 12px;
    left: 12px;
}

.toast-top-right {
    top: 12px;
    right: 12px;
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

/* toast styles */
.toast-title {
    font-weight: bold;
}

.toast-message {
    word-wrap: break-word;
}

.toast-message a,
.toast-message label {
    color: inherit;
}

.toast-message a:hover {
    color: inherit;
    opacity: 0.8;
    text-decoration: none;
}

.toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: inherit;
}

.toast-close-button:hover,
.toast-close-button:focus {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
}

.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;
    padding-top: 6px;
}

.toast-container * {
    box-sizing: border-box;
}

.toast-container .ngx-toastr {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 100%;
    max-width: 600px;
    min-width: 300px;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(#000, 0.3);
    display: flex;
    align-items: center;
    transition: all 0.2s linear;
}

.toast-container .ngx-toastr:hover {
    box-shadow: 0 0 5px rgba(#000, 0.5);
    opacity: 1;
    cursor: pointer;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
    margin-left: auto;
    margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
    width: 96%;
    min-width: 0;
    max-width: none;
    margin-left: auto;
    margin-right: auto;
}

.ngx-toastr {
    background: $alert-background;
    border: 2px solid $color-border {
        left-width: $alert-border-left-width;
    }
    border-radius: $alert-border-radius;
    font: {
        size: $alert-font-size;
    }
    min-height: $alert-min-height;
    pointer-events: auto;

    &:before {
        color: $alert-icon-color;
        font: {
            size: $alert-icon-size;
            family: $font-icon;
        }
        line-height: 1;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
    }
}

.toast-info {
    color: $color-info;
    border-color: $color-info;

    &:before {
        color: $color-info;
        content: getIconCode(alert-info);
    }
}

.toast-error {
    color: $color-danger;
    border-color: $color-danger;

    &:before {
        color: $color-danger;
        content: getIconCode(alert-error);
    }
}

.toast-success {
    color: $color-success;
    border-color: $color-success;

    &:before {
        color: $color-success;
        content: getIconCode(alert-success);
    }
}

.toast-warning {
    color: $color-warning;
    border-color: $color-warning;

    &:before {
        color: $color-warning;
        content: getIconCode(alert-warning);
    }
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 11em;
    }
    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container .ngx-toastr.div {
        padding: 8px 8px 8px 50px;
        width: 18em;
    }
    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

@media all and (min-width: 481px) and (max-width: 768px) {
    .toast-container .ngx-toastr.div {
        padding: 15px 15px 15px 50px;
        width: 25em;
    }
}
