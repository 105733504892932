@mixin makeArrow($direction, $color, $width, $height) {
    width: 0;
    height: 0;
    @if $direction == down {
        border: {
            top: $width solid $color;
            right: $height solid transparent;
            bottom: none;
            left: $height solid transparent;
        }
    } @else if $direction == up {
        border: {
            top: none;
            right: $height solid transparent;
            bottom: $width solid $color;
            left: $height solid transparent;
        }
    } @else if $direction == right {
        border: {
            top: $height solid transparent;
            right: none;
            bottom: $height solid transparent;
            left: $width solid $color;
        }
    } @else if $direction == left {
        border: {
            top: $height solid transparent;
            right: $width solid $color;
            bottom: $height solid transparent;
            left: none;
        }
    }
}
