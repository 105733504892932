.t-break-all {
    word-break: break-all;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
    margin: $typography-blocks-default-margin;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.keep-together {
    word-break: keep-all;
    white-space: nowrap;
    display: inline-block;
}

@each $block-selectors, $block-props in $typography-blocks {
    #{$block-selectors} {
        @each $prop-name, $prop-value in $block-props {
            #{$prop-name}: $prop-value;
        }
    }
}

.heading-border {
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 1px solid lighten($color-border, 5%);
    margin: {
        left: -5px;
        right: 5px;
    }
}

ul,
ol {
    margin: $typography-lists-margin;
    padding: 0;

    &.small-list {
        margin: $typography-lists-small-margin;
    }

    li {
        margin: $typography-lists-element-margin;
    }

    ul,
    ol {
        margin: $typography-lists-nested-margin;
    }

    &.treeview {
        border-left: $typography-treeview-border;
        list-style: none;
        margin-left: 0;

        .treeview {
            margin: $typography-treeview-nested-margin;
            padding: 0;
        }

        li {
            position: relative;
            padding: $typography-treeview-item-padding;

            &::before {
                position: absolute;
                width: $typography-treeview-line-width;
                height: $typography-treeview-line-height;
                background: $typography-treeview-line-color;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                content: '';
                display: block;
            }
        }
    }
}

.list-no-bullets {
    list-style: none;
    margin: 0;
    padding: 0;
}

.list-horizontal {
    display: flex;
    flex-wrap: wrap;
    margin: -5px -15px;

    li {
        margin: 5px 15px;
    }
}

p.large {
    font-size: 1.5rem;
}

.text-large {
    font-size: 1.6rem;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-strong {
    font-weight: bold;
}

.text-muted {
    opacity: 0.4;
}

.text-with-icon {
    position: relative;
    padding: {
        bottom: 3px;
        left: 30px;
        top: 3px;
    }

    @include styleFontIcon() {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        font-size: 1.8rem;
    }
}

//@each $breakpoint in $breakpoints {
//    $name: nth($breakpoint, 1);
//    $size: nth($breakpoint, 2);
//    $container: nth($breakpoint, 3);
//    @media only screen and (min-width: $size) {
//
//    }
//}
