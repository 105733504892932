.d-block {
    display: block;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: flex-end;
}

.align-self-center {
    align-self: center;
}

.align-self-end {
    align-self: flex-end;
}

@each $padding in $paddings {
    .pd-#{strip-unit($padding)} {
        padding: $padding;
    }

    .pd-hor-#{strip-unit($padding)},
    .pd-lr-#{strip-unit($padding)},
    .pd-x-#{strip-unit($padding)} {
        padding: {
            left: $padding;
            right: $padding;
        }
    }

    .pd-ver-#{strip-unit($padding)},
    .pd-tb-#{strip-unit($padding)},
    .pd-y-#{strip-unit($padding)} {
        padding: {
            bottom: $padding;
            top: $padding;
        }
    }

    .pd-t-#{strip-unit($padding)} {
        padding-top: $padding;
    }

    .pd-b-#{strip-unit($padding)} {
        padding-bottom: $padding;
    }

    .pd-l-#{strip-unit($padding)} {
        padding-left: $padding;
    }

    .pd-r-#{strip-unit($padding)} {
        padding-right: $padding;
    }
}

@each $margin in $margins {
    .mg-#{strip-unit($margin)} {
        margin: $margin;
    }

    .mg-hor-#{strip-unit($margin)},
    .mg-lr-#{strip-unit($margin)},
    .mg-x-#{strip-unit($margin)} {
        margin: {
            left: $margin;
            right: $margin;
        }
    }

    .mg-ver-#{strip-unit($margin)},
    .mg-tb-#{strip-unit($margin)},
    .mg-y-#{strip-unit($margin)} {
        margin: {
            bottom: $margin;
            top: $margin;
        }
    }

    .mg-t-#{strip-unit($margin)} {
        margin-top: $margin;
    }

    .mg-b-#{strip-unit($margin)} {
        margin-bottom: $margin;
    }

    .mg-l-#{strip-unit($margin)} {
        margin-left: $margin;
    }

    .mg-r-#{strip-unit($margin)} {
        margin-right: $margin;
    }
}

.mg-l-auto {
    margin-left: auto;
}

.mg-r-auto {
    margin-right: auto;
}

.mg-t-auto {
    margin-top: auto;
}

.mg-b-auto {
    margin-bottom: auto;
}

.mg-auto {
    margin: auto;
}

@each $breakpoint in $breakpoints {
    $name: nth($breakpoint, 1);
    $size: nth($breakpoint, 2);

    @media only screen and (min-width: $size) {
        @each $padding in $paddings {
            .pd-#{$name}-#{strip-unit($padding)} {
                padding: $padding;
            }

            .pd-#{$name}-hor-#{strip-unit($padding)} {
                padding: {
                    left: $padding;
                    right: $padding;
                }
            }

            .pd-#{$name}-ver-#{strip-unit($padding)} {
                padding: {
                    bottom: $padding;
                    top: $padding;
                }
            }

            .pd-#{$name}-t-#{strip-unit($padding)} {
                padding-top: $padding;
            }

            .pd-#{$name}-b-#{strip-unit($padding)} {
                padding-bottom: $padding;
            }

            .pd-#{$name}-l-#{strip-unit($padding)} {
                padding-left: $padding;
            }

            .pd-#{$name}-r-#{strip-unit($padding)} {
                padding-right: $padding;
            }
        }

        @each $margin in $margins {
            .mg-#{$name}-#{strip-unit($margin)} {
                margin: $margin;
            }

            .mg-#{$name}-hor-#{strip-unit($margin)} {
                margin: {
                    left: $margin;
                    right: $margin;
                }
            }

            .mg-#{$name}-ver-#{strip-unit($margin)} {
                margin: {
                    bottom: $margin;
                    top: $margin;
                }
            }

            .mg-#{$name}-t-#{strip-unit($margin)} {
                margin-top: $margin;
            }

            .mg-#{$name}-b-#{strip-unit($margin)} {
                margin-bottom: $margin;
            }

            .mg-#{$name}-l-#{strip-unit($margin)} {
                margin-left: $margin;
            }

            .mg-#{$name}-r-#{strip-unit($margin)} {
                margin-right: $margin;
            }
        }

        .mg-#{$name}-l-auto {
            margin-left: auto;
        }

        .mg-#{$name}-r-auto {
            margin-right: auto;
        }

        .mg-#{$name}-t-auto {
            margin-top: auto;
        }

        .mg-#{$name}-b-auto {
            margin-bottom: auto;
        }
    }
}
