.box-dated-note {
    text-align: center;
    margin: $box-dated-note-margin;
    display: block;

    &:first-child {
        margin-top: 0;
    }
}

.box-dated-note__date {
    color: $color-tertiary;
    margin: $box-dated-note-date-margin;
    display: block;
}

.box-dated-note__name {
    color: $color-tertiary;
    margin: $box-dated-note-category-margin;
}
