$tabs-nav-margin-vertical: 5px !default;
$tabs-nav-margin-horizontal: 0 !default;

$tabs-nav-item-padding: 10px 30px !default;
$tabs-nav-item-border-bottom: 2px solid transparent !default;
$tabs-nav-item-font-size: 1.2rem !default;
$tabs-nav-item-font-family: $font-family-secondary !default;
$tabs-nav-item-font-weight: 600 !default;
$tabs-nav-item-line-height: 1.6rem !default;
$tabs-nav-item-color: $color-default !default;
$tabs-nav-item-active-border-color: $color-primary !default;

$tabs-content-padding: 30px 0 !default;

$tabs-nav-bordered-border-color: $color-border !default;
$tabs-nav-bordered-height: 1px !default;
