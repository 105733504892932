/// @group App Header
$app-header-height: 90px !default;
/// @group App Header
$app-header-padding: 0 !default;

/// @group App Header
$app-header-logo-height: 53px !default;
/// @group App Header
$app-header-logo-margin-right: 15px !default;

$app-header-logo-max-width: 120px !default;

/// @group App Header
$app-header-name-border-left: 1px solid $color-border !default;
/// @group App Header
$app-header-name-font-size: 2rem !default;
/// @group App Header
$app-header-name-font-weight: 300 !default;
/// @group App Header
$app-header-name-line-height: 2rem !default;
/// @group App Header
$app-header-name-padding: 7px 0 7px 15px !default;

$app-header-mobile-nav-background: rgba(#000, 0.9) !default;
$app-header-mobile-nav-padding: 30px 0 !default;

$app-header-actions-item-margin: 10px !default;
$app-header-actions-item-padding: 0 10px !default;
$app-header-actions-item-padding-mobile: 0 20px !default;
$app-header-actions-item-icon-size: 2.5rem !default;

$app-header-user-link-border: 1px solid $color-border !default;
$app-header-user-link-min-height: 44px !default;
$app-header-user-link-max-width: 200px !default;
$app-header-user-link-font-size: 1.4rem !default;
$app-header-user-link-line-height: 1.153846 !default;
$app-header-user-link-margin: 0 0 0 0 !default;
$app-header-user-link-padding: 7px 10px 7px 80px !default;
$app-header-user-link-no-image-padding: 7px 10px !default;
$app-header-user-link-img-size: 38px !default;
$app-header-user-link-img-left: 26px !default;

/// @group Subheader
$app-subheader-min-height: 240px !default;
/// @group Subheader
$app-subheader-background: linear-gradient(
    to right,
    $background-gradient-start,
    $background-gradient-end 30%,
    $background-gradient-end 70%,
    $background-gradient-start 100%
) !default;
/// @group Subheader
/// @type Color
$app-subheader-link-color: #ffffff !default;
/// @group Subheader
$app-subheader-margin-bottom: -90px !default;
/// @group Subheader
$app-subheader-padding-top: 15px !default;
/// @group Subheader
$app-subheader-padding-bottom: 180px !default;

/// @group Subheader Navigation
$app-subheader-nav-font-family: $font-family-secondary !default;
/// @group Subheader Navigation
$app-subheader-nav-font-size: 1.2rem !default;
/// @group Subheader Navigation
$app-subheader-nav-font-weight: 400 !default;
/// @group Subheader Navigation
$app-subheader-nav-link-padding: 11px 18px 8px !default;
/// @group Subheader Navigation
$app-subheader-nav-link-line-height: 20px !default;
/// @group Subheader Navigation
$app-subheader-nav-link-border-bottom: 3px solid transparent !default;
/// @group Subheader Navigation
/// @type Color
$app-subheader-nav-link-color: #c1c4c8 !default;
/// @group Subheader Navigation
/// @type Color
$app-subheader-nav-link-color-hover: #ffffff !default;
/// @group Subheader Navigation
$app-subheader-nav-link-icon-size: 18px !default;

/// @group View title
$app-view-title-margin-top: -142px !default;
/// @group View title
$app-view-title-padding: 15px 30px !default;
/// @group View title
$app-view-title-line-height: 22px !default;
/// @group View title
$app-view-title-height: 48px !default;
/// @group View title
$app-view-title-font-size: 1.6rem !default;
/// @group View title
$app-view-title-font-weight: 400 !default;
$app-view-title-font-family: $font-family-secondary !default;
/// @group View title
/// @type Color
$app-view-title-color: #fff !default;
/// @group View title
$app-view-title-back-padding-left: 80px !default;
/// @group View title
$app-view-title-back-icon-left: 30px !default;
/// @group View title
$app-view-title-back-icon-size: 1.8rem !default;
/// @group View title
/// @type Color
$app-view-title-back-icon-color: #fff !default;
