.badge {
    border-radius: $badge-border-radius;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font: {
        size: $badge-font-size;
        weight: $badge-font-weight;
        style: normal;
    }
    border: $badge-border;
    padding: $badge-padding;
    margin: $badge-margin;
}

@each $name, $props in $colors {
    .badge--#{$name} {
        background: map_get($props, base);
        color: map_get($props, contrast);
    }
}

.badge--inverse {
    background: color-inverse($color-primary);
    color: $color-primary;
}

.icon-badge {
    display: inline-block;
    position: relative;
    font-size: $badge-icon-size;

    .btn & {
        font-size: inherit;
    }

    .badge {
        border-color: $badge-icon-border-color;
        min-width: $badge-icon-size;
        height: $badge-icon-size;
        position: absolute;
        right: -$badge-icon-padding;
        padding: 0;
        top: $badge-icon-top;
        margin: 0;
        border-radius: $badge-icon-size / 2;
        font: {
            size: $badge-icon-font-size;
            weight: $badge-icon-font-weight;
            style: normal;
            family: $badge-icon-font-family;
        }
    }
}
