html {
    font-size: 62.5%;
}

* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

body {
    background: $background-body;
    font: {
        family: $font-family;
        size: $font-size;
    }
    line-height: $default-line-height;
    min-width: $body-min-width;
    color: $color-body;
    overflow-y: scroll;
}

.wrap {
    display: flex;
    flex-wrap: wrap;
    margin: -15px -30px;

    & > div {
        margin: 15px 30px;
    }

    & > .mg-l-auto {
        margin-left: auto;
    }
}
