$use-tooltipster: true !default;

$tooltipster-background: $background-primary !default;
$tooltipster-border-size: 1px !default;
$tooltipster-border-color: $color-primary !default;
$tooltipster-border: $tooltipster-border-size solid $tooltipster-border-color !default;
$tooltipster-border-radius: 4px !default;

$tooltipster-color: $color-default !default;
$tooltipster-font-size: 1.2rem !default;
$tooltipster-line-height: 1.4 !default;
$tooltipster-padding: 6px 14px !default;

$tooltipster-arrow-size: 10px !default;
