/// default opacity for disabled colors
/// @group Colors
$opacity-disabled-alpha: 0.3 !default;

/// return rgb color from hex
/// @group Colors
/// @param {Color} $color HEX color, e.g. #ff0000
@function get-rgb($color) {
    @return rgb(red($color), green($color), blue($color));
}

/// return rgba color from hex and alpha
/// @group Colors
/// @param {Color} $color HEX color, e.g. #ff0000
/// @param {Number} $alpha [1] alpha opacity
@function get-rgba($color, $alpha: 1) {
    @return rgba(red($color), green($color), blue($color), $alpha);
}

/// returns color brightness
/// @group Colors
/// @param {Color} $color-value color HEX
@function color-brightness($color-value) {
    @return (red($color-value) * 0.299 + green($color-value) * 0.587 + blue($color-value) * 0.114) / 255 * 100%;
}

/// return rgba color with default disabled opacity
/// @group Colors
/// @param {Color} $color-value color HEX
@function color-disabled($color-value) {
    @return get-rgba($color-value, $opacity-disabled-alpha);
}

/// returns color inverse
/// @group Colors
/// @param {Color} $color-value color HEX
/// @param {Color} $dark [#000000] is returned when color inverse should be darker
/// @param {Color} $light [#ffffff] is returned when color inverse should be lighter
@function color-inverse($color-value, $dark: #000000, $light: #ffffff) {
    $brightness: color-brightness($color-value);
    $red: red($color-value);
    $green: green($color-value);

    @if ($brightness > 79) {
        @return $dark;
    }

    @if ($green > 240) {
        @return $dark;
    }

    @if ($red > 220 and $green > 180) {
        @return $dark;
    }

    @return $light;
}

/// returns hover color based on brightness. uses darken or lighten sass utils
/// @group Colors
/// @param {Color} $color-value color HEX
@function color-hover($color-value) {
    $brightness: color-brightness($color-value);
    $red: red($color-value);
    $green: green($color-value);

    @if ($brightness > 79 or $green > 240 or $red > 220 and $green > 180) {
        @return darken($color-value, 15%);
    }

    @return lighten($color-value, 15%);
}

/// @param {Color} $color-value color HEX
/// @link color-inverse
@function color-contrast($color) {
    @return color-inverse($color);
}

/// Gets color css variable (or sass variable) from a variation.
/// @group Colors
/// @param {String} $color-name name of color
/// @param {String} $variant [base] variant of color
@function get-color($color-name, $variant: 'base') {
    $var-name: color-#{$color-name};

    @if $variant != 'base' {
        $var-name: color-#{$color-name}-#{$variant};
    }

    @return map_get(map_get($colors, $color-name), $variant);
}

/// returns colors list with tuple: name, base, contrast, hover, disabled
/// @group Colors
@function get-colors($colors) {
    $colors-list: ();

    @each $color-name in $colors {
        $c-base: get-color($color-name);
        $c-contrast: get-color($color-name, contrast);
        $c-hover: get-color($color-name, hover);
        $c-disabled: get-color($color-name, disabled);

        $colors-list: append($colors-list, ($color-name, $c-base, $c-contrast, $c-hover, $c-disabled), comma);
    }

    @return $colors-list;
}

// Prepare background and text colors based on $backgroundColors
// ----------------------------------------------------
@mixin foreground-colors($colors: $foreground-colors) {
    @each $color-name, $color-value in $colors {
        &.color-#{$color-name} {
            color: $color-value;
        }
    }
}

// Prepare background and text colors based on $backgroundColors
// ----------------------------------------------------
@mixin background-colors($ignoreTextColor: false, $colors: $background-colors) {
    @each $color-name, $color-values in $colors {
        &.color-#{$color-name} {
            background: #{map-get($color-values, base)};
            @if not $ignoreTextColor {
                color: #{map-get($color-values, contrast)};
            }
        }
    }
}
