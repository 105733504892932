/// genetarte default button style
/// @group Buttons
/// @param $color-name class name of btn modifier, e.g. `primary` generates `.btn--primary`
/// @param $color-base base color for background and border
/// @param $color-hover hover color for background and border
/// @param $color-contrast base color for text
/// @param $color-disabled disabled color for background and border
@mixin button-default($color-name, $color-base, $color-hover, $color-contrast, $color-disabled) {
    .btn--#{$color-name} {
        background-color: $color-base;
        border-color: $color-base;
        color: $color-contrast;
    }

    .btn--#{$color-name}:hover {
        background-color: $color-hover;
        border-color: $color-hover;
        color: $color-contrast;
    }

    .btn--#{$color-name}:disabled,
    .btn--#{$color-name}.disabled {
        background-color: $color-disabled;
        border-color: $color-disabled;
        color: $color-contrast;
    }
}

/// genetarte outlined button style
/// @group Buttons
/// @param $color-name class name of btn modifier, e.g. `primary` generates `.btn--primary`
/// @param $color-base base color for text and border
/// @param $color-hover hover color for text and border
/// @param $color-contrast base color for text
/// @param $color-disabled disabled color for text and border
@mixin button-outline($color-name, $color-base, $color-hover, $color-contrast, $color-disabled) {
    .btn--outline.btn--#{$color-name} {
        border-style: $button-outline-border-style;
        border-width: $button-outline-border-width;
        border-color: $color-base;
        color: $color-base;
        background-color: $button-background-color;
    }

    .btn--outline.btn--#{$color-name}:hover {
        background-color: $color-base;
        border-color: $color-base;
        color: $color-contrast;
    }

    .btn--outline.btn--#{$color-name}:disabled,
    .btn--outline.btn--#{$color-name}.disabled {
        background-color: $button-background-color;
        border-color: $color-disabled;
        color: $color-disabled;
    }
}

/// genetarte cleared button style
/// @group Buttons
/// @param $color-name class name of btn modifier, e.g. `primary` generates `.btn--primary`
/// @param $color-base base color for text
/// @param $color-hover hover color for text
/// @param $color-contrast base color for text
/// @param $color-disabled disabled color for text
@mixin button-clear($color-name, $color-base, $color-hover, $color-disabled) {
    .btn--clear.btn--#{$color-name} {
        background-color: transparent;
        border-color: transparent;
        color: $color-base;
    }

    .btn--clear.btn--#{$color-name}:hover {
        color: $color-hover;
    }

    .btn--clear.btn--#{$color-name}:disabled,
    .btn--clear.btn--#{$color-name}.disabled {
        background-color: transparent;
        border-color: transparent;
        color: $color-disabled;
    }
}
