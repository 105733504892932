@font-face {
    font-family: $font-icon;
    src: url('#{$font-icon-file}.woff2?bz9fsi&v=#{$font-icon-version}') format('woff2'),
        url('#{$font-icon-file}.ttf?bz9fsi&v=#{$font-icon-version}') format('truetype'),
        url('#{$font-icon-file}.woff?bz9fsi&v=#{$font-icon-version}') format('woff'),
        url('#{$font-icon-file}.svg?bz9fsi&v=#{$font-icon-version}#sportbm') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='#{$font-prefix}'],
[class*=' #{$font-prefix}'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $font-icon !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@each $name, $code in $icons {
    @include generateIcon($name, $code);
}

@each $name, $size in $icon-sizes {
    .icon--#{$name} {
        font-size: $size;
    }
}
