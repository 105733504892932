.absolute-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#fff, 0.7);
    z-index: 10;
    display: flex;
    align-items: center;

    .card & {
        border-radius: $card-border-radius;
    }
}

.absolute-loader-wrapper {
    position: relative;
}

.loader {
    margin: $loader-margin;
    display: block;
    width: $loader-size;
    height: $loader-size;
    border-radius: 50%;
    border: $loader-width solid transparent;
    border-top-color: $loader-color;
    border-left: 0 !important;
    animation: circ-anim 0.7s cubic-bezier(0.47, 0, 0.74, 0.71) infinite;

    &.no-margin {
        margin: auto;
    }

    @each $size, $params in $loader-sizes {
        &.loader--#{$size} {
            width: map_get($params, size);
            height: map_get($params, size);
            border-width: map_get($params, width);
        }
    }

    &.loader--links {
        margin: $links-group-margin {
            top: 0;
            bottom: 0;
        }
    }

    &.loader--xsmall {
        margin: 0 auto;
    }

    &.loader--results,
    &.loader--icon-md,
    &.loader--paragraph {
        margin: 0;
    }

    &.loader--form,
    &.loader--popover {
        margin: 0;
    }

    &.loader--alert {
        margin: 23px auto 0px;
    }

    .loader-right & {
        margin-right: 0;
        margin-left: auto;
    }

    .loader-left & {
        margin-left: 0;
        margin-right: auto;
    }

    @each $color-name, $color-value in $colors {
        &.loader--#{$color-name} {
            border-top-color: map_get($color-value, base);
        }
    }

    &.loader--inverse {
        border-top-color: $loader-color-inverse;
    }
}

@keyframes circ-anim {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes router-load-indicator-animation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.router-load-indicator {
    // Delay the animation for a fast network connection (so users don't see the loader).
    animation-delay: 100ms;
    animation-duration: 200ms;
    animation-fill-mode: both;
    animation-name: router-load-indicator-animation;

    align-items: center;
    background-color: rgba(#fff, 0.8);
    display: flex;
    color: #000000;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}
