/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
    $multiplier: 1;
    @if str-index('' + $number, 'rem') {
        $multiplier: 10;
    }
    @if type-of($number) == 'number' and not unitless($number) {
        @return ($number / ($number * 0 + 1)) * $multiplier;
    }

    @return $number;
}

@function str-split($string, $separator) {
    // empty array/list
    @if type-of($string) == 'list' {
        @return $string;
    }
    $split-arr: ();
    // first index of separator in string
    $index: str-index($string, $separator);
    // loop through string
    @while $index != null {
        // get the substring from the first character to the separator
        $item: str-slice($string, 1, $index - 1);
        // push item to array
        $split-arr: append($split-arr, $item);
        // remove item and separator from string
        $string: str-slice($string, $index + 1);
        // find new index of separator
        $index: str-index($string, $separator);
    }
    // add the remaining string to list (the last item)
    $split-arr: append($split-arr, $string);

    @return $split-arr;
}

@function em($pixels, $context) {
    @return ($pixels / $context) * 1em;
}

@mixin clearfix {
    &:after {
        content: '';
        clear: both;
        display: block;
        visibility: hidden;
    }
}

@mixin media-query-max($media-query) {
    @media only screen and (max-width: $media-query) {
        @content;
    }
}

@mixin media-query-min($media-query) {
    @media only screen and (min-width: $media-query) {
        @content;
    }
}

@mixin media-query-max-min($media-query-max, $media-query-min) {
    @media only screen and (max-width: $media-query-max) and (min-width: $media-query-min) {
        @content;
    }
}
