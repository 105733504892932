$paginator-padding: 20px 0 !default;
$paginator-margin: 20px 0 0 !default;
$paginator-disabled-opacity: 0.6 !default;

$paginator-item-size: 26px !default;
$paginator-item-margin: 0 6px !default;
$paginator-item-border-radius: 4px !default;
$paginator-item-color: $color-default !default;

$paginator-item-active-background: $color-tertiary !default;
$paginator-item-active-color: color-inverse($paginator-item-active-background) !default;

$paginator-item-disabled-color: $color-tertiary !default;

/// color of arrows next / previous
$paginator-page-change-color: $color-primary !default;
$paginator-page-change-margin: 25px !default;
