.form-datepicker {
    position: relative;

    &.form-input-wrapper {
        overflow: visible;
    }
}

.c-datepicker {
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    width: 100%;
    max-width: 280px;
    min-width: 240px;
    background: #fff;
    z-index: 10;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(#000, 0.2);

    &.c-datepicker--right {
        left: auto;
        right: 0;
    }

    &.calendar-picker {
        max-width: 50%;
        min-width: 300px;

        .calendar-picker-disabled {
            pointer-events: none;
        }
    }
}

.c-datepicker__heading {
    display: flex;
    padding: 15px 5px;
    background: $background-tertiary;
    color: color-inverse($background-tertiary);
    text-transform: uppercase;

    a {
        color: inherit;
    }

    .c-datepicker__arrow-next,
    .c-datepicker__arrow-prev {
        flex-basis: 20px;
        flex-grow: 0;
        flex-shrink: 0;
        text-align: center;
        font: {
            size: 1.2rem;
        }
    }

    .c-datepicker__arrow-next {
        &::before {
            content: getIconCode(angle-right);
            font-family: $font-icon;
        }
    }

    .c-datepicker__arrow-prev {
        &::before {
            content: getIconCode(angle-left);
            font-family: $font-icon;
        }
    }
}

.c-datepicker__heading-date {
    flex: 1;
    text-align: center;

    a {
        margin: 0 10px;

        &::after {
            content: getIconCode(angle-down);
            font: {
                family: $font-icon;
                size: 1rem;
            }
        }
    }
}

.c-datepicker__body-week {
    display: flex;
}

.c-datepicker__day {
    flex: 1;
    border: 2px solid transparent;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    margin: 1px;

    .calendar-picker & {
        border: 1px solid $color-border;
        height: 50px;
        align-items: flex-start;
        justify-content: space-between;
        padding: 4px 3px;
        display: flex;
        flex-direction: column;

        .events-count {
            color: $color-tertiary;
            font-size: 1.2rem;
            opacity: 0.8;
        }

        &.selected .events-count {
            color: color-inverse($color-tertiary);
        }
    }

    &:hover {
        border-color: $color-border;
        background: $color-border;
        color: color-inverse($color-border);
    }

    &.today {
        border-color: $color-primary;
        background: none;
        color: inherit;
    }

    &.past,
    &.future {
        color: $color-tertiary;
        opacity: 0.6;
    }

    &.saturday {
        color: $color-primary;
    }

    &.sunday {
        color: $color-danger;
    }

    &.selected {
        border-color: $color-primary;
        background: $color-primary;
        color: color-inverse($color-primary);
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.6;
    }
}

.c-datepicker__body {
    padding: 14px;

    .calendar-picker & {
        min-height: 100px;
        position: relative;

        .c-datepicker__body-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(#fff, 0.7);
            display: flex;
            align-items: center;
        }
    }
}

.c-datepicker__months {
    display: flex;
    flex-wrap: wrap;
}

.c-datepicker__months-item {
    border: 2px solid transparent;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    margin: 1px;
    width: calc(33.33% - 2px);

    &:hover {
        border-color: $color-border;
        background: $color-border;
        color: color-inverse($color-border);
    }

    &.today {
        border-color: $color-primary;
        background: none;
        color: inherit;
    }

    &.selected {
        border-color: $color-primary;
        background: $color-primary;
        color: color-inverse($color-primary);
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.6;
    }
}

.c-datepicker__years {
    display: flex;
    flex-wrap: wrap;
}

.c-datepicker__years-item {
    border: 2px solid transparent;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    margin: 1px;
    width: calc(33.33% - 2px);

    &:hover {
        border-color: $color-border;
        background: $color-border;
        color: color-inverse($color-border);
    }

    &.today {
        border-color: $color-primary;
        background: none;
        color: inherit;
    }

    &.selected {
        border-color: $color-primary;
        background: $color-primary;
        color: color-inverse($color-primary);
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.6;
    }
}

.c-datepicker__timepicker {
    width: 100%;
    max-width: 160px;
    margin: 15px auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .form-input {
        height: 30px;
    }
}

.c-datepicker__timepicker-separator {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: ':';
    }
}

.c-datepicker__timepicker-slot {
    flex: 1;
    text-align: center;

    .form-input {
        text-align: center;
        -moz-appearance: textfield;
        width: 100%;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.c-datepicker__timepicker-increase,
.c-datepicker__timepicker-decrease {
    padding: 5px 0;
    display: inline-block;
    font: {
        family: $font-icon;
        size: 20px;
    }
}

.c-datepicker__timepicker-decrease::before {
    content: getIconCode(angle-down);
}

.c-datepicker__timepicker-increase::before {
    content: getIconCode(angle-up);
}

@include media-query-min($breakpoint-md) {
    .calendar-picker {
        .c-datepicker__day {
            font-size: 1.6rem;
            height: 60px;

            .events-count {
                font-size: 1.4rem;
                opacity: 0.8;
            }
        }
    }
}

@include media-query-max($breakpoint-md) {
    .c-datepicker {
        &.calendar-picker {
            margin-left: -15px;
        }
    }
}
