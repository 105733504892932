.calendar-month {
    padding: 0 70px;
    margin-bottom: 15px;
    display: flex;
    position: relative;
}

.calendar-month__title {
    flex: 1;

    .calendar-picker-title {
        text-transform: uppercase;
        font-size: 2rem;

        small {
            font-size: 1.2rem;
        }
    }
}

.calendar-ribbon {
    display: flex;
    width: 100%;
    padding: 0 15px;
    position: relative;
}

.calendar-ribbon__day {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 5px;
    border: 2px solid $color-border;
    border-radius: 3px;
    padding: 10px 5px;
    cursor: pointer;
    user-select: none;

    &.current-day {
        border-color: $color-primary;
        background: rgba($color-primary, 0.2);
    }

    &.today {
        border-color: $color-primary;
    }

    &.saturday {
        color: $color-primary;
    }

    &.sunday {
        color: $color-danger;
    }

    .day {
        font-size: 2rem;
    }

    .day-of-week {
        font-size: 1rem;
        text-transform: uppercase;
    }

    .month {
        text-transform: uppercase;
        font-size: 1.1rem;
    }

    .events {
        margin-top: 8px;
        color: $color-tertiary;
    }
}

.calendar-ribbon__nav {
    align-self: center;
    padding: 10px;
    width: 50px;
}

.calendar-ribbon__loader {
    background: rgba(#fff, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@include media-query-max($breakpoint-sm) {
    .calendar-ribbon,
    .calendar-month {
        padding: 0;
    }

    .calendar-ribbon__nav {
        display: none;
    }

    .calendar-month {
        flex-wrap: wrap;
        padding: 0 5px;
    }

    .calendar-month__title {
        width: 100%;
        flex: none;
    }

    .calendar-month__nav {
        margin-top: 10px;
        width: 100%;

        .links-group {
            justify-content: flex-end;
        }
    }
}

// MONTHLY CALENDAR
//.c-monthly-calendar {}

.c-monthly-calendar__heading {
    display: flex;
    padding: 15px 5px;
    justify-content: center;
    align-items: center;

    a {
        color: inherit;
    }

    .label {
        color: $color-primary;
        font-size: 2rem;
        text-transform: uppercase;
    }

    small {
        color: $color-primary;
        font-size: 1.3rem;
    }

    .c-monthly-calendar__heading-name {
        width: 420px;
        text-align: center;
        position: relative;
    }

    .c-monthly-calendar__heading-name-short {
        width: 180px;
        text-align: center;
        position: relative;
    }

    .c-monthly-calendar__arrow-next,
    .c-monthly-calendar__arrow-prev {
        flex-basis: 20px;
        flex-grow: 0;
        flex-shrink: 0;
        text-align: center;
        font: {
            size: 1.6rem;
        }
    }

    .c-monthly-calendar__arrow-next {
        &::before {
            content: getIconCode(angle-right);
            font-family: $font-icon;
        }
    }

    .c-monthly-calendar__arrow-prev {
        &::before {
            content: getIconCode(angle-left);
            font-family: $font-icon;
        }
    }
}

.c-monthly-calendar__nav {
    display: flex;
    margin: {
        top: 10px;
        bottom: 30px;
    }
    width: 100%;
    justify-content: space-between;

    .links-group {
        justify-content: flex-end;

        &:only-child {
            margin-left: auto;
        }
    }
}

.c-monthly-calendar__body-week {
    display: flex;
}

.c-monthly-calendar__day-name {
    flex: 1;
    color: $color-primary;
    text-transform: uppercase;
    font-size: 1.2rem;
    margin: 3px;
}

.c-monthly-calendar__day {
    flex: 1;
    border: 1px solid $color-border;
    min-height: 65px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 4px;
    cursor: pointer;
    padding: 6px;
    margin: 3px;
    flex-direction: column;
    transition: background-color 0.2s linear;

    .events-count {
        color: $color-tertiary;
        font-size: 1.6rem;
        line-height: 1;
        text-align: center;
        width: 100%;
        padding: 10px 0 6px;
    }

    &.today {
        border-color: $color-primary;
        color: inherit;
    }

    &.past {
        color: $color-tertiary;
        opacity: 0.6;
    }

    &.odd {
        background-color: darken($background-primary, 5);

        &:hover {
            background: darken($background-primary, 10);
        }
    }

    &.saturday {
        color: $color-primary;
    }

    &.sunday {
        color: $color-danger;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.6;
    }

    &:hover {
        background: darken($background-primary, 8);
    }
}

.c-monthly-calendar__body {
    position: relative;

    .c-monthly-calendar__body-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(#fff, 0.7);
        display: flex;
        align-items: center;
    }
}

@include media-query-max($breakpoint-sm) {
    .c-monthly-calendar__nav {
        flex-wrap: wrap;
        .links-group {
            width: 100%;
            padding-top: 25px;

            &:first-child {
                padding-top: 0;
            }
        }
    }
}

@include media-query-max($breakpoint-xs) {
    .c-monthly-calendar__day {
        min-height: 48px;
        border-radius: 4px;
        padding: 3px;
        font-size: 1.2rem;
        margin: 1px;

        .events-count {
            font-size: 1.2rem;
            padding: 10px 0 3px;
        }

        .month-short {
            font-size: 1.1rem;
        }

        @include styleFontIcon() {
            margin-bottom: 3px;
            font-size: 1rem;
            margin-right: 2px;
        }
    }

    .c-monthly-calendar__day-name {
        font-size: 1.1rem;
        margin: 1px;
    }
    .c-monthly-calendar__heading {
        .label {
            font-size: 1.5rem;
        }

        .c-monthly-calendar__heading-name {
            padding: 0 20px;
            width: 240px;
        }

        .c-monthly-calendar__arrow-next,
        .c-monthly-calendar__arrow-prev {
            font: {
                size: 1.8rem;
            }
        }
    }
}
