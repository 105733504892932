$table-header-font-size: 1.2rem !default;
$table-header-cell-padding: 6px 20px !default;
$table-lower-header-cell-padding: 5px 20px !default;
$table-header-cell-color: $color-primary !default;
$table-header-cell-border-color: $table-header-cell-color !default;

$table-cell-border: 1px solid $color-border !default;
$table-cell-background-hover: $background-hover !default;
$table-cell-padding: 15px 20px !default;
$table-lower-cell-padding: 8px 20px !default;
$table-cell-padding-mobile: 10px 15px !default;

$table-footer-cell-padding: 15px 20px !default;
$table-footer-font-size: 1.4rem !default;
