$link-default-color: $color-primary !default;
$link-default-color-hover: color-hover($color-primary) !default;
$link-transition: color 0.2s linear !default;
$link-disabled-opacity: $opacity-disabled-alpha !default;
$links-group-margin: 10px !default;
$links-group-small-margin: 5px !default;
$links-group-large-margin: 15px !default;
$link-small-factor-size: 1.2rem !default;
$link-large-factor-size: 1.6rem !default;

$links-list-item-border: 1px solid $color-border !default;
$links-list-item-padding: 7px 0 !default;

/*
  LINK WITH ICON
 */
/// padding left for link with icon on start
/// @group Links
$link-icon-start-padding-start: em(25, strip-unit($font-size)) !default;
/// @group Links
$link-icon-start-margin: em(10, strip-unit($font-size)) !default;
/// padding right for link with icon on end
/// @group Links
$link-icon-end-padding-end: em(25, strip-unit($font-size)) !default;
/// @group Links
$link-icon-end-margin: em(10, strip-unit($font-size)) !default;
/// @group Links
$link-icon-size: em(20, strip-unit($font-size)) !default;
$link-icon-size-small: 0.85em !default;
