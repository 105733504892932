.custom-file-field {
    &.custom-file-field--image {
        display: flex;

        .custom-file-field__actions {
            flex: 1;
        }
    }

    img {
        max-height: 100px;
        max-width: 260px;
        margin-right: 20px;
    }

    .filename {
        margin-bottom: 10px;

        strong {
            margin-left: 5px;
        }
    }

    .btn--file {
        position: relative;

        input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            cursor: pointer;
            z-index: 2;
            width: 100%;
            height: 100%;
        }
    }
}
