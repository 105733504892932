// for web component usage
bsc-tab:not(.hydrated) {
    display: none;
}

.tabs {
    .hidden {
        display: none !important;
    }
}

.tabs__nav {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: -1 * $tabs-nav-margin-vertical -1 * $tabs-nav-margin-horizontal;
    padding: 0;
    padding-bottom: 5px;
    list-style: none;
    user-select: none;

    .card-header__title & {
        margin: {
            top: -12px;
            bottom: -12px;
        }
    }

    .tabs--center & {
        justify-content: center;
        flex-wrap: wrap;
    }

    .tabs--bordered & {
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: $tabs-nav-bordered-height;
            background: $tabs-nav-bordered-border-color;
            bottom: $tabs-nav-margin-vertical;
            left: 0;
            z-index: 0;
        }

        .tabs__nav-item {
            position: relative;
            z-index: 1;
        }
    }

    li {
        margin: 0;
        white-space: nowrap;
    }
}

.tabs__nav-item {
    text-transform: uppercase;
    margin: $tabs-nav-margin-vertical $tabs-nav-margin-horizontal;
    padding: $tabs-nav-item-padding;
    border-bottom: $tabs-nav-item-border-bottom;
    font: {
        size: $tabs-nav-item-font-size;
        family: $tabs-nav-item-font-family;
        weight: $tabs-nav-item-font-weight;
    }
    line-height: $tabs-nav-item-line-height;
    color: $tabs-nav-item-color;
    outline: none;
    transition: border 0.1s linear;
    position: relative;

    &.active {
        border-color: $tabs-nav-item-active-border-color;
    }

    &.tabs__nav-item--icon-start,
    &.tabs__nav-item--icon-end {
        @include styleFontIcon {
            font-size: 2rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &.tabs__nav-item--icon-start {
        padding-left: 40px;

        @include styleFontIcon {
            left: 10px;
        }
    }

    &.tabs__nav-item--icon-end {
        padding-right: 40px;

        @include styleFontIcon {
            right: 10px;
        }
    }
}

.tabs__tab {
    padding: $tabs-content-padding;

    &:empty {
        display: none;
    }
}
