.paginator {
    padding: $paginator-padding;
    margin: $paginator-margin;
    display: flex;
    justify-content: center;
    user-select: none;
}

.paginator--disabled {
    opacity: $paginator-disabled-opacity;
    cursor: default;
    pointer-events: none;
}

.paginator__item {
    width: $paginator-item-size;
    height: $paginator-item-size;
    margin: $paginator-item-margin;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font: {
        weight: 400;
    }
    border-radius: $paginator-item-border-radius;
    color: $paginator-item-color;

    &.active {
        color: $paginator-item-active-color;
        background: $paginator-item-active-background;
        font: {
            weight: 600;
        }
        pointer-events: none;
    }

    &[disabled],
    &.disabled {
        color: $paginator-item-disabled-color;
        pointer-events: none;
    }

    &.item-prev {
        margin-right: $paginator-page-change-margin;
        color: $paginator-page-change-color;
    }

    &.item-next {
        margin-left: $paginator-page-change-margin;
        color: $paginator-page-change-color;
    }
}
