body.has-side-navigation {
    .app-content-wrapper {
        padding-left: $side-navigation-width;
    }

    .app-subheader {
        min-height: 0;
    }

    .app-view-title {
        margin-top: -120px;
    }
}

.side-navigation {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: $side-navigation-width;
    top: 0;
    bottom: 0;
    left: 0;
    background: $side-navigation-background;
    z-index: 5;
    box-shadow: 0 2px 6px rgba(#000, 0.5);
    overflow: hidden;

    .side-navigation__toggle {
        display: none;

        sbm-icon {
            padding: 0;
        }
    }
}

.side-navigation__nav {
    flex: 1;
    //text-transform: uppercase;
    font-family: $side-navigation-font-family;
    font-size: $side-navigation-font-size;
    font-weight: $side-navigation-font-weight;
    overflow-x: hidden;
    overflow-y: auto;
    width: $side-navigation-width;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;

        li {
            margin: 0;
            border-bottom: 1px solid #666;

            &:first-child {
                border-top: 1px solid #666;
            }
        }
    }

    a {
        display: flex;
        outline: none;
        align-items: center;
        padding: $side-navigation-link-padding-y $side-navigation-link-padding-x {
            left: $side-navigation-link-padding-x - $side-navigation-link-border-size;
        }
        line-height: $side-navigation-link-line-height;
        border-left: $side-navigation-link-border;
        //flex-direction: column;

        color: $side-navigation-link-color;

        &:hover {
            color: $side-navigation-link-color-hover;
        }

        &.active {
            border-color: $side-navigation-link-color-hover;
            color: $side-navigation-link-color-hover;
        }

        sbm-icon {
            width: $side-navigation-link-icon-size + $side-navigation-link-padding-x;
            padding-right: $side-navigation-link-padding-x;
            //padding-bottom: 5px;
        }
    }

    @include styleFontIcon() {
        display: block;
        line-height: 1;
        font-size: $side-navigation-link-icon-size;
    }
}

.side-navigation__header {
    height: 65px;
    padding: 0 0 0 15px;
    display: flex;
    align-items: center;
    font-size: 8px;

    .app-header__logo {
        min-width: 16.7em;
        height: 5.3em;
        background-image: url('/assets/images/logo-white.png');
        margin-right: 0;
        color: color-inverse($side-navigation-background);
    }

    .app-header__logo--subtitle {
        min-width: 16.8em;
        background-image: url('/assets/images/logo-white-subtitle.png');
        padding: {
            top: 3.1em;
            left: 6.2em;
        }
        font-size: inherit;
        height: 5.3em;

        span {
            font-size: 1.5em;
        }
    }
}

@include media-query-max($mobile-breakpoint) {
    body.has-side-navigation {
        .app-content-wrapper {
            padding-left: $side-navigation-width-mobile;
        }
    }

    .side-navigation {
        top: 90px !important;
        bottom: 0;
        height: auto;
        width: $side-navigation-width-mobile;
        transition: width 0.2s linear;

        &.opened {
            width: $side-navigation-width;
        }

        .side-navigation__toggle {
            display: block;

            sbm-icon {
                :before {
                    transform: scaleX(-1);
                }
            }
        }
    }

    .side-navigation__header {
        display: none;
    }

    .side-navigation__nav {
        padding-top: 20px;
    }
}
