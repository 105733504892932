.form-radio-switch {
    width: 100%;
    height: $switch-height;
    position: relative;
    border: $switch-border;
    border-radius: $switch-border-radius;
    background: $switch-background;
    display: flex;
    padding: $switch-toggle-offset-left;

    .custom-radio-switch-item {
        flex: 1;
    }
}

.custom-radio-switch-item {
    position: relative;

    input {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;

        &:checked + .custom-radio-switch-item__label {
            background: $switch-toggle-background-checked;
            color: $switch-toggle-color-yes;
        }
    }
}

.custom-radio-switch-item__label {
    border-radius: $switch-toggle-border-radius;
    height: 100%;
    color: $switch-toggle-color;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s linear, background 0.2s linear;
}
