.form-radios-list {
    .form-radio {
        margin-top: $forms-checkboxes-list-margin-top;
    }
}

.form-radio {
    display: block;
    font: {
        size: $forms-radio-label-font-size;
        weight: $forms-radio-label-font-weight;
    }
    line-height: $forms-radio-label-line-height;
    margin-top: $forms-label-padding-top;
    min-height: $forms-radio-min-height;
    padding: {
        bottom: $forms-radio-padding-vertical;
        left: $forms-radio-label-padding-left;
        top: $forms-radio-padding-vertical;
    }
    position: relative;

    .custom-radio {
        left: 0;
        position: absolute;
        top: 0;
    }
}

.custom-radio {
    border-radius: $forms-radio-border-radius;
    height: $forms-radio-size;
    position: relative;
    width: $forms-radio-size;

    input {
        border-radius: $forms-radio-border-radius;
        cursor: pointer;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;

        &:disabled {
            cursor: default;
        }

        &:disabled:hover + .custom-radio__layer {
            border-color: $forms-radio-border-color;
        }

        &:disabled + .custom-radio__layer {
            opacity: $forms-radio-disabled-opacity;
            pointer-events: none;
        }

        &:checked + .custom-radio__layer {
            background: $forms-radio-background-selected;
            border-color: $forms-radio-border-color-selected;

            &:before {
                opacity: 1;
            }
        }

        &.display-default:disabled + .custom-radio__layer {
            opacity: 1;
        }
    }

    .custom-radio__layer {
        background: $forms-radio-background;
        border: $forms-radio-border-size solid $forms-radio-border-color;
        border-radius: $forms-radio-border-radius;
        display: block;
        height: inherit;
        position: relative;
        transition: border 0.2s linear, background 0.2s linear;

        &:before {
            background: $forms-radio-tick-color;
            border-radius: $forms-radio-border-radius;
            content: '';
            display: block;
            height: $forms-radio-tick-size;
            left: 50%;
            opacity: 0;
            position: absolute;
            transition: opacity 0.2s linear;
            width: $forms-radio-tick-size;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    &:hover,
    label:hover &,
    .has-error & {
        .custom-radio__layer {
            border-color: $forms-radio-border-color-selected;
        }

        input {
            &:checked + .custom-radio__layer {
                &:before {
                    display: block;
                }
            }
        }
    }
}
