.box-event {
    padding: $box-event-padding;
    border-left: $box-event-border;
    border-left-color: $color-primary;
    margin: $box-event-margin;
    color: $color-default;

    &:first-child {
        margin-top: 0;
    }
}

.box-event__date {
    color: $color-tertiary;
    font: {
        size: $box-event-date-size;
    }
}

.box-event__start {
    color: $color-tertiary;
    margin: $box-event-start-margin;
    font: {
        size: $box-event-start-size;
        weight: $box-event-start-weight;
    }
    position: relative;
    padding-left: $box-event-start-icon-padding;

    @include styleFontIcon() {
        margin-right: $box-event-start-icon-margin;
        font: {
            size: $box-event-start-icon-size;
        }
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}
