.event-header {
    display: flex;
    flex-wrap: wrap;
}

.event-header--with-actions {
    position: relative;
}

.event-header__actions {
    position: absolute;
    top: -10px;
    right: -10px;
}

.event-header__start {
    width: 100%;
    color: $color-tertiary;
    margin-bottom: 15px;
    padding-right: 50px;
    line-height: 1.5;
}

.event-header__icon {
    width: 30px;
    margin-right: 20px;
    margin-left: 10px;
    font-size: 3rem;
    color: $color-tertiary;
    text-align: center;
    justify-content: space-between;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
}

.event-header__content {
    border-left: 1px solid $color-border;
    padding-left: 15px;
    flex: 1;

    h3 {
        margin-bottom: 10px;
    }

    .text-with-icon {
        margin-top: 7px;
    }
}

.event-header__chart {
    margin-top: 15px;
    max-width: 450px;
    width: 100%;
    align-self: flex-end;
}

.event-header__time {
    align-self: flex-end;
}

@include media-query-max($breakpoint-md) {
    .event-header__chart {
        max-width: 350px;
    }
}

@include media-query-max($mobile-breakpoint) {
    .event-header__icon {
        margin-left: 0;
    }
}
