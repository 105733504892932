body.modal-opened {
    overflow: hidden;
}

.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background: $modal-overlay-background;
    transition: opacity 0.1s linear;
    z-index: 100;

    &:empty {
        display: none;
    }

    &.active {
        opacity: 1;
    }
}

.modal-layer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background: rgba(#000, 0);
    padding: {
        top: $modal-overlay-padding-top;
        bottom: $modal-overlay-padding-bottom;
    }
    overflow: auto;
    z-index: 100;
    transition: filter 0.1s linear;
    filter: blur(4px) brightness(50%) saturate(0);

    &:last-child {
        filter: blur(0px);
    }

    &.active {
        opacity: 1;
    }
}

.modal {
    width: 100%;
    max-width: $modal-max-width;
    background: $modal-background;
    border-radius: $modal-border-radius;
    margin: auto;
    transition: top 0.1s linear;
    top: -30px;
    padding: $modal-padding;
    position: relative;
    box-shadow: 0 0 35px 20px rgba(0, 0, 0, 0.5);

    &.active {
        top: 0;
    }

    img {
        max-width: 100%;
    }
}

.modal-title {
    padding: $modal-title-padding;
    font: {
        size: $modal-title-font-size;
        weight: $modal-title-font-weight;
    }
    line-height: $modal-title-line-height;
}

.modal-content {
    padding: $modal-content-padding;
}

.modal-close {
    position: absolute;
    top: $modal-close-top;
    right: $modal-close-right;
    z-index: 2;

    &::before {
        content: $modal-close-content;
    }
}

///////////////////////////////////////////////////////
// CUSTOM STYLES FOR MODALS
///////////////////////////////////////////////////////

.modal-close {
    &::before {
        content: getIconCode(times);
        font-family: $font-icon;
    }
}

.modal--receiver-picker {
    max-width: 1100px;
    padding: 0;
    overflow: hidden;

    .modal-content {
        padding: 0;
    }
}

.modal--image {
    background: none;
    max-width: none;
    margin-top: -1 * $modal-overlay-padding-top;
    box-shadow: none;

    .modal-content {
        padding: 0 30px;
    }

    .modal-close {
        font-size: 2rem;
        right: 5px;
        color: #fff;
    }

    .image-title {
        font-size: 2rem;
        color: #fff;
        margin-bottom: 30px;
    }

    .main-image {
        display: block;
        margin: 20px auto auto;
    }
}

///////////////////////////////////////////////////////
// ALERT / CONFIRM MODALS
///////////////////////////////////////////////////////

.modal--alert {
    padding: 0;
    max-width: 600px;
    width: 90%;

    .modal-content {
        padding: 0;
    }

    &.modal--alert-wide {
        max-width: 1000px;
    }
}

.modal--form {
    max-width: 800px;
}

.modal--details-view {
    max-width: 1100px;
}

.modal--text {
    max-width: 900px;

    .alert__content {
        padding-top: 0;
        text-align: left;

        .title {
            font-size: 1.8rem;
            margin-bottom: 30px;
        }

        strong {
            font-size: 1.5rem;
        }
    }
}

.modal--content-left {
    .alert__content {
        width: 100%;

        p {
            text-align: left;
        }
    }
}
