/// @group Alerts
$alert-min-height: 66px !default;
/// @group Alerts
$alert-border: 1px solid $color-border !default;
/// @group Alerts
$alert-border-left-width: 5px !default;
/// @group Alerts
$alert-font-size: 1.4rem !default;
/// @group Alerts
$alert-line-height: 2.2rem !default;
/// @group Alerts
$alert-border-radius: 4px !default;
/// @group Alerts
$alert-background: #ffffff !default;
/// @group Alerts
$alert-margin: 15px auto !default;
/// @group Alerts
$alert-btns-group-margin: 15px !default;

/// @group Alerts
$alert-icon-width: 58px !default;
/// @group Alerts
$alert-icon-size: 2.8rem !default;
/// @group Alerts
$alert-icon-color: $color-border !default;

/// @group Alerts
$alert-content-padding: 15px 40px 15px 0 !default;
$alert-modal-content-padding: 20px 15px 15px !default;
/// @group Alerts
$alert-content-title-size: 1.4rem !default;
/// @group Alerts
$alert-first-paragraph-margin: 10px !default;

/// @group Alerts
$alert-close-size: 2rem !default;
/// @group Alerts
$alert-close-width: 50px !default;
/// @group Alerts
$alert-close-padding: 15px !default;
