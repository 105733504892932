input,
select {
    outline: none;
}

.form-input,
.form-control {
    background: $forms-input-background;
    border: $forms-input-border;
    border-radius: $forms-input-border-radius;
    height: $forms-text-input-height;
    outline: none;
    padding: 0 $forms-input-padding;
    width: 100%;

    .table & {
        height: 40px;
    }

    &::placeholder {
        opacity: 0.3;
    }

    &[disabled],
    &:disabled {
        pointer-events: none;
        background: #f9f9f9;
    }

    &:focus,
    &.focus {
        border-color: $forms-input-border-color-focus;
    }

    .is-valid &,
    &.is-valid {
        &,
        &.focus {
            border-color: $forms-input-border-color-success;
        }
    }

    .has-error &,
    &.has-error {
        &,
        &.focus {
            border-color: $forms-input-border-color-error;
        }
    }

    &.form-input--sm {
        height: ceil(0.75 * $forms-text-input-height);
    }

    &.form-input--lg {
        height: ceil(1.25 * $forms-text-input-height);
    }
}

.form-input-wrapper {
    @extend .form-input;
    display: flex;
    padding: 0;
    overflow: hidden;

    .form-input__prefix,
    .form-input__suffix {
        align-items: center;
        display: flex;
        user-select: none;
        height: 100%;

        &.text {
            font-size: 1.4rem;
        }
    }

    .form-input__suffix {
        color: $forms-input-suffix-color;
        font: {
            size: $forms-input-suffix-size;
        }
        padding: $forms-input-suffix-padding;
        background: $forms-input-suffix-background;
    }

    & > input, & > .form-input__content {
        flex: 1;
        height: 100%;
        border: none;
        min-width: 0;
        outline: none;
        width: 100%;
        padding: 0 $forms-input-padding;
        border-radius: 0;

        &::placeholder {
            opacity: 0.3;
        }
    }

    .form-input__content {
        display: flex;
        align-items: center;
    }

    .form-input__prefix {
        color: $forms-input-prefix-color;
        font: {
            size: $forms-input-prefix-size;
        }
        padding: {
            right: $forms-input-padding;
        }
        padding: $forms-input-prefix-padding;
        background: $forms-input-prefix-background;
    }
}

textarea.form-input,
textarea.form-control,
select.form-input[multiple] {
    height: $forms-textarea-height;
    padding: 0;

    &.form-input--single-line {
        height: $forms-text-input-height;
    }

    &.form-input--sm {
        height: ceil(0.75 * $forms-textarea-height);
    }

    &.form-input--lg {
        height: ceil(1.5 * $forms-textarea-height);
    }

    &.form-input--xlg {
        height: ceil(2 * $forms-textarea-height);
    }

    option {
        padding: $forms-input-padding / 2 $forms-input-padding;
    }
}

textarea.form-input,
textarea.form-control {
    padding: $forms-input-padding;
    resize: none;
    line-height: 1.4;
}
