.btns-group {
    display: flex;
    flex-wrap: wrap;
    margin: -1 * $buttons-group-margin-vertical -1 * $buttons-group-margin-horizontal;

    & > * {
        margin: $buttons-group-margin-vertical $buttons-group-margin-horizontal;

        &.mg-r-auto {
            margin-right: auto;
        }

        &.mg-l-auto {
            margin-left: auto;
        }
    }
}

.btns-group--column {
    flex-direction: column;
    margin: -1 * $buttons-group-margin-column;

    & > * {
        margin: $buttons-group-margin-column;
    }
}

.btns-group--right {
    justify-content: flex-end;
    text-align: right;
}

.btns-group--center {
    justify-content: center;
    text-align: center;
}

.btns-group--full {
    & > * {
        flex: 1;
    }
}

.btns-group--joined {
    margin: 0;

    .btn {
        border-radius: 0;
        margin: 0;

        &:first-child {
            border-radius: $button-border-radius 0 0 $button-border-radius;
        }

        &:last-child {
            border-radius: 0 $button-border-radius $button-border-radius 0;
        }
    }
}
