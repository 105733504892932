.form-checkboxes-list {
    .form-checkbox {
        margin-top: $forms-checkboxes-list-margin-top;
    }
}

.form-checkbox {
    position: relative;
    display: inline-block;
    padding-left: $forms-checkbox-label-padding-left;
    font: {
        size: $forms-checkbox-label-font-size;
        weight: $forms-checkbox-label-font-weight;
    }
    margin-top: $forms-label-padding-top;
    min-height: $forms-checkbox-min-height;
    line-height: $forms-checkbox-label-line-height;
    padding: {
        top: $forms-checkbox-padding-vertical;
        bottom: $forms-checkbox-padding-vertical;
    }

    .custom-checkbox {
        position: absolute;
        top: 0;
        left: 0;
    }

    .table & {
        margin-top: 0;
    }

    .col-checkbox & {
        display: inline;
        padding-left: $forms-checkbox-size;
    }
}

.custom-checkbox {
    width: $forms-checkbox-size;
    height: $forms-checkbox-size;
    position: relative;

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;
        cursor: pointer;

        &:disabled {
            pointer-events: none;
        }

        &:disabled:hover + .custom-checkbox__layer {
            border-color: $forms-checkbox-border-color;
        }

        &:disabled + .custom-checkbox__layer {
            opacity: $forms-checkbox-disabled-opacity;
            pointer-events: none;
        }

        &:checked + .custom-checkbox__layer {
            border-color: $forms-checkbox-border-color-selected;
            background: $forms-checkbox-background-selected;

            &:before {
                opacity: 1;
            }
        }

        &.display-default:disabled + .custom-checkbox__layer {
            opacity: 1;
        }
    }

    .custom-checkbox__layer {
        display: block;
        height: inherit;
        border-radius: $forms-checkbox-border-radius;
        border: $forms-checkbox-border-size solid $forms-checkbox-border-color;
        background: $forms-checkbox-background;
        transition: border 0.2s linear, background 0.2s linear;

        &::before {
            opacity: 0;
            display: block;
            transition: opacity 0.2s linear;
            content: $forms-checkbox-tick-content;
            font: {
                size: $forms-checkbox-tick-size;
                family: $forms-checkbox-tick-font-name;
            }
            color: $forms-checkbox-tick-color;
            line-height: $forms-checkbox-size - $forms-checkbox-border-size * 2;
            text-align: center;

            .color--white & {
                color: #fff;
            }
        }
    }
}
