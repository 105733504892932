:root {
    --tooltip-color: #{$color-body};
    --tooltip-background-color: #{$background-secondary};
    --tooltip-arrow-color: #2f2f39;
    --tooltip-font-size: 15px/20px;
    --tooltip-font-style: bold italic;
    --tooltip-font-weight: 400;
    --tooltip-font-family: $font-family;
    --tooltip-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    --tooltip-border-radius: 5px;
    --tooltip-text-align: left;
    --tooltip-padding: 5px 10px;
}

.tippy-tooltip {
    background-color: var(--tooltip-background-color) !important;
    color: var(--tooltip-color) !important;
    font-size: var(--tooltip-font-size) !important;
    font-weight: var(--tooltip-font-weight) !important;
    font-style: var(--tooltip-font-style) !important;
    font-family: var(--tooltip-font-family) !important;
    box-shadow: var(--tooltip-box-shadow) !important;
    border-radius: var(--tooltip-border-radius) !important;
    text-align: var(--tooltip-text-align) !important;
    padding: var(--tooltip-padding) !important;
}

.tippy-tooltip[data-animatefill] {
    background-color: var(--tooltip-background-color) !important;
}

.tippy-tooltip .tippy-backdrop {
    background-color: var(--tooltip-background-color) !important;
}

.tippy-popper[x-placement^='top'] .tippy-tooltip .tippy-arrow {
    border-top-color: var(--tooltip-arrow-color) !important;
}

.tippy-popper[x-placement^='bottom'] .tippy-tooltip .tippy-arrow {
    border-bottom-color: var(--tooltip-arrow-color) !important;
}

.tippy-popper[x-placement^='left'] .tippy-tooltip .tippy-arrow {
    border-left-color: var(--tooltip-arrow-color) !important;
}

.tippy-popper[x-placement^='right'] .tippy-tooltip .tippy-arrow {
    border-right-color: var(--tooltip-arrow-color) !important;
}

.tippy-tooltip .tippy-roundarrow {
    fill: var(--tooltip-arrow-color) !important;
}
