.ngx-slider.ngx-slider {
    .ngx-slider-bubble {
        color: $color-primary;
        font-size: 1.2rem;
    }

    .ngx-slider-pointer {
        background: $color-primary;
        border: 2px solid $color-border;
        width: 28px;
        height: 28px;
        top: -12px;
        outline: none;

        &,
        &:hover,
        &.ngx-slider-active {
            &::after {
                background: $color-primary;
            }
        }
    }

    .ngx-slider-bar {
        background: $color-border;
    }

    .ngx-slider-model-value {
        font-size: 1.5rem;
    }
}
