.box-filters {
    align-items: center;
    border-radius: $box-filters-border-radius;
    display: flex;
    flex-wrap: wrap;
    margin: -1 * $box-filters-margin-vertical -1 * $box-filters-margin-horizontal;

    .range-separator {
        margin: 0 -10px;
    }

    .item-group {
        display: flex;
        margin: $box-filters-margin-vertical $box-filters-margin-horizontal;
    }

    .item {
        align-self: center;
        width: $box-filters-item-width;
        position: relative;

        #{$box-filters-item-select-selector} {
            width: $box-filters-item-select-width;
        }

        &.item--large {
            width: $box-filters-item-width-large;

            #{$box-filters-item-select-selector} {
                width: $box-filters-item-width-large;
            }
        }

        &.item--datepicker {
            width: 210px;

            #{$box-filters-item-select-selector} {
                width: 210px;
            }
        }

        &.item--datepicker-range {
            width: 330px;
        }

        &.item--label {
            color: $color-tertiary;
            margin-right: 0;
            width: auto;
            max-width: $box-filters-item-select-width;

            & + .item {
                margin-left: $box-filters-margin-vertical;
            }
        }

        &.item--with-icon {
            padding-left: $box-filters-item-icon-padding;
        }

        & > .form-input {
            width: 100%;
        }

        .form-checkbox {
            margin-top: 0;
        }
    }

    .item__title {
        font: {
            size: $box-filters-item-title-size;
            weight: 600;
        }
    }

    .item__label {
        display: block;
        font: {
            size: $box-filters-item-label-size;
        }
    }

    .filter-actions {
        margin: auto $box-filters-margin-horizontal auto auto;
    }
}

.box-filters__results-count {
    padding-bottom: 10px;
    border-bottom: 1px solid $color-border;
    min-height: 30px;
}

.box-filters--column-labels {
    .item-group {
        display: block;
        margin: {
            top: 10px;
            bottom: 10px;
        }
    }

    .filter-actions {
        margin-bottom: 10px;
    }

    .item:first-child:not(.item--label) {
        margin-top: 20px;
        min-height: 44px;
        display: flex;
        align-items: center;
    }

    .item.item--label {
        margin-bottom: 3px;

        & + .item {
            margin-left: 0;
        }
    }

    .box-filters {
        align-items: flex-start;
    }
}

@include media-query-max($breakpoint-md) {
    .box-filters {
        .item-group {
            margin: #{$box-filters-margin-vertical / 2} #{$box-filters-margin-horizontal / 2};
            width: 100%;
            flex-direction: column;
        }

        .item {
            &,
            &.item--label {
                width: 100% !important;
            }
        }

        .item.item--label {
            max-width: none;
            margin-bottom: 3px;

            & + .item {
                margin-left: 0;
            }
        }

        .filter-actions {
            margin: #{$box-filters-margin-vertical / 2} #{$box-filters-margin-horizontal / 2} auto auto;
        }
    }
}

@include media-query-max($breakpoint-sm) {
    .box-filters {
        margin: -1.5 * $box-filters-margin-vertical -1.5 * $box-filters-margin-horizontal;
    }
    sbm-box-filters {
        display: block;
        margin: 0 -15px;
    }
}
