.receiver-picker {
    display: flex;
    height: 660px;
    max-height: 80vh;
    min-height: 400px;
    position: relative;

    // for ng-scrollbar
    .tabs__tab {
        height: inherit;
    }

    .receiver-picker__close {
        position: absolute;
        font: {
            size: 12px;
        }
        z-index: 2;
        top: 8px;
        right: 10px;
        color: $color-primary;
        cursor: pointer;
    }

    .receiver-picker__tabs {
        display: flex;
        flex-direction: column;
        height: 100%;

        // to jest jedyny element bezpośredni ng-transclude który zawiera taby
        & > ng-transclude {
            position: relative;
            height: 100%;
        }

        .tabs__tab {
            padding: 0;
        }
    }

    .receiver-picker__filters-title {
        line-height: 25px;
        min-height: 25px;
        font: {
            weight: bold;
        }
        margin-bottom: 5px;

        //&::after {
        //    @include clear;
        //}
    }

    .receiver-picker__filters-box {
        background: #f8f8fa;
        border-top: 1px solid #dddddd;
        padding: 15px 20px 20px;
    }

    .receiver-picker__filters {
        &.found-list {
            margin-top: 20px;
            padding-right: 15px;
            margin-bottom: -13px;
        }

        &:after {
            display: block;
            content: '';
            clear: both;
        }
    }

    .receiver-picker__filters-action {
        float: right;
        position: relative;
        padding-right: 30px;
        line-height: 22px;
        font: {
            size: 12px;
        }

        @include styleFontIcon() {
            font: {
                size: 22px;
            }
            position: absolute;
            top: 0;
            right: 0;
            line-height: inherit;

            &.ico-gray {
                color: #aeb0b4;
            }
        }
    }

    ul {
        margin: 0;
        padding: 15px 0;
        list-style: none;

        &.small li {
            padding: 5px 10px;

            @include styleFontIcon() {
                width: 18px;
                font: {
                    size: 18px;
                }
            }
        }

        li {
            margin: 0;
            padding: 10px 15px;
            line-height: 20px;
            border-bottom: 1px solid #dddddd;
            display: flex;
            align-items: center;
            transition: brackground 0.2s linear;

            &.receiver-picker__team {
                padding: 0;

                &:hover {
                    background: none;
                }

                .receiver-picker__team-toggle {
                    padding: 10px 55px 10px 15px;
                    width: 100%;
                }

                .receiver-picker__team-content {
                    width: 100%;

                    ul {
                        padding: 0;
                    }

                    li {
                        border-top: 1px dashed #dddddd;
                        border-bottom: none;
                    }
                }
            }

            &:first-child {
                border-top: 1px solid #dddddd;
            }

            span {
                flex: 1;
            }

            @include styleFontIcon() {
                width: 22px;
                text-align: right;
                font: {
                    size: 22px;
                }
                transition: color 0.2s linear;
                color: $color-default;

                &.ico-circle-times {
                    color: #6bb2e9;
                }

                &:hover {
                    color: #1871c3;
                }
            }

            &:hover {
                background: #eef8fa;
            }
        }
    }
}

.receiver-picker__team {
    display: flex;
    flex-direction: column;
}

.receiver-picker__team-toggle {
    background: #eff0f0;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;

    &::after {
        font-family: $font-icon;
        content: getIconCode(angle-down);
        position: absolute;
        top: 50%;
        right: 15px;
        color: $color-primary;
        font: {
            size: 18px;
        }
        margin-top: -9px;
        transition: transform 0.2s linear;

        .receiver-picker__team.active & {
            transform: rotate(-180deg);
        }
    }

    .name {
        flex: 1;
    }

    .choose-group {
        line-height: 1;
        margin-left: 10px;

        @include styleFontIcon() {
            color: $color-tertiary;
        }
    }
}

.receiver-picker__team-content {
    overflow: hidden;
}

.receiver-picker__content {
    flex: 1;
    padding: 30px;
}

.receiver-picker__results {
    display: flex;
    flex-direction: column;
    width: 360px;
    background: $background-secondary;
}

.receiver-picker__results-list {
    padding: 45px 30px 30px;
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;

    &::after {
        position: absolute;
        display: block;
        top: 66px;
        left: 0;
        content: '';
        @include makeArrow(right, #fff, 10px, 10px);
    }
}

.receiver-picker__results-title {
    font: {
        size: 14px;
        weight: bold;
    }
    text-transform: uppercase;
    display: block;
    margin-bottom: 5px;
}

.receiver-picker__results-container {
    position: relative;
    flex: 1;

    .scroller {
        top: 20px;
        padding-right: 20px;
        right: -20px;
    }

    ul:first-child {
        padding-top: 0;
    }
}

.receiver-picker__results-save {
    background: $background-tertiary;
    padding: 20px 30px;
}

.receiver-picker__action-icon {
    width: 40px;
    line-height: 1;
}

.receiver-picker__add-item {
    line-height: 1;
    margin-left: 10px;
}

////////
// FOR NGX SPORTBM-CORE

.receiver-picker {
    sbm-tabs {
        display: block;
        height: inherit;

        .tabs__nav {
            padding-bottom: 20px;
        }

        .tabs {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        sbm-tab {
            flex: 1;
            height: 100%;
            position: relative;
        }
    }
}
