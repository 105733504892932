@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400|Open+Sans:300,400,600&display=swap");
@font-face {
  font-family: "sportbm";
  src: url("/assets/fonts/sportbm.woff2?bz9fsi&v=6") format("woff2"), url("/assets/fonts/sportbm.ttf?bz9fsi&v=6") format("truetype"), url("/assets/fonts/sportbm.woff?bz9fsi&v=6") format("woff"), url("/assets/fonts/sportbm.svg?bz9fsi&v=6#sportbm") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=ico-],
[class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "sportbm" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-about::before {
  content: "\e901";
}

.ico-about-light::before {
  content: "\e902";
}

.ico-academy::before {
  content: "\e904";
}

.ico-academy-light::before {
  content: "\e905";
}

.ico-alert-error::before {
  content: "\e907";
}

.ico-alert-error-light::before {
  content: "\e908";
}

.ico-alert-info::before {
  content: "\e90a";
}

.ico-alert-info-light::before {
  content: "\e90b";
}

.ico-alert-success::before {
  content: "\e90d";
}

.ico-alert-success-light::before {
  content: "\e90e";
}

.ico-alert-warning::before {
  content: "\e910";
}

.ico-alert-warning-light::before {
  content: "\e911";
}

.ico-angle-down::before {
  content: "\e913";
}

.ico-angle-down-light::before {
  content: "\e914";
}

.ico-angle-left::before {
  content: "\e916";
}

.ico-angle-left-light::before {
  content: "\e917";
}

.ico-angle-right::before {
  content: "\e919";
}

.ico-angle-right-light::before {
  content: "\e91a";
}

.ico-angle-up::before {
  content: "\e91c";
}

.ico-angle-up-light::before {
  content: "\e91d";
}

.ico-apps::before {
  content: "\e91f";
}

.ico-apps-light::before {
  content: "\e920";
}

.ico-apps-cog::before {
  content: "\e900";
}

.ico-apps-cog-light::before {
  content: "\e903";
}

.ico-archive::before {
  content: "\e922";
}

.ico-archive-light::before {
  content: "\e923";
}

.ico-arrow-back::before {
  content: "\e925";
}

.ico-arrow-back-light::before {
  content: "\e926";
}

.ico-arrow-forward::before {
  content: "\e942";
}

.ico-arrow-forward-light::before {
  content: "\e945";
}

.ico-arrow-horizontal::before {
  content: "\e936";
}

.ico-arrow-horizontal-light::before {
  content: "\e939";
}

.ico-arrow-left::before {
  content: "\e928";
}

.ico-arrow-left-light::before {
  content: "\e929";
}

.ico-arrow-right::before {
  content: "\e92b";
}

.ico-arrow-right-light::before {
  content: "\e92c";
}

.ico-arrow-vertical::before {
  content: "\e93c";
}

.ico-arrow-vertical-light::before {
  content: "\e93f";
}

.ico-article-add::before {
  content: "\e92e";
}

.ico-article-add-light::before {
  content: "\e92f";
}

.ico-bank-account-add::before {
  content: "\e931";
}

.ico-bank-account-add-light::before {
  content: "\e932";
}

.ico-bank-account::before {
  content: "\e934";
}

.ico-bank-account-light::before {
  content: "\e935";
}

.ico-bell-add::before {
  content: "\e937";
}

.ico-bell-add-light::before {
  content: "\e938";
}

.ico-bell-arrow::before {
  content: "\e93a";
}

.ico-bell-arrow-light::before {
  content: "\e93b";
}

.ico-bell::before {
  content: "\e93d";
}

.ico-bell-light::before {
  content: "\e93e";
}

.ico-bulb::before {
  content: "\e940";
}

.ico-bulb-light::before {
  content: "\e941";
}

.ico-calendar-add::before {
  content: "\e943";
}

.ico-calendar-add-light::before {
  content: "\e944";
}

.ico-calendar::before {
  content: "\e946";
}

.ico-calendar-light::before {
  content: "\e947";
}

.ico-calendar-remind::before {
  content: "\e949";
}

.ico-calendar-remind-light::before {
  content: "\e94a";
}

.ico-calendar-square::before {
  content: "\e991";
}

.ico-calendar-square-light::before {
  content: "\e994";
}

.ico-camp::before {
  content: "\e94c";
}

.ico-camp-light::before {
  content: "\e94d";
}

.ico-certification::before {
  content: "\e9d9";
}

.ico-certification-light::before {
  content: "\e9dc";
}

.ico-check::before {
  content: "\e94f";
}

.ico-check-light::before {
  content: "\e950";
}

.ico-circle-add::before {
  content: "\e952";
}

.ico-circle-add-light::before {
  content: "\e953";
}

.ico-circle-arrow-constants::before {
  content: "\e955";
}

.ico-circle-arrow-constants-light::before {
  content: "\e956";
}

.ico-circle-arrow-decrease::before {
  content: "\e958";
}

.ico-circle-arrow-decrease-light::before {
  content: "\e959";
}

.ico-circle-arrow-increase::before {
  content: "\e95b";
}

.ico-circle-arrow-increase-light::before {
  content: "\e95c";
}

.ico-circle-check::before {
  content: "\e95e";
}

.ico-circle-check-light::before {
  content: "\e95f";
}

.ico-circle-help::before {
  content: "\e961";
}

.ico-circle-help-light::before {
  content: "\e962";
}

.ico-circle-remove::before {
  content: "\e964";
}

.ico-circle-remove-light::before {
  content: "\e965";
}

.ico-circle-star::before {
  content: "\e967";
}

.ico-circle-star-light::before {
  content: "\e968";
}

.ico-circle-times::before {
  content: "\e96a";
}

.ico-circle-times-light::before {
  content: "\e96b";
}

.ico-clipboard-bookmark::before {
  content: "\e9a3";
}

.ico-clipboard-bookmark-light::before {
  content: "\e9a6";
}

.ico-clipboard-dots-bookmark::before {
  content: "\e99d";
}

.ico-clipboard-dots-bookmark-light::before {
  content: "\e9a0";
}

.ico-clock-history::before {
  content: "\e96d";
}

.ico-clock-history-light::before {
  content: "\e96e";
}

.ico-clock-forward::before {
  content: "\ea8e";
}

.ico-clock-forward-light::before {
  content: "\ea8f";
}

.ico-clock::before {
  content: "\e970";
}

.ico-clock-light::before {
  content: "\e971";
}

.ico-coach::before {
  content: "\e906";
}

.ico-coach-light::before {
  content: "\e909";
}

.ico-coaching-add::before {
  content: "\e973";
}

.ico-coaching-add-light::before {
  content: "\e974";
}

.ico-coaching::before {
  content: "\e976";
}

.ico-coaching-light::before {
  content: "\e977";
}

.ico-cog::before {
  content: "\e979";
}

.ico-cog-light::before {
  content: "\e97a";
}

.ico-consultation::before {
  content: "\e9f7";
}

.ico-consultation-light::before {
  content: "\e9fa";
}

.ico-cookies::before {
  content: "\e97c";
}

.ico-cookies-light::before {
  content: "\e97d";
}

.ico-copy::before {
  content: "\e972";
}

.ico-copy-light::before {
  content: "\e975";
}

.ico-devices::before {
  content: "\e97f";
}

.ico-devices-light::before {
  content: "\e980";
}

.ico-dots::before {
  content: "\e981";
}

.ico-download::before {
  content: "\e983";
}

.ico-download-light::before {
  content: "\e984";
}

.ico-edit::before {
  content: "\e986";
}

.ico-edit-light::before {
  content: "\e987";
}

.ico-edit-module::before {
  content: "\e989";
}

.ico-edit-module-light::before {
  content: "\e98a";
}

.ico-email::before {
  content: "\e98c";
}

.ico-email-light::before {
  content: "\e98d";
}

.ico-email-sms::before {
  content: "\e98f";
}

.ico-email-sms-light::before {
  content: "\e990";
}

.ico-exercise-add::before {
  content: "\e992";
}

.ico-exercise-add-light::before {
  content: "\e993";
}

.ico-exercise::before {
  content: "\e995";
}

.ico-exercise-light::before {
  content: "\e996";
}

.ico-exercise-plan-add::before {
  content: "\e998";
}

.ico-exercise-plan-add-light::before {
  content: "\e999";
}

.ico-exercise-plan::before {
  content: "\e99b";
}

.ico-exercise-plan-light::before {
  content: "\e99c";
}

.ico-facebook::before {
  content: "\e94b";
}

.ico-female::before {
  content: "\e9df";
}

.ico-female-light::before {
  content: "\e9e2";
}

.ico-file::before {
  content: "\e9c1";
}

.ico-file-light::before {
  content: "\e9c4";
}

.ico-folder::before {
  content: "\e9bb";
}

.ico-folder-light::before {
  content: "\e9be";
}

.ico-forms::before {
  content: "\e9eb";
}

.ico-forms-light::before {
  content: "\e9ee";
}

.ico-filter-clear::before {
  content: "\e99e";
}

.ico-filter-clear-light::before {
  content: "\e99f";
}

.ico-gallery::before {
  content: "\e9a1";
}

.ico-gallery-light::before {
  content: "\e9a2";
}

.ico-gathering::before {
  content: "\e9a4";
}

.ico-gathering-light::before {
  content: "\e9a5";
}

.ico-geolocation::before {
  content: "\e9a7";
}

.ico-geolocation-light::before {
  content: "\e9a8";
}

.ico-geolocation-target::before {
  content: "\e9aa";
}

.ico-geolocation-target-light::before {
  content: "\e9ab";
}

.ico-globe::before {
  content: "\e9ad";
}

.ico-globe-light::before {
  content: "\e9ae";
}

.ico-goal-add::before {
  content: "\e9b0";
}

.ico-goal-add-light::before {
  content: "\e9b1";
}

.ico-goal::before {
  content: "\e9b3";
}

.ico-goal-light::before {
  content: "\e9b4";
}

.ico-group-add::before {
  content: "\e9b6";
}

.ico-group-add-light::before {
  content: "\e9b7";
}

.ico-group::before {
  content: "\e9b9";
}

.ico-group-light::before {
  content: "\e9ba";
}

.ico-helmet::before {
  content: "\e9d3";
}

.ico-helmet-light::before {
  content: "\e9d6";
}

.ico-home::before {
  content: "\e9bc";
}

.ico-home-light::before {
  content: "\e9bd";
}

.ico-instagram::before {
  content: "\e951";
}

.ico-login::before {
  content: "\e9bf";
}

.ico-login-light::before {
  content: "\e9c0";
}

.ico-logout::before {
  content: "\e9c2";
}

.ico-logout-light::before {
  content: "\e9c3";
}

.ico-magic::before {
  content: "\e9c5";
}

.ico-magic-light::before {
  content: "\e9c6";
}

.ico-male::before {
  content: "\e9e5";
}

.ico-male-light::before {
  content: "\e9e8";
}

.ico-match::before {
  content: "\e9c8";
}

.ico-match-light::before {
  content: "\e9c9";
}

.ico-medal::before {
  content: "\e9cb";
}

.ico-medal-light::before {
  content: "\e9cc";
}

.ico-medical-exam::before {
  content: "\e91e";
}

.ico-medical-exam-light::before {
  content: "\e921";
}

.ico-meeting::before {
  content: "\e9ce";
}

.ico-meeting-light::before {
  content: "\e9cf";
}

.ico-menu::before {
  content: "\e9d1";
}

.ico-menu-light::before {
  content: "\e9d2";
}

.ico-message-add::before {
  content: "\e9d4";
}

.ico-message-add-light::before {
  content: "\e9d5";
}

.ico-message-cog::before {
  content: "\e96c";
}

.ico-message-cog-light::before {
  content: "\e96f";
}

.ico-message-forward::before {
  content: "\e960";
}

.ico-message-forward-light::before {
  content: "\e963";
}

.ico-message-order::before {
  content: "\e966";
}

.ico-message-order-light::before {
  content: "\e969";
}

.ico-message::before {
  content: "\e9d7";
}

.ico-message-light::before {
  content: "\e9d8";
}

.ico-messaging::before {
  content: "\e9da";
}

.ico-messaging-light::before {
  content: "\e9db";
}

.ico-order::before {
  content: "\e94e";
}

.ico-order-light::before {
  content: "\e957";
}

.ico-page::before {
  content: "\e9dd";
}

.ico-page-light::before {
  content: "\e9de";
}

.ico-page-attachment::before {
  content: "\e98b";
}

.ico-page-attachment-light::before {
  content: "\e98e";
}

.ico-page-download::before {
  content: "\e985";
}

.ico-page-download-light::before {
  content: "\e988";
}

.ico-page-preview::before {
  content: "\e95a";
}

.ico-page-preview-light::before {
  content: "\e95d";
}

.ico-payment-add::before {
  content: "\e9e0";
}

.ico-payment-add-light::before {
  content: "\e9e1";
}

.ico-payment::before {
  content: "\e9e3";
}

.ico-payment-light::before {
  content: "\e9e4";
}

.ico-payment-return::before {
  content: "\e90c";
}

.ico-payment-return-light::before {
  content: "\e90f";
}

.ico-payments-bookmark::before {
  content: "\e997";
}

.ico-payments-bookmark-light::before {
  content: "\e99a";
}

.ico-payments-summary::before {
  content: "\e9b5";
}

.ico-payments-summary-light::before {
  content: "\e9b8";
}

.ico-payments-warning::before {
  content: "\e9b2";
}

.ico-payments-warning-light::before {
  content: "\e9af";
}

.ico-phone::before {
  content: "\e9e6";
}

.ico-phone-light::before {
  content: "\e9e7";
}

.ico-phone-mail::before {
  content: "\e9e9";
}

.ico-phone-mail-light::before {
  content: "\e9ea";
}

.ico-phone-message::before {
  content: "\e9ec";
}

.ico-phone-message-light::before {
  content: "\e9ed";
}

.ico-plus::before {
  content: "\ea8b";
}

.ico-plus-light::before {
  content: "\ea8c";
}

.ico-podium::before {
  content: "\e92a";
}

.ico-podium-light::before {
  content: "\e92d";
}

.ico-preview::before {
  content: "\e9ef";
}

.ico-preview-light::before {
  content: "\e9f0";
}

.ico-preview-off::before {
  content: "\e9f2";
}

.ico-preview-off-light::before {
  content: "\e9f3";
}

.ico-register::before {
  content: "\e9f5";
}

.ico-register-light::before {
  content: "\e9f6";
}

.ico-reorder::before {
  content: "\e9f8";
}

.ico-reorder-light::before {
  content: "\e9f9";
}

.ico-report::before {
  content: "\e9fb";
}

.ico-report-light::before {
  content: "\e9fc";
}

.ico-reviews::before {
  content: "\e9fe";
}

.ico-reviews-light::before {
  content: "\e9ff";
}

.ico-save::before {
  content: "\ea01";
}

.ico-save-light::before {
  content: "\ea02";
}

.ico-search::before {
  content: "\ea04";
}

.ico-search-light::before {
  content: "\ea05";
}

.ico-secure-payments::before {
  content: "\e912";
}

.ico-secure-payments-light::before {
  content: "\e915";
}

.ico-share::before {
  content: "\e9cd";
}

.ico-share-light::before {
  content: "\e9d0";
}

.ico-shield::before {
  content: "\e930";
}

.ico-shield-light::before {
  content: "\e933";
}

.ico-shop-add::before {
  content: "\ea07";
}

.ico-shop-add-light::before {
  content: "\ea08";
}

.ico-shop-times::before {
  content: "\e978";
}

.ico-shop-times-light::before {
  content: "\e97b";
}

.ico-shop::before {
  content: "\ea0a";
}

.ico-shop-light::before {
  content: "\ea0b";
}

.ico-sliders::before {
  content: "\ea0d";
}

.ico-sliders-light::before {
  content: "\ea0e";
}

.ico-sms::before {
  content: "\ea10";
}

.ico-sms-light::before {
  content: "\ea11";
}

.ico-socialmedia::before {
  content: "\ea13";
}

.ico-socialmedia-light::before {
  content: "\ea14";
}

.ico-sort-down::before {
  content: "\ea15";
}

.ico-sort-up::before {
  content: "\ea16";
}

.ico-sparring::before {
  content: "\ea18";
}

.ico-sparring-light::before {
  content: "\ea19";
}

.ico-special-event-add::before {
  content: "\ea1b";
}

.ico-special-event-add-light::before {
  content: "\ea1c";
}

.ico-special-event::before {
  content: "\ea1e";
}

.ico-special-event-light::before {
  content: "\ea1f";
}

.ico-square-add::before {
  content: "\ea21";
}

.ico-square-add-light::before {
  content: "\ea22";
}

.ico-square-check::before {
  content: "\ea24";
}

.ico-square-check-light::before {
  content: "\ea25";
}

.ico-square-remove::before {
  content: "\ea27";
}

.ico-square-remove-light::before {
  content: "\ea28";
}

.ico-star::before {
  content: "\ea29";
}

.ico-star-empty::before {
  content: "\e948";
}

.ico-star-half::before {
  content: "\ea2b";
}

.ico-star-half-light::before {
  content: "\ea2c";
}

.ico-star-outline::before {
  content: "\ea2e";
}

.ico-star-outline-light::before {
  content: "\ea2f";
}

.ico-statistics::before {
  content: "\e918";
}

.ico-statistics-light::before {
  content: "\e91b";
}

.ico-step-by-step::before {
  content: "\ea31";
}

.ico-step-by-step-light::before {
  content: "\ea32";
}

.ico-submission-user::before {
  content: "\e9f1";
}

.ico-submission-user-light::before {
  content: "\e9f4";
}

.ico-switch::before {
  content: "\ea34";
}

.ico-switch-light::before {
  content: "\ea35";
}

.ico-tag::before {
  content: "\e97e";
}

.ico-tag-light::before {
  content: "\e982";
}

.ico-task-add::before {
  content: "\ea37";
}

.ico-task-add-light::before {
  content: "\ea38";
}

.ico-task-check::before {
  content: "\ea3a";
}

.ico-task-check-light::before {
  content: "\ea3b";
}

.ico-task-check-times::before {
  content: "\ea3d";
}

.ico-task-check-times-light::before {
  content: "\ea3e";
}

.ico-task-dots::before {
  content: "\ea40";
}

.ico-task-dots-light::before {
  content: "\ea41";
}

.ico-task::before {
  content: "\ea43";
}

.ico-task-light::before {
  content: "\ea44";
}

.ico-task-multi-check::before {
  content: "\ea46";
}

.ico-task-multi-check-light::before {
  content: "\ea47";
}

.ico-task-star::before {
  content: "\ea49";
}

.ico-task-star-light::before {
  content: "\ea4a";
}

.ico-task-text::before {
  content: "\ea4c";
}

.ico-task-text-light::before {
  content: "\ea4d";
}

.ico-task-times::before {
  content: "\ea4f";
}

.ico-task-times-light::before {
  content: "\ea50";
}

.ico-team-add::before {
  content: "\ea52";
}

.ico-team-add-light::before {
  content: "\ea53";
}

.ico-team::before {
  content: "\ea55";
}

.ico-team-light::before {
  content: "\ea56";
}

.ico-test::before {
  content: "\e924";
}

.ico-test-light::before {
  content: "\e927";
}

.ico-thumb-up::before {
  content: "\ea58";
}

.ico-thumb-up-light::before {
  content: "\ea59";
}

.ico-tiktok::before {
  content: "\e9fd";
}

.ico-times::before {
  content: "\ea5b";
}

.ico-times-light::before {
  content: "\ea5c";
}

.ico-todo-add::before {
  content: "\ea5e";
}

.ico-todo-add-light::before {
  content: "\ea5f";
}

.ico-todo::before {
  content: "\ea61";
}

.ico-todo-light::before {
  content: "\ea62";
}

.ico-training-add::before {
  content: "\ea64";
}

.ico-training-add-light::before {
  content: "\ea65";
}

.ico-training::before {
  content: "\ea67";
}

.ico-training-light::before {
  content: "\ea68";
}

.ico-training-plan-add::before {
  content: "\ea6a";
}

.ico-training-plan-add-light::before {
  content: "\ea6b";
}

.ico-training-plan::before {
  content: "\ea6d";
}

.ico-training-plan-light::before {
  content: "\ea6e";
}

.ico-training-plan-check::before {
  content: "\ea70";
}

.ico-training-plan-check-light::before {
  content: "\ea71";
}

.ico-trash::before {
  content: "\ea73";
}

.ico-trash-light::before {
  content: "\ea74";
}

.ico-trophy::before {
  content: "\ea76";
}

.ico-trophy-light::before {
  content: "\ea77";
}

.ico-twitter::before {
  content: "\e954";
}

.ico-user-add::before {
  content: "\ea79";
}

.ico-user-add-light::before {
  content: "\ea7a";
}

.ico-user::before {
  content: "\ea7c";
}

.ico-user-light::before {
  content: "\ea7d";
}

.ico-user-assign::before {
  content: "\e9c7";
}

.ico-user-assign-light::before {
  content: "\e9ca";
}

.ico-users::before {
  content: "\ea7f";
}

.ico-users-light::before {
  content: "\ea80";
}

.ico-users-question::before {
  content: "\e9ac";
}

.ico-users-question-light::before {
  content: "\e9a9";
}

.ico-webinar-add::before {
  content: "\ea82";
}

.ico-webinar-add-light::before {
  content: "\ea83";
}

.ico-webinar::before {
  content: "\ea85";
}

.ico-webinar-light::before {
  content: "\ea86";
}

.ico-whistle::before {
  content: "\ea88";
}

.ico-whistle-light::before {
  content: "\ea89";
}

.icon--xs {
  font-size: 0.5rem;
}

.icon--sm {
  font-size: 0.8rem;
}

.icon--md {
  font-size: 1.8rem;
}

.icon--lg {
  font-size: 2.5rem;
}

.icon--xl {
  font-size: 3rem;
}

.icon--xxl {
  font-size: 4rem;
}

.icon--form {
  font-size: 4.2rem;
}

.wrapper {
  box-sizing: border-box;
  max-width: 1700px;
  margin: 0 auto;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.row.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.col.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.col-xs {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex-basis: auto;
  -moz-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.col-xs-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex-basis: 8.3333333333%;
  -moz-flex-basis: 8.3333333333%;
  -ms-flex-preferred-size: 8.3333333333%;
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-xs-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex-basis: 16.6666666667%;
  -moz-flex-basis: 16.6666666667%;
  -ms-flex-preferred-size: 16.6666666667%;
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-xs-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex-basis: 25%;
  -moz-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex-basis: 33.3333333333%;
  -moz-flex-basis: 33.3333333333%;
  -ms-flex-preferred-size: 33.3333333333%;
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-xs-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex-basis: 41.6666666667%;
  -moz-flex-basis: 41.6666666667%;
  -ms-flex-preferred-size: 41.6666666667%;
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-xs-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex-basis: 50%;
  -moz-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex-basis: 58.3333333333%;
  -moz-flex-basis: 58.3333333333%;
  -ms-flex-preferred-size: 58.3333333333%;
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-xs-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex-basis: 66.6666666667%;
  -moz-flex-basis: 66.6666666667%;
  -ms-flex-preferred-size: 66.6666666667%;
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-xs-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex-basis: 75%;
  -moz-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex-basis: 83.3333333333%;
  -moz-flex-basis: 83.3333333333%;
  -ms-flex-preferred-size: 83.3333333333%;
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-xs-11 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex-basis: 91.6666666667%;
  -moz-flex-basis: 91.6666666667%;
  -ms-flex-preferred-size: 91.6666666667%;
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-xs-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 8.3333333333%;
}

.col-xs-offset-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 16.6666666667%;
}

.col-xs-offset-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 25%;
}

.col-xs-offset-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 33.3333333333%;
}

.col-xs-offset-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 41.6666666667%;
}

.col-xs-offset-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 50%;
}

.col-xs-offset-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 58.3333333333%;
}

.col-xs-offset-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 66.6666666667%;
}

.col-xs-offset-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 75%;
}

.col-xs-offset-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 83.3333333333%;
}

.col-xs-offset-11 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 91.6666666667%;
}

.col-xs-offset-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 100%;
}

.col-xs {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 0;
  -moz-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}

.start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center;
}

.end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start;
}

.middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
}

.around-xs {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
}

.between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (min-width: 479px) {
  .container {
    width: 480px;
  }
  .col-xs {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .col-xs-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 8.3333333333%;
    -moz-flex-basis: 8.3333333333%;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xs-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 16.6666666667%;
    -moz-flex-basis: 16.6666666667%;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xs-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 33.3333333333%;
    -moz-flex-basis: 33.3333333333%;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xs-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 41.6666666667%;
    -moz-flex-basis: 41.6666666667%;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xs-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 58.3333333333%;
    -moz-flex-basis: 58.3333333333%;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xs-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 66.6666666667%;
    -moz-flex-basis: 66.6666666667%;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xs-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 83.3333333333%;
    -moz-flex-basis: 83.3333333333%;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xs-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 91.6666666667%;
    -moz-flex-basis: 91.6666666667%;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xs-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-xs-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 8.3333333333%;
  }
  .col-xs-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 16.6666666667%;
  }
  .col-xs-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 25%;
  }
  .col-xs-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 33.3333333333%;
  }
  .col-xs-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 41.6666666667%;
  }
  .col-xs-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 50%;
  }
  .col-xs-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 58.3333333333%;
  }
  .col-xs-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 66.6666666667%;
  }
  .col-xs-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 75%;
  }
  .col-xs-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 83.3333333333%;
  }
  .col-xs-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 91.6666666667%;
  }
  .col-xs-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 100%;
  }
  .col-xs {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .start-xs {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-xs {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center;
  }
  .end-xs {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-xs {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start;
  }
  .middle-xs {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
  }
  .bottom-xs {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end;
  }
  .around-xs {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around;
  }
  .between-xs {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
  .first-xs {
    order: -1;
  }
  .last-xs {
    order: 1;
  }
}
@media only screen and (min-width: 767px) {
  .container {
    width: 768px;
  }
  .col-sm {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .col-sm-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 8.3333333333%;
    -moz-flex-basis: 8.3333333333%;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 16.6666666667%;
    -moz-flex-basis: 16.6666666667%;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 33.3333333333%;
    -moz-flex-basis: 33.3333333333%;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 41.6666666667%;
    -moz-flex-basis: 41.6666666667%;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 58.3333333333%;
    -moz-flex-basis: 58.3333333333%;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 66.6666666667%;
    -moz-flex-basis: 66.6666666667%;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 83.3333333333%;
    -moz-flex-basis: 83.3333333333%;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 91.6666666667%;
    -moz-flex-basis: 91.6666666667%;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-sm-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 8.3333333333%;
  }
  .col-sm-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 16.6666666667%;
  }
  .col-sm-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 33.3333333333%;
  }
  .col-sm-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 41.6666666667%;
  }
  .col-sm-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 58.3333333333%;
  }
  .col-sm-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 66.6666666667%;
  }
  .col-sm-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 83.3333333333%;
  }
  .col-sm-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 91.6666666667%;
  }
  .col-sm-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 100%;
  }
  .col-sm {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center;
  }
  .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start;
  }
  .middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
  }
  .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end;
  }
  .around-sm {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around;
  }
  .between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
  .first-sm {
    order: -1;
  }
  .last-sm {
    order: 1;
  }
}
@media only screen and (min-width: 993px) {
  .container {
    width: 994px;
  }
  .col-md {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .col-md-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 8.3333333333%;
    -moz-flex-basis: 8.3333333333%;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 16.6666666667%;
    -moz-flex-basis: 16.6666666667%;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 33.3333333333%;
    -moz-flex-basis: 33.3333333333%;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 41.6666666667%;
    -moz-flex-basis: 41.6666666667%;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 58.3333333333%;
    -moz-flex-basis: 58.3333333333%;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 66.6666666667%;
    -moz-flex-basis: 66.6666666667%;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 83.3333333333%;
    -moz-flex-basis: 83.3333333333%;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 91.6666666667%;
    -moz-flex-basis: 91.6666666667%;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-md-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 8.3333333333%;
  }
  .col-md-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 16.6666666667%;
  }
  .col-md-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 25%;
  }
  .col-md-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 33.3333333333%;
  }
  .col-md-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 41.6666666667%;
  }
  .col-md-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 50%;
  }
  .col-md-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 58.3333333333%;
  }
  .col-md-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 66.6666666667%;
  }
  .col-md-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 75%;
  }
  .col-md-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 83.3333333333%;
  }
  .col-md-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 91.6666666667%;
  }
  .col-md-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 100%;
  }
  .col-md {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center;
  }
  .end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start;
  }
  .middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
  }
  .bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end;
  }
  .around-md {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around;
  }
  .between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
  .first-md {
    order: -1;
  }
  .last-md {
    order: 1;
  }
}
@media only screen and (min-width: 1199px) {
  .container {
    width: 1200px;
  }
  .col-lg {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .col-lg-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 8.3333333333%;
    -moz-flex-basis: 8.3333333333%;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 16.6666666667%;
    -moz-flex-basis: 16.6666666667%;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 33.3333333333%;
    -moz-flex-basis: 33.3333333333%;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 41.6666666667%;
    -moz-flex-basis: 41.6666666667%;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 58.3333333333%;
    -moz-flex-basis: 58.3333333333%;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 66.6666666667%;
    -moz-flex-basis: 66.6666666667%;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 83.3333333333%;
    -moz-flex-basis: 83.3333333333%;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 91.6666666667%;
    -moz-flex-basis: 91.6666666667%;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-lg-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 8.3333333333%;
  }
  .col-lg-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 16.6666666667%;
  }
  .col-lg-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 33.3333333333%;
  }
  .col-lg-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 41.6666666667%;
  }
  .col-lg-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 58.3333333333%;
  }
  .col-lg-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 66.6666666667%;
  }
  .col-lg-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 83.3333333333%;
  }
  .col-lg-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 91.6666666667%;
  }
  .col-lg-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 100%;
  }
  .col-lg {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center;
  }
  .end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start;
  }
  .middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
  }
  .bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end;
  }
  .around-lg {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around;
  }
  .between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
  .first-lg {
    order: -1;
  }
  .last-lg {
    order: 1;
  }
}
.switch.switch-small {
  min-width: 33px;
}

.switch.switch-medium {
  min-width: 50px;
}

.switch.switch-large {
  min-width: 60px;
}

.switch.switch-small > .switch-pane > span {
  font-size: 9px;
}

.switch.switch-medium > .switch-pane > span {
  font-size: 16px;
}

.switch.switch-large > .switch-pane > span {
  font-size: 16px;
}

.switch {
  border: 1px solid #dfdfdf;
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  padding: 0;
  margin: 0;
  cursor: pointer;
  box-shadow: rgb(223, 223, 223) 0 0 0 0 inset;
  transition: 0.3s ease-out all;
  -webkit-transition: 0.3s ease-out all;
  white-space: nowrap;
}
.switch small {
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  right: calc(100% - 30px);
  transition: 0.3s ease-out all;
  -webkit-transition: 0.3s ease-out all;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.switch.switch-small {
  height: 20px;
  border-radius: 20px;
}
.switch.switch-small small {
  width: 20px;
  height: 20px;
  right: calc(100% - 20px);
}
.switch.switch-small > .switch-pane > span {
  line-height: 20px;
}
.switch.switch-small > .switch-pane .switch-label-checked {
  padding-right: 25px;
  padding-left: 10px;
}
.switch.switch-small > .switch-pane .switch-label-unchecked {
  padding-left: 25px;
  padding-right: 10px;
}
.switch.switch-medium {
  height: 30px;
  border-radius: 30px;
}
.switch.switch-medium small {
  width: 30px;
  height: 30px;
  right: calc(100% - 30px);
}
.switch.switch-medium > .switch-pane > span {
  line-height: 30px;
}
.switch.switch-medium > .switch-pane .switch-label-checked {
  padding-right: 35px;
  padding-left: 15px;
}
.switch.switch-medium > .switch-pane .switch-label-unchecked {
  padding-left: 35px;
  padding-right: 15px;
}
.switch.switch-large {
  height: 40px;
  border-radius: 40px;
}
.switch.switch-large small {
  width: 40px;
  height: 40px;
  right: calc(100% - 40px);
}
.switch.switch-large > .switch-pane > span {
  line-height: 40px;
}
.switch.switch-large > .switch-pane .switch-label-checked {
  padding-right: 45px;
  padding-left: 20px;
}
.switch.switch-large > .switch-pane .switch-label-unchecked {
  padding-left: 45px;
  padding-right: 20px;
}
.switch.checked {
  background: rgb(100, 189, 99);
}
.switch.checked small {
  right: 0;
  left: auto;
}
.switch.checked .switch-pane {
  top: 0;
}
.switch.checked .switch-pane .switch-label-checked {
  display: block;
}
.switch.checked .switch-pane .switch-label-unchecked {
  display: none;
}
.switch.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.switch .switch-pane {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  justify-content: flex-start;
  align-items: center;
  top: 0;
  position: relative;
  pointer-events: none;
}
.switch .switch-pane > span {
  display: block;
  min-height: 100%;
}
.switch .switch-pane .switch-label {
  color: black;
}
.switch .switch-pane .switch-label-checked {
  display: none;
}
.switch .switch-pane .switch-label-unchecked {
  display: block;
}
.switch.loading {
  background-color: #f1f1f1;
}
.switch.loading small {
  background-color: transparent;
  border: none;
  box-shadow: none;
  right: 50%;
  transform: translateX(50%);
}

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0;
}

/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Links
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button,
[type=button],
[type=reset],
[type=submit] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
  /**
   * Remove the inner padding in Chrome and Safari on macOS.
   */
}
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  color: inherit; /* 2 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}

mark {
  background: #4f81bb;
  color: #ffffff;
  display: inline-block;
  padding: 1px 7px;
  margin: -1px 2px;
}

hr {
  margin: 30px 0;
  border: none;
  border-top: 1px solid #d8d8d8;
}

.row .col {
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-uppercase {
  text-transform: uppercase;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.height-full {
  height: 100%;
}

@media only screen and (min-width: 479px) {
  .xs-hide {
    display: none;
  }
  .xs-show {
    display: block;
  }
  .xs-text-left {
    text-align: left;
  }
  .xs-text-center {
    text-align: center;
  }
  .xs-text-right {
    text-align: right;
  }
  .xs-text-justify {
    text-align: justify;
  }
  .xs-text-uppercase {
    text-transform: uppercase;
  }
  .xs-float-left {
    float: left;
  }
  .xs-float-right {
    float: right;
  }
  .xs-float-none {
    float: none;
  }
}
@media only screen and (min-width: 767px) {
  .sm-hide {
    display: none;
  }
  .sm-show {
    display: block;
  }
  .sm-text-left {
    text-align: left;
  }
  .sm-text-center {
    text-align: center;
  }
  .sm-text-right {
    text-align: right;
  }
  .sm-text-justify {
    text-align: justify;
  }
  .sm-text-uppercase {
    text-transform: uppercase;
  }
  .sm-float-left {
    float: left;
  }
  .sm-float-right {
    float: right;
  }
  .sm-float-none {
    float: none;
  }
}
@media only screen and (min-width: 993px) {
  .md-hide {
    display: none;
  }
  .md-show {
    display: block;
  }
  .md-text-left {
    text-align: left;
  }
  .md-text-center {
    text-align: center;
  }
  .md-text-right {
    text-align: right;
  }
  .md-text-justify {
    text-align: justify;
  }
  .md-text-uppercase {
    text-transform: uppercase;
  }
  .md-float-left {
    float: left;
  }
  .md-float-right {
    float: right;
  }
  .md-float-none {
    float: none;
  }
}
@media only screen and (min-width: 1199px) {
  .lg-hide {
    display: none;
  }
  .lg-show {
    display: block;
  }
  .lg-text-left {
    text-align: left;
  }
  .lg-text-center {
    text-align: center;
  }
  .lg-text-right {
    text-align: right;
  }
  .lg-text-justify {
    text-align: justify;
  }
  .lg-text-uppercase {
    text-transform: uppercase;
  }
  .lg-float-left {
    float: left;
  }
  .lg-float-right {
    float: right;
  }
  .lg-float-none {
    float: none;
  }
}
.bg-primary {
  background: #fff;
  color: #000000;
}

.bg-secondary {
  background: #f0f3f6;
  color: #000000;
}

.bg-tertiary {
  background: #778593;
  color: #ffffff;
}

.bg-other {
  background: #778593;
  color: #ffffff;
}

.bg-quaternary {
  background: #e0f1f8;
  color: #000000;
}

.bg-success {
  background: #5fa200;
  color: #ffffff;
}

.bg-info {
  background: #4f81bb;
  color: #ffffff;
}

.bg-warning {
  background: #f49317;
  color: #ffffff;
}

.bg-danger {
  background: #d10000;
  color: #ffffff;
}

.text-primary {
  color: #4f81bb;
}

.text-secondary {
  color: #464e57;
}

.text-tertiary {
  color: #778593;
}

.text-lime {
  color: #b6cd2a;
}

.text-other {
  color: #778593;
}

.text-success {
  color: #5fa200;
}

.text-info {
  color: #4f81bb;
}

.text-warning {
  color: #f49317;
}

.text-danger {
  color: #d10000;
}

.text-inverse {
  color: #ffffff;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.pd-0 {
  padding: 0px;
}

.pd-hor-0,
.pd-lr-0,
.pd-x-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.pd-ver-0,
.pd-tb-0,
.pd-y-0 {
  padding-bottom: 0px;
  padding-top: 0px;
}

.pd-t-0 {
  padding-top: 0px;
}

.pd-b-0 {
  padding-bottom: 0px;
}

.pd-l-0 {
  padding-left: 0px;
}

.pd-r-0 {
  padding-right: 0px;
}

.pd-5 {
  padding: 5px;
}

.pd-hor-5,
.pd-lr-5,
.pd-x-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.pd-ver-5,
.pd-tb-5,
.pd-y-5 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.pd-t-5 {
  padding-top: 5px;
}

.pd-b-5 {
  padding-bottom: 5px;
}

.pd-l-5 {
  padding-left: 5px;
}

.pd-r-5 {
  padding-right: 5px;
}

.pd-10 {
  padding: 10px;
}

.pd-hor-10,
.pd-lr-10,
.pd-x-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pd-ver-10,
.pd-tb-10,
.pd-y-10 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.pd-t-10 {
  padding-top: 10px;
}

.pd-b-10 {
  padding-bottom: 10px;
}

.pd-l-10 {
  padding-left: 10px;
}

.pd-r-10 {
  padding-right: 10px;
}

.pd-15 {
  padding: 15px;
}

.pd-hor-15,
.pd-lr-15,
.pd-x-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.pd-ver-15,
.pd-tb-15,
.pd-y-15 {
  padding-bottom: 15px;
  padding-top: 15px;
}

.pd-t-15 {
  padding-top: 15px;
}

.pd-b-15 {
  padding-bottom: 15px;
}

.pd-l-15 {
  padding-left: 15px;
}

.pd-r-15 {
  padding-right: 15px;
}

.pd-30 {
  padding: 30px;
}

.pd-hor-30,
.pd-lr-30,
.pd-x-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.pd-ver-30,
.pd-tb-30,
.pd-y-30 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.pd-t-30 {
  padding-top: 30px;
}

.pd-b-30 {
  padding-bottom: 30px;
}

.pd-l-30 {
  padding-left: 30px;
}

.pd-r-30 {
  padding-right: 30px;
}

.pd-45 {
  padding: 45px;
}

.pd-hor-45,
.pd-lr-45,
.pd-x-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.pd-ver-45,
.pd-tb-45,
.pd-y-45 {
  padding-bottom: 45px;
  padding-top: 45px;
}

.pd-t-45 {
  padding-top: 45px;
}

.pd-b-45 {
  padding-bottom: 45px;
}

.pd-l-45 {
  padding-left: 45px;
}

.pd-r-45 {
  padding-right: 45px;
}

.pd-60 {
  padding: 60px;
}

.pd-hor-60,
.pd-lr-60,
.pd-x-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.pd-ver-60,
.pd-tb-60,
.pd-y-60 {
  padding-bottom: 60px;
  padding-top: 60px;
}

.pd-t-60 {
  padding-top: 60px;
}

.pd-b-60 {
  padding-bottom: 60px;
}

.pd-l-60 {
  padding-left: 60px;
}

.pd-r-60 {
  padding-right: 60px;
}

.mg-0 {
  margin: 0px;
}

.mg-hor-0,
.mg-lr-0,
.mg-x-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mg-ver-0,
.mg-tb-0,
.mg-y-0 {
  margin-bottom: 0px;
  margin-top: 0px;
}

.mg-t-0 {
  margin-top: 0px;
}

.mg-b-0 {
  margin-bottom: 0px;
}

.mg-l-0 {
  margin-left: 0px;
}

.mg-r-0 {
  margin-right: 0px;
}

.mg-5 {
  margin: 5px;
}

.mg-hor-5,
.mg-lr-5,
.mg-x-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mg-ver-5,
.mg-tb-5,
.mg-y-5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.mg-t-5 {
  margin-top: 5px;
}

.mg-b-5 {
  margin-bottom: 5px;
}

.mg-l-5 {
  margin-left: 5px;
}

.mg-r-5 {
  margin-right: 5px;
}

.mg-10 {
  margin: 10px;
}

.mg-hor-10,
.mg-lr-10,
.mg-x-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mg-ver-10,
.mg-tb-10,
.mg-y-10 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.mg-t-10 {
  margin-top: 10px;
}

.mg-b-10 {
  margin-bottom: 10px;
}

.mg-l-10 {
  margin-left: 10px;
}

.mg-r-10 {
  margin-right: 10px;
}

.mg-15 {
  margin: 15px;
}

.mg-hor-15,
.mg-lr-15,
.mg-x-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mg-ver-15,
.mg-tb-15,
.mg-y-15 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.mg-t-15 {
  margin-top: 15px;
}

.mg-b-15 {
  margin-bottom: 15px;
}

.mg-l-15 {
  margin-left: 15px;
}

.mg-r-15 {
  margin-right: 15px;
}

.mg-30 {
  margin: 30px;
}

.mg-hor-30,
.mg-lr-30,
.mg-x-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mg-ver-30,
.mg-tb-30,
.mg-y-30 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.mg-t-30 {
  margin-top: 30px;
}

.mg-b-30 {
  margin-bottom: 30px;
}

.mg-l-30 {
  margin-left: 30px;
}

.mg-r-30 {
  margin-right: 30px;
}

.mg-45 {
  margin: 45px;
}

.mg-hor-45,
.mg-lr-45,
.mg-x-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.mg-ver-45,
.mg-tb-45,
.mg-y-45 {
  margin-bottom: 45px;
  margin-top: 45px;
}

.mg-t-45 {
  margin-top: 45px;
}

.mg-b-45 {
  margin-bottom: 45px;
}

.mg-l-45 {
  margin-left: 45px;
}

.mg-r-45 {
  margin-right: 45px;
}

.mg-60 {
  margin: 60px;
}

.mg-hor-60,
.mg-lr-60,
.mg-x-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.mg-ver-60,
.mg-tb-60,
.mg-y-60 {
  margin-bottom: 60px;
  margin-top: 60px;
}

.mg-t-60 {
  margin-top: 60px;
}

.mg-b-60 {
  margin-bottom: 60px;
}

.mg-l-60 {
  margin-left: 60px;
}

.mg-r-60 {
  margin-right: 60px;
}

.mg-l-auto {
  margin-left: auto;
}

.mg-r-auto {
  margin-right: auto;
}

.mg-t-auto {
  margin-top: auto;
}

.mg-b-auto {
  margin-bottom: auto;
}

.mg-auto {
  margin: auto;
}

@media only screen and (min-width: 479px) {
  .pd-xs-0 {
    padding: 0px;
  }
  .pd-xs-hor-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pd-xs-ver-0 {
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .pd-xs-t-0 {
    padding-top: 0px;
  }
  .pd-xs-b-0 {
    padding-bottom: 0px;
  }
  .pd-xs-l-0 {
    padding-left: 0px;
  }
  .pd-xs-r-0 {
    padding-right: 0px;
  }
  .pd-xs-5 {
    padding: 5px;
  }
  .pd-xs-hor-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .pd-xs-ver-5 {
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .pd-xs-t-5 {
    padding-top: 5px;
  }
  .pd-xs-b-5 {
    padding-bottom: 5px;
  }
  .pd-xs-l-5 {
    padding-left: 5px;
  }
  .pd-xs-r-5 {
    padding-right: 5px;
  }
  .pd-xs-10 {
    padding: 10px;
  }
  .pd-xs-hor-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pd-xs-ver-10 {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .pd-xs-t-10 {
    padding-top: 10px;
  }
  .pd-xs-b-10 {
    padding-bottom: 10px;
  }
  .pd-xs-l-10 {
    padding-left: 10px;
  }
  .pd-xs-r-10 {
    padding-right: 10px;
  }
  .pd-xs-15 {
    padding: 15px;
  }
  .pd-xs-hor-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pd-xs-ver-15 {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .pd-xs-t-15 {
    padding-top: 15px;
  }
  .pd-xs-b-15 {
    padding-bottom: 15px;
  }
  .pd-xs-l-15 {
    padding-left: 15px;
  }
  .pd-xs-r-15 {
    padding-right: 15px;
  }
  .pd-xs-30 {
    padding: 30px;
  }
  .pd-xs-hor-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .pd-xs-ver-30 {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .pd-xs-t-30 {
    padding-top: 30px;
  }
  .pd-xs-b-30 {
    padding-bottom: 30px;
  }
  .pd-xs-l-30 {
    padding-left: 30px;
  }
  .pd-xs-r-30 {
    padding-right: 30px;
  }
  .pd-xs-45 {
    padding: 45px;
  }
  .pd-xs-hor-45 {
    padding-left: 45px;
    padding-right: 45px;
  }
  .pd-xs-ver-45 {
    padding-bottom: 45px;
    padding-top: 45px;
  }
  .pd-xs-t-45 {
    padding-top: 45px;
  }
  .pd-xs-b-45 {
    padding-bottom: 45px;
  }
  .pd-xs-l-45 {
    padding-left: 45px;
  }
  .pd-xs-r-45 {
    padding-right: 45px;
  }
  .pd-xs-60 {
    padding: 60px;
  }
  .pd-xs-hor-60 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .pd-xs-ver-60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  .pd-xs-t-60 {
    padding-top: 60px;
  }
  .pd-xs-b-60 {
    padding-bottom: 60px;
  }
  .pd-xs-l-60 {
    padding-left: 60px;
  }
  .pd-xs-r-60 {
    padding-right: 60px;
  }
  .mg-xs-0 {
    margin: 0px;
  }
  .mg-xs-hor-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mg-xs-ver-0 {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .mg-xs-t-0 {
    margin-top: 0px;
  }
  .mg-xs-b-0 {
    margin-bottom: 0px;
  }
  .mg-xs-l-0 {
    margin-left: 0px;
  }
  .mg-xs-r-0 {
    margin-right: 0px;
  }
  .mg-xs-5 {
    margin: 5px;
  }
  .mg-xs-hor-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .mg-xs-ver-5 {
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .mg-xs-t-5 {
    margin-top: 5px;
  }
  .mg-xs-b-5 {
    margin-bottom: 5px;
  }
  .mg-xs-l-5 {
    margin-left: 5px;
  }
  .mg-xs-r-5 {
    margin-right: 5px;
  }
  .mg-xs-10 {
    margin: 10px;
  }
  .mg-xs-hor-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mg-xs-ver-10 {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .mg-xs-t-10 {
    margin-top: 10px;
  }
  .mg-xs-b-10 {
    margin-bottom: 10px;
  }
  .mg-xs-l-10 {
    margin-left: 10px;
  }
  .mg-xs-r-10 {
    margin-right: 10px;
  }
  .mg-xs-15 {
    margin: 15px;
  }
  .mg-xs-hor-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .mg-xs-ver-15 {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .mg-xs-t-15 {
    margin-top: 15px;
  }
  .mg-xs-b-15 {
    margin-bottom: 15px;
  }
  .mg-xs-l-15 {
    margin-left: 15px;
  }
  .mg-xs-r-15 {
    margin-right: 15px;
  }
  .mg-xs-30 {
    margin: 30px;
  }
  .mg-xs-hor-30 {
    margin-left: 30px;
    margin-right: 30px;
  }
  .mg-xs-ver-30 {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .mg-xs-t-30 {
    margin-top: 30px;
  }
  .mg-xs-b-30 {
    margin-bottom: 30px;
  }
  .mg-xs-l-30 {
    margin-left: 30px;
  }
  .mg-xs-r-30 {
    margin-right: 30px;
  }
  .mg-xs-45 {
    margin: 45px;
  }
  .mg-xs-hor-45 {
    margin-left: 45px;
    margin-right: 45px;
  }
  .mg-xs-ver-45 {
    margin-bottom: 45px;
    margin-top: 45px;
  }
  .mg-xs-t-45 {
    margin-top: 45px;
  }
  .mg-xs-b-45 {
    margin-bottom: 45px;
  }
  .mg-xs-l-45 {
    margin-left: 45px;
  }
  .mg-xs-r-45 {
    margin-right: 45px;
  }
  .mg-xs-60 {
    margin: 60px;
  }
  .mg-xs-hor-60 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .mg-xs-ver-60 {
    margin-bottom: 60px;
    margin-top: 60px;
  }
  .mg-xs-t-60 {
    margin-top: 60px;
  }
  .mg-xs-b-60 {
    margin-bottom: 60px;
  }
  .mg-xs-l-60 {
    margin-left: 60px;
  }
  .mg-xs-r-60 {
    margin-right: 60px;
  }
  .mg-xs-l-auto {
    margin-left: auto;
  }
  .mg-xs-r-auto {
    margin-right: auto;
  }
  .mg-xs-t-auto {
    margin-top: auto;
  }
  .mg-xs-b-auto {
    margin-bottom: auto;
  }
}
@media only screen and (min-width: 767px) {
  .pd-sm-0 {
    padding: 0px;
  }
  .pd-sm-hor-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pd-sm-ver-0 {
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .pd-sm-t-0 {
    padding-top: 0px;
  }
  .pd-sm-b-0 {
    padding-bottom: 0px;
  }
  .pd-sm-l-0 {
    padding-left: 0px;
  }
  .pd-sm-r-0 {
    padding-right: 0px;
  }
  .pd-sm-5 {
    padding: 5px;
  }
  .pd-sm-hor-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .pd-sm-ver-5 {
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .pd-sm-t-5 {
    padding-top: 5px;
  }
  .pd-sm-b-5 {
    padding-bottom: 5px;
  }
  .pd-sm-l-5 {
    padding-left: 5px;
  }
  .pd-sm-r-5 {
    padding-right: 5px;
  }
  .pd-sm-10 {
    padding: 10px;
  }
  .pd-sm-hor-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pd-sm-ver-10 {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .pd-sm-t-10 {
    padding-top: 10px;
  }
  .pd-sm-b-10 {
    padding-bottom: 10px;
  }
  .pd-sm-l-10 {
    padding-left: 10px;
  }
  .pd-sm-r-10 {
    padding-right: 10px;
  }
  .pd-sm-15 {
    padding: 15px;
  }
  .pd-sm-hor-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pd-sm-ver-15 {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .pd-sm-t-15 {
    padding-top: 15px;
  }
  .pd-sm-b-15 {
    padding-bottom: 15px;
  }
  .pd-sm-l-15 {
    padding-left: 15px;
  }
  .pd-sm-r-15 {
    padding-right: 15px;
  }
  .pd-sm-30 {
    padding: 30px;
  }
  .pd-sm-hor-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .pd-sm-ver-30 {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .pd-sm-t-30 {
    padding-top: 30px;
  }
  .pd-sm-b-30 {
    padding-bottom: 30px;
  }
  .pd-sm-l-30 {
    padding-left: 30px;
  }
  .pd-sm-r-30 {
    padding-right: 30px;
  }
  .pd-sm-45 {
    padding: 45px;
  }
  .pd-sm-hor-45 {
    padding-left: 45px;
    padding-right: 45px;
  }
  .pd-sm-ver-45 {
    padding-bottom: 45px;
    padding-top: 45px;
  }
  .pd-sm-t-45 {
    padding-top: 45px;
  }
  .pd-sm-b-45 {
    padding-bottom: 45px;
  }
  .pd-sm-l-45 {
    padding-left: 45px;
  }
  .pd-sm-r-45 {
    padding-right: 45px;
  }
  .pd-sm-60 {
    padding: 60px;
  }
  .pd-sm-hor-60 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .pd-sm-ver-60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  .pd-sm-t-60 {
    padding-top: 60px;
  }
  .pd-sm-b-60 {
    padding-bottom: 60px;
  }
  .pd-sm-l-60 {
    padding-left: 60px;
  }
  .pd-sm-r-60 {
    padding-right: 60px;
  }
  .mg-sm-0 {
    margin: 0px;
  }
  .mg-sm-hor-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mg-sm-ver-0 {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .mg-sm-t-0 {
    margin-top: 0px;
  }
  .mg-sm-b-0 {
    margin-bottom: 0px;
  }
  .mg-sm-l-0 {
    margin-left: 0px;
  }
  .mg-sm-r-0 {
    margin-right: 0px;
  }
  .mg-sm-5 {
    margin: 5px;
  }
  .mg-sm-hor-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .mg-sm-ver-5 {
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .mg-sm-t-5 {
    margin-top: 5px;
  }
  .mg-sm-b-5 {
    margin-bottom: 5px;
  }
  .mg-sm-l-5 {
    margin-left: 5px;
  }
  .mg-sm-r-5 {
    margin-right: 5px;
  }
  .mg-sm-10 {
    margin: 10px;
  }
  .mg-sm-hor-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mg-sm-ver-10 {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .mg-sm-t-10 {
    margin-top: 10px;
  }
  .mg-sm-b-10 {
    margin-bottom: 10px;
  }
  .mg-sm-l-10 {
    margin-left: 10px;
  }
  .mg-sm-r-10 {
    margin-right: 10px;
  }
  .mg-sm-15 {
    margin: 15px;
  }
  .mg-sm-hor-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .mg-sm-ver-15 {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .mg-sm-t-15 {
    margin-top: 15px;
  }
  .mg-sm-b-15 {
    margin-bottom: 15px;
  }
  .mg-sm-l-15 {
    margin-left: 15px;
  }
  .mg-sm-r-15 {
    margin-right: 15px;
  }
  .mg-sm-30 {
    margin: 30px;
  }
  .mg-sm-hor-30 {
    margin-left: 30px;
    margin-right: 30px;
  }
  .mg-sm-ver-30 {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .mg-sm-t-30 {
    margin-top: 30px;
  }
  .mg-sm-b-30 {
    margin-bottom: 30px;
  }
  .mg-sm-l-30 {
    margin-left: 30px;
  }
  .mg-sm-r-30 {
    margin-right: 30px;
  }
  .mg-sm-45 {
    margin: 45px;
  }
  .mg-sm-hor-45 {
    margin-left: 45px;
    margin-right: 45px;
  }
  .mg-sm-ver-45 {
    margin-bottom: 45px;
    margin-top: 45px;
  }
  .mg-sm-t-45 {
    margin-top: 45px;
  }
  .mg-sm-b-45 {
    margin-bottom: 45px;
  }
  .mg-sm-l-45 {
    margin-left: 45px;
  }
  .mg-sm-r-45 {
    margin-right: 45px;
  }
  .mg-sm-60 {
    margin: 60px;
  }
  .mg-sm-hor-60 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .mg-sm-ver-60 {
    margin-bottom: 60px;
    margin-top: 60px;
  }
  .mg-sm-t-60 {
    margin-top: 60px;
  }
  .mg-sm-b-60 {
    margin-bottom: 60px;
  }
  .mg-sm-l-60 {
    margin-left: 60px;
  }
  .mg-sm-r-60 {
    margin-right: 60px;
  }
  .mg-sm-l-auto {
    margin-left: auto;
  }
  .mg-sm-r-auto {
    margin-right: auto;
  }
  .mg-sm-t-auto {
    margin-top: auto;
  }
  .mg-sm-b-auto {
    margin-bottom: auto;
  }
}
@media only screen and (min-width: 993px) {
  .pd-md-0 {
    padding: 0px;
  }
  .pd-md-hor-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pd-md-ver-0 {
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .pd-md-t-0 {
    padding-top: 0px;
  }
  .pd-md-b-0 {
    padding-bottom: 0px;
  }
  .pd-md-l-0 {
    padding-left: 0px;
  }
  .pd-md-r-0 {
    padding-right: 0px;
  }
  .pd-md-5 {
    padding: 5px;
  }
  .pd-md-hor-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .pd-md-ver-5 {
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .pd-md-t-5 {
    padding-top: 5px;
  }
  .pd-md-b-5 {
    padding-bottom: 5px;
  }
  .pd-md-l-5 {
    padding-left: 5px;
  }
  .pd-md-r-5 {
    padding-right: 5px;
  }
  .pd-md-10 {
    padding: 10px;
  }
  .pd-md-hor-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pd-md-ver-10 {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .pd-md-t-10 {
    padding-top: 10px;
  }
  .pd-md-b-10 {
    padding-bottom: 10px;
  }
  .pd-md-l-10 {
    padding-left: 10px;
  }
  .pd-md-r-10 {
    padding-right: 10px;
  }
  .pd-md-15 {
    padding: 15px;
  }
  .pd-md-hor-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pd-md-ver-15 {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .pd-md-t-15 {
    padding-top: 15px;
  }
  .pd-md-b-15 {
    padding-bottom: 15px;
  }
  .pd-md-l-15 {
    padding-left: 15px;
  }
  .pd-md-r-15 {
    padding-right: 15px;
  }
  .pd-md-30 {
    padding: 30px;
  }
  .pd-md-hor-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .pd-md-ver-30 {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .pd-md-t-30 {
    padding-top: 30px;
  }
  .pd-md-b-30 {
    padding-bottom: 30px;
  }
  .pd-md-l-30 {
    padding-left: 30px;
  }
  .pd-md-r-30 {
    padding-right: 30px;
  }
  .pd-md-45 {
    padding: 45px;
  }
  .pd-md-hor-45 {
    padding-left: 45px;
    padding-right: 45px;
  }
  .pd-md-ver-45 {
    padding-bottom: 45px;
    padding-top: 45px;
  }
  .pd-md-t-45 {
    padding-top: 45px;
  }
  .pd-md-b-45 {
    padding-bottom: 45px;
  }
  .pd-md-l-45 {
    padding-left: 45px;
  }
  .pd-md-r-45 {
    padding-right: 45px;
  }
  .pd-md-60 {
    padding: 60px;
  }
  .pd-md-hor-60 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .pd-md-ver-60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  .pd-md-t-60 {
    padding-top: 60px;
  }
  .pd-md-b-60 {
    padding-bottom: 60px;
  }
  .pd-md-l-60 {
    padding-left: 60px;
  }
  .pd-md-r-60 {
    padding-right: 60px;
  }
  .mg-md-0 {
    margin: 0px;
  }
  .mg-md-hor-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mg-md-ver-0 {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .mg-md-t-0 {
    margin-top: 0px;
  }
  .mg-md-b-0 {
    margin-bottom: 0px;
  }
  .mg-md-l-0 {
    margin-left: 0px;
  }
  .mg-md-r-0 {
    margin-right: 0px;
  }
  .mg-md-5 {
    margin: 5px;
  }
  .mg-md-hor-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .mg-md-ver-5 {
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .mg-md-t-5 {
    margin-top: 5px;
  }
  .mg-md-b-5 {
    margin-bottom: 5px;
  }
  .mg-md-l-5 {
    margin-left: 5px;
  }
  .mg-md-r-5 {
    margin-right: 5px;
  }
  .mg-md-10 {
    margin: 10px;
  }
  .mg-md-hor-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mg-md-ver-10 {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .mg-md-t-10 {
    margin-top: 10px;
  }
  .mg-md-b-10 {
    margin-bottom: 10px;
  }
  .mg-md-l-10 {
    margin-left: 10px;
  }
  .mg-md-r-10 {
    margin-right: 10px;
  }
  .mg-md-15 {
    margin: 15px;
  }
  .mg-md-hor-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .mg-md-ver-15 {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .mg-md-t-15 {
    margin-top: 15px;
  }
  .mg-md-b-15 {
    margin-bottom: 15px;
  }
  .mg-md-l-15 {
    margin-left: 15px;
  }
  .mg-md-r-15 {
    margin-right: 15px;
  }
  .mg-md-30 {
    margin: 30px;
  }
  .mg-md-hor-30 {
    margin-left: 30px;
    margin-right: 30px;
  }
  .mg-md-ver-30 {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .mg-md-t-30 {
    margin-top: 30px;
  }
  .mg-md-b-30 {
    margin-bottom: 30px;
  }
  .mg-md-l-30 {
    margin-left: 30px;
  }
  .mg-md-r-30 {
    margin-right: 30px;
  }
  .mg-md-45 {
    margin: 45px;
  }
  .mg-md-hor-45 {
    margin-left: 45px;
    margin-right: 45px;
  }
  .mg-md-ver-45 {
    margin-bottom: 45px;
    margin-top: 45px;
  }
  .mg-md-t-45 {
    margin-top: 45px;
  }
  .mg-md-b-45 {
    margin-bottom: 45px;
  }
  .mg-md-l-45 {
    margin-left: 45px;
  }
  .mg-md-r-45 {
    margin-right: 45px;
  }
  .mg-md-60 {
    margin: 60px;
  }
  .mg-md-hor-60 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .mg-md-ver-60 {
    margin-bottom: 60px;
    margin-top: 60px;
  }
  .mg-md-t-60 {
    margin-top: 60px;
  }
  .mg-md-b-60 {
    margin-bottom: 60px;
  }
  .mg-md-l-60 {
    margin-left: 60px;
  }
  .mg-md-r-60 {
    margin-right: 60px;
  }
  .mg-md-l-auto {
    margin-left: auto;
  }
  .mg-md-r-auto {
    margin-right: auto;
  }
  .mg-md-t-auto {
    margin-top: auto;
  }
  .mg-md-b-auto {
    margin-bottom: auto;
  }
}
@media only screen and (min-width: 1199px) {
  .pd-lg-0 {
    padding: 0px;
  }
  .pd-lg-hor-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pd-lg-ver-0 {
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .pd-lg-t-0 {
    padding-top: 0px;
  }
  .pd-lg-b-0 {
    padding-bottom: 0px;
  }
  .pd-lg-l-0 {
    padding-left: 0px;
  }
  .pd-lg-r-0 {
    padding-right: 0px;
  }
  .pd-lg-5 {
    padding: 5px;
  }
  .pd-lg-hor-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .pd-lg-ver-5 {
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .pd-lg-t-5 {
    padding-top: 5px;
  }
  .pd-lg-b-5 {
    padding-bottom: 5px;
  }
  .pd-lg-l-5 {
    padding-left: 5px;
  }
  .pd-lg-r-5 {
    padding-right: 5px;
  }
  .pd-lg-10 {
    padding: 10px;
  }
  .pd-lg-hor-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pd-lg-ver-10 {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .pd-lg-t-10 {
    padding-top: 10px;
  }
  .pd-lg-b-10 {
    padding-bottom: 10px;
  }
  .pd-lg-l-10 {
    padding-left: 10px;
  }
  .pd-lg-r-10 {
    padding-right: 10px;
  }
  .pd-lg-15 {
    padding: 15px;
  }
  .pd-lg-hor-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pd-lg-ver-15 {
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .pd-lg-t-15 {
    padding-top: 15px;
  }
  .pd-lg-b-15 {
    padding-bottom: 15px;
  }
  .pd-lg-l-15 {
    padding-left: 15px;
  }
  .pd-lg-r-15 {
    padding-right: 15px;
  }
  .pd-lg-30 {
    padding: 30px;
  }
  .pd-lg-hor-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .pd-lg-ver-30 {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .pd-lg-t-30 {
    padding-top: 30px;
  }
  .pd-lg-b-30 {
    padding-bottom: 30px;
  }
  .pd-lg-l-30 {
    padding-left: 30px;
  }
  .pd-lg-r-30 {
    padding-right: 30px;
  }
  .pd-lg-45 {
    padding: 45px;
  }
  .pd-lg-hor-45 {
    padding-left: 45px;
    padding-right: 45px;
  }
  .pd-lg-ver-45 {
    padding-bottom: 45px;
    padding-top: 45px;
  }
  .pd-lg-t-45 {
    padding-top: 45px;
  }
  .pd-lg-b-45 {
    padding-bottom: 45px;
  }
  .pd-lg-l-45 {
    padding-left: 45px;
  }
  .pd-lg-r-45 {
    padding-right: 45px;
  }
  .pd-lg-60 {
    padding: 60px;
  }
  .pd-lg-hor-60 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .pd-lg-ver-60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  .pd-lg-t-60 {
    padding-top: 60px;
  }
  .pd-lg-b-60 {
    padding-bottom: 60px;
  }
  .pd-lg-l-60 {
    padding-left: 60px;
  }
  .pd-lg-r-60 {
    padding-right: 60px;
  }
  .mg-lg-0 {
    margin: 0px;
  }
  .mg-lg-hor-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mg-lg-ver-0 {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .mg-lg-t-0 {
    margin-top: 0px;
  }
  .mg-lg-b-0 {
    margin-bottom: 0px;
  }
  .mg-lg-l-0 {
    margin-left: 0px;
  }
  .mg-lg-r-0 {
    margin-right: 0px;
  }
  .mg-lg-5 {
    margin: 5px;
  }
  .mg-lg-hor-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .mg-lg-ver-5 {
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .mg-lg-t-5 {
    margin-top: 5px;
  }
  .mg-lg-b-5 {
    margin-bottom: 5px;
  }
  .mg-lg-l-5 {
    margin-left: 5px;
  }
  .mg-lg-r-5 {
    margin-right: 5px;
  }
  .mg-lg-10 {
    margin: 10px;
  }
  .mg-lg-hor-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mg-lg-ver-10 {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .mg-lg-t-10 {
    margin-top: 10px;
  }
  .mg-lg-b-10 {
    margin-bottom: 10px;
  }
  .mg-lg-l-10 {
    margin-left: 10px;
  }
  .mg-lg-r-10 {
    margin-right: 10px;
  }
  .mg-lg-15 {
    margin: 15px;
  }
  .mg-lg-hor-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .mg-lg-ver-15 {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .mg-lg-t-15 {
    margin-top: 15px;
  }
  .mg-lg-b-15 {
    margin-bottom: 15px;
  }
  .mg-lg-l-15 {
    margin-left: 15px;
  }
  .mg-lg-r-15 {
    margin-right: 15px;
  }
  .mg-lg-30 {
    margin: 30px;
  }
  .mg-lg-hor-30 {
    margin-left: 30px;
    margin-right: 30px;
  }
  .mg-lg-ver-30 {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .mg-lg-t-30 {
    margin-top: 30px;
  }
  .mg-lg-b-30 {
    margin-bottom: 30px;
  }
  .mg-lg-l-30 {
    margin-left: 30px;
  }
  .mg-lg-r-30 {
    margin-right: 30px;
  }
  .mg-lg-45 {
    margin: 45px;
  }
  .mg-lg-hor-45 {
    margin-left: 45px;
    margin-right: 45px;
  }
  .mg-lg-ver-45 {
    margin-bottom: 45px;
    margin-top: 45px;
  }
  .mg-lg-t-45 {
    margin-top: 45px;
  }
  .mg-lg-b-45 {
    margin-bottom: 45px;
  }
  .mg-lg-l-45 {
    margin-left: 45px;
  }
  .mg-lg-r-45 {
    margin-right: 45px;
  }
  .mg-lg-60 {
    margin: 60px;
  }
  .mg-lg-hor-60 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .mg-lg-ver-60 {
    margin-bottom: 60px;
    margin-top: 60px;
  }
  .mg-lg-t-60 {
    margin-top: 60px;
  }
  .mg-lg-b-60 {
    margin-bottom: 60px;
  }
  .mg-lg-l-60 {
    margin-left: 60px;
  }
  .mg-lg-r-60 {
    margin-right: 60px;
  }
  .mg-lg-l-auto {
    margin-left: auto;
  }
  .mg-lg-r-auto {
    margin-right: auto;
  }
  .mg-lg-t-auto {
    margin-top: auto;
  }
  .mg-lg-b-auto {
    margin-bottom: auto;
  }
}
.t-break-all {
  word-break: break-all;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 10px 0;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
p:first-child,
ul:first-child,
ol:first-child {
  margin-top: 0;
}
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child {
  margin-bottom: 0;
}

.keep-together {
  word-break: keep-all;
  white-space: nowrap;
  display: inline-block;
}

h1, .heading1 {
  font-size: 4.4rem;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  line-height: 1.136363;
  margin: 30px 0;
  text-transform: none;
}

h2, .heading2 {
  font-size: 2rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  line-height: 1.2;
  text-transform: uppercase;
  margin: 20px 0;
}

h3, .heading3 {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  line-height: 1.25;
  text-transform: none;
  margin: 15px 0;
}

h4, .heading4 {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  line-height: 1.166667;
  text-transform: uppercase;
  margin: 10px 0;
}

h5, .heading5 {
  font-size: 1.4rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  line-height: 1.142857;
  text-transform: uppercase;
  margin: 10px 0;
}

h6, .heading6 {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  line-height: 1.166667;
  text-transform: uppercase;
  margin: 10px 0;
}

p.lead {
  font-size: 1.6rem;
  font-weight: 600;
}

.text-big {
  font-size: 1.4rem;
  line-height: 1.384615;
}

.text-small {
  font-size: 1.3rem;
  line-height: 1.333333;
}

.heading-border {
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #e5e5e5;
  margin-left: -5px;
  margin-right: 5px;
}

ul,
ol {
  margin: 15px 0 15px 20px;
  padding: 0;
}
ul.small-list,
ol.small-list {
  margin: 10px 0 10px 13px;
}
ul li,
ol li {
  margin: 5px 0;
}
ul ul,
ul ol,
ol ul,
ol ol {
  margin: 0 0 0 30px;
}
ul.treeview,
ol.treeview {
  border-left: 1px solid #4f81bb;
  list-style: none;
  margin-left: 0;
}
ul.treeview .treeview,
ol.treeview .treeview {
  margin: -7px 0 -7px -15px;
  padding: 0;
}
ul.treeview li,
ol.treeview li {
  position: relative;
  padding: 7px 0 7px 35px;
}
ul.treeview li::before,
ol.treeview li::before {
  position: absolute;
  width: 20px;
  height: 1px;
  background: #4f81bb;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  display: block;
}

.list-no-bullets {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-horizontal {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -15px;
}
.list-horizontal li {
  margin: 5px 15px;
}

p.large {
  font-size: 1.5rem;
}

.text-large {
  font-size: 1.6rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-strong {
  font-weight: bold;
}

.text-muted {
  opacity: 0.4;
}

.text-with-icon {
  position: relative;
  padding-bottom: 3px;
  padding-left: 30px;
  padding-top: 3px;
}
.text-with-icon [class^=ico-],
.text-with-icon [class*=" ico-"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-size: 1.8rem;
}

.accordion-item {
  padding: 5px 15px;
  border-bottom: 2px solid #d8d8d8;
  margin-top: 15px;
  transition: background-color 0.1s linear;
}
.accordion-item:first-child {
  margin-top: 0;
}
.accordion-item.opened .accordion-item__header,
.accordion-item .accordion-item__header:hover {
  color: #4f81bb;
}
.highlight-opened .accordion-item.opened {
  background: #f6f6f6;
}
.accordion-item.opened .trigger-angle [class^=ico-],
.accordion-item.opened .trigger-angle [class*=" ico-"] {
  display: inline-block;
  transform: rotate(-180deg);
}

.accordion-item__header {
  transition: color 0.2s linear;
  position: relative;
  padding: 10px 24px 10px 0;
  cursor: pointer;
}
.accordion-item__header .trigger-angle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  user-select: none;
  font-size: 24px;
}
.accordion-item__header .trigger-angle [class^=ico-],
.accordion-item__header .trigger-angle [class*=" ico-"] {
  display: block;
  width: 1em;
  height: 1em;
  font-size: 1em;
  transition: transform 0.1s linear;
}

.accordion-item__body {
  display: none;
  padding: 10px 0;
  font-size: 1.4rem;
}

bsc-flash-message {
  display: block;
  margin: 15px auto;
}
bsc-flash-message:first-child {
  margin-top: 0;
}
bsc-flash-message:last-child {
  margin-bottom: 0;
}

.alert {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-left-width: 5px;
  border-radius: 4px;
  display: flex;
  font-size: 1.4rem;
  margin: 15px auto;
  min-height: 66px;
  position: relative;
}
.alert:first-child {
  margin-top: 0;
}
.alert:last-child {
  margin-bottom: 0;
}
.alert.alert--success {
  border-left-color: #5fa200;
}
.alert.alert--info {
  border-left-color: #4f81bb;
}
.alert.alert--warning {
  border-left-color: #f49317;
}
.alert.alert--danger {
  border-left-color: #d10000;
}
.alert .btns-group {
  margin-top: 15px;
}

.alert--modal {
  border: none;
  border-top: 5px solid #d8d8d8;
  flex-direction: column;
  margin: auto;
}
.alert--modal.alert--success {
  border-top-color: #5fa200;
}
.alert--modal.alert--info {
  border-top-color: #4f81bb;
}
.alert--modal.alert--warning {
  border-top-color: #f49317;
}
.alert--modal.alert--danger {
  border-top-color: #d10000;
}

.alert__icon {
  align-items: flex-start;
  color: #d8d8d8;
  display: flex;
  font-size: 2.8rem;
  font-family: "sportbm";
  justify-content: center;
  line-height: 1;
  padding-top: 19px;
  text-align: center;
  width: 58px;
}
.alert--modal .alert__icon {
  margin: auto;
  padding-top: 25px;
}
.alert--success .alert__icon {
  color: #5fa200;
}
.alert--success .alert__icon:before {
  content: "\e90d";
}
.alert--info .alert__icon {
  color: #4f81bb;
}
.alert--info .alert__icon:before {
  content: "\e90a";
}
.alert--warning .alert__icon {
  color: #f49317;
}
.alert--warning .alert__icon:before {
  content: "\e910";
}
.alert--danger .alert__icon {
  color: #d10000;
}
.alert--danger .alert__icon:before {
  content: "\e907";
}

.alert__form {
  padding: 20px 15px 15px;
}

.alert__content {
  align-self: center;
  flex: 1;
  line-height: 2.2rem;
  padding: 15px 40px 15px 0;
}
.alert--modal .alert__content {
  text-align: center;
  padding: 20px 15px 15px;
  width: 100%;
}
.alert--modal .alert__content .btns-group {
  justify-content: center;
}
.alert__content .title {
  color: #222;
  display: block;
  font-size: 1.4rem;
}
.alert__content .title + p {
  margin-top: 10px;
}
.alert--success .alert__content .title {
  color: #5fa200;
}
.alert--info .alert__content .title {
  color: #4f81bb;
}
.alert--warning .alert__content .title {
  color: #f49317;
}
.alert--danger .alert__content .title {
  color: #d10000;
}

.alert__close {
  font-size: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  width: 50px;
}
.alert__close [class^=ico-],
.alert__close [class*=" ico-"] {
  cursor: pointer;
}

.badge {
  border-radius: 6px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  font-weight: 300;
  font-style: normal;
  border: 2px solid transparent;
  padding: 0 8px;
  margin: 0 5px;
}

.badge--primary {
  background: #4f81bb;
  color: #ffffff;
}

.badge--secondary {
  background: #464e57;
  color: #ffffff;
}

.badge--tertiary {
  background: #778593;
  color: #ffffff;
}

.badge--lime {
  background: #b6cd2a;
  color: #ffffff;
}

.badge--other {
  background: #778593;
  color: #ffffff;
}

.badge--success {
  background: #5fa200;
  color: #ffffff;
}

.badge--info {
  background: #4f81bb;
  color: #ffffff;
}

.badge--warning {
  background: #f49317;
  color: #ffffff;
}

.badge--danger {
  background: #d10000;
  color: #ffffff;
}

.badge--inverse {
  background: #ffffff;
  color: #000000;
}

.badge--inverse {
  background: #ffffff;
  color: #4f81bb;
}

.icon-badge {
  display: inline-block;
  position: relative;
  font-size: 24px;
}
.btn .icon-badge, .shepherd-button-secondary .icon-badge, .shepherd-button .icon-badge {
  font-size: inherit;
}
.icon-badge .badge {
  border-color: #fff;
  min-width: 24px;
  height: 24px;
  position: absolute;
  right: -11px;
  padding: 0;
  top: -9px;
  margin: 0;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  font-family: "Open Sans", sans-serif;
}

.multiple-joined-boxes {
  margin: 30px 0;
}
.multiple-joined-boxes:first-child {
  margin-top: 0;
}
.multiple-joined-boxes.no-margin-bottom {
  margin-bottom: 0;
}

.box-simple {
  padding: 30px;
  border-radius: 4px;
  margin: 30px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.box-simple.cdk-drag-preview {
  background: #fff;
}
.box-simple.cdk-drag-placeholder {
  opacity: 0.4;
}
.box-simple .box-simple__action {
  position: absolute;
  top: 10px;
  right: 10px;
}
.box-simple:first-child {
  margin-top: 0;
}
.box-simple:last-child {
  margin-bottom: 0;
}
.box-simple.color-primary {
  background: #fff;
  color: #000000;
}
.box-simple.color-secondary {
  background: #f0f3f6;
  color: #000000;
}
.box-simple.color-tertiary {
  background: #778593;
  color: #ffffff;
}
.box-simple.color-other {
  background: #778593;
  color: #ffffff;
}
.box-simple.color-quaternary {
  background: #e0f1f8;
  color: #000000;
}
.box-simple.color-success {
  background: #5fa200;
  color: #ffffff;
}
.box-simple.color-info {
  background: #4f81bb;
  color: #ffffff;
}
.box-simple.color-warning {
  background: #f49317;
  color: #ffffff;
}
.box-simple.color-danger {
  background: #d10000;
  color: #ffffff;
}
.box-simple.with-border {
  border: 1px solid #d8d8d8;
}
.box-simple.with-border .box-simple__footer {
  border-top: 1px solid #d8d8d8;
}
.box-simple.with-shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.multiple-joined-boxes .box-simple {
  margin: 0;
  border-radius: 0;
}
.multiple-joined-boxes .box-simple:first-child {
  border-radius: 4px 4px 0 0;
}
.multiple-joined-boxes .box-simple:last-child {
  border-radius: 0 0 4px 4px;
}
.box-simple .box-simple__footer {
  margin: 30px -30px -30px;
  padding: 30px;
}

.box-simple--small {
  padding: 15px;
  margin: 15px 0;
}
.multiple-joined-boxes .box-simple--small {
  margin: 0;
}
.box-simple--small .box-simple__footer {
  margin: 15px -15px -15px;
  padding: 15px;
}

.box-simple__content {
  margin-bottom: auto;
}

.box-simple__footer:empty {
  display: none !important;
}

.box-stats {
  margin: -1px;
  display: flex;
  flex-wrap: wrap;
}

.box-stats__item {
  flex: 1;
  flex-basis: calc(50% - 2px);
  margin: 1px;
  background: #f0f3f6;
  padding: 25px 30px 20px;
  text-align: center;
  position: relative;
  border-radius: 4px;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #778593;
}
.box-stats--small .box-stats__item {
  padding: 10px 15px;
}
.box-stats__item a {
  color: #778593;
}
.box-stats--col-3 .box-stats__item {
  flex-basis: calc(100%/3 - 4px);
}
.box-stats--col-4 .box-stats__item {
  flex-basis: calc(100%/4 - 6px);
}
.box-stats--col-5 .box-stats__item {
  flex-basis: calc(100%/5 - 8px);
}
.box-stats__item.color-primary {
  background: #fff;
}
.box-stats__item.color-secondary {
  background: #f0f3f6;
}
.box-stats__item.color-tertiary {
  background: #778593;
}
.box-stats__item.color-other {
  background: #778593;
}
.box-stats__item.color-quaternary {
  background: #e0f1f8;
}
.box-stats__item.color-success {
  background: #5fa200;
}
.box-stats__item.color-info {
  background: #4f81bb;
}
.box-stats__item.color-warning {
  background: #f49317;
}
.box-stats__item.color-danger {
  background: #d10000;
}
.box-stats__item.with-border {
  border: 1px solid #d8d8d8;
}
.box-stats__item strong,
.box-stats__item .value {
  display: block;
  line-height: 1;
}
.box-stats__item strong.fancy-amount,
.box-stats__item .value.fancy-amount {
  font-size: 1.4rem;
  min-height: 4.4rem;
  padding-top: 0.6rem;
}
.box-stats__item strong.color-default,
.box-stats__item .value.color-default {
  color: #222;
}
.box-stats__item strong.color-primary,
.box-stats__item .value.color-primary {
  color: #4f81bb;
}
.box-stats__item strong.color-secondary,
.box-stats__item .value.color-secondary {
  color: #464e57;
}
.box-stats__item strong.color-tertiary,
.box-stats__item .value.color-tertiary {
  color: #778593;
}
.box-stats__item strong.color-lime,
.box-stats__item .value.color-lime {
  color: #b6cd2a;
}
.box-stats__item strong.color-other,
.box-stats__item .value.color-other {
  color: #778593;
}
.box-stats__item strong.color-danger,
.box-stats__item .value.color-danger {
  color: #d10000;
}
.box-stats__item strong.color-info,
.box-stats__item .value.color-info {
  color: #4f81bb;
}
.box-stats__item strong.color-success,
.box-stats__item .value.color-success {
  color: #5fa200;
}
.box-stats__item strong.color-warning,
.box-stats__item .value.color-warning {
  color: #f49317;
}
.box-stats--small .box-stats__item strong.fancy-amount,
.box-stats--small .box-stats__item .value.fancy-amount {
  font-size: 1.333333rem;
}
.box-stats__item strong {
  font-size: 4.4rem;
  font-weight: 600;
  margin-bottom: 0.07em;
}
.box-stats--small .box-stats__item strong {
  font-size: 3.2rem;
}
.box-stats__item .value {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 0.3em;
}
.box-stats--small .box-stats__item .value {
  font-size: 1.6rem;
}
.box-stats__item .corner-link {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 3.2rem;
  color: #4f81bb;
  z-index: 2;
}

@media only screen and (max-width: 1199px) and (min-width: 993px) {
  .box-stats--small .box-stats__item strong {
    font-size: 2.4rem;
  }
  .box-stats--small .box-stats__item strong.fancy-amount {
    font-size: 1rem;
  }
}
.box-dated-note {
  text-align: center;
  margin: 60px 0 0;
  display: block;
}
.box-dated-note:first-child {
  margin-top: 0;
}

.box-dated-note__date {
  color: #778593;
  margin: 0 0 5px;
  display: block;
}

.box-dated-note__name {
  color: #778593;
  margin: 0 0 10px;
}

.box-event {
  padding: 3px 20px 10px;
  border-left: 2px solid transparent;
  border-left-color: #4f81bb;
  margin: 30px 0 0;
  color: #222;
}
.box-event:first-child {
  margin-top: 0;
}

.box-event__date {
  color: #778593;
  font-size: 1.2rem;
}

.box-event__start {
  color: #778593;
  margin: 5px 0 -10px;
  font-size: 1.3rem;
  font-weight: 500;
  position: relative;
  padding-left: 30px;
}
.box-event__start [class^=ico-],
.box-event__start [class*=" ico-"] {
  margin-right: 5px;
  font-size: 1.8rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.box-detailed-heading {
  align-items: center;
  background: #778593;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0;
}
.box-detailed-heading a {
  color: #ffffff;
}
.box-detailed-heading a:hover {
  color: #d9d9d9;
}
.box-detailed-heading .item {
  align-self: center;
  margin: 15px 30px;
  max-width: 250px;
  position: relative;
}
.box-detailed-heading .item.item--with-icon {
  padding-left: 50px;
}
.box-detailed-heading .item--wide {
  max-width: 350px;
}
.box-detailed-heading .item__title {
  font-size: 1.6rem;
  font-weight: 600;
  word-break: break-word;
}
.box-detailed-heading .item__label {
  display: block;
  font-size: 1.2rem;
}
.box-detailed-heading .form-checkbox {
  margin-top: 0;
}
.box-detailed-heading .form-checkbox input:checked + .custom-checkbox__layer,
.box-detailed-heading .form-checkbox .custom-checkbox__layer {
  background: inherit;
}
.box-detailed-heading .form-radio {
  margin-top: 0;
}
.box-detailed-heading .form-radio:checked + .custom-radio__layer,
.box-detailed-heading .form-radio .custom-radio__layer {
  background: inherit;
}
.box-detailed-heading [class^=ico-],
.box-detailed-heading [class*=" ico-"] {
  color: #d8d8d8;
  font-size: 30px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.box-filters {
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  margin: -15px -15px;
}
.box-filters .range-separator {
  margin: 0 -10px;
}
.box-filters .item-group {
  display: flex;
  margin: 15px 15px;
}
.box-filters .item {
  align-self: center;
  width: 200px;
  position: relative;
}
.box-filters .item select {
  width: 200px;
}
.box-filters .item.item--large {
  width: 300px;
}
.box-filters .item.item--large select {
  width: 300px;
}
.box-filters .item.item--datepicker {
  width: 210px;
}
.box-filters .item.item--datepicker select {
  width: 210px;
}
.box-filters .item.item--datepicker-range {
  width: 330px;
}
.box-filters .item.item--label {
  color: #778593;
  margin-right: 0;
  width: auto;
  max-width: 200px;
}
.box-filters .item.item--label + .item {
  margin-left: 15px;
}
.box-filters .item.item--with-icon {
  padding-left: 50px;
}
.box-filters .item > .form-input, .box-filters .ng-select.ng-select .item > .ng-select-container, .ng-select.ng-select .box-filters .item > .ng-select-container, .box-filters .item > .form-input-wrapper {
  width: 100%;
}
.box-filters .item .form-checkbox {
  margin-top: 0;
}
.box-filters .item__title {
  font-size: 1.6rem;
  font-weight: 600;
}
.box-filters .item__label {
  display: block;
  font-size: 1.2rem;
}
.box-filters .filter-actions {
  margin: auto 15px auto auto;
}

.box-filters__results-count {
  padding-bottom: 10px;
  border-bottom: 1px solid #d8d8d8;
  min-height: 30px;
}

.box-filters--column-labels .item-group {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.box-filters--column-labels .filter-actions {
  margin-bottom: 10px;
}
.box-filters--column-labels .item:first-child:not(.item--label) {
  margin-top: 20px;
  min-height: 44px;
  display: flex;
  align-items: center;
}
.box-filters--column-labels .item.item--label {
  margin-bottom: 3px;
}
.box-filters--column-labels .item.item--label + .item {
  margin-left: 0;
}
.box-filters--column-labels .box-filters {
  align-items: flex-start;
}

@media only screen and (max-width: 993px) {
  .box-filters .item-group {
    margin: 7.5px 7.5px;
    width: 100%;
    flex-direction: column;
  }
  .box-filters .item, .box-filters .item.item--label {
    width: 100% !important;
  }
  .box-filters .item.item--label {
    max-width: none;
    margin-bottom: 3px;
  }
  .box-filters .item.item--label + .item {
    margin-left: 0;
  }
  .box-filters .filter-actions {
    margin: 7.5px 7.5px auto auto;
  }
}
@media only screen and (max-width: 767px) {
  .box-filters {
    margin: -22.5px -22.5px;
  }
  sbm-box-filters {
    display: block;
    margin: 0 -15px;
  }
}
/*
  BUTTON WITH ICON
 */
/*
  END BUTTON WITH ICON
 */
.btns-group {
  display: flex;
  flex-wrap: wrap;
  margin: -8px -8px;
}
.btns-group > * {
  margin: 8px 8px;
}
.btns-group > *.mg-r-auto {
  margin-right: auto;
}
.btns-group > *.mg-l-auto {
  margin-left: auto;
}

.btns-group--column {
  flex-direction: column;
  margin: -5px;
}
.btns-group--column > * {
  margin: 5px;
}

.btns-group--right {
  justify-content: flex-end;
  text-align: right;
}

.btns-group--center {
  justify-content: center;
  text-align: center;
}

.btns-group--full > * {
  flex: 1;
}

.btns-group--joined {
  margin: 0;
}
.btns-group--joined .btn, .btns-group--joined .shepherd-button-secondary, .btns-group--joined .shepherd-button {
  border-radius: 0;
  margin: 0;
}
.btns-group--joined .btn:first-child, .btns-group--joined .shepherd-button-secondary:first-child, .btns-group--joined .shepherd-button:first-child {
  border-radius: 4px 0 0 4px;
}
.btns-group--joined .btn:last-child, .btns-group--joined .shepherd-button-secondary:last-child, .btns-group--joined .shepherd-button:last-child {
  border-radius: 0 4px 4px 0;
}

.btn, .shepherd-button-secondary, .shepherd-button {
  display: inline-block;
  cursor: pointer;
  outline: none;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
  background: transparent;
  font-size: 1.3rem;
  line-height: 1.5384615385em;
  padding: 0.8461538462em 2.3076923077em 0.8461538462em 2.3076923077em;
  transition: background-color 0.2s linear, color 0.2s linear, border 0.2s linear;
}
.btns-group--column .btn, .btns-group--column .shepherd-button-secondary, .btns-group--column .shepherd-button {
  padding: 0.8461538462em;
}
.btn:disabled, .shepherd-button-secondary:disabled, .shepherd-button:disabled, .btn.disabled, .disabled.shepherd-button-secondary, .disabled.shepherd-button, .btn.pointer-disabled, .pointer-disabled.shepherd-button-secondary, .pointer-disabled.shepherd-button {
  pointer-events: none;
}

.btn--primary, .shepherd-button {
  background-color: #4f81bb;
  border-color: #4f81bb;
  color: #ffffff;
}

.btn--primary:hover, .shepherd-button:hover {
  background-color: #3c689b;
  border-color: #3c689b;
  color: #ffffff;
}

.btn--primary:disabled, .shepherd-button:disabled,
.btn--primary.disabled,
.disabled.shepherd-button {
  background-color: rgba(79, 129, 187, 0.3);
  border-color: rgba(79, 129, 187, 0.3);
  color: #ffffff;
}

.btn--outline.btn--primary, .btn--outline.shepherd-button, .shepherd-button.cancel-button {
  border-style: solid;
  border-width: 1px;
  border-color: #4f81bb;
  color: #4f81bb;
  background-color: transparent;
}

.btn--outline.btn--primary:hover, .btn--outline.shepherd-button:hover, .shepherd-button.cancel-button:hover {
  background-color: #4f81bb;
  border-color: #4f81bb;
  color: #ffffff;
}

.btn--outline.btn--primary:disabled, .btn--outline.shepherd-button:disabled, .shepherd-button.cancel-button:disabled,
.btn--outline.btn--primary.disabled,
.btn--outline.disabled.shepherd-button,
.disabled.shepherd-button.cancel-button {
  background-color: transparent;
  border-color: rgba(79, 129, 187, 0.3);
  color: rgba(79, 129, 187, 0.3);
}

.btn--clear.btn--primary, .btn--clear.shepherd-button {
  background-color: transparent;
  border-color: transparent;
  color: #4f81bb;
}

.btn--clear.btn--primary:hover, .btn--clear.shepherd-button:hover {
  color: #3c689b;
}

.btn--clear.btn--primary:disabled, .btn--clear.shepherd-button:disabled,
.btn--clear.btn--primary.disabled,
.btn--clear.disabled.shepherd-button {
  background-color: transparent;
  border-color: transparent;
  color: rgba(79, 129, 187, 0.3);
}

.btn--secondary, .shepherd-button-secondary {
  background-color: #464e57;
  border-color: #464e57;
  color: #ffffff;
}

.btn--secondary:hover, .shepherd-button-secondary:hover {
  background-color: #687481;
  border-color: #687481;
  color: #ffffff;
}

.btn--secondary:disabled, .shepherd-button-secondary:disabled,
.btn--secondary.disabled,
.disabled.shepherd-button-secondary {
  background-color: rgba(70, 78, 87, 0.3);
  border-color: rgba(70, 78, 87, 0.3);
  color: #ffffff;
}

.btn--outline.btn--secondary, .btn--secondary.shepherd-button.cancel-button, .btn--outline.shepherd-button-secondary, .shepherd-button-secondary.shepherd-button.cancel-button {
  border-style: solid;
  border-width: 1px;
  border-color: #464e57;
  color: #464e57;
  background-color: transparent;
}

.btn--outline.btn--secondary:hover, .btn--secondary.shepherd-button.cancel-button:hover, .btn--outline.shepherd-button-secondary:hover, .shepherd-button-secondary.shepherd-button.cancel-button:hover {
  background-color: #464e57;
  border-color: #464e57;
  color: #ffffff;
}

.btn--outline.btn--secondary:disabled, .btn--secondary.shepherd-button.cancel-button:disabled, .btn--outline.shepherd-button-secondary:disabled, .shepherd-button-secondary.shepherd-button.cancel-button:disabled,
.btn--outline.btn--secondary.disabled,
.btn--secondary.disabled.shepherd-button.cancel-button,
.btn--outline.disabled.shepherd-button-secondary,
.disabled.shepherd-button-secondary.shepherd-button.cancel-button {
  background-color: transparent;
  border-color: rgba(70, 78, 87, 0.3);
  color: rgba(70, 78, 87, 0.3);
}

.btn--clear.btn--secondary, .btn--clear.shepherd-button-secondary {
  background-color: transparent;
  border-color: transparent;
  color: #464e57;
}

.btn--clear.btn--secondary:hover, .btn--clear.shepherd-button-secondary:hover {
  color: #687481;
}

.btn--clear.btn--secondary:disabled, .btn--clear.shepherd-button-secondary:disabled,
.btn--clear.btn--secondary.disabled,
.btn--clear.disabled.shepherd-button-secondary {
  background-color: transparent;
  border-color: transparent;
  color: rgba(70, 78, 87, 0.3);
}

.btn--tertiary {
  background-color: #778593;
  border-color: #778593;
  color: #ffffff;
}

.btn--tertiary:hover {
  background-color: #a2abb5;
  border-color: #a2abb5;
  color: #ffffff;
}

.btn--tertiary:disabled,
.btn--tertiary.disabled {
  background-color: rgba(119, 133, 147, 0.3);
  border-color: rgba(119, 133, 147, 0.3);
  color: #ffffff;
}

.btn--outline.btn--tertiary, .btn--tertiary.shepherd-button.cancel-button {
  border-style: solid;
  border-width: 1px;
  border-color: #778593;
  color: #778593;
  background-color: transparent;
}

.btn--outline.btn--tertiary:hover, .btn--tertiary.shepherd-button.cancel-button:hover {
  background-color: #778593;
  border-color: #778593;
  color: #ffffff;
}

.btn--outline.btn--tertiary:disabled, .btn--tertiary.shepherd-button.cancel-button:disabled,
.btn--outline.btn--tertiary.disabled,
.btn--tertiary.disabled.shepherd-button.cancel-button {
  background-color: transparent;
  border-color: rgba(119, 133, 147, 0.3);
  color: rgba(119, 133, 147, 0.3);
}

.btn--clear.btn--tertiary {
  background-color: transparent;
  border-color: transparent;
  color: #778593;
}

.btn--clear.btn--tertiary:hover {
  color: #a2abb5;
}

.btn--clear.btn--tertiary:disabled,
.btn--clear.btn--tertiary.disabled {
  background-color: transparent;
  border-color: transparent;
  color: rgba(119, 133, 147, 0.3);
}

.btn--other {
  background-color: #778593;
  border-color: #778593;
  color: #ffffff;
}

.btn--other:hover {
  background-color: #a2abb5;
  border-color: #a2abb5;
  color: #ffffff;
}

.btn--other:disabled,
.btn--other.disabled {
  background-color: rgba(119, 133, 147, 0.3);
  border-color: rgba(119, 133, 147, 0.3);
  color: #ffffff;
}

.btn--outline.btn--other, .btn--other.shepherd-button.cancel-button {
  border-style: solid;
  border-width: 1px;
  border-color: #778593;
  color: #778593;
  background-color: transparent;
}

.btn--outline.btn--other:hover, .btn--other.shepherd-button.cancel-button:hover {
  background-color: #778593;
  border-color: #778593;
  color: #ffffff;
}

.btn--outline.btn--other:disabled, .btn--other.shepherd-button.cancel-button:disabled,
.btn--outline.btn--other.disabled,
.btn--other.disabled.shepherd-button.cancel-button {
  background-color: transparent;
  border-color: rgba(119, 133, 147, 0.3);
  color: rgba(119, 133, 147, 0.3);
}

.btn--clear.btn--other {
  background-color: transparent;
  border-color: transparent;
  color: #778593;
}

.btn--clear.btn--other:hover {
  color: #a2abb5;
}

.btn--clear.btn--other:disabled,
.btn--clear.btn--other.disabled {
  background-color: transparent;
  border-color: transparent;
  color: rgba(119, 133, 147, 0.3);
}

.btn--danger {
  background-color: #d10000;
  border-color: #d10000;
  color: #ffffff;
}

.btn--danger:hover {
  background-color: #ff1f1f;
  border-color: #ff1f1f;
  color: #ffffff;
}

.btn--danger:disabled,
.btn--danger.disabled {
  background-color: rgba(209, 0, 0, 0.3);
  border-color: rgba(209, 0, 0, 0.3);
  color: #ffffff;
}

.btn--outline.btn--danger, .btn--danger.shepherd-button.cancel-button {
  border-style: solid;
  border-width: 1px;
  border-color: #d10000;
  color: #d10000;
  background-color: transparent;
}

.btn--outline.btn--danger:hover, .btn--danger.shepherd-button.cancel-button:hover {
  background-color: #d10000;
  border-color: #d10000;
  color: #ffffff;
}

.btn--outline.btn--danger:disabled, .btn--danger.shepherd-button.cancel-button:disabled,
.btn--outline.btn--danger.disabled,
.btn--danger.disabled.shepherd-button.cancel-button {
  background-color: transparent;
  border-color: rgba(209, 0, 0, 0.3);
  color: rgba(209, 0, 0, 0.3);
}

.btn--clear.btn--danger {
  background-color: transparent;
  border-color: transparent;
  color: #d10000;
}

.btn--clear.btn--danger:hover {
  color: #ff1f1f;
}

.btn--clear.btn--danger:disabled,
.btn--clear.btn--danger.disabled {
  background-color: transparent;
  border-color: transparent;
  color: rgba(209, 0, 0, 0.3);
}

.btn--info {
  background-color: #4f81bb;
  border-color: #4f81bb;
  color: #ffffff;
}

.btn--info:hover {
  background-color: #86a9d0;
  border-color: #86a9d0;
  color: #ffffff;
}

.btn--info:disabled,
.btn--info.disabled {
  background-color: rgba(79, 129, 187, 0.3);
  border-color: rgba(79, 129, 187, 0.3);
  color: #ffffff;
}

.btn--outline.btn--info, .btn--info.shepherd-button.cancel-button {
  border-style: solid;
  border-width: 1px;
  border-color: #4f81bb;
  color: #4f81bb;
  background-color: transparent;
}

.btn--outline.btn--info:hover, .btn--info.shepherd-button.cancel-button:hover {
  background-color: #4f81bb;
  border-color: #4f81bb;
  color: #ffffff;
}

.btn--outline.btn--info:disabled, .btn--info.shepherd-button.cancel-button:disabled,
.btn--outline.btn--info.disabled,
.btn--info.disabled.shepherd-button.cancel-button {
  background-color: transparent;
  border-color: rgba(79, 129, 187, 0.3);
  color: rgba(79, 129, 187, 0.3);
}

.btn--clear.btn--info {
  background-color: transparent;
  border-color: transparent;
  color: #4f81bb;
}

.btn--clear.btn--info:hover {
  color: #86a9d0;
}

.btn--clear.btn--info:disabled,
.btn--clear.btn--info.disabled {
  background-color: transparent;
  border-color: transparent;
  color: rgba(79, 129, 187, 0.3);
}

.btn--success {
  background-color: #5fa200;
  border-color: #5fa200;
  color: #ffffff;
}

.btn--success:hover {
  background-color: #8cef00;
  border-color: #8cef00;
  color: #ffffff;
}

.btn--success:disabled,
.btn--success.disabled {
  background-color: rgba(95, 162, 0, 0.3);
  border-color: rgba(95, 162, 0, 0.3);
  color: #ffffff;
}

.btn--outline.btn--success, .btn--success.shepherd-button.cancel-button {
  border-style: solid;
  border-width: 1px;
  border-color: #5fa200;
  color: #5fa200;
  background-color: transparent;
}

.btn--outline.btn--success:hover, .btn--success.shepherd-button.cancel-button:hover {
  background-color: #5fa200;
  border-color: #5fa200;
  color: #ffffff;
}

.btn--outline.btn--success:disabled, .btn--success.shepherd-button.cancel-button:disabled,
.btn--outline.btn--success.disabled,
.btn--success.disabled.shepherd-button.cancel-button {
  background-color: transparent;
  border-color: rgba(95, 162, 0, 0.3);
  color: rgba(95, 162, 0, 0.3);
}

.btn--clear.btn--success {
  background-color: transparent;
  border-color: transparent;
  color: #5fa200;
}

.btn--clear.btn--success:hover {
  color: #8cef00;
}

.btn--clear.btn--success:disabled,
.btn--clear.btn--success.disabled {
  background-color: transparent;
  border-color: transparent;
  color: rgba(95, 162, 0, 0.3);
}

.btn--warning {
  background-color: #f49317;
  border-color: #f49317;
  color: #ffffff;
}

.btn--warning:hover {
  background-color: #f7b560;
  border-color: #f7b560;
  color: #ffffff;
}

.btn--warning:disabled,
.btn--warning.disabled {
  background-color: rgba(244, 147, 23, 0.3);
  border-color: rgba(244, 147, 23, 0.3);
  color: #ffffff;
}

.btn--outline.btn--warning, .btn--warning.shepherd-button.cancel-button {
  border-style: solid;
  border-width: 1px;
  border-color: #f49317;
  color: #f49317;
  background-color: transparent;
}

.btn--outline.btn--warning:hover, .btn--warning.shepherd-button.cancel-button:hover {
  background-color: #f49317;
  border-color: #f49317;
  color: #ffffff;
}

.btn--outline.btn--warning:disabled, .btn--warning.shepherd-button.cancel-button:disabled,
.btn--outline.btn--warning.disabled,
.btn--warning.disabled.shepherd-button.cancel-button {
  background-color: transparent;
  border-color: rgba(244, 147, 23, 0.3);
  color: rgba(244, 147, 23, 0.3);
}

.btn--clear.btn--warning {
  background-color: transparent;
  border-color: transparent;
  color: #f49317;
}

.btn--clear.btn--warning:hover {
  color: #f7b560;
}

.btn--clear.btn--warning:disabled,
.btn--clear.btn--warning.disabled {
  background-color: transparent;
  border-color: transparent;
  color: rgba(244, 147, 23, 0.3);
}

.btn--inverse {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #000000;
}

.btn--inverse:hover {
  background-color: #d9d9d9;
  border-color: #d9d9d9;
  color: #000000;
}

.btn--inverse:disabled,
.btn--inverse.disabled {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0.3);
  color: #000000;
}

.btn--outline.btn--inverse, .btn--inverse.shepherd-button.cancel-button {
  border-style: solid;
  border-width: 1px;
  border-color: #ffffff;
  color: #ffffff;
  background-color: transparent;
}

.btn--outline.btn--inverse:hover, .btn--inverse.shepherd-button.cancel-button:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #000000;
}

.btn--outline.btn--inverse:disabled, .btn--inverse.shepherd-button.cancel-button:disabled,
.btn--outline.btn--inverse.disabled,
.btn--inverse.disabled.shepherd-button.cancel-button {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.3);
}

.btn--clear.btn--inverse {
  background-color: transparent;
  border-color: transparent;
  color: #ffffff;
}

.btn--clear.btn--inverse:hover {
  color: #d9d9d9;
}

.btn--clear.btn--inverse:disabled,
.btn--clear.btn--inverse.disabled {
  background-color: transparent;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.3);
}

.btn, .shepherd-button-secondary, .shepherd-button {
  font-size: 1.3rem;
  line-height: 1.5384615385em;
}

.btn--small, .shepherd-button-secondary, .shepherd-button {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.btn--medium {
  font-size: 1.6rem;
  line-height: 2.2rem;
}

.btn--large {
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.btn--full {
  width: 100%;
}

.btn--wide {
  padding-left: 4.6153846154em;
  padding-right: 4.6153846154em;
}

.btn--icon-only {
  position: relative;
  padding-right: 1.0769230769em;
  padding-left: 1.0769230769em;
}
.btn--icon-only [class^=ico-],
.btn--icon-only [class*=" ico-"] {
  display: block;
  font-size: 1.5384615385em;
}

.btn--rounded {
  border-radius: 50%;
  padding: 0.8461538462em;
}

.btn--icon-start {
  padding-left: 4.2307692308em;
  position: relative;
}
.btn--icon-start [class^=ico-],
.btn--icon-start [class*=" ico-"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.9230769231em;
}
.btn--icon-start [class^=ico-]::before,
.btn--icon-start [class*=" ico-"]::before {
  display: block;
  font-size: 1.5384615385em;
}

.btn--icon-end {
  position: relative;
  padding-right: 4.2307692308em;
}
.btn--icon-end [class^=ico-],
.btn--icon-end [class*=" ico-"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.9230769231em;
}
.btn--icon-end [class^=ico-]::before,
.btn--icon-end [class*=" ico-"]::before {
  display: block;
  font-size: 1.5384615385em;
}

.calendar-month {
  padding: 0 70px;
  margin-bottom: 15px;
  display: flex;
  position: relative;
}

.calendar-month__title {
  flex: 1;
}
.calendar-month__title .calendar-picker-title {
  text-transform: uppercase;
  font-size: 2rem;
}
.calendar-month__title .calendar-picker-title small {
  font-size: 1.2rem;
}

.calendar-ribbon {
  display: flex;
  width: 100%;
  padding: 0 15px;
  position: relative;
}

.calendar-ribbon__day {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 5px;
  border: 2px solid #d8d8d8;
  border-radius: 3px;
  padding: 10px 5px;
  cursor: pointer;
  user-select: none;
}
.calendar-ribbon__day.current-day {
  border-color: #4f81bb;
  background: rgba(79, 129, 187, 0.2);
}
.calendar-ribbon__day.today {
  border-color: #4f81bb;
}
.calendar-ribbon__day.saturday {
  color: #4f81bb;
}
.calendar-ribbon__day.sunday {
  color: #d10000;
}
.calendar-ribbon__day .day {
  font-size: 2rem;
}
.calendar-ribbon__day .day-of-week {
  font-size: 1rem;
  text-transform: uppercase;
}
.calendar-ribbon__day .month {
  text-transform: uppercase;
  font-size: 1.1rem;
}
.calendar-ribbon__day .events {
  margin-top: 8px;
  color: #778593;
}

.calendar-ribbon__nav {
  align-self: center;
  padding: 10px;
  width: 50px;
}

.calendar-ribbon__loader {
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .calendar-ribbon,
  .calendar-month {
    padding: 0;
  }
  .calendar-ribbon__nav {
    display: none;
  }
  .calendar-month {
    flex-wrap: wrap;
    padding: 0 5px;
  }
  .calendar-month__title {
    width: 100%;
    flex: none;
  }
  .calendar-month__nav {
    margin-top: 10px;
    width: 100%;
  }
  .calendar-month__nav .links-group {
    justify-content: flex-end;
  }
}
.c-monthly-calendar__heading {
  display: flex;
  padding: 15px 5px;
  justify-content: center;
  align-items: center;
}
.c-monthly-calendar__heading a {
  color: inherit;
}
.c-monthly-calendar__heading .label {
  color: #4f81bb;
  font-size: 2rem;
  text-transform: uppercase;
}
.c-monthly-calendar__heading small {
  color: #4f81bb;
  font-size: 1.3rem;
}
.c-monthly-calendar__heading .c-monthly-calendar__heading-name {
  width: 420px;
  text-align: center;
  position: relative;
}
.c-monthly-calendar__heading .c-monthly-calendar__heading-name-short {
  width: 180px;
  text-align: center;
  position: relative;
}
.c-monthly-calendar__heading .c-monthly-calendar__arrow-next,
.c-monthly-calendar__heading .c-monthly-calendar__arrow-prev {
  flex-basis: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  font-size: 1.6rem;
}
.c-monthly-calendar__heading .c-monthly-calendar__arrow-next::before {
  content: "\e919";
  font-family: "sportbm";
}
.c-monthly-calendar__heading .c-monthly-calendar__arrow-prev::before {
  content: "\e916";
  font-family: "sportbm";
}

.c-monthly-calendar__nav {
  display: flex;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 100%;
  justify-content: space-between;
}
.c-monthly-calendar__nav .links-group {
  justify-content: flex-end;
}
.c-monthly-calendar__nav .links-group:only-child {
  margin-left: auto;
}

.c-monthly-calendar__body-week {
  display: flex;
}

.c-monthly-calendar__day-name {
  flex: 1;
  color: #4f81bb;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin: 3px;
}

.c-monthly-calendar__day {
  flex: 1;
  border: 1px solid #d8d8d8;
  min-height: 65px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 4px;
  cursor: pointer;
  padding: 6px;
  margin: 3px;
  flex-direction: column;
  transition: background-color 0.2s linear;
}
.c-monthly-calendar__day .events-count {
  color: #778593;
  font-size: 1.6rem;
  line-height: 1;
  text-align: center;
  width: 100%;
  padding: 10px 0 6px;
}
.c-monthly-calendar__day.today {
  border-color: #4f81bb;
  color: inherit;
}
.c-monthly-calendar__day.past {
  color: #778593;
  opacity: 0.6;
}
.c-monthly-calendar__day.odd {
  background-color: #f2f2f2;
}
.c-monthly-calendar__day.odd:hover {
  background: #e6e6e6;
}
.c-monthly-calendar__day.saturday {
  color: #4f81bb;
}
.c-monthly-calendar__day.sunday {
  color: #d10000;
}
.c-monthly-calendar__day.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.c-monthly-calendar__day:hover {
  background: #ebebeb;
}

.c-monthly-calendar__body {
  position: relative;
}
.c-monthly-calendar__body .c-monthly-calendar__body-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .c-monthly-calendar__nav {
    flex-wrap: wrap;
  }
  .c-monthly-calendar__nav .links-group {
    width: 100%;
    padding-top: 25px;
  }
  .c-monthly-calendar__nav .links-group:first-child {
    padding-top: 0;
  }
}
@media only screen and (max-width: 479px) {
  .c-monthly-calendar__day {
    min-height: 48px;
    border-radius: 4px;
    padding: 3px;
    font-size: 1.2rem;
    margin: 1px;
  }
  .c-monthly-calendar__day .events-count {
    font-size: 1.2rem;
    padding: 10px 0 3px;
  }
  .c-monthly-calendar__day .month-short {
    font-size: 1.1rem;
  }
  .c-monthly-calendar__day [class^=ico-],
  .c-monthly-calendar__day [class*=" ico-"] {
    margin-bottom: 3px;
    font-size: 1rem;
    margin-right: 2px;
  }
  .c-monthly-calendar__day-name {
    font-size: 1.1rem;
    margin: 1px;
  }
  .c-monthly-calendar__heading .label {
    font-size: 1.5rem;
  }
  .c-monthly-calendar__heading .c-monthly-calendar__heading-name {
    padding: 0 20px;
    width: 240px;
  }
  .c-monthly-calendar__heading .c-monthly-calendar__arrow-next,
  .c-monthly-calendar__heading .c-monthly-calendar__arrow-prev {
    font-size: 1.8rem;
  }
}
.event-header {
  display: flex;
  flex-wrap: wrap;
}

.event-header--with-actions {
  position: relative;
}

.event-header__actions {
  position: absolute;
  top: -10px;
  right: -10px;
}

.event-header__start {
  width: 100%;
  color: #778593;
  margin-bottom: 15px;
  padding-right: 50px;
  line-height: 1.5;
}

.event-header__icon {
  width: 30px;
  margin-right: 20px;
  margin-left: 10px;
  font-size: 3rem;
  color: #778593;
  text-align: center;
  justify-content: space-between;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
}

.event-header__content {
  border-left: 1px solid #d8d8d8;
  padding-left: 15px;
  flex: 1;
}
.event-header__content h3 {
  margin-bottom: 10px;
}
.event-header__content .text-with-icon {
  margin-top: 7px;
}

.event-header__chart {
  margin-top: 15px;
  max-width: 450px;
  width: 100%;
  align-self: flex-end;
}

.event-header__time {
  align-self: flex-end;
}

@media only screen and (max-width: 993px) {
  .event-header__chart {
    max-width: 350px;
  }
}
@media only screen and (max-width: 993px) {
  .event-header__icon {
    margin-left: 0;
  }
}
.events-list__day {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 20px 10px 10px;
}
.events-list__day:first-child {
  margin-top: 0;
}

.event-occurrence-box {
  display: flex;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  margin: 20px 5px;
}

.event-occurrence-box__dates {
  width: 140px;
  font-size: 15px;
}
.event-occurrence-box__dates strong {
  display: block;
}

.event-occurrence-box__content {
  flex: 1;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #d8d8d8;
}
table .event-occurrence-box__content {
  margin-left: 0;
}
.event-occurrence-box__content .event-details {
  padding-left: 50px;
  position: relative;
}
.event-occurrence-box__content .event-details [class^=ico-],
.event-occurrence-box__content .event-details [class*=" ico-"] {
  font-size: 30px;
  position: absolute;
  top: 5px;
  left: 0;
  color: #778593;
}
.event-occurrence-box__content .event-info {
  margin-top: 10px;
  color: #778593;
}
.event-occurrence-box__content .event-info p {
  margin: 5px 0;
}
.event-occurrence-box__content .name {
  margin: 0 0 5px;
}
.event-occurrence-box__content .place {
  color: #778593;
}

.event-occurrence-box__chart {
  width: 450px;
}

.event-occurrence-box__training-unit {
  margin-left: 20px;
  text-align: right;
  width: 40px;
  padding-top: 5px;
}
.event-occurrence-box__training-unit [class^=ico-],
.event-occurrence-box__training-unit [class*=" ico-"] {
  display: block;
  font-size: 30px;
}

.event-occurrence-box__actions {
  margin-left: 20px;
  width: 46px;
}

.event-occurrence__attendance-info {
  position: relative;
  padding-left: 45px;
}
.event-occurrence__attendance-info .type-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.event-occurrence__attendance-info .type {
  display: block;
  font-size: 1.5rem;
}
.event-occurrence__attendance-info .notes {
  display: block;
  margin-top: 10px;
}

@media only screen and (max-width: 993px) {
  .table .event-occurrence-box__content {
    padding-left: 0;
    border-left: none;
  }
}
.card {
  padding: 15px 30px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  position: relative;
}
[no-margin] .card, .card.no-margin {
  margin-bottom: 0;
  height: 100%;
}

.loading-locked-wrapper {
  position: relative;
}

.card-locked {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.65);
  display: block;
  border-radius: 4px;
}

.card--persona {
  background: linear-gradient(to bottom, #f0f3f6 0, #f0f3f6 145px, #fff 145px);
}

.card--color-transparent {
  background: transparent;
  box-shadow: none;
  padding: 0;
}
.card--color-transparent > .card-header, .card--color-transparent > .card-footer {
  padding-left: 30px;
  padding-right: 30px;
}
.card--color-transparent > .card-footer {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 30px;
}
.card--color-transparent.card--with-icon > .card-header {
  padding-left: 80px;
}
.card--color-transparent.card--with-icon > .card-header > .card-header__icon {
  left: 30px;
}
.card--color-transparent.card--with-icon > .card-body {
  padding-left: 0;
  padding-right: 0;
}

.card-header {
  padding: 15px 0;
  display: flex;
  position: relative;
  align-items: center;
  flex-wrap: wrap;
}
.card--with-icon > .card-header {
  padding-left: 50px;
}
.card--with-options > .card-header {
  padding-right: 70px;
}
.card--persona .card-header {
  padding: 0;
}
.card-header:empty {
  display: none;
}

.card-header__icon {
  position: absolute;
  left: 0;
  top: 10px;
  font-size: 3.3rem;
}
.card-header__icon [class^=ico-],
.card-header__icon [class*=" ico-"] {
  display: block;
  line-height: 1;
}

.card-header__actions {
  max-width: 50%;
}

.card-header__actions .btns-group,
.card-header__options .btns-group,
.card-options .btns-group {
  margin: -18px -8px;
}

.card-header__options,
.card-options {
  position: absolute;
  top: 15px;
  right: 0;
}

.card-header__title {
  flex: 1;
  font-size: 2rem;
  line-height: 1.2;
  word-break: break-word;
}
.card-header__title small {
  display: block;
  color: #778593;
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-top: 2px;
}
.card-header__title a {
  color: inherit;
}

.card-body {
  padding: 15px 0;
  flex: 1;
}
.card-body:empty {
  display: none;
}

.card-footer {
  padding: 15px 0;
}
.card-footer .links-group,
.card-footer .btns-group {
  text-align: right;
  justify-content: flex-end;
}

@media only screen and (max-width: 1024px) {
  .card-header__actions {
    margin-top: 15px;
    width: 100%;
    max-width: none;
  }
  .card-header__actions .btns-group,
  .card-header__options .btns-group,
  .card-options .btns-group {
    margin: -8px -8px;
  }
  .card-header {
    padding-right: 60px;
    position: relative;
  }
}
@media only screen and (max-width: 767px) {
  .card {
    padding: 0 15px;
  }
}
.p-carousel-indicators {
  list-style: none;
  margin: 0;
}
.p-carousel-indicators button {
  border: 2px solid #464e57;
  border-radius: 5px;
  background-color: transparent;
  width: 20px;
  height: 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: border 0.2s linear, background-color 0.2s linear;
}
.p-carousel-indicators .p-highlight button {
  border-color: #4f81bb;
  background-color: #4f81bb;
}

.conversation-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 30px 0;
}
.conversation-item .author {
  font-size: 12px;
  color: #778593;
  margin-bottom: 10px;
}
.conversation-item .message {
  position: relative;
  background: #f0f3f6;
  border: 1px solid #f0f3f6;
  border-radius: 5px;
  line-height: 1.5;
  padding: 10px 15px;
  max-width: 90%;
}
.conversation-item .message:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: none;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f0f3f6;
  border-left: 10px solid transparent;
  top: -10px;
  left: 5px;
}
.conversation-item.user-message {
  align-items: flex-end;
  justify-content: flex-end;
}
.conversation-item.user-message .message {
  background: #e0f1f8;
  border: 1px solid #e0f1f8;
  border-radius: 3px;
}
.conversation-item.user-message .message:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: none;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #e0f1f8;
  border-left: 10px solid transparent;
  top: -10px;
  left: auto;
  right: 5px;
}

.cc-window {
  opacity: 1;
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
}

.cc-window.cc-invisible {
  opacity: 0;
}

.cc-animate.cc-revoke {
  -webkit-transition: transform 1s ease;
  -webkit-transition: -webkit-transform 1s ease;
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease;
}

.cc-animate.cc-revoke.cc-top {
  -webkit-transform: translateY(-2em);
  transform: translateY(-2em);
}

.cc-animate.cc-revoke.cc-bottom {
  -webkit-transform: translateY(2em);
  transform: translateY(2em);
}

.cc-animate.cc-revoke.cc-active.cc-top {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.cc-animate.cc-revoke.cc-active.cc-bottom {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.cc-revoke:hover {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.cc-grower {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 1s;
  transition: max-height 1s;
}

.cc-revoke,
.cc-window {
  position: fixed;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  z-index: 9999;
}

.cc-window.cc-static {
  position: static;
}

.cc-window.cc-floating {
  padding: 2em;
  max-width: 24em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.cc-window.cc-banner {
  padding: 1em 1.8em;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.cc-revoke {
  padding: 0.5em;
}

.cc-revoke:hover {
  text-decoration: underline;
}

.cc-header {
  font-size: 18px;
  font-weight: 700;
}

.cc-btn,
.cc-close,
.cc-link,
.cc-revoke {
  cursor: pointer;
}

.cc-link {
  opacity: 0.8;
  display: inline-block;
  padding: 0.2em;
  text-decoration: underline;
}

.cc-link:hover {
  opacity: 1;
}

.cc-link:active,
.cc-link:visited {
  color: initial;
}

.cc-btn {
  display: block;
  padding: 0.4em 0.8em;
  font-size: 0.9em;
  font-weight: 700;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
}

.cc-highlight .cc-btn:first-child {
  background-color: transparent;
  border-color: transparent;
}

.cc-highlight .cc-btn:first-child:focus,
.cc-highlight .cc-btn:first-child:hover {
  background-color: transparent;
  text-decoration: underline;
}

.cc-close {
  display: block;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 1.6em;
  opacity: 0.9;
  line-height: 0.75;
}

.cc-close:focus,
.cc-close:hover {
  opacity: 1;
}

.cc-revoke.cc-top {
  top: 0;
  left: 3em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.cc-revoke.cc-bottom {
  bottom: 0;
  left: 3em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.cc-revoke.cc-left {
  left: 3em;
  right: unset;
}

.cc-revoke.cc-right {
  right: 3em;
  left: unset;
}

.cc-top {
  top: 1em;
}

.cc-left {
  left: 1em;
}

.cc-right {
  right: 1em;
}

.cc-bottom {
  bottom: 1em;
}

.cc-floating > .cc-link {
  margin-bottom: 1em;
}

.cc-floating .cc-message {
  display: block;
  margin-bottom: 1em;
  font-size: 1.4rem;
  line-height: 1.4;
}

.cc-window.cc-floating .cc-compliance {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.cc-window.cc-banner {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cc-banner.cc-top {
  left: 0;
  right: 0;
  top: 0;
}

.cc-banner.cc-bottom {
  left: 0;
  right: 0;
  bottom: 0;
}

.cc-banner .cc-message {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  max-width: 100%;
  margin-right: 1em;
}

.cc-compliance {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.cc-floating .cc-compliance > .cc-btn {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.cc-btn + .cc-btn {
  margin-left: 0.5em;
}

@media print {
  .cc-revoke,
  .cc-window {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal;
  }
}
@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-top {
    top: 0;
  }
  .cc-window.cc-bottom {
    bottom: 0;
  }
  .cc-window.cc-banner,
  .cc-window.cc-floating,
  .cc-window.cc-left,
  .cc-window.cc-right {
    left: 0;
    right: 0;
  }
  .cc-window.cc-banner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .cc-window.cc-banner .cc-compliance {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .cc-window.cc-floating {
    max-width: none;
  }
  .cc-window .cc-message {
    margin-bottom: 1em;
  }
  .cc-window.cc-banner {
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;
  }
  .cc-window.cc-banner .cc-message {
    margin-right: 0;
  }
}
.cc-floating.cc-theme-classic {
  padding: 1.2em;
  border-radius: 5px;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
  text-align: center;
  display: inline;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.cc-theme-classic .cc-btn {
  border-radius: 5px;
}

.cc-theme-classic .cc-btn:last-child {
  min-width: 140px;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
  display: inline-block;
}

.cc-theme-edgeless.cc-window {
  padding: 0;
}

.cc-floating.cc-theme-edgeless .cc-message {
  margin: 2em;
  margin-bottom: 1.5em;
}

.cc-banner.cc-theme-edgeless .cc-btn {
  margin: 0;
  padding: 0.8em 1.8em;
  height: 100%;
}

.cc-banner.cc-theme-edgeless .cc-message {
  margin-left: 1em;
}

.cc-floating.cc-theme-edgeless .cc-btn + .cc-btn {
  margin-left: 0;
}

.chart-400 canvas {
  height: 400px !important;
}

.divider {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #d8d8d8;
}

.cursor-pointer {
  cursor: pointer;
}

.text-line-through {
  text-decoration: line-through;
}

.ordered-rows {
  display: flex;
  flex-direction: column;
}
.ordered-rows .ordered-rows__end {
  order: 99999;
}

.video-wrapper {
  display: block;
  width: 100%;
  max-width: 600px;
  margin: 15px 0;
}
.video-wrapper .video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: block;
  padding-top: 56.25%;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.fancy-amount {
  font-size: 10px;
  line-height: 2em;
  font-weight: 400;
}
.float-right .fancy-amount {
  position: relative;
  top: -4px;
}
.fancy-amount.amount-minus {
  color: #d10000;
}
.fancy-amount .value-wrapper {
  font-size: 2.4em;
}
.fancy-amount .decimal-wrapper {
  font-size: 1.4em;
  line-height: 1;
  width: 2.1em;
  position: relative;
  text-align: right;
  top: -0.5em;
}
.fancy-amount .currency-wrapper {
  font-size: 0.9em;
  line-height: 1;
  position: relative;
  top: 2px;
  margin-left: -1.85em;
  min-width: 17px;
  display: inline-block;
}

.date-with-icon {
  padding-left: 40px;
  position: relative;
}
.date-with-icon [class^=ico-],
.date-with-icon [class*=" ico-"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-size: 2.5rem;
}

.profile-intro {
  text-align: center;
  margin-bottom: 30px;
}
.profile-intro.profile-intro--leader img {
  border-radius: 10px 10px 50% 50%;
}
.profile-intro.profile-intro--player img {
  border-radius: 50%;
}
.profile-intro img {
  height: 180px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.25);
}
.profile-intro .profile-intro__image {
  position: relative;
  margin: auto;
  height: 180px;
  max-width: 60%;
  display: inline-block;
}
.profile-intro .profile-intro__image .action {
  position: absolute;
  bottom: 5%;
  right: 5%;
}
.profile-intro h3 {
  font-size: 2.4rem;
  font-weight: 300;
}
.profile-intro h3 small {
  font-size: 1.6rem;
  font-weight: 300;
  font-style: italic;
  display: block;
  color: #778593;
}
.profile-intro .profile__role {
  margin-top: -5px;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #778593;
}

.user-tile {
  display: flex;
}

.user-tile__image {
  margin-right: 15px;
}
.user-tile__image .image-wrapper {
  display: block;
  position: relative;
}
.user-tile__image .user-tile__age {
  position: absolute;
  bottom: 2px;
  left: 2px;
  background: #4f81bb;
  color: #ffffff;
  font-size: 1.1rem;
  padding: 3px;
  border-radius: 4px;
}
.user-tile__image, .user-tile__image img {
  width: 90px;
}
.user-tile__image img {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.user-tile--large .user-tile__image {
  width: 180px;
  margin-right: 30px;
}
.user-tile--leader .user-tile__image img {
  border-radius: 10px 10px 50% 50%;
}
.user-tile--player .user-tile__image img {
  border-radius: 50%;
}
.user-tile--group .user-tile__image img {
  border-radius: 5px;
}

.user-tile__content {
  flex: 1;
  padding: 12px 0 0;
}

.user-tile__values {
  margin: -10px -15px;
  display: flex;
  padding-top: 10px;
}
.user-tile__values > * {
  margin: 10px 15px;
}

.user-tile__value {
  position: relative;
  padding-left: 40px;
  font-size: 1.8rem;
}
.user-tile__value [class^=ico-],
.user-tile__value [class*=" ico-"] {
  font-size: 3rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  color: #778593;
}

.shortcuts {
  margin: 20px 0;
}
.shortcuts:last-child {
  margin-bottom: 0;
}
.shortcuts:first-child {
  margin-top: 0;
}

.shortcut-item {
  background: #fff;
  padding: 30px 15px 30px 75px;
  border: 1px solid #d8d8d8;
  position: relative;
  font-size: 1.6rem;
  transition: background-color 0.1s linear;
  border-radius: 3px;
  display: block;
}
.shortcut-item.active, .shortcut-item:hover {
  background: #f2f2f2;
}
.shortcut-item [class^=ico-],
.shortcut-item [class*=" ico-"] {
  font-size: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  color: #4f81bb;
}

.pinned-scrollable-bar {
  padding: 8px 0;
  transition: box-shadow 0.2s linear;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
.pinned-scrollable-bar.pinned {
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 10;
  box-shadow: 0 9px 5px -6px rgba(0, 0, 0, 0.2);
}

.package-box .box-simple__content {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  height: 100%;
}

.campaign.campaign--is-highlighted .card {
  border: 5px solid #b6cd2a;
}
.campaign .campaign-image img {
  display: block;
  margin: 0 auto 15px;
}

.campaign-actions {
  display: flex;
  align-items: flex-end;
}
.campaign-actions > * {
  flex: 1;
}
.campaign-actions .campaign-actions__cta {
  text-align: right;
}

@media only screen and (max-width: 993px) {
  .pinned-scrollable-bar {
    position: static !important;
    box-shadow: none !important;
  }
  .profile-intro {
    padding-top: 20px;
  }
  .profile-intro .profile-intro__image {
    height: 120px;
  }
  .profile-intro img {
    height: 120px;
  }
  .profile-intro h3 {
    font-size: 1.8rem;
  }
}
sbm-form-actions-row {
  display: block;
  margin-top: 30px;
}

.form-header {
  width: calc(100% + 20px);
  font-size: 14px;
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
  margin: 45px -10px 15px;
  text-transform: uppercase;
}

.form-header--first {
  margin-top: 0;
}

.form-header-hint {
  text-transform: none;
  display: block;
  color: #778593;
  margin: -8px auto 15px;
  font-size: 11px;
  padding-left: 20px;
  position: relative;
  line-height: 15px;
}
.form-header-hint [class^=ico-],
.form-header-hint [class*=" ico-"] {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 15px;
}

.form-row {
  margin-top: 15px;
}
.form-row:first-child:not(.ordered-rows__end):not(.form-row--first) {
  margin-top: 0;
}

.form-row--summary {
  margin-top: 5px;
}
.form-row--summary:first-child {
  margin-top: 0;
}

.form-row__hint {
  color: #778593;
  display: block;
  font-size: 12px;
  font-weight: 300;
  margin-top: 2px;
}

.field-with-hint, .field-with-icon {
  display: flex;
}
.field-with-hint > *, .field-with-icon > * {
  flex: 1;
}
.field-with-hint .hint-icon, .field-with-hint .field-icon, .field-with-icon .hint-icon, .field-with-icon .field-icon {
  display: flex;
  flex: none;
  width: 40px;
  height: 44px;
  padding-left: 10px;
  align-items: center;
}
.field-with-hint [class^=ico-],
.field-with-hint [class*=" ico-"], .field-with-icon [class^=ico-],
.field-with-icon [class*=" ico-"] {
  font-size: 2.4rem;
  color: #778593;
}

.form-actions {
  margin-top: 30px;
}
.form-actions hr:first-child {
  margin-top: 0;
}
.form-actions:first-child {
  margin-top: 0;
}

.form-actions-hint {
  margin-top: 30px;
  position: relative;
  color: #778593;
  margin-bottom: -15px;
}
.form-actions-hint [class^=ico-],
.form-actions-hint [class*=" ico-"] {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.form-actions-hint [class^=ico-] + .form-actions-hint__content,
.form-actions-hint [class*=" ico-"] + .form-actions-hint__content {
  padding: 0 30px;
}
.form-actions-hint sbm-icon + .form-actions-hint__content {
  padding: 0 30px;
}

.form-actions-hint__content {
  padding: 0 10px;
}

.form-label {
  display: flex;
  font-weight: 400;
  color: #778593;
  justify-content: flex-start;
  line-height: 24px;
  text-align: left;
}
.form-label br {
  width: 100%;
}
.form-horizontal .form-label {
  height: 100%;
  justify-content: flex-end;
  text-align: right;
  min-height: 44px;
  padding-top: 10px;
}
.form-row--summary .form-label {
  color: #778593;
  min-height: 24px;
  padding-top: 0;
}
.form-label + ul {
  margin-top: 0;
}

.form-static {
  align-self: center;
}

.form-error,
.form-error-msg,
.errorlist {
  font-size: 1.2rem;
  color: #d10000;
  line-height: 2rem;
  display: block;
}
[margin-top] > .form-error,
[margin-top] > .form-error-msg,
[margin-top] > .errorlist {
  margin-top: 15px;
}
.form-error.hide,
.form-error-msg.hide,
.errorlist.hide {
  display: none;
}
.form-checkbox .form-error, .form-radio .form-error, .form-checkboxes > .form-error,
.form-checkbox .form-error-msg,
.form-radio .form-error-msg,
.form-checkboxes > .form-error-msg,
.form-checkbox .errorlist,
.form-radio .errorlist,
.form-checkboxes > .errorlist {
  margin-top: 0.5em;
}

ul.errorlist {
  margin: 0;
  padding: 0;
  list-style: none;
}

.fieldset {
  padding: 15px 5px;
  border: none;
  border-top: 1px solid #d8d8d8;
  margin: 30px 0 0;
}
.fieldset:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}
.fieldset .legend {
  color: #778593;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  margin: 0 0 30px;
}

input,
select {
  outline: none;
}

.form-input, .ng-select.ng-select .ng-select-container, .form-input-wrapper,
.form-control {
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  height: 44px;
  outline: none;
  padding: 0 15px;
  width: 100%;
}
.table .form-input, .table .ng-select.ng-select .ng-select-container, .ng-select.ng-select .table .ng-select-container, .table .form-input-wrapper,
.table .form-control {
  height: 40px;
}
.form-input::placeholder, .ng-select.ng-select .ng-select-container::placeholder, .form-input-wrapper::placeholder,
.form-control::placeholder {
  opacity: 0.3;
}
.form-input[disabled], .ng-select.ng-select [disabled].ng-select-container, [disabled].form-input-wrapper, .form-input:disabled, .ng-select.ng-select .ng-select-container:disabled, .form-input-wrapper:disabled,
.form-control[disabled],
.form-control:disabled {
  pointer-events: none;
  background: #f9f9f9;
}
.form-input:focus, .ng-select.ng-select .ng-select-container:focus, .form-input-wrapper:focus, .form-input.focus, .ng-select.ng-select .focus.ng-select-container, .focus.form-input-wrapper,
.form-control:focus,
.form-control.focus {
  border-color: #4f81bb;
}
.is-valid .form-input, .is-valid .ng-select.ng-select .ng-select-container, .ng-select.ng-select .is-valid .ng-select-container, .is-valid .form-input-wrapper, .is-valid .form-input.focus, .form-input.is-valid, .ng-select.ng-select .is-valid.ng-select-container, .is-valid.form-input-wrapper, .form-input.is-valid.focus,
.is-valid .form-control,
.is-valid .form-control.focus,
.form-control.is-valid,
.form-control.is-valid.focus {
  border-color: #5fa200;
}
.has-error .form-input, .has-error .ng-select.ng-select .ng-select-container, .ng-select.ng-select .has-error .ng-select-container, .has-error .form-input-wrapper, .has-error .form-input.focus, .form-input.has-error, .ng-select.ng-select .has-error.ng-select-container, .has-error.form-input-wrapper, .form-input.has-error.focus,
.has-error .form-control,
.has-error .form-control.focus,
.form-control.has-error,
.form-control.has-error.focus {
  border-color: #d10000;
}
.form-input.form-input--sm, .ng-select.ng-select .form-input--sm.ng-select-container, .form-input--sm.form-input-wrapper,
.form-control.form-input--sm {
  height: 33px;
}
.form-input.form-input--lg, .ng-select.ng-select .form-input--lg.ng-select-container, .form-input--lg.form-input-wrapper,
.form-control.form-input--lg {
  height: 55px;
}

.form-input-wrapper {
  display: flex;
  padding: 0;
  overflow: hidden;
}
.form-input-wrapper .form-input__prefix,
.form-input-wrapper .form-input__suffix {
  align-items: center;
  display: flex;
  user-select: none;
  height: 100%;
}
.form-input-wrapper .form-input__prefix.text,
.form-input-wrapper .form-input__suffix.text {
  font-size: 1.4rem;
}
.form-input-wrapper .form-input__suffix {
  color: #4f81bb;
  font-size: 2rem;
  padding: 0 10px;
  background: #f0f3f6;
}
.form-input-wrapper > input, .form-input-wrapper > .form-input__content {
  flex: 1;
  height: 100%;
  border: none;
  min-width: 0;
  outline: none;
  width: 100%;
  padding: 0 15px;
  border-radius: 0;
}
.form-input-wrapper > input::placeholder, .form-input-wrapper > .form-input__content::placeholder {
  opacity: 0.3;
}
.form-input-wrapper .form-input__content {
  display: flex;
  align-items: center;
}
.form-input-wrapper .form-input__prefix {
  color: #4f81bb;
  font-size: 2rem;
  padding-right: 15px;
  padding: 0 10px;
  background: #f0f3f6;
}

textarea.form-input, .ng-select.ng-select textarea.ng-select-container, textarea.form-input-wrapper,
textarea.form-control,
select.form-input[multiple],
.ng-select.ng-select select[multiple].ng-select-container,
select[multiple].form-input-wrapper {
  height: 100px;
  padding: 0;
}
textarea.form-input.form-input--single-line, .ng-select.ng-select textarea.form-input--single-line.ng-select-container, textarea.form-input--single-line.form-input-wrapper,
textarea.form-control.form-input--single-line,
select.form-input[multiple].form-input--single-line,
.ng-select.ng-select select[multiple].form-input--single-line.ng-select-container,
select[multiple].form-input--single-line.form-input-wrapper {
  height: 44px;
}
textarea.form-input.form-input--sm, .ng-select.ng-select textarea.form-input--sm.ng-select-container, textarea.form-input--sm.form-input-wrapper,
textarea.form-control.form-input--sm,
select.form-input[multiple].form-input--sm,
.ng-select.ng-select select[multiple].form-input--sm.ng-select-container,
select[multiple].form-input--sm.form-input-wrapper {
  height: 75px;
}
textarea.form-input.form-input--lg, .ng-select.ng-select textarea.form-input--lg.ng-select-container, textarea.form-input--lg.form-input-wrapper,
textarea.form-control.form-input--lg,
select.form-input[multiple].form-input--lg,
.ng-select.ng-select select[multiple].form-input--lg.ng-select-container,
select[multiple].form-input--lg.form-input-wrapper {
  height: 150px;
}
textarea.form-input.form-input--xlg, .ng-select.ng-select textarea.form-input--xlg.ng-select-container, textarea.form-input--xlg.form-input-wrapper,
textarea.form-control.form-input--xlg,
select.form-input[multiple].form-input--xlg,
.ng-select.ng-select select[multiple].form-input--xlg.ng-select-container,
select[multiple].form-input--xlg.form-input-wrapper {
  height: 200px;
}
textarea.form-input option, .ng-select.ng-select textarea.ng-select-container option, textarea.form-input-wrapper option,
textarea.form-control option,
select.form-input[multiple] option,
.ng-select.ng-select select[multiple].ng-select-container option,
select[multiple].form-input-wrapper option {
  padding: 7.5px 15px;
}

textarea.form-input, .ng-select.ng-select textarea.ng-select-container, textarea.form-input-wrapper,
textarea.form-control {
  padding: 15px;
  resize: none;
  line-height: 1.4;
}

.form-checkboxes-list .form-checkbox {
  margin-top: 10px;
}

.form-checkbox {
  position: relative;
  display: inline-block;
  padding-left: 40px;
  font-size: 1.4rem;
  font-weight: 300;
  margin-top: 10px;
  min-height: 25px;
  line-height: 18px;
  padding-top: 3.5px;
  padding-bottom: 3.5px;
}
.form-checkbox .custom-checkbox {
  position: absolute;
  top: 0;
  left: 0;
}
.table .form-checkbox {
  margin-top: 0;
}
.col-checkbox .form-checkbox {
  display: inline;
  padding-left: 25px;
}

.custom-checkbox {
  width: 25px;
  height: 25px;
  position: relative;
}
.custom-checkbox input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
.custom-checkbox input:disabled {
  pointer-events: none;
}
.custom-checkbox input:disabled:hover + .custom-checkbox__layer {
  border-color: #d8d8d8;
}
.custom-checkbox input:disabled + .custom-checkbox__layer {
  opacity: 0.4;
  pointer-events: none;
}
.custom-checkbox input:checked + .custom-checkbox__layer {
  border-color: #d8d8d8;
  background: #fff;
}
.custom-checkbox input:checked + .custom-checkbox__layer:before {
  opacity: 1;
}
.custom-checkbox input.display-default:disabled + .custom-checkbox__layer {
  opacity: 1;
}
.custom-checkbox .custom-checkbox__layer {
  display: block;
  height: inherit;
  border-radius: 4px;
  border: 2px solid #d8d8d8;
  background: #fff;
  transition: border 0.2s linear, background 0.2s linear;
}
.custom-checkbox .custom-checkbox__layer::before {
  opacity: 0;
  display: block;
  transition: opacity 0.2s linear;
  content: "\e94f";
  font-size: 13px;
  font-family: "sportbm";
  color: #4f81bb;
  line-height: 21px;
  text-align: center;
}
.color--white .custom-checkbox .custom-checkbox__layer::before {
  color: #fff;
}

.form-radios-list .form-radio {
  margin-top: 10px;
}

.form-radio {
  display: block;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 18px;
  margin-top: 10px;
  min-height: 26px;
  padding-bottom: 3.5px;
  padding-left: 40px;
  padding-top: 3.5px;
  position: relative;
}
.form-radio .custom-radio {
  left: 0;
  position: absolute;
  top: 0;
}

.custom-radio {
  border-radius: 50%;
  height: 26px;
  position: relative;
  width: 26px;
}
.custom-radio input {
  border-radius: 50%;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.custom-radio input:disabled {
  cursor: default;
}
.custom-radio input:disabled:hover + .custom-radio__layer {
  border-color: #d8d8d8;
}
.custom-radio input:disabled + .custom-radio__layer {
  opacity: 0.4;
  pointer-events: none;
}
.custom-radio input:checked + .custom-radio__layer {
  background: #4f81bb;
  border-color: #d8d8d8;
}
.custom-radio input:checked + .custom-radio__layer:before {
  opacity: 1;
}
.custom-radio input.display-default:disabled + .custom-radio__layer {
  opacity: 1;
}
.custom-radio .custom-radio__layer {
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
  display: block;
  height: inherit;
  position: relative;
  transition: border 0.2s linear, background 0.2s linear;
}
.custom-radio .custom-radio__layer:before {
  background: #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: 10px;
  left: 50%;
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s linear;
  width: 10px;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.custom-radio:hover .custom-radio__layer, label:hover .custom-radio .custom-radio__layer, .has-error .custom-radio .custom-radio__layer {
  border-color: #d8d8d8;
}
.custom-radio:hover input:checked + .custom-radio__layer:before, label:hover .custom-radio input:checked + .custom-radio__layer:before, .has-error .custom-radio input:checked + .custom-radio__layer:before {
  display: block;
}

.form-star-rating {
  display: inline-flex;
  height: 44px;
  font-size: 2rem;
  color: #d8d8d8;
  align-items: center;
}
.form-star-rating.size-large {
  font-size: 2.5rem;
}
.form-star-rating > * {
  margin: 0 3px;
  cursor: pointer;
}
.form-star-rating .active {
  color: #4f81bb;
}
.form-star-rating .readonly, .form-star-rating.readonly {
  pointer-events: none;
  cursor: default;
}

.star-rating-result {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1;
  color: #778593;
}
.star-rating-result strong {
  color: #4f81bb;
  display: inline-block;
  font-size: 2.75em;
  margin-right: 0.1em;
}

.custom-file-field.custom-file-field--image {
  display: flex;
}
.custom-file-field.custom-file-field--image .custom-file-field__actions {
  flex: 1;
}
.custom-file-field img {
  max-height: 100px;
  max-width: 260px;
  margin-right: 20px;
}
.custom-file-field .filename {
  margin-bottom: 10px;
}
.custom-file-field .filename strong {
  margin-left: 5px;
}
.custom-file-field .btn--file {
  position: relative;
}
.custom-file-field .btn--file input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.form-radio-switch {
  width: 100%;
  height: 44px;
  position: relative;
  border: 1px solid #dedede;
  border-radius: 22px;
  background: white;
  display: flex;
  padding: 6px;
}
.form-radio-switch .custom-radio-switch-item {
  flex: 1;
}

.custom-radio-switch-item {
  position: relative;
}
.custom-radio-switch-item input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}
.custom-radio-switch-item input:checked + .custom-radio-switch-item__label {
  background: #4f81bb;
  color: white;
}

.custom-radio-switch-item__label {
  border-radius: 16px;
  height: 100%;
  color: rgba(153, 153, 153, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s linear, background 0.2s linear;
}

.form-datepicker {
  position: relative;
}
.form-datepicker.form-input-wrapper {
  overflow: visible;
}

.c-datepicker {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  max-width: 280px;
  min-width: 240px;
  background: #fff;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.c-datepicker.c-datepicker--right {
  left: auto;
  right: 0;
}
.c-datepicker.calendar-picker {
  max-width: 50%;
  min-width: 300px;
}
.c-datepicker.calendar-picker .calendar-picker-disabled {
  pointer-events: none;
}

.c-datepicker__heading {
  display: flex;
  padding: 15px 5px;
  background: #778593;
  color: #ffffff;
  text-transform: uppercase;
}
.c-datepicker__heading a {
  color: inherit;
}
.c-datepicker__heading .c-datepicker__arrow-next,
.c-datepicker__heading .c-datepicker__arrow-prev {
  flex-basis: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  font-size: 1.2rem;
}
.c-datepicker__heading .c-datepicker__arrow-next::before {
  content: "\e919";
  font-family: "sportbm";
}
.c-datepicker__heading .c-datepicker__arrow-prev::before {
  content: "\e916";
  font-family: "sportbm";
}

.c-datepicker__heading-date {
  flex: 1;
  text-align: center;
}
.c-datepicker__heading-date a {
  margin: 0 10px;
}
.c-datepicker__heading-date a::after {
  content: "\e913";
  font-family: "sportbm";
  font-size: 1rem;
}

.c-datepicker__body-week {
  display: flex;
}

.c-datepicker__day {
  flex: 1;
  border: 2px solid transparent;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  margin: 1px;
}
.calendar-picker .c-datepicker__day {
  border: 1px solid #d8d8d8;
  height: 50px;
  align-items: flex-start;
  justify-content: space-between;
  padding: 4px 3px;
  display: flex;
  flex-direction: column;
}
.calendar-picker .c-datepicker__day .events-count {
  color: #778593;
  font-size: 1.2rem;
  opacity: 0.8;
}
.calendar-picker .c-datepicker__day.selected .events-count {
  color: #ffffff;
}
.c-datepicker__day:hover {
  border-color: #d8d8d8;
  background: #d8d8d8;
  color: #000000;
}
.c-datepicker__day.today {
  border-color: #4f81bb;
  background: none;
  color: inherit;
}
.c-datepicker__day.past, .c-datepicker__day.future {
  color: #778593;
  opacity: 0.6;
}
.c-datepicker__day.saturday {
  color: #4f81bb;
}
.c-datepicker__day.sunday {
  color: #d10000;
}
.c-datepicker__day.selected {
  border-color: #4f81bb;
  background: #4f81bb;
  color: #ffffff;
}
.c-datepicker__day.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.c-datepicker__body {
  padding: 14px;
}
.calendar-picker .c-datepicker__body {
  min-height: 100px;
  position: relative;
}
.calendar-picker .c-datepicker__body .c-datepicker__body-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
}

.c-datepicker__months {
  display: flex;
  flex-wrap: wrap;
}

.c-datepicker__months-item {
  border: 2px solid transparent;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  margin: 1px;
  width: calc(33.33% - 2px);
}
.c-datepicker__months-item:hover {
  border-color: #d8d8d8;
  background: #d8d8d8;
  color: #000000;
}
.c-datepicker__months-item.today {
  border-color: #4f81bb;
  background: none;
  color: inherit;
}
.c-datepicker__months-item.selected {
  border-color: #4f81bb;
  background: #4f81bb;
  color: #ffffff;
}
.c-datepicker__months-item.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.c-datepicker__years {
  display: flex;
  flex-wrap: wrap;
}

.c-datepicker__years-item {
  border: 2px solid transparent;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  margin: 1px;
  width: calc(33.33% - 2px);
}
.c-datepicker__years-item:hover {
  border-color: #d8d8d8;
  background: #d8d8d8;
  color: #000000;
}
.c-datepicker__years-item.today {
  border-color: #4f81bb;
  background: none;
  color: inherit;
}
.c-datepicker__years-item.selected {
  border-color: #4f81bb;
  background: #4f81bb;
  color: #ffffff;
}
.c-datepicker__years-item.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.c-datepicker__timepicker {
  width: 100%;
  max-width: 160px;
  margin: 15px auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.c-datepicker__timepicker .form-input, .c-datepicker__timepicker .ng-select.ng-select .ng-select-container, .ng-select.ng-select .c-datepicker__timepicker .ng-select-container, .c-datepicker__timepicker .form-input-wrapper {
  height: 30px;
}

.c-datepicker__timepicker-separator {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-datepicker__timepicker-separator::before {
  content: ":";
}

.c-datepicker__timepicker-slot {
  flex: 1;
  text-align: center;
}
.c-datepicker__timepicker-slot .form-input, .c-datepicker__timepicker-slot .ng-select.ng-select .ng-select-container, .ng-select.ng-select .c-datepicker__timepicker-slot .ng-select-container, .c-datepicker__timepicker-slot .form-input-wrapper {
  text-align: center;
  -moz-appearance: textfield;
  width: 100%;
}
.c-datepicker__timepicker-slot .form-input::-webkit-outer-spin-button, .c-datepicker__timepicker-slot .ng-select.ng-select .ng-select-container::-webkit-outer-spin-button, .ng-select.ng-select .c-datepicker__timepicker-slot .ng-select-container::-webkit-outer-spin-button, .c-datepicker__timepicker-slot .form-input-wrapper::-webkit-outer-spin-button, .c-datepicker__timepicker-slot .form-input::-webkit-inner-spin-button, .c-datepicker__timepicker-slot .ng-select.ng-select .ng-select-container::-webkit-inner-spin-button, .ng-select.ng-select .c-datepicker__timepicker-slot .ng-select-container::-webkit-inner-spin-button, .c-datepicker__timepicker-slot .form-input-wrapper::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.c-datepicker__timepicker-increase,
.c-datepicker__timepicker-decrease {
  padding: 5px 0;
  display: inline-block;
  font-family: "sportbm";
  font-size: 20px;
}

.c-datepicker__timepicker-decrease::before {
  content: "\e913";
}

.c-datepicker__timepicker-increase::before {
  content: "\e91c";
}

@media only screen and (min-width: 993px) {
  .calendar-picker .c-datepicker__day {
    font-size: 1.6rem;
    height: 60px;
  }
  .calendar-picker .c-datepicker__day .events-count {
    font-size: 1.4rem;
    opacity: 0.8;
  }
}
@media only screen and (max-width: 993px) {
  .c-datepicker.calendar-picker {
    margin-left: -15px;
  }
}
.ng-select.ng-select.ng-select-opened > .ng-select-container {
  background: #fff;
  border-color: #4f81bb;
}
.ng-select.ng-select.ng-select-opened > .ng-select-container:hover {
  box-shadow: none;
}
.ng-select.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: -2px;
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}
.ng-select.ng-select.ng-select-opened > .ng-select-container .ng-arrow:hover {
  border-color: transparent transparent #666;
}
.ng-select.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.ng-select.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.ng-select.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #f9f9f9;
}
.ng-select.ng-select .ng-has-value .ng-placeholder {
  display: none;
}
.ng-select.ng-select .ng-select-container {
  cursor: pointer;
}
.ng-select.ng-select .ng-select-container .ng-value-container {
  align-items: center;
}
.ng-select.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #aaa;
}
.ng-select.ng-select .ng-select-container .ng-value-container .ng-input input {
  font-family: "Open Sans", sans-serif;
  cursor: pointer;
}
.ng-select.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-left: 15px;
  padding-right: 60px;
}
.ng-select.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
  background-color: #f9f9f9;
  border: 1px solid #e3e3e3;
}
.ng-select.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 0px 5px;
}
.ng-select.ng-select.ng-select-multiple .ng-select-container {
  height: auto;
  min-height: 44px;
}
.ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 5px;
}
.ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: 1em;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: #f5faff;
  border-radius: 2px;
  border: 1px solid #c2e0ff;
}
.ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: #f9f9f9;
  border: 1px solid #e3e3e3;
}
.ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 5px;
}
.ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 0px 5px 0px 5px;
}
.ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: inline-block;
  padding: 0px 5px;
}
.ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: #d8eafd;
}
.ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #c2e0ff;
}
.ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 1px solid #c2e0ff;
}
.ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding-bottom: 3px;
  padding-left: 3px;
}
.ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-bottom: 5px;
  padding-left: 3px;
}
.ng-select.ng-select .ng-clear-wrapper {
  color: #999;
  width: 10px;
}
.ng-select.ng-select .ng-clear-wrapper:before {
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-family: "sportbm";
  font-size: 1rem;
  content: "\ea5b";
}
.ng-select.ng-select .ng-clear-wrapper .ng-clear {
  display: none;
}
.ng-select.ng-select .ng-spinner-zone {
  padding-right: 5px;
  padding-top: 5px;
}
.ng-select.ng-select .ng-arrow-wrapper {
  margin-right: 0;
  margin-left: 10px;
  width: 12px;
  position: relative;
  color: #4f81bb;
}
.ng-select.ng-select .ng-arrow-wrapper:before {
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-family: "sportbm";
  font-size: 1.2rem;
  content: "\e913";
}
.ng-dropdown-panel {
  background-color: #fff;
  border: 1px solid #4f81bb;
}
.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-color: #d8d8d8;
  margin-top: -1px;
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-color: #d8d8d8;
  margin-bottom: -1px;
}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid #ccc;
  padding: 5px 7px;
}
.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid #ccc;
  padding: 5px 7px;
}
.ng-dropdown-panel .ng-dropdown-panel-items {
  margin-bottom: 1px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  user-select: none;
  padding: 8px 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: #ebf5ff;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
  background-color: #f5faff;
  font-weight: 600;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #fff;
  color: #333;
  padding: 10px 15px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option {
  white-space: normal;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: #f5f7f9;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label {
  font-weight: 600;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #f5f7f9;
  color: #333;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #cccccc;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 22px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  padding-right: 5px;
  font-size: 80%;
  font-weight: 400;
}

.ngx-slider.ngx-slider .ngx-slider-bubble {
  color: #4f81bb;
  font-size: 1.2rem;
}
.ngx-slider.ngx-slider .ngx-slider-pointer {
  background: #4f81bb;
  border: 2px solid #d8d8d8;
  width: 28px;
  height: 28px;
  top: -12px;
  outline: none;
}
.ngx-slider.ngx-slider .ngx-slider-pointer::after, .ngx-slider.ngx-slider .ngx-slider-pointer:hover::after, .ngx-slider.ngx-slider .ngx-slider-pointer.ngx-slider-active::after {
  background: #4f81bb;
}
.ngx-slider.ngx-slider .ngx-slider-bar {
  background: #d8d8d8;
}
.ngx-slider.ngx-slider .ngx-slider-model-value {
  font-size: 1.5rem;
}

.builder-field {
  display: flex;
}

.field-order {
  align-self: center;
  width: 30px;
}
.field-order [class^=ico-],
.field-order [class*=" ico-"] {
  cursor: move;
}

.field-data {
  flex: 1;
}

.field-actions {
  width: 40px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.field-actions > * {
  margin: 10px 0 0;
}
.field-actions > *:first-child {
  margin-top: 0;
}

.hint {
  margin: 10px 0;
  display: flex;
  min-height: 50px;
  align-items: center;
}

.hint__icon {
  color: #4f81bb;
  font-size: 30px;
}
.hint__icon * {
  display: block;
}

.hint__content {
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.4;
  align-self: center;
}

/*
  LINK WITH ICON
 */
.link {
  cursor: pointer;
}

.links-group {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}
.links-group > * {
  margin: 10px;
}

.links-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.links-list li {
  border-top: 1px solid #d8d8d8;
  padding: 7px 0;
}
.links-list li:first-child {
  border-top: none;
  padding-top: 0;
}
.links-list li:last-child {
  padding-bottom: 0;
}

.links-group--right {
  justify-content: flex-end;
}

.links-group--center {
  justify-content: center;
}

.links-group--small {
  margin: -5px;
}
.links-group--small > * {
  margin: 5px;
}

.links-group--large {
  margin: -15px;
}
.links-group--large > * {
  margin: 15px;
}

a {
  text-decoration: none;
  color: #4f81bb;
  transition: color 0.2s linear;
  display: inline-block;
}
a:hover {
  color: #86a9d0;
}
a.disabled {
  pointer-events: none;
  opacity: 0.3;
}
a [class^=ico-]::before,
a [class*=" ico-"]::before {
  display: inline-block;
}

.link--primary {
  color: #4f81bb;
}
.link--primary:hover {
  color: #3c689b;
}

.link--secondary {
  color: #464e57;
}
.link--secondary:hover {
  color: #687481;
}

.link--tertiary {
  color: #778593;
}
.link--tertiary:hover {
  color: #a2abb5;
}

.link--other {
  color: #778593;
}
.link--other:hover {
  color: #a2abb5;
}

.link--danger {
  color: #d10000;
}
.link--danger:hover {
  color: #ff1f1f;
}

.link--info {
  color: #4f81bb;
}
.link--info:hover {
  color: #86a9d0;
}

.link--success {
  color: #5fa200;
}
.link--success:hover {
  color: #8cef00;
}

.link--warning {
  color: #f49317;
}
.link--warning:hover {
  color: #f7b560;
}

.link--inverse {
  color: #ffffff;
}
.link--inverse:hover {
  color: #d9d9d9;
}

.link--small {
  font-size: 1.2rem;
}

.link--large {
  font-size: 1.6rem;
}

.link--icon-only [class^=ico-]::before,
.link--icon-only [class*=" ico-"]::before {
  display: inline-block;
  font-size: 1.4285714286em;
}

.link--icon-start {
  padding-left: 2.1428571429em;
  position: relative;
}
.link--icon-start [class^=ico-],
.link--icon-start [class*=" ico-"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.link--icon-start [class^=ico-]::before,
.link--icon-start [class*=" ico-"]::before {
  display: block;
  font-size: 1.4285714286em;
}

.link--icon-start-small {
  padding-left: 1.5642857143em;
}
.link--icon-start-small [class^=ico-]::before,
.link--icon-start-small [class*=" ico-"]::before {
  font-size: 0.85em;
}

.link--icon-end {
  position: relative;
  padding-right: 2.1428571429em;
}
.link--icon-end [class^=ico-],
.link--icon-end [class*=" ico-"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.link--icon-end [class^=ico-]::before,
.link--icon-end [class*=" ico-"]::before {
  display: block;
  font-size: 1.4285714286em;
}

.link--icon-end-small {
  padding-right: 1.5642857143em;
}
.link--icon-end-small [class^=ico-]::before,
.link--icon-end-small [class*=" ico-"]::before {
  font-size: 0.85em;
}

.link-stretched-wrapper {
  position: relative;
}

.link--stretched::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  content: "";
  z-index: 1;
}

.absolute-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
  display: flex;
  align-items: center;
}
.card .absolute-loader {
  border-radius: 4px;
}

.absolute-loader-wrapper {
  position: relative;
}

.loader {
  margin: 20px auto;
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 6px solid transparent;
  border-top-color: #4f81bb;
  border-left: 0 !important;
  animation: circ-anim 0.7s cubic-bezier(0.47, 0, 0.74, 0.71) infinite;
}
.loader.no-margin {
  margin: auto;
}
.loader.loader--small {
  width: 44px;
  height: 44px;
  border-width: 5px;
}
.loader.loader--form {
  width: 44px;
  height: 44px;
  border-width: 5px;
}
.loader.loader--popover {
  width: 41px;
  height: 41px;
  border-width: 5px;
}
.loader.loader--alert {
  width: 44px;
  height: 44px;
  border-width: 5px;
}
.loader.loader--xsmall {
  width: 34px;
  height: 34px;
  border-width: 4px;
}
.loader.loader--links {
  width: 38px;
  height: 38px;
  border-width: 4px;
}
.loader.loader--icon-md {
  width: 26.5px;
  height: 26.5px;
  border-width: 3px;
}
.loader.loader--paragraph {
  width: 22px;
  height: 22px;
  border-width: 3px;
}
.loader.loader--results {
  width: 17px;
  height: 17px;
  border-width: 2px;
}
.loader.loader--large {
  width: 96px;
  height: 96px;
  border-width: 8px;
}
.loader.loader--links {
  margin: 10px;
  margin-top: 0;
  margin-bottom: 0;
}
.loader.loader--xsmall {
  margin: 0 auto;
}
.loader.loader--results, .loader.loader--icon-md, .loader.loader--paragraph {
  margin: 0;
}
.loader.loader--form, .loader.loader--popover {
  margin: 0;
}
.loader.loader--alert {
  margin: 23px auto 0px;
}
.loader-right .loader {
  margin-right: 0;
  margin-left: auto;
}
.loader-left .loader {
  margin-left: 0;
  margin-right: auto;
}
.loader.loader--primary {
  border-top-color: #4f81bb;
}
.loader.loader--secondary {
  border-top-color: #464e57;
}
.loader.loader--tertiary {
  border-top-color: #778593;
}
.loader.loader--lime {
  border-top-color: #b6cd2a;
}
.loader.loader--other {
  border-top-color: #778593;
}
.loader.loader--success {
  border-top-color: #5fa200;
}
.loader.loader--info {
  border-top-color: #4f81bb;
}
.loader.loader--warning {
  border-top-color: #f49317;
}
.loader.loader--danger {
  border-top-color: #d10000;
}
.loader.loader--inverse {
  border-top-color: #ffffff;
}
.loader.loader--inverse {
  border-top-color: #ffffff;
}

@keyframes circ-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes router-load-indicator-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.router-load-indicator {
  animation-delay: 100ms;
  animation-duration: 200ms;
  animation-fill-mode: both;
  animation-name: router-load-indicator-animation;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  color: #000000;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.dnd-box,
.dnd-item {
  margin: 5px 0;
  border: 1px solid #d8d8d8;
  background: #f0f3f6;
  padding: 15px 20px;
  cursor: move;
}
.dnd-box:not(.has-handle):not(.move-disabled), .dnd-box.has-handle [ngxdraghandle], .dnd-box.has-handle [ngxDragHandle],
.dnd-item:not(.has-handle):not(.move-disabled),
.dnd-item.has-handle [ngxdraghandle],
.dnd-item.has-handle [ngxDragHandle] {
  cursor: move;
}
.dnd-box .dnd-content,
.dnd-item .dnd-content {
  user-select: none;
}
.dnd-box:hover,
.dnd-item:hover {
  border: 1px solid #b2b2b2;
}

.dnd-box {
  height: 40px;
  width: 40px;
  line-height: 20px;
  text-align: center;
  float: left;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .dnd-box:not(.cdk-drag-placeholder),
.cdk-drop-list-dragging .dnd-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

body.modal-opened {
  overflow: hidden;
}

.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: opacity 0.1s linear;
  z-index: 100;
}
.modal-overlay:empty {
  display: none;
}
.modal-overlay.active {
  opacity: 1;
}

.modal-layer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0);
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: auto;
  z-index: 100;
  transition: filter 0.1s linear;
  filter: blur(4px) brightness(50%) saturate(0);
}
.modal-layer:last-child {
  filter: blur(0px);
}
.modal-layer.active {
  opacity: 1;
}

.modal {
  width: 100%;
  max-width: 700px;
  background: #fff;
  border-radius: 4px;
  margin: auto;
  transition: top 0.1s linear;
  top: -30px;
  padding: 15px 0;
  position: relative;
  box-shadow: 0 0 35px 20px rgba(0, 0, 0, 0.5);
}
.modal.active {
  top: 0;
}
.modal img {
  max-width: 100%;
}

.modal-title {
  padding: 15px 30px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
}

.modal-content {
  padding: 15px 30px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}
.modal-close::before {
  content: "X";
}

.modal-close::before {
  content: "\ea5b";
  font-family: "sportbm";
}

.modal--receiver-picker {
  max-width: 1100px;
  padding: 0;
  overflow: hidden;
}
.modal--receiver-picker .modal-content {
  padding: 0;
}

.modal--image {
  background: none;
  max-width: none;
  margin-top: -50px;
  box-shadow: none;
}
.modal--image .modal-content {
  padding: 0 30px;
}
.modal--image .modal-close {
  font-size: 2rem;
  right: 5px;
  color: #fff;
}
.modal--image .image-title {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 30px;
}
.modal--image .main-image {
  display: block;
  margin: 20px auto auto;
}

.modal--alert {
  padding: 0;
  max-width: 600px;
  width: 90%;
}
.modal--alert .modal-content {
  padding: 0;
}
.modal--alert.modal--alert-wide {
  max-width: 1000px;
}

.modal--form {
  max-width: 800px;
}

.modal--details-view {
  max-width: 1100px;
}

.modal--text {
  max-width: 900px;
}
.modal--text .alert__content {
  padding-top: 0;
  text-align: left;
}
.modal--text .alert__content .title {
  font-size: 1.8rem;
  margin-bottom: 30px;
}
.modal--text .alert__content strong {
  font-size: 1.5rem;
}

.modal--content-left .alert__content {
  width: 100%;
}
.modal--content-left .alert__content p {
  text-align: left;
}

image-cropper[hidden] {
  display: none;
}

.ngx-ic-cropper {
  --cropper-outline-color: hsla(0, 0%, 100%, 0.6);
}

.modal--photo-crop {
  max-width: none;
  width: 90%;
}
.modal--photo-crop image-cropper {
  max-height: 65vh;
}

.photo-uploader-current {
  text-align: center;
}
.photo-uploader-current .label {
  font-weight: 300;
  text-transform: uppercase;
  display: block;
}
.photo-uploader-current img {
  max-width: 600px;
  max-height: 400px;
  width: 100%;
  display: block;
  margin: 15px auto;
  object-fit: contain;
}

.photo-uploader {
  position: relative;
  height: 200px;
  text-align: center;
  color: #778593;
  padding-top: 60px;
  font-weight: 300;
  text-transform: uppercase;
}
.photo-uploader [class^=ico-],
.photo-uploader [class*=" ico-"] {
  font-size: 60px;
  color: #778593;
  display: block;
  margin-bottom: 20px;
}
.photo-uploader input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.cropper-selection-ellipse .ngx-ic-cropper {
  border-radius: 50% !important;
}

.cropper-selection-shield .ngx-ic-cropper {
  border-radius: 5% 5% 50% 50% !important;
}

.paginator {
  padding: 20px 0;
  margin: 20px 0 0;
  display: flex;
  justify-content: center;
  user-select: none;
}

.paginator--disabled {
  opacity: 0.6;
  cursor: default;
  pointer-events: none;
}

.paginator__item {
  width: 26px;
  height: 26px;
  margin: 0 6px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  border-radius: 4px;
  color: #222;
}
.paginator__item.active {
  color: #ffffff;
  background: #778593;
  font-weight: 600;
  pointer-events: none;
}
.paginator__item[disabled], .paginator__item.disabled {
  color: #778593;
  pointer-events: none;
}
.paginator__item.item-prev {
  margin-right: 25px;
  color: #4f81bb;
}
.paginator__item.item-next {
  margin-left: 25px;
  color: #4f81bb;
}

.payment-methods {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: -10px;
}

.payment-methods__item {
  width: 120px;
  padding: 10px;
  margin: 10px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
}
.payment-methods__item img {
  width: 100px;
  height: 100px;
  display: block;
}
.payment-methods__item.selected {
  border: 1px solid #4f81bb;
}

.payment-cards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: -10px;
}

.payment-cards__item {
  width: 220px;
  padding: 20px 10px;
  margin: 10px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.payment-cards__item.selected {
  border: 1px solid #4f81bb;
}

bsc-popover:not(.hydrated) {
  display: none;
}

.popover {
  position: relative;
}

.popover__content {
  position: absolute;
  z-index: 5;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 200px;
  text-align: left;
  pointer-events: all;
  opacity: 1;
  transition: opacity 0.1s linear;
}
[opened] .popover__content {
  pointer-events: auto;
  opacity: 1;
}
.popover__content::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 4;
}
.popover__content::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 4;
}
.popover--left .popover__content, .popover__content.popover--left {
  right: calc(100% + 10px);
  top: 0;
}
.popover--left .popover__content::after, .popover__content.popover--left::after {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-right: none;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #fff;
  right: -5px;
  top: 15px;
}
.popover--left .popover__content::before, .popover__content.popover--left::before {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: none;
  border-bottom: 7px solid transparent;
  border-left: 6px solid rgba(0, 0, 0, 0.05);
  right: -7px;
  top: 15px;
}
.popover--right .popover__content, .popover__content.popover--right {
  left: calc(100% + 10px);
  top: 50%;
  transform: translateY(-50%);
}
.popover--right .popover__content::after, .popover__content.popover--right::after {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-right: 5px solid #fff;
  border-bottom: 5px solid transparent;
  border-left: none;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
}
.popover--right .popover__content::before, .popover__content.popover--right::before {
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 6px solid rgba(0, 0, 0, 0.05);
  border-bottom: 7px solid transparent;
  border-left: none;
  left: -7px;
  top: 50%;
  transform: translateY(-50%);
}
.popover--top .popover__content, .popover__content.popover--top {
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}
.popover--top .popover__content::after, .popover__content.popover--top::after {
  width: 0;
  height: 0;
  border-top: 5px solid #fff;
  border-right: 5px solid transparent;
  border-bottom: none;
  border-left: 5px solid transparent;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}
.popover--top .popover__content::before, .popover__content.popover--top::before {
  width: 0;
  height: 0;
  border-top: 6px solid rgba(0, 0, 0, 0.05);
  border-right: 7px solid transparent;
  border-bottom: none;
  border-left: 7px solid transparent;
  bottom: -7px;
  left: 50%;
  transform: translateX(-50%);
}
.popover--bottom .popover__content, .popover__content.popover--bottom {
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}
.popover--bottom .popover__content::after, .popover__content.popover--bottom::after {
  width: 0;
  height: 0;
  border-top: none;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  border-left: 5px solid transparent;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}
.popover--bottom .popover__content::before, .popover__content.popover--bottom::before {
  width: 0;
  height: 0;
  border-top: none;
  border-right: 7px solid transparent;
  border-bottom: 6px solid rgba(0, 0, 0, 0.05);
  border-left: 7px solid transparent;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
}
.popover--bottom-right .popover__content, .popover__content.popover--bottom-right {
  top: calc(100% + 10px);
  right: 0;
}
.popover--bottom-right .popover__content::after, .popover__content.popover--bottom-right::after {
  width: 0;
  height: 0;
  border-top: none;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  border-left: 5px solid transparent;
  top: -5px;
  right: 15px;
}
.popover--bottom-right .popover__content::before, .popover__content.popover--bottom-right::before {
  width: 0;
  height: 0;
  border-top: none;
  border-right: 8px solid transparent;
  border-bottom: 8px solid rgba(0, 0, 0, 0.05);
  border-left: 8px solid transparent;
  top: -8px;
  right: 12px;
}
.popover--bottom-left .popover__content, .popover__content.popover--bottom-left {
  top: calc(100% + 10px);
  left: 0;
}
.popover--bottom-left .popover__content::after, .popover__content.popover--bottom-left::after {
  width: 0;
  height: 0;
  border-top: none;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  border-left: 5px solid transparent;
  top: -5px;
  left: 15px;
}
.popover--bottom-left .popover__content::before, .popover__content.popover--bottom-left::before {
  width: 0;
  height: 0;
  border-top: none;
  border-right: 8px solid transparent;
  border-bottom: 8px solid rgba(0, 0, 0, 0.05);
  border-left: 8px solid transparent;
  top: -8px;
  left: 12px;
}

.popover-header {
  background: #f0f3f6;
  border-bottom: 1px solid #d8d8d8;
  font-size: 1.2rem;
  padding: 5px 15px;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  cursor: default;
  font-size: 1.4rem;
  padding: 10px 15px;
}
.popover-body > *:first-child {
  margin-top: 0;
}
.popover-body > *:last-child {
  margin-bottom: 0;
}
.popover-body:empty {
  display: none;
}

.popover-footer {
  background: #f0f3f6;
  border-top: 1px solid #d8d8d8;
  font-size: 1.2rem;
  padding: 5px 15px;
}
.popover-footer:empty {
  display: none;
}

.popover-menu {
  font-size: 1.3rem;
}
.popover-menu .popover-menu__header {
  font-size: 1rem;
  text-transform: uppercase;
  color: #778593;
  font-family: "Montserrat", sans-serif;
  padding: 10px 15px 5px;
}
.popover-menu ul, .popover__content .popover-menu ul {
  margin: 0;
  list-style: none;
}
.popover-menu li {
  margin: 0;
}
.popover-menu li.separator {
  height: 1px;
  background: #d8d8d8;
  margin: 0 5px;
}
.popover-menu li.separator:last-child {
  display: none;
}
.popover-menu a {
  display: block;
  padding: 10px 15px;
}
.popover-menu a:hover {
  background: #f2f2f2;
}

.receiver-picker {
  display: flex;
  height: 660px;
  max-height: 80vh;
  min-height: 400px;
  position: relative;
}
.receiver-picker .tabs__tab {
  height: inherit;
}
.receiver-picker .receiver-picker__close {
  position: absolute;
  font-size: 12px;
  z-index: 2;
  top: 8px;
  right: 10px;
  color: #4f81bb;
  cursor: pointer;
}
.receiver-picker .receiver-picker__tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.receiver-picker .receiver-picker__tabs > ng-transclude {
  position: relative;
  height: 100%;
}
.receiver-picker .receiver-picker__tabs .tabs__tab {
  padding: 0;
}
.receiver-picker .receiver-picker__filters-title {
  line-height: 25px;
  min-height: 25px;
  font-weight: bold;
  margin-bottom: 5px;
}
.receiver-picker .receiver-picker__filters-box {
  background: #f8f8fa;
  border-top: 1px solid #dddddd;
  padding: 15px 20px 20px;
}
.receiver-picker .receiver-picker__filters.found-list {
  margin-top: 20px;
  padding-right: 15px;
  margin-bottom: -13px;
}
.receiver-picker .receiver-picker__filters:after {
  display: block;
  content: "";
  clear: both;
}
.receiver-picker .receiver-picker__filters-action {
  float: right;
  position: relative;
  padding-right: 30px;
  line-height: 22px;
  font-size: 12px;
}
.receiver-picker .receiver-picker__filters-action [class^=ico-],
.receiver-picker .receiver-picker__filters-action [class*=" ico-"] {
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0;
  line-height: inherit;
}
.receiver-picker .receiver-picker__filters-action [class^=ico-].ico-gray,
.receiver-picker .receiver-picker__filters-action [class*=" ico-"].ico-gray {
  color: #aeb0b4;
}
.receiver-picker ul {
  margin: 0;
  padding: 15px 0;
  list-style: none;
}
.receiver-picker ul.small li {
  padding: 5px 10px;
}
.receiver-picker ul.small li [class^=ico-],
.receiver-picker ul.small li [class*=" ico-"] {
  width: 18px;
  font-size: 18px;
}
.receiver-picker ul li {
  margin: 0;
  padding: 10px 15px;
  line-height: 20px;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
  transition: brackground 0.2s linear;
}
.receiver-picker ul li.receiver-picker__team {
  padding: 0;
}
.receiver-picker ul li.receiver-picker__team:hover {
  background: none;
}
.receiver-picker ul li.receiver-picker__team .receiver-picker__team-toggle {
  padding: 10px 55px 10px 15px;
  width: 100%;
}
.receiver-picker ul li.receiver-picker__team .receiver-picker__team-content {
  width: 100%;
}
.receiver-picker ul li.receiver-picker__team .receiver-picker__team-content ul {
  padding: 0;
}
.receiver-picker ul li.receiver-picker__team .receiver-picker__team-content li {
  border-top: 1px dashed #dddddd;
  border-bottom: none;
}
.receiver-picker ul li:first-child {
  border-top: 1px solid #dddddd;
}
.receiver-picker ul li span {
  flex: 1;
}
.receiver-picker ul li [class^=ico-],
.receiver-picker ul li [class*=" ico-"] {
  width: 22px;
  text-align: right;
  font-size: 22px;
  transition: color 0.2s linear;
  color: #222;
}
.receiver-picker ul li [class^=ico-].ico-circle-times,
.receiver-picker ul li [class*=" ico-"].ico-circle-times {
  color: #6bb2e9;
}
.receiver-picker ul li [class^=ico-]:hover,
.receiver-picker ul li [class*=" ico-"]:hover {
  color: #1871c3;
}
.receiver-picker ul li:hover {
  background: #eef8fa;
}

.receiver-picker__team {
  display: flex;
  flex-direction: column;
}

.receiver-picker__team-toggle {
  background: #eff0f0;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.receiver-picker__team-toggle::after {
  font-family: "sportbm";
  content: "\e913";
  position: absolute;
  top: 50%;
  right: 15px;
  color: #4f81bb;
  font-size: 18px;
  margin-top: -9px;
  transition: transform 0.2s linear;
}
.receiver-picker__team.active .receiver-picker__team-toggle::after {
  transform: rotate(-180deg);
}
.receiver-picker__team-toggle .name {
  flex: 1;
}
.receiver-picker__team-toggle .choose-group {
  line-height: 1;
  margin-left: 10px;
}
.receiver-picker__team-toggle .choose-group [class^=ico-],
.receiver-picker__team-toggle .choose-group [class*=" ico-"] {
  color: #778593;
}

.receiver-picker__team-content {
  overflow: hidden;
}

.receiver-picker__content {
  flex: 1;
  padding: 30px;
}

.receiver-picker__results {
  display: flex;
  flex-direction: column;
  width: 360px;
  background: #f0f3f6;
}

.receiver-picker__results-list {
  padding: 45px 30px 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}
.receiver-picker__results-list::after {
  position: absolute;
  display: block;
  top: 66px;
  left: 0;
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: none;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #fff;
}

.receiver-picker__results-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;
}

.receiver-picker__results-container {
  position: relative;
  flex: 1;
}
.receiver-picker__results-container .scroller {
  top: 20px;
  padding-right: 20px;
  right: -20px;
}
.receiver-picker__results-container ul:first-child {
  padding-top: 0;
}

.receiver-picker__results-save {
  background: #778593;
  padding: 20px 30px;
}

.receiver-picker__action-icon {
  width: 40px;
  line-height: 1;
}

.receiver-picker__add-item {
  line-height: 1;
  margin-left: 10px;
}

.receiver-picker sbm-tabs {
  display: block;
  height: inherit;
}
.receiver-picker sbm-tabs .tabs__nav {
  padding-bottom: 20px;
}
.receiver-picker sbm-tabs .tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.receiver-picker sbm-tabs sbm-tab {
  flex: 1;
  height: 100%;
  position: relative;
}

.shepherd-footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px dashed #d8d8d8;
  padding: 15px 0;
  margin: 0 15px;
}
.shepherd-footer .shepherd-button {
  margin: 0 5px;
}
.shepherd-footer .shepherd-button:last-child {
  margin-right: 0;
}

.shepherd-cancel-icon {
  background: transparent;
  border: none;
  color: #778593;
  font-size: 3rem;
  line-height: 2rem;
  cursor: pointer;
  font-weight: 400;
  margin: 5px;
  padding: 0;
  transition: color 0.1s linear;
}
.shepherd-cancel-icon:hover {
  color: #939faa;
}

.shepherd-title {
  display: flex;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
}

.shepherd-header {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  border-bottom: 1px dashed #d8d8d8;
  margin: 0 15px;
  padding: 15px 0;
}

.shepherd-text {
  font-size: 1.4rem;
  line-height: 1.5em;
  padding: 15px;
}
.shepherd-text p {
  margin-top: 0;
  margin-bottom: 10px;
}
.shepherd-text p:last-child {
  margin-bottom: 0;
}

.shepherd-content {
  outline: none;
  padding: 0;
}

.shepherd-element {
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  width: 400px;
  opacity: 0;
  outline: none;
  transition: opacity 0.3s;
  z-index: 9999;
}

.shepherd-enabled.shepherd-element {
  opacity: 1;
}

.shepherd-element {
  box-sizing: border-box;
  box-shadow: 0 0 10px 5px rgba(51, 51, 51, 0.4);
}
.shepherd-element *,
.shepherd-element :after,
.shepherd-element :before {
  box-sizing: border-box;
}
.shepherd-element .shepherd-arrow {
  border: 16px solid transparent;
  content: "";
  display: block;
  height: 16px;
  pointer-events: none;
  position: absolute;
  width: 16px;
  z-index: 10000;
}
.shepherd-element.shepherd-element-attached-bottom.shepherd-element-attached-center .shepherd-arrow {
  bottom: 0;
  border-top-color: #fff;
  left: 50%;
  transform: translate(-50%, 100%);
}
.shepherd-element.shepherd-element-attached-top.shepherd-element-attached-center .shepherd-arrow {
  border-bottom-color: #fff;
  left: 50%;
  top: 0;
  transform: translate(-50%, -100%);
}
.shepherd-element.shepherd-element-attached-top.shepherd-element-attached-center.shepherd-has-title .shepherd-arrow {
  border-bottom-color: #e6e6e6;
}
.shepherd-element.shepherd-element-attached-middle.shepherd-element-attached-left .shepherd-arrow {
  border-right-color: #fff;
  left: 0;
  top: 50%;
  transform: translate(-100%, -50%);
}
.shepherd-element.shepherd-element-attached-middle.shepherd-element-attached-right .shepherd-arrow {
  border-left-color: #fff;
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target {
  pointer-events: none;
}
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none;
}

.shepherd-modal-overlay-container {
  -ms-filter: progid:dximagetransform.microsoft.gradient.alpha(Opacity=50);
  filter: alpha(opacity=50);
  fill-rule: evenodd;
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
  width: 100vw;
  z-index: 9997;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: 0.5;
  transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all;
}

.side-filters {
  position: fixed;
  top: 0;
  right: -420px;
  bottom: 0;
  max-width: 400px;
  width: 100%;
  min-width: 320px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  background: #f0f3f6;
  transition: right 0.3s ease-in-out;
  z-index: 99;
  padding: 10px 10px 30px 30px;
  display: flex;
  flex-direction: column;
}
.side-filters.active {
  right: 0;
}

.side-filters__content {
  flex: 1;
}

.side-filters__actions {
  margin-bottom: 10px;
}

.side-filters__overlay {
  z-index: 98;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .side-filters {
    padding: 10px 10px 15px 15px;
  }
}
body.has-side-navigation .app-content-wrapper {
  padding-left: 250px;
}
body.has-side-navigation .app-subheader {
  min-height: 0;
}
body.has-side-navigation .app-view-title {
  margin-top: -120px;
}

.side-navigation {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 250px;
  top: 0;
  bottom: 0;
  left: 0;
  background: #3b444e;
  z-index: 5;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
.side-navigation .side-navigation__toggle {
  display: none;
}
.side-navigation .side-navigation__toggle sbm-icon {
  padding: 0;
}

.side-navigation__nav {
  flex: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: auto;
  width: 250px;
}
.side-navigation__nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
.side-navigation__nav ul li {
  margin: 0;
  border-bottom: 1px solid #666;
}
.side-navigation__nav ul li:first-child {
  border-top: 1px solid #666;
}
.side-navigation__nav a {
  display: flex;
  outline: none;
  align-items: center;
  padding: 15px 20px;
  padding-left: 15px;
  line-height: 20px;
  border-left: 5px solid transparent;
  color: #c1c4c8;
}
.side-navigation__nav a:hover {
  color: #ffffff;
}
.side-navigation__nav a.active {
  border-color: #ffffff;
  color: #ffffff;
}
.side-navigation__nav a sbm-icon {
  width: 40px;
  padding-right: 20px;
}
.side-navigation__nav [class^=ico-],
.side-navigation__nav [class*=" ico-"] {
  display: block;
  line-height: 1;
  font-size: 20px;
}

.side-navigation__header {
  height: 65px;
  padding: 0 0 0 15px;
  display: flex;
  align-items: center;
  font-size: 8px;
}
.side-navigation__header .app-header__logo {
  min-width: 16.7em;
  height: 5.3em;
  background-image: url("/assets/images/logo-white.png");
  margin-right: 0;
  color: #ffffff;
}
.side-navigation__header .app-header__logo--subtitle {
  min-width: 16.8em;
  background-image: url("/assets/images/logo-white-subtitle.png");
  padding-top: 3.1em;
  padding-left: 6.2em;
  font-size: inherit;
  height: 5.3em;
}
.side-navigation__header .app-header__logo--subtitle span {
  font-size: 1.5em;
}

@media only screen and (max-width: 993px) {
  body.has-side-navigation .app-content-wrapper {
    padding-left: 60px;
  }
  .side-navigation {
    top: 90px !important;
    bottom: 0;
    height: auto;
    width: 60px;
    transition: width 0.2s linear;
  }
  .side-navigation.opened {
    width: 250px;
  }
  .side-navigation .side-navigation__toggle {
    display: block;
  }
  .side-navigation .side-navigation__toggle sbm-icon :before {
    transform: scaleX(-1);
  }
  .side-navigation__header {
    display: none;
  }
  .side-navigation__nav {
    padding-top: 20px;
  }
}
.table {
  width: 100%;
  border-spacing: 0;
}
.table.table-insurance {
  border-collapse: collapse;
}
.table.table-insurance td {
  border: 1px solid #d8d8d8;
}
.table.table-insurance tbody tr:hover td {
  background: none;
}
.table.table-insurance .table-insurance-group {
  background: #f5f7f9;
  font-weight: bold;
}
.table.table--no-border td {
  border-bottom: none;
  border-top: none !important;
}
.table th,
.table td {
  text-align: left;
  border-bottom: 1px solid #d8d8d8;
  padding: 15px 20px;
  transition: background 0.2s linear;
  color: #222;
}
.table tbody tr:hover td {
  background: #f5f7f9;
}
.table tbody:first-child tr:first-child td {
  border-top: 1px solid #d8d8d8;
}
.table thead th,
.table thead td {
  border-bottom-color: #4f81bb;
  color: #4f81bb;
  font-weight: normal;
  font-size: 1.2rem;
  padding: 6px 20px;
}
.table tfoot tr:last-child td,
.table tfoot tr:last-child th {
  border-bottom: none;
}
.table tfoot tr:first-child td,
.table tfoot tr:first-child th {
  position: relative;
  top: -1px;
  border-top: 1px solid #d8d8d8;
  border-top-color: #4f81bb;
}
.table tfoot th,
.table tfoot td {
  font-weight: normal;
  font-size: 1.4rem;
  padding: 15px 20px;
  background: #f5f7f9;
}
.table .table-row-header {
  font-size: 14px;
  background: #f0f3f6;
  text-transform: uppercase;
}

table.table--lower th,
table.table--lower td {
  padding: 8px 20px;
}
table.table--lower tfoot th,
table.table--lower tfoot td,
table.table--lower thead th,
table.table--lower thead td {
  padding: 5px 20px;
}

table.table .text-right {
  text-align: right;
}
table.table .text-center {
  text-align: center;
}
table.table .text-left {
  text-align: left;
}
table.table .align-top {
  vertical-align: top;
}
table.table .align-middle {
  vertical-align: middle;
}
table.table .align-bottom {
  vertical-align: bottom;
}
table.table .col-actions {
  width: 90px;
  text-align: right;
}
table.table .col-actions .loader--icon-md {
  margin-left: auto;
}
table.table .col-date {
  width: 140px;
}
table.table .col-datetime {
  width: 180px;
}
table.table .col-dates-range {
  width: 210px;
}
table.table .col-checkbox {
  width: 65px;
}
table.table .col-index {
  width: 40px;
  color: #778593;
  font-weight: 300;
  font-size: 2rem;
}

@media only screen and (max-width: 993px) {
  .table--with-actions tbody tr {
    position: relative;
  }
  .table--with-actions tbody tr td:not(.col-actions) {
    padding-right: 80px;
  }
  .table--with-actions tbody .col-actions {
    padding: 0;
  }
  .table--with-actions tbody sbm-popover {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
@media only screen and (max-width: 993px) {
  .table:not(.table-not-responsive) {
    width: 100%;
    border-bottom: 1px solid #d8d8d8;
  }
  .table:not(.table-not-responsive) thead {
    display: none;
  }
  .table:not(.table-not-responsive) tr {
    position: relative;
  }
  .table:not(.table-not-responsive) tr .full-row-link::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: "";
  }
  .table:not(.table-not-responsive) tr,
  .table:not(.table-not-responsive) td {
    display: block;
    width: 100% !important;
  }
  .table:not(.table-not-responsive) td {
    padding: 10px 15px;
    text-align: left !important;
  }
  .table:not(.table-not-responsive) td:not(.col-actions) .links-group--right {
    justify-content: flex-start;
    text-align: left;
  }
  .table:not(.table-not-responsive) td:not(.col-actions) .btns-group--right {
    justify-content: flex-start;
    text-align: left;
  }
  .table:not(.table-not-responsive) td[data-label]:before {
    display: block;
    content: attr(data-label);
    width: 100%;
    font-size: 0.9em;
    color: #4f81bb;
  }
  .table:not(.table-not-responsive) td:first-child {
    border-top: 1px solid #d8d8d8;
  }
  .table:not(.table-not-responsive) td:not(:first-child) {
    border-top: none !important;
  }
  .table:not(.table-not-responsive) td:not(:last-child) {
    border-bottom: none;
  }
  .table:not(.table-not-responsive) tfoot td {
    padding: 10px 15px;
  }
  .table:not(.table-not-responsive) .col-actions.col-static .loader--icon-md {
    margin-left: 0;
  }
  .table:not(.table-not-responsive) .col-actions:not(.col-static) {
    text-align: right;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px !important;
    padding: 0;
    border: none;
  }
  .table:not(.table-not-responsive) .col-actions:not(.col-static):hover {
    background: transparent;
  }
}
bsc-tab:not(.hydrated) {
  display: none;
}

.tabs .hidden {
  display: none !important;
}

.tabs__nav {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: -5px 0;
  padding: 0;
  padding-bottom: 5px;
  list-style: none;
  user-select: none;
}
.card-header__title .tabs__nav {
  margin-top: -12px;
  margin-bottom: -12px;
}
.tabs--center .tabs__nav {
  justify-content: center;
  flex-wrap: wrap;
}
.tabs--bordered .tabs__nav {
  position: relative;
}
.tabs--bordered .tabs__nav::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  background: #d8d8d8;
  bottom: 5px;
  left: 0;
  z-index: 0;
}
.tabs--bordered .tabs__nav .tabs__nav-item {
  position: relative;
  z-index: 1;
}
.tabs__nav li {
  margin: 0;
  white-space: nowrap;
}

.tabs__nav-item {
  text-transform: uppercase;
  margin: 5px 0;
  padding: 10px 30px;
  border-bottom: 2px solid transparent;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 1.6rem;
  color: #222;
  outline: none;
  transition: border 0.1s linear;
  position: relative;
}
.tabs__nav-item.active {
  border-color: #4f81bb;
}
.tabs__nav-item.tabs__nav-item--icon-start [class^=ico-],
.tabs__nav-item.tabs__nav-item--icon-start [class*=" ico-"], .tabs__nav-item.tabs__nav-item--icon-end [class^=ico-],
.tabs__nav-item.tabs__nav-item--icon-end [class*=" ico-"] {
  font-size: 2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.tabs__nav-item.tabs__nav-item--icon-start {
  padding-left: 40px;
}
.tabs__nav-item.tabs__nav-item--icon-start [class^=ico-],
.tabs__nav-item.tabs__nav-item--icon-start [class*=" ico-"] {
  left: 10px;
}
.tabs__nav-item.tabs__nav-item--icon-end {
  padding-right: 40px;
}
.tabs__nav-item.tabs__nav-item--icon-end [class^=ico-],
.tabs__nav-item.tabs__nav-item--icon-end [class*=" ico-"] {
  right: 10px;
}

.tabs__tab {
  padding: 30px 0;
}
.tabs__tab:empty {
  display: none;
}

.timeline {
  padding: 30px 0 30px 60px;
  position: relative;
}
.timeline:before {
  content: "";
  display: block;
  width: 2px;
  background: #778593;
  height: 100%;
  position: absolute;
  top: 2px;
  left: 29px;
}
.timeline:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 22px;
  width: 0;
  height: 0;
  border-top: none;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #778593;
  border-left: 8px solid transparent;
}

.timeline__item {
  margin: 30px 0;
  padding: 20px;
  border: 2px solid #d8d8d8;
  border-radius: 6px;
  background: #fff;
  position: relative;
}
.timeline__item:first-child {
  margin-top: 0;
}
.timeline__item:last-child {
  margin-bottom: 0;
}
.timeline__item:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -10px;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 2px solid #d8d8d8;
  border-right: none;
  border-bottom: none;
  transform: translateY(-50%) rotate(-45deg);
}
.timeline__item:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border: 2px solid #778593;
  background: #d8d8d8;
  border-radius: 50%;
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
}

.tooltipster-fall,
.tooltipster-grow.tooltipster-show {
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-base {
  display: flex;
  pointer-events: none;
  position: absolute;
}

.tooltipster-box {
  flex: 1 1 auto;
}

.tooltipster-content {
  box-sizing: border-box;
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
}

.tooltipster-ruler {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
}

.tooltipster-fade {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  -ms-transition-property: opacity;
  transition-property: opacity;
}
.tooltipster-fade.tooltipster-show {
  opacity: 1;
}

.tooltipster-grow {
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-backface-visibility: hidden;
}
.tooltipster-grow.tooltipster-show {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-swing {
  opacity: 0;
  -webkit-transform: rotateZ(4deg);
  -moz-transform: rotateZ(4deg);
  -o-transform: rotateZ(4deg);
  -ms-transform: rotateZ(4deg);
  transform: rotateZ(4deg);
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
}
.tooltipster-swing.tooltipster-show {
  opacity: 1;
  -webkit-transform: rotateZ(0);
  -moz-transform: rotateZ(0);
  -o-transform: rotateZ(0);
  -ms-transform: rotateZ(0);
  transform: rotateZ(0);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 1);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -moz-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -ms-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -o-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
}

.tooltipster-fall {
  -webkit-transition-property: top;
  -moz-transition-property: top;
  -o-transition-property: top;
  -ms-transition-property: top;
  transition-property: top;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}
.tooltipster-fall.tooltipster-initial {
  top: 0 !important;
}
.tooltipster-fall.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  top: 0 !important;
  opacity: 0;
}

.tooltipster-slide {
  -webkit-transition-property: left;
  -moz-transition-property: left;
  -o-transition-property: left;
  -ms-transition-property: left;
  transition-property: left;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}
.tooltipster-slide.tooltipster-initial {
  left: -40px !important;
}
.tooltipster-slide.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  left: 0 !important;
  opacity: 0;
}

@keyframes tooltipster-fading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.tooltipster-update-fade {
  animation: tooltipster-fading 0.4s;
}

@keyframes tooltipster-rotating {
  25% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0);
  }
}
.tooltipster-update-rotate {
  animation: tooltipster-rotating 0.6s;
}

@keyframes tooltipster-scaling {
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.tooltipster-update-scale {
  animation: tooltipster-scaling 0.6s;
}

.tooltipster-sidetip .tooltipster-box {
  background: #fff;
  border: 1px solid #4f81bb;
  border-radius: 4px;
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-box {
  margin-top: 9px;
}
.tooltipster-sidetip.tooltipster-left .tooltipster-box {
  margin-right: 9px;
}
.tooltipster-sidetip.tooltipster-right .tooltipster-box {
  margin-left: 9px;
}
.tooltipster-sidetip.tooltipster-top .tooltipster-box {
  margin-bottom: 9px;
}
.tooltipster-sidetip .tooltipster-content {
  color: #222;
  font-size: 1.2rem;
  line-height: 1.4;
  padding: 6px 14px;
}
.tooltipster-sidetip .tooltipster-arrow {
  overflow: hidden;
  position: absolute;
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow {
  height: 10px;
  margin-left: -10px;
  top: 0;
  width: 20px;
}
.tooltipster-sidetip.tooltipster-left .tooltipster-arrow {
  height: 20px;
  margin-top: -10px;
  right: 0;
  top: 0;
  width: 10px;
}
.tooltipster-sidetip.tooltipster-right .tooltipster-arrow {
  height: 20px;
  margin-top: -10px;
  left: 0;
  top: 0;
  width: 10px;
}
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow {
  bottom: 0;
  height: 10px;
  margin-left: -10px;
  width: 20px;
}
.tooltipster-sidetip .tooltipster-arrow-background,
.tooltipster-sidetip .tooltipster-arrow-border {
  height: 0;
  position: absolute;
  width: 0;
}
.tooltipster-sidetip .tooltipster-arrow-background {
  border: 10px solid transparent;
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: #fff;
  left: 0;
  top: 1px;
}
.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-background {
  border-left-color: #fff;
  left: -1px;
  top: 0;
}
.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background {
  border-right-color: #fff;
  left: 1px;
  top: 0;
}
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #fff;
  left: 0;
  top: -1px;
}
.tooltipster-sidetip .tooltipster-arrow-border {
  border: 10px solid transparent;
  left: 0;
  top: 0;
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: #4f81bb;
}
.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border {
  border-left-color: #4f81bb;
}
.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border {
  border-right-color: #4f81bb;
}
.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
  border-top-color: #4f81bb;
}
.tooltipster-sidetip .tooltipster-arrow-uncropped {
  position: relative;
}
.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-uncropped {
  top: -10px;
}
.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-uncropped {
  left: -10px;
}

:root {
  --tooltip-color: #464e57;
  --tooltip-background-color: #f0f3f6;
  --tooltip-arrow-color: #2f2f39;
  --tooltip-font-size: 15px/20px;
  --tooltip-font-style: bold italic;
  --tooltip-font-weight: 400;
  --tooltip-font-family: $font-family;
  --tooltip-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  --tooltip-border-radius: 5px;
  --tooltip-text-align: left;
  --tooltip-padding: 5px 10px;
}

.tippy-tooltip {
  background-color: var(--tooltip-background-color) !important;
  color: var(--tooltip-color) !important;
  font-size: var(--tooltip-font-size) !important;
  font-weight: var(--tooltip-font-weight) !important;
  font-style: var(--tooltip-font-style) !important;
  font-family: var(--tooltip-font-family) !important;
  box-shadow: var(--tooltip-box-shadow) !important;
  border-radius: var(--tooltip-border-radius) !important;
  text-align: var(--tooltip-text-align) !important;
  padding: var(--tooltip-padding) !important;
}

.tippy-tooltip[data-animatefill] {
  background-color: var(--tooltip-background-color) !important;
}

.tippy-tooltip .tippy-backdrop {
  background-color: var(--tooltip-background-color) !important;
}

.tippy-popper[x-placement^=top] .tippy-tooltip .tippy-arrow {
  border-top-color: var(--tooltip-arrow-color) !important;
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip .tippy-arrow {
  border-bottom-color: var(--tooltip-arrow-color) !important;
}

.tippy-popper[x-placement^=left] .tippy-tooltip .tippy-arrow {
  border-left-color: var(--tooltip-arrow-color) !important;
}

.tippy-popper[x-placement^=right] .tippy-tooltip .tippy-arrow {
  border-right-color: var(--tooltip-arrow-color) !important;
}

.tippy-tooltip .tippy-roundarrow {
  fill: var(--tooltip-arrow-color) !important;
}

.view-navigation {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: -142px;
}
.app-rwd-menu-wrapper .view-navigation {
  border-top: none;
  border-bottom: 3px dashed #999;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.view-navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.view-navigation ul li {
  margin: 0;
}
.app-rwd-menu-wrapper .view-navigation ul {
  justify-content: center;
  text-align: center;
  width: 100%;
}
.view-navigation a {
  display: block;
  outline: none;
  padding: 14px 18px;
  line-height: 20px;
  transition: transform 0.1s linear, color 0.1s linear;
}
.view-navigation a, .app-header .view-navigation a {
  color: #c1c4c8;
}
.view-navigation a:hover {
  color: #ffffff;
}
.view-navigation a.active {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px 5px 0 0;
}
.app-rwd-menu-wrapper .view-navigation a.active {
  font-weight: bold;
}
.view-navigation [class^=ico-],
.view-navigation [class*=" ico-"] {
  line-height: 1;
  font-size: 18px;
}

.votes-chart-wrapper {
  padding: 25px 0;
  position: relative;
}
.votes-chart-wrapper .votes-yes-label,
.votes-chart-wrapper .votes-no-label {
  top: -10px;
  color: #778593;
  font-size: 12px;
  position: absolute;
  text-transform: uppercase;
}
.votes-chart-wrapper strong {
  font-size: 26px;
  font-weight: normal;
  position: relative;
  top: 1px;
}
.votes-chart-wrapper .votes-yes-label {
  left: 0;
}
.votes-chart-wrapper .votes-yes-label strong {
  color: #71db9a;
}
.votes-chart-wrapper .votes-no-label {
  right: 0;
}
.votes-chart-wrapper .votes-no-label strong {
  color: #e74d63;
}
.votes-chart-wrapper .votes-required-label {
  bottom: 0;
  color: #545454;
  font-size: 12px;
  left: 0;
  position: absolute;
}
.votes-chart-wrapper .votes-addressees {
  bottom: 0;
  color: #778593;
  font-size: 12px;
  position: absolute;
  right: 0;
}

.votes-chart {
  background: #f0f3f6;
  height: 50px;
  position: relative;
  border-radius: 3px;
}
.votes-chart .votes-yes {
  height: inherit;
  left: 0;
  width: 0;
  position: absolute;
  background: #6bd692;
  transition: all cubic-bezier(0.21, 0.62, 0.46, 1.01) 1s;
  border-radius: 3px 0 0 3px;
}
.votes-chart .votes-no {
  height: inherit;
  position: absolute;
  right: 0;
  width: 0;
  background: #d44051;
  transition: all cubic-bezier(0.21, 0.62, 0.46, 1.01) 1s;
  border-radius: 0 3px 3px 0;
}
.votes-chart .votes-required {
  height: inherit;
  position: absolute;
  left: 0;
  border: 2px solid #545454;
  border-radius: 3px 0 0 3px;
}
.votes-chart .votes-required:after {
  content: "";
  position: absolute;
  bottom: -7px;
  left: 50%;
  margin-left: -4px;
  width: 0;
  height: 0;
  border-top: 5px solid #545454;
  border-right: 4px solid transparent;
  border-bottom: none;
  border-left: 4px solid transparent;
}

@media only screen and (max-width: 993px) {
  .votes-chart-wrapper .votes-required-label {
    bottom: 5px;
  }
  .votes-chart-wrapper .votes-addressees {
    bottom: -10px;
  }
}
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: inherit;
}

.toast-message a:hover {
  color: inherit;
  opacity: 0.8;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: inherit;
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
  padding-top: 6px;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 100%;
  max-width: 600px;
  min-width: 300px;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  opacity: 1;
  cursor: pointer;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  min-width: 0;
  max-width: none;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background: #ffffff;
  border: 2px solid #d8d8d8;
  border-left-width: 5px;
  border-radius: 4px;
  font-size: 1.4rem;
  min-height: 66px;
  pointer-events: auto;
}
.ngx-toastr:before {
  color: #d8d8d8;
  font-size: 2.8rem;
  font-family: "sportbm";
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.toast-info {
  color: #4f81bb;
  border-color: #4f81bb;
}
.toast-info:before {
  color: #4f81bb;
  content: "\e90a";
}

.toast-error {
  color: #d10000;
  border-color: #d10000;
}
.toast-error:before {
  color: #d10000;
  content: "\e907";
}

.toast-success {
  color: #5fa200;
  border-color: #5fa200;
}
.toast-success:before {
  color: #5fa200;
  content: "\e90d";
}

.toast-warning {
  color: #f49317;
  border-color: #f49317;
}
.toast-warning:before {
  color: #f49317;
  content: "\e910";
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
ng-scrollbar {
  --scrollbar-size: 10px !important;
  --scrollbar-padding: 6px !important;
}

.ng-scroll-viewport {
  height: auto !important;
  padding-right: 22px;
}

html {
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

body {
  background: #f0f3f6;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  line-height: 1.285714;
  min-width: 320px;
  color: #464e57;
  overflow-y: scroll;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -15px -30px;
}
.wrap > div {
  margin: 15px 30px;
}
.wrap > .mg-l-auto {
  margin-left: auto;
}

.app-header {
  background: #fff;
  color: #778593;
  height: 90px;
  padding: 0;
  position: relative;
}
.app-header a {
  color: #778593;
}
.app-header a.app-header__logo--subtitle, .app-header a.app-header__logo--subtitle:hover {
  color: #435a78;
}
.app-header .container-fluid {
  display: flex;
  height: 100%;
}

.app-header--with-colors {
  height: 110px;
  padding-bottom: 20px;
}

.academy-colors {
  position: absolute;
  bottom: 0;
  z-index: 2;
  left: 0;
  right: 0;
  height: 20px;
  display: flex;
  flex-direction: column;
}
.academy-colors.with-border {
  border-top: 1px solid #3b444e;
}
.academy-colors > * {
  flex: 1;
}

.academy-logo img {
  max-height: 60px;
  max-width: 200px;
  display: block;
}
.app-header__logo + .academy-logo {
  margin-left: 20px;
}

.app-header__name {
  align-items: center;
  display: flex;
}

.app-header__logo {
  margin-right: 15px;
}
.app-header__logo img {
  max-height: 53px;
  max-width: 120px;
}

.app-header__app-name {
  align-self: center;
  border-left: 1px solid #d8d8d8;
  color: #435a78;
  font-size: 2rem;
  font-weight: 300;
  line-height: 2rem;
  padding: 7px 0 7px 15px;
}

.app-header__actions {
  margin-left: auto;
  align-items: center;
  display: flex;
}
.app-header__actions > * {
  margin-left: 10px;
  padding: 0 10px;
}
.app-header__actions [class^=ico-],
.app-header__actions [class*=" ico-"] {
  display: block;
  position: relative;
  top: 1px;
  line-height: 1;
  font-size: 2.5rem;
}

.mobile-menu-content {
  align-items: center;
  display: flex;
}
.mobile-menu-content > * {
  margin-left: 10px;
  padding: 0 20px;
}
.mobile-menu-content [class^=ico-],
.mobile-menu-content [class*=" ico-"] {
  font-size: 2.5rem;
}

.user-link {
  outline: none;
  position: relative;
  border-left: 1px solid #d8d8d8;
  display: inline-flex;
  min-height: 44px;
  margin: 0 0 0 0;
  padding: 7px 10px 7px 80px;
  max-width: 200px;
  font-size: 1.4rem;
  line-height: 1.153846;
  align-items: center;
}
.user-link.no-image {
  padding: 7px 10px;
}
.user-link.user-link--short {
  border: none;
  min-width: 0;
  padding: 0;
}
.user-link.user-link--short img {
  position: static;
  transform: none;
  left: 0;
}
.user-link img {
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 26px;
  width: 38px;
}
.app-rwd-menu-wrapper .user-link {
  border-left: none;
  margin-left: 0;
  flex: 1;
  max-width: none;
}

.user-link__initials {
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4f81bb;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1;
}

.user-details-popover {
  width: 350px;
  z-index: 95;
}
.user-details-popover .btns-group {
  padding: 0 30px;
}

.user-details {
  text-align: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #d8d8d8;
}
.user-details .user-avatar {
  margin-bottom: 10px;
}
.user-details .user-avatar .user-link__initials,
.user-details .user-avatar img {
  margin: auto;
  border-radius: 50%;
  width: 76px;
  height: 76px;
  font-size: 2.8rem;
}

.user-details__name {
  color: #464e57;
  font-size: 1.8rem;
  margin-bottom: 10px;
}
.user-details__name small {
  display: block;
  font-size: 1.1rem;
  color: #778593;
}

.user-details__role {
  color: #464e57;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.user-details__academy {
  color: #464e57;
  font-size: 1.5rem;
}

.app-subheader {
  background: #3b444e;
  min-height: 240px;
  padding-top: 15px;
  padding-bottom: 180px;
  margin-bottom: -90px;
}
.app-subheader a {
  color: #ffffff;
}

.app-subheader__nav {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
}
.app-subheader__nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.app-rwd-menu-wrapper .app-subheader__nav ul {
  flex-direction: column;
  text-align: center;
  width: 100%;
}
.app-subheader__nav a {
  display: block;
  outline: none;
  padding: 11px 18px 8px;
  line-height: 20px;
  border-bottom: 3px solid transparent;
  color: #c1c4c8;
}
.app-subheader__nav a * {
  pointer-events: none;
}
.app-subheader__nav a:hover {
  color: #ffffff;
}
.app-subheader__nav a.active {
  border-color: #ffffff;
  color: #ffffff;
}
.app-subheader__nav a.link--icon-start {
  padding-left: 2.1428571429em;
}
.app-subheader__nav [class^=ico-],
.app-subheader__nav [class*=" ico-"] {
  line-height: 1;
  font-size: 18px;
}

.app-view-title {
  position: relative;
  text-transform: uppercase;
  margin-top: -142px;
  padding: 15px 30px;
  line-height: 22px;
  height: 48px;
  font-size: 1.6rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #fff;
}
.app-view-title.app-view-title--no-subheader, body.has-view-navigation .app-view-title {
  margin-top: 0;
}
.with-back .app-view-title, .app-view-title.with-back {
  padding-left: 80px;
}
.with-back .app-view-title .go-back, .app-view-title.with-back .go-back {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  font-size: 1.8rem;
  color: #fff;
}

.mobile-menu-trigger {
  display: none;
}

@media only screen and (max-width: 993px) {
  .mobile-menu-trigger {
    display: block;
  }
  .mobile-menu-content {
    display: none;
  }
  .app-subheader {
    min-height: 180px;
  }
  .rwd-menu {
    overflow: hidden;
  }
  body {
    padding-top: 90px;
  }
  .app-header {
    position: fixed;
    z-index: 94;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 320px;
  }
  .app-rwd-menu-wrapper {
    position: fixed;
    z-index: 94;
    background: rgba(0, 0, 0, 0.9);
    top: 90px;
    padding: 30px 0;
    min-width: 320px;
    left: 0;
    width: 100%;
    height: calc(100% - 90px);
    overflow: auto;
  }
  .app-rwd-menu-wrapper .mobile-menu-content {
    display: flex;
    width: 100%;
    margin-top: 30px;
    border-top: 1px solid #d8d8d8;
  }
  .app-rwd-menu-wrapper .mobile-menu-content:first-child {
    margin-top: 0;
    border-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .with-back .app-view-title, .app-view-title.with-back {
    padding-left: 40px;
  }
  .with-back .app-view-title .go-back, .app-view-title.with-back .go-back {
    left: 15px;
  }
  .app-header__app-name {
    display: none;
  }
  .user-details-popover {
    width: 290px;
  }
  .user-details-popover .btns-group {
    padding: 0 15px;
  }
}
.app-header__logo {
  min-width: 167px;
  height: 53px;
  background: url("/assets/images/logo.png") no-repeat left center;
  background-size: contain;
  margin-right: 15px;
  overflow: hidden;
  text-indent: -999px;
  align-self: center;
}

.app-header__logo--subtitle {
  min-width: 168px;
  background-image: url("/assets/images/logo-subtitle.png");
  text-indent: 0;
  padding-top: 31px;
  padding-left: 62px;
  height: 53px;
  line-height: 1;
  font-size: 1.4rem;
  color: #435a78;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.apps-list {
  font-size: 1.6rem;
  text-align: center;
}
.apps-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.apps-list li {
  border-top: 1px solid #d8d8d8;
  margin: 0;
}
.apps-list li:first-child {
  border-top: none;
}
.apps-list a {
  padding: 15px 0;
  line-height: 2rem;
  display: block;
}

sbm-mobile-menu {
  display: none;
}

@media only screen and (max-width: 993px) {
  sbm-mobile-menu {
    display: block;
  }
}
.app-footer {
  padding: 15px;
}

.footer .languages-dropdown {
  display: inline-block;
}
.footer .languages-dropdown .btns-group {
  flex-wrap: wrap;
  margin: -5px;
}
.footer .languages-dropdown .btns-group .btn, .footer .languages-dropdown .btns-group .shepherd-button, .footer .languages-dropdown .btns-group .shepherd-button-secondary {
  margin: 5px;
  padding-left: 0;
  padding-right: 0;
  width: 50px;
}

.platform-partners {
  padding: 0 15px 15px;
}

.platform-partners__list {
  list-style: none;
  display: flex;
  margin: -15px;
}
.platform-partners__list img {
  max-height: 45px;
  margin: 15px;
}

.footer__nav-links {
  padding-top: 5px;
}
.footer__nav-links ul {
  list-style: none;
}
.footer__nav-links ul li {
  padding-left: 10px;
  position: relative;
  margin-top: 10px;
}
.footer__nav-links ul li:first-child {
  margin-top: 0;
}
.footer__nav-links ul li:before {
  content: "";
  width: 3px;
  height: 3px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 6px;
}

.footer__copy {
  padding: 0 0 30px;
  color: #8c959a;
  font-size: 11px;
}

.footer__copy-content {
  padding-top: 15px;
}

.footer__powered {
  width: 193px;
  height: 36px;
  background: url("/assets/images/poweredby_sportbm.png") center center no-repeat;
  background-size: cover;
  float: right;
  margin-top: 2px;
  text-indent: -999px;
  overflow: hidden;
  display: block;
}

.auth-page {
  padding-left: 550px;
  background: #fff;
}

.auth-page__side {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 550px;
  left: 0;
  background: #435a79 url("/assets/images/auth-side.jpg") no-repeat right top;
  background-size: 550px auto;
  padding: 50px 50px 50px 50px;
  color: #ffffff;
}
.auth-page__side a {
  color: #ffffff;
}
.auth-page__side a:hover {
  color: #bfbfbf;
}

.auth-page__side-logo {
  width: 251px;
  height: 78px;
  background: url("/assets/images/auth-logo.png") no-repeat center center;
  background-size: contain;
  display: block;
  text-indent: -900px;
  overflow: hidden;
}

.auth-page__side-content {
  margin: 60px 0 160px;
}
.auth-page__side-content h1 {
  font-size: 32px;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 30px;
  line-height: 1.2;
}
.auth-page__side-content h2 {
  font-size: 22px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
  line-height: 1.3;
  text-transform: none;
}

.auth-page__side-links {
  margin: 0;
  list-style: none;
  font-size: 16px;
}
.auth-page__side-links li {
  margin: 15px 0;
}
.auth-page__side-links [class^=ico-],
.auth-page__side-links [class*=" ico-"] {
  font-size: 60%;
  margin-left: 3px;
}

.auth-page__main {
  position: relative;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.auth-page__content {
  padding: 10vh 30px 30px;
  flex: 1;
  margin: auto;
  max-width: 700px;
  width: 100%;
}
.auth-page__content .header__language {
  position: absolute;
  top: 10px;
  right: 0;
  margin: 0;
  border: none;
  z-index: 5;
}

.auth-page__footer {
  width: 100%;
}

.auth-content__title {
  margin-bottom: 40px;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.3;
}

.auth-content__subtitle {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
}

.auth-content__leading {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3;
}

.auth-page__login-app-info {
  margin-top: 10vh;
  padding-top: 10px;
  border-top: 1px solid #f0f0f0;
}
.auth-page__login-app-info .apps {
  padding-left: 50px;
  margin: -5px -10px;
  display: flex;
  flex-wrap: wrap;
}
.auth-page__login-app-info .apps > * {
  margin: 5px 10px;
}
.auth-page__login-app-info .apps img {
  height: 40px;
}

.auth-page__registration-legal {
  color: #9a9a9a;
  font-size: 12px;
  line-height: 1.4;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #f0f0f0;
}

.register-section {
  max-width: 140px;
  width: 100%;
  border-right: 1px solid #2364b0;
  color: #2364b0;
  padding-right: 40px;
  text-align: right;
  height: calc(100% - 10px);
  margin: 10px 0 0;
  padding-top: 5px;
}

@media only screen and (max-width: 1439px), only screen and (max-height: 650px) {
  .auth-page__side {
    width: 360px;
    padding: 20px 20px 20px 20px;
    background-size: 360px auto;
  }
  .auth-page {
    padding-left: 360px;
  }
  .auth-page__side-logo {
    width: 180px;
    height: 56px;
  }
  .auth-page__side-content {
    margin: 20px 0 70px;
    height: 180px;
  }
  .auth-page__side-content h1 {
    font-size: 24px;
    margin-bottom: 30px;
  }
  .auth-page__side-content h2 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 993px) {
  .auth-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-left: 0;
  }
  .auth-page.auth-page--no-header {
    margin-top: -90px;
  }
  .auth-page__side {
    position: static;
    background: none;
    width: 100%;
    padding: 30px;
    height: 110px;
    background: #fff;
  }
  .auth-page__side-links {
    display: none;
  }
  .auth-page__side-content {
    display: none;
  }
  .auth-page__side-logo {
    background-image: url("/assets/images/auth-logo-alt.png");
  }
  .auth-page__main {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: stretch;
  }
  .auth-page__content {
    min-height: 0;
    padding: 30px;
    flex: 1;
  }
}
@media only screen and (max-width: 767px) {
  .register-section {
    max-width: none;
    text-align: left;
    border-bottom: 1px solid #2364b0;
    border-right: none;
    padding-right: 0;
    padding-bottom: 20px;
    height: auto;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 479px) {
  .auth-page__side {
    height: 100px;
  }
  .auth-page__content .header__language {
    top: -70px;
  }
  .auth-page__side-logo {
    width: 125px;
    height: 39px;
  }
  .auth-page__login-app-info {
    margin-top: 60px;
  }
}
.dashboard-boxes {
  min-height: 600px;
}

.dashboard-multi-boxes {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dashboard-multi-boxes .card {
  flex: 1;
}
.dashboard-multi-boxes .height-fill {
  flex: 1;
}

.special-offer-box {
  border-color: #f49317;
  border-width: 3px;
  border-left-width: 10px;
  font-size: 16px;
  font-weight: 700;
  position: relative;
}
.special-offer-box .alert__icon {
  color: #f49317;
  padding-top: 17px;
}
.special-offer-box a {
  color: inherit;
}
.special-offer-box .special-offer__logo {
  padding: 6px 10px;
  display: flex;
  align-items: center;
}
.special-offer-box .special-offer__logo img {
  display: block;
  max-height: 50px;
  max-width: 100px;
}

.modal--exercise-details {
  max-width: 1100px;
}
.modal--exercise-details h1 {
  font-size: 2.6rem;
  font-weight: 300;
  margin-bottom: 20px;
}

.exercise-attachment-preview {
  position: relative;
  min-height: 60px;
}
.exercise-attachment-preview img {
  display: block;
}
.exercise-attachment-preview .actions-layer {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s linear;
}
.exercise-attachment-preview .actions-layer [class^=ico-],
.exercise-attachment-preview .actions-layer [class*=" ico-"] {
  font-size: 30px;
  color: #fff;
  margin: 0 20px;
}
.exercise-attachment-preview:hover .actions-layer {
  opacity: 1;
  pointer-events: all;
}

.exercise-with-attachment {
  position: relative;
  padding-left: 30px;
}
.exercise-with-attachment .attachment-link {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.modal--unit-events {
  max-width: 900px;
}

.modal--training-form {
  max-width: 1200px;
}

.illustrator-loader {
  background: #fff;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.illustrator-loader--opacity {
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 2rem;
  text-shadow: 0 2px 2px rgba(255, 255, 255, 0.5);
}

body.illustrator-stage {
  overflow: hidden;
  user-select: none;
}
body.illustrator-stage .ng-dropdown-panel-items {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}
body.illustrator-stage.modal-opened {
  padding-right: 0 !important;
}
body.illustrator-stage.add-mode .konvajs-content {
  cursor: copy;
}
body.illustrator-stage .app-header {
  display: none;
}
body.illustrator-stage .app-subheader {
  display: none;
}
body.illustrator-stage .container-fluid.wrapper {
  width: 100%;
  max-width: none;
  padding: 0;
}
body.illustrator-stage .toolbar-window {
  padding: 20px;
  overflow: auto;
  height: 100%;
}
body.illustrator-stage .toolbar-window__header {
  margin: -20px -20px 10px;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 20px;
  border-bottom: 1px solid #8ca2b8;
}
body.illustrator-stage .app {
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-template-rows: 60px 1fr;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
body.illustrator-stage .app__toolbar-top {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row: 1;
  background: #627281;
  position: relative;
  z-index: 10;
  height: 60px;
  display: flex;
  align-items: center;
}
body.illustrator-stage .app__toolbar-top .app-subheader__nav {
  width: 100%;
  padding-top: 1px;
  display: flex;
}
body.illustrator-stage .app__toolbar-top .app-subheader__nav .sportbm-logo {
  height: 36px;
  margin: 2px 0 0 -10px;
}
body.illustrator-stage .app__toolbar-top .app-subheader__nav li {
  margin-left: 20px;
}
body.illustrator-stage .app__toolbar-top .app-subheader__nav li.mg-l-auto {
  margin-left: auto;
}
body.illustrator-stage .app__toolbar-top .app-subheader__nav .link--icon-start [class^=ico-],
body.illustrator-stage .app__toolbar-top .app-subheader__nav .link--icon-start [class*=" ico-"] {
  font-size: 12px;
}
body.illustrator-stage .app__toolbar-top a[sbmLink],
body.illustrator-stage .app__toolbar-top .popover__trigger {
  color: #fff;
}
body.illustrator-stage .app__toolbar-tools {
  position: absolute;
  bottom: 0;
  left: 240px;
  right: 0;
  display: flex;
  flex-direction: column;
  background: #627281;
  height: auto;
  max-height: 200px;
  z-index: 5;
}
body.illustrator-stage .app__toolbar-tools .tabs__nav-item {
  color: #fff;
  font-weight: 300;
}
body.illustrator-stage .app__toolbar-tools .tabs__tab {
  padding: 10px 0 0;
}
body.illustrator-stage .app__toolbar-tools .toolbar-tools__wrapper {
  overflow: hidden;
  border-top: 1px solid #aebecd;
  border-left: 1px solid #aebecd;
}
body.illustrator-stage .app__toolbar-tools .btn--circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 10px;
  top: calc(100% + 10px);
}
body.illustrator-stage .app__toolbar-properties {
  grid-column: 1;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  background: #627281;
  height: calc(100vh - 60px);
  overflow: hidden;
}
body.illustrator-stage .app__toolbar-properties .toolbar-tools-trigger {
  height: 74px;
  padding: 15px 5px;
}
body.illustrator-stage .app__toolbar-properties .toolbar-window {
  padding: 10px;
}
body.illustrator-stage .app__toolbar-properties .toolbar-window__header {
  margin: -20px -10px 10px;
}
body.illustrator-stage .app__stage-layers {
  position: absolute;
  top: 60px;
  right: -310px;
  bottom: 0;
  width: 300px;
  z-index: 10;
  background: #627281;
  transition: right 0.2s ease-in-out;
  box-shadow: -4px 0 4px -4px rgba(0, 0, 0, 0.4);
  border-top: 1px solid #aebecd;
}
body.illustrator-stage .app__stage-layers.opened {
  right: 0;
}
body.illustrator-stage .app__stage-wrapper {
  height: calc(100vh - 60px);
  background: #dce3ea;
  display: flex;
  align-items: center;
  overflow: auto;
  box-shadow: inset 0 0 3px 2px rgba(0, 0, 0, 0.4);
  position: relative;
}
body.illustrator-stage .app__stage-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #dce3ea;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 3px 2px rgba(0, 0, 0, 0.4);
}
body.illustrator-stage .stage-wrapper {
  padding: 30px 20px 20px;
  width: 100%;
  height: 100%;
}
body.illustrator-stage .stage-wrapper .stage {
  margin: auto;
}
body.illustrator-stage .konvajs-content {
  margin: auto;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}
body.illustrator-stage .toolbar-tools {
  padding: 15px;
}
body.illustrator-stage .toolbar-tools__header {
  text-transform: uppercase;
  color: #fff;
  padding: 10px 0;
  border-bottom: 1px solid #8ca2b8;
  margin-bottom: 10px;
  display: block;
  font-size: 14px;
}
body.illustrator-stage .toolbar-tools__tools-wrapper {
  overflow: auto;
  padding-bottom: 5px;
  min-height: 105px;
  align-items: center;
  display: flex;
}
body.illustrator-stage .toolbar-tools__tools {
  display: -moz-box;
  display: -webkit-box;
  list-style: none;
}
body.illustrator-stage .toolbar-tools__tools li {
  margin: 5px;
  padding: 10px;
  border: 1px dashed #4a5561;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  flex-direction: column;
}
body.illustrator-stage .toolbar-tools__tools li.active {
  border-color: #fff;
  background: rgba(255, 255, 255, 0.3);
}
body.illustrator-stage .toolbar-tools__tools .tool-label {
  font-size: 10px;
  margin-top: 5px;
}
body.illustrator-stage .item-properties,
body.illustrator-stage .item-property__fields {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7px;
  align-items: center;
  color: #fff;
}
body.illustrator-stage .item-properties > *,
body.illustrator-stage .item-property__fields > * {
  display: flex;
  flex: 1;
  margin-right: 7px;
  margin-left: 7px;
}
body.illustrator-stage .item-properties .size-auto,
body.illustrator-stage .item-property__fields .size-auto {
  width: auto;
  flex: 0;
}
body.illustrator-stage .item-property .ngx-slider-bubble {
  color: #fff;
  font-size: 1.2rem;
}
body.illustrator-stage .item-property .ngx-slider-pointer {
  background: #4f81bb;
  border: 2px solid #fff;
  width: 28px;
  height: 28px;
  top: -12px;
  outline: none;
}
body.illustrator-stage .item-property .ngx-slider-pointer::after {
  background: #4f81bb;
}
body.illustrator-stage .item-property .ngx-slider-bar {
  background: #fff;
}
body.illustrator-stage .item-property .ngx-slider-model-value {
  font-size: 1.5rem;
}
body.illustrator-stage .item-property__select-option-image {
  max-height: 50px;
}
body.illustrator-stage .player-chooser .ng-dropdown-panel-items > div {
  display: flex;
  justify-content: space-around;
}
body.illustrator-stage .player-chooser .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 10px;
  display: flex;
  align-items: center;
}
body.illustrator-stage .item-property__select-image {
  max-height: 60px;
}
body.illustrator-stage .item-property {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 10px;
}
body.illustrator-stage .item-property__label {
  font-size: 11px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 1px;
}
body.illustrator-stage .layers-list {
  list-style: none;
  margin: -10px -20px 0;
  padding: 0;
  font-weight: 300;
}
body.illustrator-stage .layers-list li {
  margin: 0;
  color: #fff;
  padding: 15px 12px 12px;
  border-bottom: 1px solid #4a5561;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  transition: background-color 0.1s linear;
}
body.illustrator-stage .layers-list li.active, body.illustrator-stage .layers-list li:hover {
  background: #4a5561;
}
body.illustrator-stage .layers-list li [class^=ico-],
body.illustrator-stage .layers-list li [class*=" ico-"] {
  margin-right: 10px;
  font-size: 2rem;
  padding-right: 10px;
  border-right: 1px solid #343b44;
  line-height: 1.2;
  display: block;
  position: relative;
  top: -2px;
}
body.illustrator-stage .layers-list li .ico-preview-off-light {
  opacity: 0.5;
}

.color-picker.color-picker .preset-area {
  padding: 10px;
}
.color-picker.color-picker .preset-area .preset-color {
  margin: 4px;
}
.color-picker.color-picker .preset-area hr,
.color-picker.color-picker .preset-area .preset-label {
  display: none;
}

.player-chooser,
.line-chooser {
  height: 80px;
}
.player-chooser .ng-select-container,
.line-chooser .ng-select-container {
  height: inherit !important;
}
.player-chooser .ng-option,
.line-chooser .ng-option {
  max-width: 50%;
}
.player-chooser .ng-dropdown-panel-items,
.line-chooser .ng-dropdown-panel-items {
  background: #ffffff;
}
.player-chooser .ng-dropdown-panel-items > div,
.line-chooser .ng-dropdown-panel-items > div {
  display: flex;
  flex-wrap: wrap;
}

.line-chooser {
  height: 44px;
}
.line-chooser .ng-value,
.line-chooser .ng-option {
  display: flex !important;
  align-items: center !important;
  min-height: 40px;
}

.modal--settings {
  max-width: 1000px;
}
.modal--settings img {
  display: block;
  margin: 15px 0;
  border: 2px solid #ccc;
}
.modal--settings img.active {
  border-color: #4f81bb;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.5);
}

.table--events tr:first-child td {
  border-top: 1px solid #d8d8d8;
}
.table--events td {
  border-bottom: 1px solid #d8d8d8;
}
.table--events .event-details {
  padding: 5px 0 5px 15px;
  border-left: 1px solid #d8d8d8;
}
.table--events .event-details__name {
  font-size: 1.4rem;
  margin-bottom: 10px;
}
.table--events .col-date {
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  vertical-align: top;
  width: 170px;
  padding-top: 16px;
}
.table--events .col-date strong {
  font-size: 1.4rem;
}
.table--events .col-date .time {
  margin-bottom: 10px;
  margin-top: 2px;
  font-size: 2rem;
  font-weight: 300;
}
.table--events .col-date .multidays {
  margin-bottom: 10px;
  display: block;
  line-height: 2rem;
}
.table--events .col-date .multidays .multi-time {
  vertical-align: top;
  font-size: 2rem;
  font-weight: 300;
  display: inline-block;
  margin-left: 3px;
}
.table--events .col-date .multidays .date-range {
  margin: 7px 0;
}
.table--events .col-date .due-time {
  font-size: 16px;
}
.table--events .col-date .label {
  color: #999;
}
.table--events .col-date .time,
.table--events .col-date .due-time {
  display: block;
}

@media only screen and (max-width: 993px) {
  body.illustrator-stage {
    padding-top: 0;
  }
  body.illustrator-stage .wrapper {
    min-width: 900px;
  }
  .table--events tr:first-child td,
  .table--events td {
    border-top: none;
    border-bottom: none;
  }
  .table--events tr {
    border-bottom: 1px solid #d8d8d8;
  }
  .table--events .col-date {
    text-align: left;
  }
  .table--events .event-details {
    padding: 0;
    border-left: none;
  }
}
.shepherd-title {
  width: 100%;
}

.modal--text .modal-close {
  display: none;
}

.targets-list {
  margin: -15px;
}
.targets-list > * {
  padding: 15px;
}

.exercises-list {
  margin: -15px;
}
.exercises-list > * {
  padding: 15px;
}

.exercise-box {
  padding: 0;
  overflow: hidden;
}
.exercise-box img {
  display: block;
}
.exercise-box .exercise-categories {
  display: block;
  color: #778593;
  font-size: 1.1rem;
  margin-top: 3px;
}
.exercise-box .exercise-details {
  padding: 15px;
}
.exercise-box .exercise-source {
  position: absolute;
  background: #778593;
  color: #ffffff;
  padding: 5px 10px;
  line-height: 1;
  top: 0;
  right: 0;
  font-size: 1rem;
}
.exercise-box .exercise-sport {
  position: absolute;
  background: #f0f3f6;
  color: #464e57;
  padding: 5px 10px;
  line-height: 1;
  top: 0;
  left: 0;
  font-size: 1rem;
}

.table--receivers .col-send-reminder {
  width: 80px;
  text-align: center;
}
.table--receivers .col-send-reminder .form-checkbox {
  margin-top: 0;
  display: inline;
  padding-left: 25px;
}
.table--receivers .col-delete {
  text-align: center;
  width: 60px;
}