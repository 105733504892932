mark {
    background: $color-primary;
    color: color-contrast($color-primary);
    display: inline-block;
    padding: 1px 7px;
    margin: -1px 2px;
}

hr {
    margin: 30px 0;
    border: none {
        top: 1px solid $color-border;
    }
}

.row {
    .col {
        flex: 1;
        padding-left: $half-gutter-width;
        padding-right: $half-gutter-width;
    }
}

.hide {
    display: none;
}

.show {
    display: block;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.text-uppercase {
    text-transform: uppercase;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.float-none {
    float: none;
}

.height-full {
    height: 100%;
}

@each $breakpoint in $breakpoints {
    $name: nth($breakpoint, 1);
    $size: nth($breakpoint, 2);

    @media only screen and (min-width: $size) {
        .#{$name}-hide {
            display: none;
        }

        .#{$name}-show {
            display: block;
        }

        .#{$name}-text-left {
            text-align: left;
        }

        .#{$name}-text-center {
            text-align: center;
        }

        .#{$name}-text-right {
            text-align: right;
        }

        .#{$name}-text-justify {
            text-align: justify;
        }

        .#{$name}-text-uppercase {
            text-transform: uppercase;
        }

        .#{$name}-float-left {
            float: left;
        }

        .#{$name}-float-right {
            float: right;
        }

        .#{$name}-float-none {
            float: none;
        }
    }
}
