$forms-disabled-opacity: 0.4 !default;

// FORM ROW
$forms-row-margin-top: 15px !default;
$forms-row-summary-margin-top: 5px !default;

// FORM FIELDSET
$forms-fieldset-padding: 15px 5px !default;
$forms-fieldset-border: 1px solid $color-border !default;
$forms-fieldset-margin: 30px 0 0 !default;
$forms-fieldset-label-margin: 0 0 30px !default;
$forms-fieldset-label-font-family: $font-family-secondary !default;
$forms-fieldset-label-font-size: 1.5rem !default;

// FORM LABEL
$forms-label-weight: 400 !default;
$forms-label-line-height: 24px !default;
$forms-label-summary-color: $color-tertiary !default;
$forms-label-padding-top: 10px !default;

// FORM INPUT
$forms-input-border-radius: 4px !default;
$forms-input-padding: 15px !default;
$forms-input-border: 1px solid $color-border !default;
$forms-input-background: $background-primary !default;
$forms-input-border-color-focus: $color-primary !default;
$forms-input-border-color-success: $color-success !default;
$forms-input-border-color-error: $color-danger !default;
$forms-input-prefix-color: $color-primary !default;
$forms-input-prefix-size: 2rem !default;
$forms-input-prefix-padding: 0 10px !default;
$forms-input-prefix-background: $background-secondary !default;
$forms-input-suffix-color: $color-primary !default;
$forms-input-suffix-size: 2rem !default;
$forms-input-suffix-padding: 0 10px !default;
$forms-input-suffix-background: $background-secondary !default;

// FORM TEXT INPUT
$forms-text-input-height: 44px !default;

// FORM TEXTAREA
$forms-textarea-height: 100px !default;

// FORM ERROR MESSAGES
$forms-error-msg-size: 1.2rem !default;
$forms-error-msg-color: $color-danger !default;

// FORM ACTIONS
$forms-actions-margin-top: 30px !default;
$forms-actions-hint-padding: 0 10px !default;
$forms-actions-hint-icon-padding: 0 30px !default;
$forms-actions-hint-color: $color-tertiary !default;
$forms-actions-hint-icon-size: 2rem !default;

// FORM CHECKBOX
$forms-checkbox-background: #fff !default;
$forms-checkbox-background-selected: #fff !default;
$forms-checkbox-border-color: $color-border !default;
$forms-checkbox-border-color-selected: $color-border !default;
$forms-checkbox-border-radius: 4px !default;
$forms-checkbox-border-size: 2px !default;
$forms-checkbox-size: 25px !default;
$forms-checkbox-tick-color: $color-primary !default;
$forms-checkbox-tick-content: '\e94f' !default;
$forms-checkbox-tick-font-name: $font-icon-name !default;
$forms-checkbox-tick-size: 13px !default;
$forms-checkbox-disabled-opacity: $forms-disabled-opacity !default;

$forms-checkbox-label-padding-left: 40px !default;
$forms-checkbox-label-font-size: 1.4rem !default;
$forms-checkbox-label-font-weight: 300 !default;
$forms-checkbox-label-line-height: 18px !default;
$forms-checkbox-min-height: $forms-checkbox-size !default;
$forms-checkbox-padding-vertical: ($forms-checkbox-min-height - $forms-checkbox-label-line-height) / 2 !default;
$forms-checkboxes-list-margin-top: $forms-label-padding-top !default;
// END CHECKBOX

// FORM RADIO BUTTON
$forms-radio-background: $background-primary !default;
$forms-radio-background-selected: $color-primary !default;
$forms-radio-border-color: $color-border !default;
$forms-radio-border-color-selected: $color-border !default;
$forms-radio-border-radius: 50% !default;
$forms-radio-border-size: 1px !default;
$forms-radio-size: 26px !default;
$forms-radio-tick-color: $background-primary !default;
$forms-radio-tick-size: 10px !default;
$forms-radio-disabled-opacity: $forms-disabled-opacity !default;

$forms-radio-label-padding-left: 40px !default;
$forms-radio-label-font-size: 1.4rem !default;
$forms-radio-label-font-weight: 300 !default;
$forms-radio-label-line-height: 18px !default;
$forms-radio-min-height: $forms-radio-size !default;
$forms-radio-padding-vertical: ($forms-checkbox-min-height - 18px) / 2 !default;
$forms-radio-list-margin-top: $forms-label-padding-top !default;
// END RADIO BUTTON

// FORM RADIO SWITCH
$switch-width: 140px !default;
$switch-height: 44px !default;
$switch-border-radius: 22px !default;
$switch-background: white !default;
$switch-border: 1px solid #dedede !default;
$switch-toggle-offset-left: 6px !default;
$switch-toggle-background-checked: $color-primary !default;
$switch-toggle-border-radius: 16px !default;
$switch-toggle-color: rgba(#999999, 0.5) !default;
$switch-toggle-color-yes: white !default;
// END RADIO SWITCH

// STAR RATING
$forms-star-rating-height: $forms-text-input-height !default;
$forms-star-rating-color: $color-border !default;
$forms-star-rating-color-selected: $color-primary !default;
$forms-star-rating-size: 2rem !default;
$forms-star-rating-size-large: 2.5rem !default;
$forms-star-rating-item-margin: 0 3px !default;

$forms-star-rating-result-color: $color-tertiary !default;
$forms-star-rating-result-size: 1.6rem !default;
$forms-star-rating-result-strong-color: $color-primary !default;
$forms-star-rating-result-strong-size: 2.75em !default;
// END STAR RATING
