.app-header {
    background: $background-primary;
    color: $color-tertiary;
    height: $app-header-height;
    padding: $app-header-padding;
    position: relative;

    a {
        color: $color-tertiary;

        &.app-header__logo--subtitle {
            &,
            &:hover {
                color: $color-brand;
            }
        }
    }

    .container-fluid {
        display: flex;
        height: 100%;
    }
}

.app-header--with-colors {
    height: $app-header-height + 20px;
    padding-bottom: 20px;
}

.academy-colors {
    position: absolute;
    bottom: 0;
    z-index: 2;
    left: 0;
    right: 0;
    height: 20px;
    display: flex;
    flex-direction: column;

    &.with-border {
        border-top: 1px solid $app-subheader-background;
    }

    & > * {
        flex: 1;
    }
}

.academy-logo {
    img {
        max-height: 60px;
        max-width: 200px;
        display: block;
    }

    .app-header__logo + & {
        margin-left: 20px;
    }
}

.app-header__name {
    align-items: center;
    display: flex;
}

.app-header__logo {
    margin-right: $app-header-logo-margin-right;

    img {
        max-height: $app-header-logo-height;
        max-width: $app-header-logo-max-width;
    }
}

.app-header__app-name {
    align-self: center;
    border-left: $app-header-name-border-left;
    color: $color-brand;
    font-size: $app-header-name-font-size;
    font-weight: $app-header-name-font-weight;
    line-height: $app-header-name-line-height;
    padding: $app-header-name-padding;
}

.app-header__actions {
    margin-left: auto;
    align-items: center;
    display: flex;

    & > * {
        margin-left: $app-header-actions-item-margin;
        padding: $app-header-actions-item-padding;
    }

    @include styleFontIcon {
        display: block;
        position: relative;
        top: 1px;
        line-height: 1;
        font: {
            size: $app-header-actions-item-icon-size;
        }
    }
}

.mobile-menu-content {
    align-items: center;
    display: flex;

    & > * {
        margin-left: $app-header-actions-item-margin;
        padding: $app-header-actions-item-padding-mobile;
    }

    @include styleFontIcon {
        font: {
            size: $app-header-actions-item-icon-size;
        }
    }
}

.user-link {
    outline: none;
    position: relative;
    border-left: $app-header-user-link-border;
    display: inline-flex;
    min-height: $app-header-user-link-min-height;
    margin: $app-header-user-link-margin;
    padding: $app-header-user-link-padding;
    max-width: $app-header-user-link-max-width;
    font: {
        size: $app-header-user-link-font-size;
    }
    line-height: $app-header-user-link-line-height;
    align-items: center;

    &.no-image {
        padding: $app-header-user-link-no-image-padding;
    }

    &.user-link--short {
        border: none;
        min-width: 0;
        padding: 0;

        img {
            position: static;
            transform: none;
            left: 0;
        }
    }

    img {
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: $app-header-user-link-img-left;
        width: $app-header-user-link-img-size;
    }

    .app-rwd-menu-wrapper & {
        border-left: none;
        margin-left: 0;
        flex: 1;
        max-width: none;
    }
}

.user-link__initials {
    border-radius: 50%;
    width: $app-header-user-link-img-size;
    height: $app-header-user-link-img-size;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-primary;
    color: color-inverse($color-primary);
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1;
}

.user-details-popover {
    width: 350px;
    z-index: 95;

    .btns-group {
        padding: 0 30px;
    }
}

.user-details {
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $color-border;

    .user-avatar {
        margin-bottom: 10px;

        .user-link__initials,
        img {
            margin: auto;
            border-radius: 50%;
            width: $app-header-user-link-img-size * 2;
            height: $app-header-user-link-img-size * 2;
            font-size: 2.8rem;
        }
    }
}

.user-details__name {
    color: $color-body;
    font-size: 1.8rem;
    margin-bottom: 10px;

    small {
        display: block;
        font-size: 1.1rem;
        color: $color-tertiary;
    }
}

.user-details__role {
    color: $color-body;
    font: {
        size: 1.5rem;
        weight: bold;
    }
    text-transform: uppercase;
}

.user-details__academy {
    color: $color-body;
    font: {
        size: 1.5rem;
    }
}

//
// SUBHEADER
//
.app-subheader {
    background: $app-subheader-background;

    min-height: $app-subheader-min-height;
    padding-top: $app-subheader-padding-top;
    padding-bottom: $app-subheader-padding-bottom;
    margin-bottom: $app-subheader-margin-bottom;

    a {
        color: $app-subheader-link-color;
    }
}

.app-subheader__nav {
    text-transform: uppercase;
    font-family: $app-subheader-nav-font-family;
    font-size: $app-subheader-nav-font-size;
    font-weight: $app-subheader-nav-font-weight;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .app-rwd-menu-wrapper & {
            flex-direction: column;
            text-align: center;
            width: 100%;
        }
    }

    a {
        display: block;
        outline: none;
        padding: $app-subheader-nav-link-padding;
        line-height: $app-subheader-nav-link-line-height;
        border-bottom: $app-subheader-nav-link-border-bottom;

        color: $app-subheader-nav-link-color;

        * {
            pointer-events: none;
        }

        &:hover {
            color: $app-subheader-nav-link-color-hover;
        }

        &.active {
            border-color: $app-subheader-nav-link-color-hover;
            color: $app-subheader-nav-link-color-hover;
        }

        &.link--icon-start {
            padding-left: #{$link-icon-size + $link-icon-start-margin};
        }
    }

    @include styleFontIcon() {
        line-height: 1;
        font-size: $app-subheader-nav-link-icon-size;
    }
}

.app-view-title {
    position: relative;
    text-transform: uppercase;
    margin-top: $app-view-title-margin-top;
    padding: $app-view-title-padding;
    line-height: $app-view-title-line-height;
    height: $app-view-title-height;
    font-size: $app-view-title-font-size;
    font-weight: $app-view-title-font-weight;
    font-family: $app-view-title-font-family;

    color: $app-view-title-color;

    &.app-view-title--no-subheader,
    body.has-view-navigation & {
        margin-top: 0;
    }

    .with-back &,
    &.with-back {
        padding-left: $app-view-title-back-padding-left;

        .go-back {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: $app-view-title-back-icon-left;
            font-size: $app-view-title-back-icon-size;

            color: $app-view-title-back-icon-color;
        }
    }
}

.mobile-menu-trigger {
    display: none;
}

@include media-query-max($mobile-breakpoint) {
    .mobile-menu-trigger {
        display: block;
    }

    .mobile-menu-content {
        display: none;
    }

    .app-subheader {
        min-height: $app-subheader-padding-bottom;
    }

    .rwd-menu {
        overflow: hidden;
    }

    body {
        padding-top: $app-header-height;
    }

    .app-header {
        position: fixed;
        z-index: 94;
        top: 0;
        left: 0;
        width: 100%;
        min-width: $body-min-width;
    }

    .app-rwd-menu-wrapper {
        position: fixed;
        z-index: 94;
        background: $app-header-mobile-nav-background;
        top: $app-header-height;
        padding: $app-header-mobile-nav-padding;
        min-width: $body-min-width;
        left: 0;
        width: 100%;
        height: calc(100% - #{$app-header-height});
        overflow: auto;

        .mobile-menu-content {
            display: flex;
            width: 100%;
            margin-top: 30px;
            border-top: 1px solid $color-border;

            &:first-child {
                margin-top: 0;
                border-top: 0;
            }

            //a {
            //    color: color-inverse($app-header-mobile-nav-background)
            //}
        }
    }
}

@include media-query-max($breakpoint-sm) {
    .app-view-title {
        .with-back &,
        &.with-back {
            padding-left: $app-view-title-back-padding-left / 2;

            .go-back {
                left: $app-view-title-back-icon-left / 2;
            }
        }
    }

    .app-header__app-name {
        display: none;
    }

    .user-details-popover {
        width: 290px;

        .btns-group {
            padding: 0 15px;
        }
    }
}
