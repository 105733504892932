/// @group Buttons
$buttons-group-margin-horizontal: 8px !default;
/// @group Buttons
$buttons-group-margin-vertical: 8px !default;
/// @group Buttons
$buttons-group-margin-column: 5px !default;

/// @group Buttons
$button-font-family: $font-family !default;
/// @group Buttons
$button-font-size: 1.3rem !default;
/// @group Buttons
$button-line-height: 1.538461538461538em !default;

/// @group Buttons
$button-transitions: background-color 0.2s linear, color 0.2s linear, border 0.2s linear !default;

/// @group Buttons
$button-border: 1px solid transparent !default;
/// @group Buttons
$button-border-radius: 4px !default;

/// @group Buttons
$button-background-color: transparent !default;
/// @group Buttons
$button-outline-border-style: solid !default;
/// @group Buttons
$button-outline-border-width: 1px !default;

/// @group Buttons
$button-padding-start: em(30, strip-unit($button-font-size)) !default;
/// @group Buttons
$button-padding-top: em(11, strip-unit($button-font-size)) !default;
/// @group Buttons
$button-padding-bottom: $button-padding-top !default;
/// @group Buttons
$button-padding-end: $button-padding-start !default;

/// @group Buttons
$button-large-font-size: 1.8rem !default;
/// @group Buttons
$button-large-line-height: 2.4rem !default;

/// @group Buttons
$button-medium-font-size: 1.6rem !default;
/// @group Buttons
$button-medium-line-height: 2.2rem !default;

/// @group Buttons
$button-small-font-size: 1.2rem !default;
/// @group Buttons
$button-small-line-height: 1.8rem !default;

/*
  BUTTON WITH ICON
 */
/// padding left for button with icon on start
/// @group Buttons
$button-icon-start-padding-start: em(25, strip-unit($button-font-size)) !default;
/// @group Buttons
$button-icon-start-margin: em(10, strip-unit($button-font-size)) !default;
/// padding right for button with icon on end
/// @group Buttons
$button-icon-end-padding-end: em(25, strip-unit($button-font-size)) !default;
/// @group Buttons
$button-icon-end-margin: em(10, strip-unit($button-font-size)) !default;
/// @group Buttons
$button-icon-size: em(20, strip-unit($button-font-size)) !default;
/*
  END BUTTON WITH ICON
 */

/// button sizes map for auto generate size modifiers
/// @group Buttons
$buttons-sizes: (
    '': (
        font-size: $button-font-size,
        line-height: $button-line-height,
    ),
    'small': (
        font-size: $button-small-font-size,
        line-height: $button-small-line-height,
    ),
    'medium': (
        font-size: $button-medium-font-size,
        line-height: $button-medium-line-height,
    ),
    'large': (
        font-size: $button-large-font-size,
        line-height: $button-large-line-height,
    ),
) !default;
