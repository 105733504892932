.payment-methods {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: -1 * $payment-methods-item-spacing;
}

.payment-methods__item {
    width: $payment-methods-item-size + 2 * $payment-methods-item-spacing;
    padding: $payment-methods-item-spacing;
    margin: $payment-methods-item-spacing;
    border: $payment-methods-item-border;
    cursor: pointer;

    img {
        width: $payment-methods-item-size;
        height: $payment-methods-item-size;
        display: block;
    }

    &.selected {
        border: $payment-methods-item-border-selected;
    }
}

.payment-cards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: -1 * $payment-methods-item-spacing;
}

.payment-cards__item {
    width: 2 * $payment-methods-item-size + 2 * $payment-methods-item-spacing;
    padding: 2 * $payment-methods-item-spacing $payment-methods-item-spacing;
    margin: $payment-methods-item-spacing;
    border: $payment-methods-item-border;
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.selected {
        border: $payment-methods-item-border-selected;
    }
}
