@import "illustrator";
@import "training";

.table--receivers {
    .col-send-reminder {
        width: 80px;
        text-align: center;

        .form-checkbox {
            margin-top: 0;
            display: inline;
            padding-left: $forms-checkbox-size;
        }
    }

    .col-delete {
        text-align: center;
        width: 60px;
    }
}
