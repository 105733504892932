$box-simple-padding: 30px !default;
$box-simple-border-radius: 4px !default;
$box-simple-margin: 30px 0 !default;
$box-simple-border: 1px solid $color-border !default;
$box-simple-small-padding: 15px !default;
$box-simple-small-margin: 15px 0 !default;

$box-stats-corner-link-size: 3.2rem !default;
$box-stats-corner-link-top: 5px !default;
$box-stats-corner-link-right: 10px !default;
$box-stats-spacing: 1px !default;
$box-stats-border: 1px solid $color-border !default;
$box-stats-min-width: calc(50% - #{2 * $box-stats-spacing}) !default;
$box-stats-padding: 25px 30px 20px !default;
$box-stats-border-radius: 4px !default;
$box-stats-font-size: 1.2rem !default;
$box-stats-line-height: 1.4rem !default;
$box-stats-small-value-size: 1.6rem !default;
$box-stats-value-size: 1.8rem !default;
$box-stats-value-weight: 600 !default;
$box-stats-number-size: 4.4rem !default;
$box-stats-number-weight: 600 !default;

$box-stats-small-padding: 10px 15px !default;
$box-stats-small-number-size: 3.2rem !default;

$box-dated-note-margin: 60px 0 0 !default;
$box-dated-note-date-margin: 0 0 5px !default;
$box-dated-note-category-margin: 0 0 10px !default;

$box-event-border: 2px solid transparent !default;
$box-event-date-size: 1.2rem !default;
$box-event-start-margin: 5px 0 -10px !default;
$box-event-start-size: 1.3rem !default;
$box-event-start-weight: 500 !default;
$box-event-start-icon-padding: 30px !default;
$box-event-start-icon-size: 1.8rem !default;
$box-event-start-icon-margin: 5px !default;
$box-event-padding: 3px 20px 10px !default;
$box-event-margin: 30px 0 0 !default;

$box-detailed-heading-background: $background-tertiary !default;
$box-detailed-heading-color: color-contrast($box-detailed-heading-background) !default;
$box-detailed-heading-padding: 5px 0 !default;
$box-detailed-heading-border-radius: 4px !default;
$box-detailed-heading-icon-size: 30px !default;
$box-detailed-heading-item-label-size: 1.2rem !default;
$box-detailed-heading-item-title-size: 1.6rem !default;
$box-detailed-heading-item-margin: 15px 30px !default;
$box-detailed-heading-item-min-width: 250px !default;
$box-detailed-heading-item-min-width-wide: 350px !default;
$box-detailed-heading-item-icon-padding: 50px !default;

$box-filters-border-radius: 4px !default;
$box-filters-margin-horizontal: 15px !default;
$box-filters-margin-vertical: 15px !default;
$box-filters-item-width: 200px !default;
$box-filters-item-width-medium: 250px !default;
$box-filters-item-width-large: 300px !default;
$box-filters-item-icon-padding: 50px !default;
$box-filters-item-input-width: 160px !default;
$box-filters-item-select-width: 200px !default;
$box-filters-item-select-selector: select !default;
$box-filters-icon-size: $box-detailed-heading-icon-size !default;
$box-filters-icon-color: $color-border !default;
$box-filters-item-title-size: $box-detailed-heading-item-title-size !default;
$box-filters-item-label-size: $box-detailed-heading-item-label-size !default;
