.table {
    width: 100%;
    border-spacing: 0;

    &.table-insurance {
        border-collapse: collapse;
        td {
            border: $table-cell-border;
        }
        tbody {
            tr:hover {
                td {
                    background: none;
                }
            }
        }

        .table-insurance-group {
            background: $table-cell-background-hover;
            font-weight: bold;
        }
    }

    &.table--no-border {
        td {
            border-bottom: none;
            border-top: none !important;
        }
    }

    th,
    td {
        text-align: left;
        border-bottom: $table-cell-border;
        padding: $table-cell-padding;
        transition: background 0.2s linear;
        color: $color-default;
    }

    tbody {
        tr:hover {
            td {
                background: $table-cell-background-hover;
            }
        }

        &:first-child {
            tr:first-child {
                td {
                    border-top: $table-cell-border;
                }
            }
        }
    }

    thead {
        th,
        td {
            border-bottom-color: $table-header-cell-border-color;
            color: $table-header-cell-color;
            font: {
                weight: normal;
                size: $table-header-font-size;
            }
            padding: $table-header-cell-padding;
        }
    }

    tfoot {
        tr:last-child {
            td,
            th {
                border-bottom: none;
            }
        }

        tr:first-child {
            td,
            th {
                position: relative;
                top: -1px;
                border-top: $table-cell-border;
                border-top-color: $color-primary;
            }
        }

        th,
        td {
            font: {
                weight: normal;
                size: $table-footer-font-size;
            }
            padding: $table-footer-cell-padding;
            background: $table-cell-background-hover;
        }
    }

    .table-row-header {
        font-size: 14px;
        background: $background-secondary;
        text-transform: uppercase;
    }
}

table.table--lower {
    th,
    td {
        padding: $table-lower-cell-padding;
    }

    tfoot,
    thead {
        th,
        td {
            padding: $table-lower-header-cell-padding;
        }
    }
}

table.table {
    .text-right {
        text-align: right;
    }

    .text-center {
        text-align: center;
    }

    .text-left {
        text-align: left;
    }

    .align-top {
        vertical-align: top;
    }

    .align-middle {
        vertical-align: middle;
    }

    .align-bottom {
        vertical-align: bottom;
    }

    .col-actions {
        width: 90px;
        text-align: right;

        .loader--icon-md {
            margin-left: auto;
        }
    }

    .col-date {
        width: 140px;
    }

    .col-datetime {
        width: 180px;
    }

    .col-dates-range {
        width: 210px;
    }

    .col-checkbox {
        width: 65px;
    }

    .col-index {
        width: 40px;
        color: $color-tertiary;
        font-weight: 300;
        font-size: 2rem;
    }
}

@include media-query-max($breakpoint-md) {
    .table--with-actions {
        tbody {
            tr {
                position: relative;
            }

            tr td:not(.col-actions) {
                padding-right: 80px;
            }

            .col-actions {
                padding: 0;
            }

            sbm-popover {
                position: absolute;
                top: 15px;
                right: 15px;
            }
        }
    }
}

@include media-query-max($mobile-breakpoint) {
    .table:not(.table-not-responsive) {
        width: 100%;
        border-bottom: $table-cell-border;

        thead {
            display: none;
        }

        tr {
            position: relative;

            .full-row-link {
                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: block;
                    content: '';
                }
            }
        }

        tr,
        td {
            display: block;
            width: 100% !important;
        }

        td {
            padding: $table-cell-padding-mobile;
            text-align: left !important;
        }

        td:not(.col-actions) {
            .links-group--right {
                justify-content: flex-start;
                text-align: left;
            }

            .btns-group--right {
                justify-content: flex-start;
                text-align: left;
            }
        }

        td[data-label] {
            &:before {
                display: block;
                content: attr(data-label);
                width: 100%;
                font-size: 0.9em;
                color: $table-header-cell-color;
            }
        }

        td {
            &:first-child {
                border-top: $table-cell-border;
            }
            &:not(:first-child) {
                border-top: none !important;
            }
            &:not(:last-child) {
                border-bottom: none;
            }
        }

        tfoot {
            td {
                padding: $table-cell-padding-mobile;
            }
        }

        .col-actions.col-static {
            .loader--icon-md {
                margin-left: 0;
            }
        }

        .col-actions:not(.col-static) {
            text-align: right;
            position: absolute;
            top: 10px;
            right: 10px;
            width: 50px !important;
            padding: 0;
            border: none;

            &:hover {
                background: transparent;
            }
        }
    }
}
