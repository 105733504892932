image-cropper[hidden] {
    display: none;
}

.ngx-ic-cropper {
    --cropper-outline-color: hsla(0, 0%, 100%, 0.6);
}

.modal--photo-crop {
    max-width: none;
    width: 90%;

    image-cropper {
        max-height: 65vh;
    }
}

.photo-uploader-current {
    .label {
        font: {
            weight: 300;
        }
        text-transform: uppercase;
        display: block;
    }

    img {
        max-width: 600px;
        max-height: 400px;
        width: 100%;
        display: block;
        margin: 15px auto;
        object-fit: contain;
    }
    text-align: center;
}

.photo-uploader {
    position: relative;
    height: 200px;
    text-align: center;
    color: $color-tertiary;
    padding-top: 60px;
    font: {
        weight: 300;
    }
    text-transform: uppercase;

    @include styleFontIcon() {
        font-size: 60px;
        color: $color-tertiary;
        display: block;
        margin-bottom: 20px;
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
    }
}

.ngx-ic-cropper {
    .cropper-selection-ellipse & {
        border-radius: 50% !important;
    }
}

.ngx-ic-cropper {
    .cropper-selection-shield & {
        border-radius: 5% 5% 50% 50% !important;
    }
}
