$auth-page-side-width: 550px;
$auth-page-side-background-color: #435a79;
$auth-page-side-padding: 50px 50px 50px 50px;
$auth-page-side-logo-width: 251px;
$auth-page-side-logo-height: 78px;
$auth-page-side-content-margin: 60px 0 160px;

.auth-page {
    padding-left: $auth-page-side-width;
    background: #fff;
}

.auth-page__side {
    position: fixed;
    top: 0;
    bottom: 0;
    width: $auth-page-side-width;
    left: 0;
    background: $auth-page-side-background-color url('/assets/images/auth-side.jpg') no-repeat right top {
        size: $auth-page-side-width auto;
    }
    padding: $auth-page-side-padding;
    color: color-inverse($auth-page-side-background-color);

    a {
        color: color-inverse($auth-page-side-background-color);

        &:hover {
            color: darken(color-inverse($auth-page-side-background-color), 25);
        }
    }
}

.auth-page__side-logo {
    width: $auth-page-side-logo-width;
    height: $auth-page-side-logo-height;
    background: url('/assets/images/auth-logo.png') no-repeat center center {
        size: contain;
    }
    display: block;
    text-indent: -900px;
    overflow: hidden;
}

.auth-page__side-content {
    margin: $auth-page-side-content-margin;

    h1 {
        font-size: 32px;
        font-weight: 300;
        font-family: $font-family;
        margin-bottom: 30px;
        line-height: 1.2;
    }

    h2 {
        font-size: 22px;
        font-weight: 300;
        font-family: $font-family-secondary;
        line-height: 1.3;
        text-transform: none;
    }
}

.auth-page__side-links {
    margin: 0;
    list-style: none;
    font-size: 16px;

    li {
        margin: 15px 0;
    }

    @include styleFontIcon {
        font-size: 60%;
        margin-left: 3px;
    }
}

.auth-page__main {
    position: relative;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}

.auth-page__content {
    padding: 10vh 30px 30px;
    //min-height: calc(100vh - 60px);
    flex: 1;
    margin: auto;
    max-width: 700px;
    width: 100%;

    .header__language {
        position: absolute;
        top: 10px;
        right: 0;
        margin: 0;
        border: none;
        z-index: 5;
    }
}

.auth-page__footer {
    width: 100%;
}

.auth-content__title {
    margin-bottom: 40px;
    font-size: 36px;
    font-weight: 300;
    line-height: 1.3;
}

.auth-content__subtitle {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
}

.auth-content__leading {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.3;
}

.auth-page__login-app-info {
    margin-top: 10vh;
    padding-top: 10px;
    border-top: 1px solid #f0f0f0;

    .apps {
        padding-left: 50px;
        margin: -5px -10px;
        display: flex;
        flex-wrap: wrap;

        > * {
            margin: 5px 10px;
        }

        img {
            height: 40px;
        }
    }
}

.auth-page__registration-legal {
    color: #9a9a9a;
    font-size: 12px;
    line-height: 1.4;
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #f0f0f0;
}

.register-section {
    max-width: 140px;
    width: 100%;
    border-right: 1px solid #2364b0;
    color: #2364b0;
    padding-right: 40px;
    text-align: right;
    height: calc(100% - 10px);
    margin: 10px 0 0;
    padding-top: 5px;
}

@media only screen and (max-width: $breakpoint-xl), only screen and (max-height: 650px) {
    $auth-page-side-width: 360px;
    $auth-page-side-logo-width: 180px;
    $auth-page-side-logo-height: 56px;

    .auth-page__side {
        width: $auth-page-side-width;
        padding: 20px 20px 20px 20px;
        background-size: $auth-page-side-width auto;
    }

    .auth-page {
        padding-left: $auth-page-side-width;
    }

    .auth-page__side-logo {
        width: $auth-page-side-logo-width;
        height: $auth-page-side-logo-height;
    }

    .auth-page__side-content {
        margin: 20px 0 70px;
        height: 180px;
    }
    .auth-page__side-content h1 {
        font-size: 24px;
        margin-bottom: 30px;
    }
    .auth-page__side-content h2 {
        font-size: 18px;
    }
}

@media only screen and (max-width: $breakpoint-md) {
    .auth-page {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        padding-left: 0;

        &.auth-page--no-header {
            margin-top: -1 * $app-header-height;
        }
    }

    .auth-page__side {
        position: static;
        background: none;
        width: 100%;
        padding: 30px;
        height: 110px;
        background: #fff;
    }

    .auth-page__side-links {
        display: none;
    }

    .auth-page__side-content {
        display: none;
    }

    .auth-page__side-logo {
        background-image: url('/assets/images/auth-logo-alt.png');
    }

    .auth-page__main {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: stretch;
    }

    .auth-page__content {
        min-height: 0;
        padding: 30px;
        flex: 1;
    }
}

@media only screen and (max-width: 767px) {
    .register-section {
        max-width: none;
        text-align: left;
        border-bottom: 1px solid #2364b0;
        border-right: none;
        padding-right: 0;
        padding-bottom: 20px;
        height: auto;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 479px) {
    .auth-page__side {
        height: 100px;
    }

    .auth-page__content .header__language {
        top: -70px;
    }

    .auth-page__side-logo {
        width: 125px;
        height: 39px;
    }

    .auth-page__login-app-info {
        margin-top: 60px;
    }
}
