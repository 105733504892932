.btn {
    display: inline-block;
    cursor: pointer;
    outline: none;
    text-align: center;
    border: $button-border;
    border-radius: $button-border-radius;
    font-family: $button-font-family;
    background: $button-background-color;
    font-size: $button-font-size;
    line-height: $button-line-height;
    padding: $button-padding-top $button-padding-end $button-padding-bottom $button-padding-start;
    transition: $button-transitions;

    .btns-group--column & {
        padding: $button-padding-top;
    }

    &:disabled,
    &.disabled,
    &.pointer-disabled {
        pointer-events: none;
    }
}

@each $color-name, $color-base, $color-contrast, $color-hover, $color-disabled in get-colors($buttons-colors) {
    @include button-default($color-name, $color-base, $color-hover, $color-contrast, $color-disabled);
    @include button-outline($color-name, $color-base, $color-hover, $color-contrast, $color-disabled);
    @include button-clear($color-name, $color-base, $color-hover, $color-disabled);
}

@each $size, $params in $buttons-sizes {
    @if $size != '' {
        $size: --#{$size};
    }

    .btn#{$size} {
        @each $prop, $value in $params {
            #{$prop}: $value;
        }
    }
}

.btn--full {
    width: 100%;
}

.btn--wide {
    padding: {
        left: $button-padding-start * 2;
        right: $button-padding-start * 2;
    }
}

.btn--icon-only {
    position: relative;
    padding-right: #{$button-icon-size * 0.7};
    padding-left: #{$button-icon-size * 0.7};

    @include styleFontIcon() {
        display: block;
        font-size: $button-icon-size;
    }
}

.btn--rounded {
    border-radius: 50%;
    padding: em(11, strip-unit($button-font-size));
}

.btn--icon-start {
    padding-left: #{$button-icon-start-padding-start + $button-icon-size + $button-icon-start-margin};
    position: relative;

    @include styleFontIcon() {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: $button-icon-start-padding-start;

        &::before {
            display: block;
            font-size: $button-icon-size;
        }
    }
}

.btn--icon-end {
    position: relative;
    padding-right: #{$button-icon-end-padding-end + $button-icon-size + $button-icon-end-margin};

    @include styleFontIcon() {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: $button-icon-end-padding-end;

        &::before {
            display: block;
            font-size: $button-icon-size;
        }
    }
}
