.card {
    padding: #{$card-padding / 2} $card-padding;
    background: $card-background;
    border-radius: $card-border-radius;
    box-shadow: $card-box-shadow;
    margin-bottom: $card-margin-bottom;
    height: calc(100% - #{$card-margin-bottom});
    display: flex;
    flex-direction: column;
    position: relative;

    [no-margin] &,
    &.no-margin {
        margin-bottom: 0;
        height: 100%;
    }
}

.loading-locked-wrapper {
    position: relative;
}

.card-locked {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(#fff, 0.65);
    display: block;
    border-radius: $card-border-radius;
}

.card--persona {
    background: linear-gradient(to bottom, $background-secondary 0, $background-secondary 145px, #fff 145px);
}

.card--color-transparent {
    background: transparent;
    box-shadow: none;
    padding: 0;

    & > .card-header,
    & > .card-footer {
        padding-left: $card-padding;
        padding-right: $card-padding;
    }

    & > .card-footer {
        border-bottom: $card-transparent-footer-border;
        padding-bottom: $card-padding;
    }

    &.card--with-icon {
        & > .card-header {
            padding-left: $card-icon-padding + $card-padding;

            & > .card-header__icon {
                left: $card-padding;
            }
        }

        & > .card-body {
            padding: {
                left: 0;
                right: 0;
            }
        }
    }
}

.card-header {
    padding: #{$card-padding / 2} 0;
    display: flex;
    position: relative;
    align-items: center;
    flex-wrap: wrap;

    .card--with-icon > & {
        padding-left: $card-icon-padding;
    }

    .card--with-options > & {
        padding-right: $card-options-padding;
    }

    .card--persona & {
        padding: 0;
    }

    &:empty {
        display: none;
    }
}

.card-header__icon {
    position: absolute;
    left: 0;
    top: 10px;
    font-size: $card-icon-size;

    @include styleFontIcon() {
        display: block;
        line-height: 1;
    }
}

.card-header__actions {
    max-width: 50%;
}

.card-header__actions,
.card-header__options,
.card-options {
    .btns-group {
        margin: #{-1 * $buttons-group-margin-vertical - 10px} #{-1 * $buttons-group-margin-horizontal};
    }
}

.card-header__options,
.card-options {
    position: absolute;
    top: #{$card-padding / 2};
    right: 0;
}

.card-header__title {
    flex: 1;
    font-size: $card-header-title-font-size;
    line-height: $card-header-title-line-height;
    word-break: break-word;

    small {
        display: block;
        color: $card-header-subtitle-color;
        font-size: $card-header-subtitle-font-size;
        line-height: $card-header-subtitle-line-height;
        margin-top: $card-header-subtitle-margin-top;
    }

    a {
        color: inherit;
    }
}

.card-body {
    padding: #{$card-padding / 2} 0;
    flex: 1;

    &:empty {
        display: none;
    }
}

.card-footer {
    padding: #{$card-padding / 2} 0;

    .links-group,
    .btns-group {
        text-align: right;
        justify-content: flex-end;
    }
}

@include media-query-max(1024px) {
    .card-header__actions {
        margin-top: $card-padding / 2;
        width: 100%;
        max-width: none;
    }

    .card-header__actions,
    .card-header__options,
    .card-options {
        .btns-group {
            margin: -1 * $buttons-group-margin-vertical -1 * $buttons-group-margin-horizontal;
        }
    }

    .card-header {
        padding-right: 60px;
        position: relative;
    }
}

@include media-query-max($breakpoint-sm) {
    .card {
        padding: 0 #{$card-padding / 2};
    }
}
