// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.app-footer {
    padding: 15px;
}

.footer {
    .languages-dropdown {
        display: inline-block;

        .btns-group {
            flex-wrap: wrap;
            margin: -5px;

            .btn {
                margin: 5px;
                padding: {
                    left: 0;
                    right: 0;
                }
                width: 50px;
            }
        }
    }
}

.platform-partners {
    padding: 0 15px 15px;
}

.platform-partners__list {
    list-style: none;
    display: flex;
    margin: -15px;

    img {
        max-height: 45px;
        margin: 15px;
    }
}

.footer__nav-links {
    padding-top: 5px;

    ul {
        list-style: none;

        li {
            padding-left: 10px;
            position: relative;
            margin-top: 10px;

            &:first-child {
                margin-top: 0;
            }

            &:before {
                content: '';
                width: 3px;
                height: 3px;
                background: #fff;
                position: absolute;
                left: 0;
                top: 6px;
            }
        }
    }
}

.footer__copy {
    padding: 0 0 30px;
    color: #8c959a;
    font-size: 11px;
}

.footer__copy-content {
    padding-top: 15px;
}

.footer__powered {
    width: 193px;
    height: 36px;
    background: url('/assets/images/poweredby_sportbm.png') center center no-repeat {
        size: cover;
    }
    float: right;
    margin-top: 2px;
    text-indent: -999px;
    overflow: hidden;
    display: block;
}
