.ng-select.ng-select {
    &.ng-select-opened {
        > .ng-select-container {
            background: #fff;
            border-color: $color-primary;

            &:hover {
                box-shadow: none;
            }

            .ng-arrow {
                top: -2px;
                border-color: transparent transparent #999;
                border-width: 0 5px 5px;

                &:hover {
                    border-color: transparent transparent #666;
                }
            }
        }

        &.ng-select-bottom > .ng-select-container {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        &.ng-select-top > .ng-select-container {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }
    }

    &.ng-select-disabled > .ng-select-container {
        background-color: #f9f9f9;
    }

    .ng-has-value .ng-placeholder {
        display: none;
    }

    .ng-select-container {
        @extend .form-input;
        cursor: pointer;

        .ng-value-container {
            align-items: center;

            .ng-placeholder {
                color: #aaa;
            }

            .ng-input input {
                font-family: $font-family;
                cursor: pointer;
            }
        }
    }

    &.ng-select-single .ng-select-container {
        //height: 36px;
        .ng-value-container .ng-input {
            padding-left: $forms-input-padding;
            //padding-left: 10px;
            padding-right: 60px;
            //top: 5px;
        }
    }

    &.ng-select-multiple {
        &.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
            background-color: #f9f9f9;
            border: 1px solid #e3e3e3;

            .ng-value-label {
                padding: 0px 5px;
            }
        }

        .ng-select-container {
            height: auto;
            min-height: $forms-text-input-height;

            .ng-value-container {
                padding-top: 5px;

                .ng-value {
                    font-size: 1em;
                    margin-right: 5px;
                    margin-bottom: 5px;
                    background-color: #f5faff;
                    border-radius: 2px;
                    border: 1px solid #c2e0ff;

                    &.ng-value-disabled {
                        background-color: #f9f9f9;
                        border: 1px solid #e3e3e3;

                        .ng-value-label {
                            padding-left: 5px;
                        }
                    }

                    .ng-value-label {
                        display: inline-block;
                        padding: 0px 5px 0px 5px;
                    }

                    .ng-value-icon {
                        display: inline-block;
                        padding: 0px 5px;

                        &:hover {
                            background-color: #d8eafd;
                        }

                        &.left {
                            border-right: 1px solid #c2e0ff;
                        }

                        &.right {
                            border-left: 1px solid #c2e0ff;
                        }
                    }
                }

                .ng-input {
                    padding-bottom: 3px;
                    padding-left: 3px;
                }

                .ng-placeholder {
                    padding-bottom: 5px;
                    padding-left: 3px;
                }
            }
        }
    }

    .ng-clear-wrapper {
        color: #999;
        width: 10px;

        &:before {
            position: absolute;
            display: block;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            font: {
                family: $font-icon;
                size: 1rem;
            }
            content: getIconCode(times);
        }

        .ng-clear {
            display: none;
        }
    }

    .ng-spinner-zone {
        padding-right: 5px;
        padding-top: 5px;
    }

    .ng-arrow-wrapper {
        margin-right: 0;
        margin-left: 10px;
        width: 12px;
        position: relative;
        color: $color-primary;

        &:before {
            position: absolute;
            display: block;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            font: {
                family: $font-icon;
                size: 1.2rem;
            }
            content: getIconCode(angle-down);
        }

        .ng-arrow {
            //display: none;
        }
    }
}

.ng-dropdown-panel {
    background-color: #fff;
    border: 1px solid $color-primary;

    &.ng-select-bottom {
        top: 100%;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-color: $color-border;
        margin-top: -1px;

        .ng-dropdown-panel-items .ng-option:last-child {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }

    &.ng-select-top {
        bottom: 100%;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-color: $color-border;
        margin-bottom: -1px;

        .ng-dropdown-panel-items .ng-option:first-child {
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
        }
    }

    .ng-dropdown-header {
        border-bottom: 1px solid #ccc;
        padding: 5px 7px;
    }

    .ng-dropdown-footer {
        border-top: 1px solid #ccc;
        padding: 5px 7px;
    }

    .ng-dropdown-panel-items {
        margin-bottom: 1px;

        .ng-optgroup {
            user-select: none;
            padding: 8px 10px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.54);
            cursor: pointer;

            &.ng-option-disabled {
                cursor: default;
            }

            &.ng-option-marked {
                background-color: #ebf5ff;
            }

            &.ng-option-selected {
                background-color: #f5faff;
                font-weight: 600;
            }
        }

        .ng-option {
            background-color: #fff;
            color: #333;
            padding: 10px 15px;

            &.ng-option {
                white-space: normal;
            }

            &.ng-option-selected {
                background-color: #f5f7f9;

                .ng-option-label {
                    font-weight: 600;
                }
            }

            &.ng-option-marked {
                background-color: #f5f7f9;
                color: #333;
            }

            &.ng-option-disabled {
                color: #cccccc;
            }

            &.ng-option-child {
                padding-left: 22px;
            }

            .ng-tag-label {
                padding-right: 5px;
                font-size: 80%;
                font-weight: 400;
            }
        }
    }
}
