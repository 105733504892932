.modal--exercise-details {
    max-width: 1100px;

    h1 {
        font: {
            size: 2.6rem;
            weight: 300;
        }
        margin-bottom: 20px;
    }
}

.exercise-attachment-preview {
    position: relative;
    min-height: 60px;

    img {
        display: block;
    }

    .actions-layer {
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.1s linear;

        @include styleFontIcon {
            font-size: 30px;
            color: #fff;
            margin: 0 20px;
        }
    }

    &:hover {
        .actions-layer {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.exercise-with-attachment {
    position: relative;
    padding-left: 30px;

    .attachment-link {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.modal--unit-events {
    max-width: 900px;
}

.modal--training-form {
    max-width: 1200px;
}
