.hint {
    margin: $hint-margin;
    display: flex;
    min-height: $hint-min-height;
    align-items: center;
}

.hint__icon {
    color: $color-primary;
    font: {
        size: $hint-icon-size;
    }

    * {
        display: block;
    }
}

.hint__content {
    padding: $hint-content-padding;
    font: {
        size: $hint-font-size;
        weight: $hint-font-weight;
    }
    line-height: 1.4;
    align-self: center;
}
