.illustrator-loader {
    background: #fff;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.illustrator-loader--opacity {
    background: rgba(#fff, 0.5);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 2rem;
    text-shadow: 0 2px 2px rgba(#fff, 0.5);
}

body.illustrator-stage {
    overflow: hidden;
    user-select: none;

    .ng-dropdown-panel-items {
        box-shadow: 2px 2px 2px rgba(#000, 0.3);
    }

    &.modal-opened {
        padding-right: 0 !important;
    }

    &.add-mode .konvajs-content {
        cursor: copy;
    }

    .app-header {
        display: none;
    }

    .app-subheader {
        display: none;
    }

    .container-fluid.wrapper {
        width: 100%;
        max-width: none;
        padding: 0;
    }

    .toolbar-window {
        padding: 20px;
        overflow: auto;
        height: 100%;
    }

    .toolbar-window__header {
        margin: -20px -20px 10px;
        font-size: 12px;
        text-transform: uppercase;
        color: #fff;
        padding: 10px 20px;
        border-bottom: 1px solid #8ca2b8;
    }

    $toolbar-top-height: 60px;

    .app {
        display: grid;
        grid-template-columns: 240px 1fr;
        grid-template-rows: $toolbar-top-height 1fr;
        width: 100%;
        height: 100vh;
        position: relative;
        overflow: hidden;
    }

    .app__toolbar-top {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row: 1;
        background: #627281;
        position: relative;
        z-index: 10;
        height: $toolbar-top-height;

        display: flex;
        align-items: center;

        .app-subheader__nav {
            width: 100%;
            padding-top: 1px;
            display: flex;

            .sportbm-logo {
                height: 36px;
                margin: 2px 0 0 -10px;
            }

            li {
                margin-left: 20px;

                &.mg-l-auto {
                    margin-left: auto;
                }
            }

            .link--icon-start {
                @include styleFontIcon() {
                    font-size: 12px;
                }
            }
        }

        a[sbmLink],
        .popover__trigger {
            color: #fff;
        }
    }

    .app__toolbar-tools {
        position: absolute;
        bottom: 0;
        left: 240px;
        right: 0;
        display: flex;
        flex-direction: column;
        background: #627281;
        height: auto;
        max-height: 200px;
        z-index: 5;

        .tabs__nav-item {
            color: #fff;
            font-weight: 300;
        }

        .tabs__tab {
            padding: 10px 0 0;
        }

        .toolbar-tools__wrapper {
            overflow: hidden;
            border: {
                top: 1px solid #aebecd;
                left: 1px solid #aebecd;
            }
        }

        .btn--circle {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            position: absolute;
            left: 10px;
            top: calc(100% + 10px);
        }
    }

    .app__toolbar-properties {
        grid-column: 1;
        grid-row: 2;
        display: flex;
        flex-direction: column;
        background: #627281;
        height: calc(100vh - #{$toolbar-top-height});
        overflow: hidden;

        .toolbar-tools-trigger {
            height: 74px;
            padding: 15px 5px;
        }

        .toolbar-window {
            padding: 10px;
        }

        .toolbar-window__header {
            margin: -20px -10px 10px;
        }
    }

    .app__stage-layers {
        position: absolute;
        top: $toolbar-top-height;
        right: -310px;
        bottom: 0;
        width: 300px;
        z-index: 10;
        background: #627281;
        transition: right 0.2s ease-in-out;
        box-shadow: -4px 0 4px -4px rgba(#000, 0.4);
        border-top: 1px solid #aebecd;

        &.opened {
            right: 0;
        }
    }

    .app__stage-wrapper {
        height: calc(100vh - #{$toolbar-top-height});
        background: #dce3ea;
        display: flex;
        align-items: center;
        overflow: auto;
        box-shadow: inset 0 0 3px 2px rgba(#000, 0.4);
        position: relative;
    }

    .app__stage-loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #dce3ea;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: inset 0 0 3px 2px rgba(#000, 0.4);
    }

    .stage-wrapper {
        padding: 30px 20px 20px;
        width: 100%;
        height: 100%;

        .stage {
            margin: auto;
        }
    }

    .konvajs-content {
        margin: auto;
        background: #fff;
        box-shadow: 0 0 4px rgba(#000, 0.3);
    }

    .toolbar-tools {
        padding: 15px;
    }

    .toolbar-tools__header {
        text-transform: uppercase;
        color: #fff;
        padding: 10px 0;
        border-bottom: 1px solid #8ca2b8;
        margin-bottom: 10px;
        display: block;
        font-size: 14px;
    }

    .toolbar-tools__tools-wrapper {
        overflow: auto;
        padding-bottom: 5px;
        min-height: 105px;
        align-items: center;
        display: flex;
    }

    .toolbar-tools__tools {
        display: -moz-box;
        display: -webkit-box;
        list-style: none;

        li {
            margin: 5px;
            padding: 10px;
            border: 1px dashed #4a5561;
            display: flex;
            align-items: center;
            justify-content: space-around;
            color: #fff;
            cursor: pointer;
            text-align: center;
            text-transform: uppercase;
            font-size: 11px;
            flex-direction: column;

            &.active {
                border-color: #fff;
                background: rgba(#fff, 0.3);
            }
        }

        .tool-label {
            font-size: 10px;
            margin-top: 5px;
        }
    }

    .item-properties,
    .item-property__fields {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -7px;
        align-items: center;
        color: #fff;

        & > * {
            display: flex;
            flex: 1;
            margin: {
                right: 7px;
                left: 7px;
            }
        }

        .size-auto {
            width: auto;
            flex: 0;
        }
    }

    .item-property {
        .ngx-slider-bubble {
            color: #fff;
            font-size: 1.2rem;
        }

        .ngx-slider-pointer {
            background: $color-primary;
            border: 2px solid #fff;
            width: 28px;
            height: 28px;
            top: -12px;
            outline: none;

            &::after {
                background: $color-primary;
            }
        }

        .ngx-slider-bar {
            background: #fff;
        }

        .ngx-slider-model-value {
            font-size: 1.5rem;
        }
    }

    .item-property__select-option-image {
        max-height: 50px;
    }

    .player-chooser {
        .ng-dropdown-panel-items {
            > div {
                display: flex;
                justify-content: space-around;
            }
        }

        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
            padding: 10px;
            display: flex;
            align-items: center;
        }
    }

    .item-property__select-image {
        max-height: 60px;
    }

    .item-property {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .item-property__label {
        font-size: 11px;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 1px;
    }

    .layers-list {
        list-style: none;
        margin: -10px -20px 0;
        padding: 0;
        font-weight: 300;

        li {
            margin: 0;
            color: #fff;
            padding: 15px 12px 12px;
            border-bottom: 1px solid #4a5561;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 14px;
            transition: background-color 0.1s linear;

            &.active,
            &:hover {
                background: #4a5561;
            }

            @include styleFontIcon() {
                margin-right: 10px;
                font-size: 2rem;
                padding-right: 10px;
                border-right: 1px solid #343b44;
                line-height: 1.2;
                display: block;
                position: relative;
                top: -2px;
            }

            .ico-preview-off-light {
                opacity: 0.5;
            }
        }
    }
}

.color-picker.color-picker {
    .preset-area {
        padding: 10px;

        .preset-color {
            margin: 4px;
        }

        hr,
        .preset-label {
            display: none;
        }
    }
}

.player-chooser,
.line-chooser {
    height: 80px;

    .ng-select-container {
        height: inherit !important;
    }

    .ng-option {
        max-width: 50%;
    }

    .ng-dropdown-panel-items {
        background: #ffffff;
        & > div {
            display: flex;
            flex-wrap: wrap;
        }
    }
}

.line-chooser {
    height: $forms-text-input-height;

    .ng-value,
    .ng-option {
        display: flex !important;
        align-items: center !important;
        min-height: 40px;
    }
}

.modal--settings {
    max-width: 1000px;

    img {
        display: block;
        margin: 15px 0;
        border: 2px solid #ccc;

        &.active {
            border-color: $color-primary;
            box-shadow: 0 0 5px 3px rgba(#000, 0.5);
        }
    }
}

.table--events {
    tr:first-child {
        td {
            border-top: 1px solid $color-border;
        }
    }
    td {
        border-bottom: 1px solid $color-border;
    }

    .event-details {
        padding: 5px 0 5px 15px;
        border-left: 1px solid $color-border;
    }

    .event-details__name {
        font-size: 1.4rem;
        margin-bottom: 10px;
    }

    .col-date {
        font-size: 1.2rem;
        line-height: 1.8rem;
        text-align: center;
        text-transform: uppercase;
        vertical-align: top;
        width: 170px;
        padding-top: 16px;

        strong {
            font-size: 1.4rem;
        }

        .time {
            margin-bottom: 10px;
            margin-top: 2px;
            font-size: 2rem;
            font-weight: 300;
        }

        .multidays {
            margin-bottom: 10px;
            display: block;
            line-height: 2rem;

            .multi-time {
                vertical-align: top;
                font-size: 2rem;
                font-weight: 300;
                display: inline-block;
                margin-left: 3px;
            }

            .date-range {
                margin: 7px 0;
            }
        }

        .due-time {
            font-size: 16px;
        }

        .label {
            color: #999;
        }

        .time,
        .due-time {
            display: block;
        }
    }
}

@include media-query-max($breakpoint-md) {
    body.illustrator-stage {
        padding-top: 0;

        .wrapper {
            min-width: 900px;
        }
    }

    .table--events {
        tr:first-child td,
        td {
            border-top: none;
            border-bottom: none;
        }

        tr {
            border-bottom: 1px solid $color-border;
        }

        .col-date {
            text-align: left;
        }

        .event-details {
            padding: 0;
            border-left: none;
        }
    }
}

.shepherd-title {
    width: 100%;
}

.modal--text {
    .modal-close {
        display: none;
    }
}
