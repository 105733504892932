.fieldset {
    padding: $forms-fieldset-padding;
    border: none {
        top: $forms-fieldset-border;
    }
    margin: $forms-fieldset-margin;

    &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
    }

    .legend {
        color: $color-tertiary;
        display: block;
        font: {
            family: $forms-fieldset-label-font-family;
            size: $forms-fieldset-label-font-size;
        }
        margin: $forms-fieldset-label-margin;
    }
}
