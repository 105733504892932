.p-carousel-indicators {
    list-style: none;
    margin: 0;

    button {
        border: 2px solid $color-secondary;
        border-radius: 5px;
        background-color: transparent;
        width: 20px;
        height: 10px;
        margin: 0 5px;
        cursor: pointer;
        transition:
            border 0.2s linear,
            background-color 0.2s linear;
    }

    .p-highlight button {
        border-color: $color-primary;
        background-color: $color-primary;
    }
}
