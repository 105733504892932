.events-list__day {
    font: {
        size: 14px;
        family: $font-family-secondary;
        weight: 400;
    }
    margin: 20px 10px 10px;

    &:first-child {
        margin-top: 0;
    }
}

.event-occurrence-box {
    display: flex;
    padding: 15px;
    border-radius: 3px;
    box-shadow: 0 0 3px rgba(#000, 0.3);
    margin: 20px 5px;
}

.event-occurrence-box__dates {
    width: 140px;
    font-size: 15px;

    strong {
        display: block;
    }
}

.event-occurrence-box__content {
    flex: 1;
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid $color-border;

    table & {
        margin-left: 0;
    }

    .event-details {
        padding-left: 50px;
        position: relative;

        @include styleFontIcon() {
            font-size: 30px;
            position: absolute;
            top: 5px;
            left: 0;
            color: $color-tertiary;
        }
    }

    .event-info {
        margin-top: 10px;
        color: $color-tertiary;

        p {
            margin: 5px 0;
        }
    }

    .name {
        margin: 0 0 5px;
    }

    .place {
        color: $color-tertiary;
    }
}

.event-occurrence-box__chart {
    width: 450px;
}

.event-occurrence-box__training-unit {
    margin-left: 20px;
    text-align: right;
    width: 40px;
    padding-top: 5px;

    @include styleFontIcon() {
        display: block;
        font-size: 30px;
    }
}

.event-occurrence-box__actions {
    margin-left: 20px;
    width: 46px;
}

.event-occurrence__attendance-info {
    position: relative;
    padding-left: 45px;

    .type-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }

    .type {
        display: block;
        font-size: 1.5rem;
    }

    .notes {
        display: block;
        margin-top: 10px;
    }
}

@include media-query-max($mobile-breakpoint) {
    .event-occurrence-box__content {
        .table & {
            padding-left: 0;
            border-left: none;
        }
    }
}
