.form-star-rating {
    display: inline-flex;
    height: $forms-star-rating-height;
    font: {
        size: $forms-star-rating-size;
    }
    color: $forms-star-rating-color;
    align-items: center;

    &.size-large {
        font-size: $forms-star-rating-size-large;
    }

    & > * {
        margin: $forms-star-rating-item-margin;
        cursor: pointer;
    }

    .active {
        color: $forms-star-rating-color-selected;
    }

    .readonly,
    &.readonly {
        pointer-events: none;
        cursor: default;
    }
}

.star-rating-result {
    font: {
        size: $forms-star-rating-result-size;
        weight: 600;
    }
    line-height: 1;
    color: $forms-star-rating-result-color;

    strong {
        color: $forms-star-rating-result-strong-color;
        display: inline-block;
        font: {
            size: $forms-star-rating-result-strong-size;
        }
        margin-right: 0.1em;
    }
}
