.multiple-joined-boxes {
    margin: $box-simple-margin;

    &:first-child {
        margin-top: 0;
    }

    &.no-margin-bottom {
        margin-bottom: 0;
    }
}

.box-simple {
    padding: $box-simple-padding;
    border-radius: $box-simple-border-radius;
    margin: $box-simple-margin;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &.cdk-drag-preview {
        background: #fff;
    }

    &.cdk-drag-placeholder {
        opacity: 0.4;
    }

    .box-simple__action {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    @include background-colors();

    &.with-border {
        border: $box-simple-border;

        .box-simple__footer {
            border-top: 1px solid $color-border;
        }
    }

    &.with-shadow {
        box-shadow: 0 0 3px rgba(#000, 0.3);
    }

    .multiple-joined-boxes & {
        margin: 0;
        border-radius: 0;

        &:first-child {
            border-radius: $box-simple-border-radius $box-simple-border-radius 0 0;
        }

        &:last-child {
            border-radius: 0 0 $box-simple-border-radius $box-simple-border-radius;
        }
    }

    .box-simple__footer {
        margin: $box-simple-padding #{-1 * $box-simple-padding} #{-1 * $box-simple-padding};
        padding: $box-simple-padding;
    }
}

.box-simple--small {
    padding: $box-simple-small-padding;
    margin: $box-simple-small-margin;

    .multiple-joined-boxes & {
        margin: 0;
    }

    .box-simple__footer {
        margin: $box-simple-small-padding #{-1 * $box-simple-small-padding} #{-1 * $box-simple-small-padding};
        padding: $box-simple-small-padding;
    }
}

.box-simple__content {
    margin-bottom: auto;
}

.box-simple__footer:empty {
    display: none !important;
}
