.view-navigation {
    text-transform: uppercase;
    font-family: $view-navigation-font-family;
    font-size: $view-navigation-font-size;
    font-weight: $view-navigation-font-weight;
    margin-top: $view-navigation-margin-top;
    //border-top: 1px dashed #999; // v1

    .app-rwd-menu-wrapper & {
        border-top: none;
        border-bottom: 3px dashed #999;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        // v2
        li {
            margin: 0;
        }

        .app-rwd-menu-wrapper & {
            justify-content: center;
            text-align: center;
            width: 100%;
        }
    }

    a {
        display: block;
        outline: none;
        padding: $view-navigation-link-padding;
        line-height: $view-navigation-link-line-height;
        transition: transform 0.1s linear, color 0.1s linear;

        &,
        .app-header & {
            color: $view-navigation-link-color;
        }

        &:hover {
            color: $view-navigation-link-color-hover;
        }

        &.active {
            color: $view-navigation-link-color-hover;
            //transform: scale(1.2); // v1
            background: rgba(#fff, 0.1); // v2
            border-radius: 5px 5px 0 0; // v2

            .app-rwd-menu-wrapper & {
                font-weight: bold;
            }
        }
    }

    @include styleFontIcon() {
        line-height: 1;
        font-size: $view-navigation-link-icon-size;
    }
}
