$font-icon: 'sportbm' !default;
$font-icon-file: '/assets/fonts/sportbm' !default;
$font-prefix: 'ico-' !default;
$icon-sizes: (
    xs: 0.5rem,
    sm: 0.8rem,
    md: 1.8rem,
    lg: 2.5rem,
    xl: 3rem,
    xxl: 4rem,
    form: 4.2rem,
);
$font-icon-version: 6;
