$accordion-padding: 5px 15px !default;
$accordion-border: 2px solid $color-border !default;
$accordion-margin-top: 15px !default;
$accordion-active-color: $color-primary !default;
$accordion-arrow-size: 24px !default;
$accordion-header-padding: 10px 24px 10px 0 !default;
$accordion-highlight-opened-color: #f6f6f6 !default;

$accordion-body-padding: 10px 0 !default;
$accordion-body-font-size: 1.4rem !default;
