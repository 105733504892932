.side-filters {
    position: fixed;
    top: 0;
    right: -420px;
    bottom: 0;
    max-width: 400px;
    width: 100%;
    min-width: $body-min-width;
    box-shadow: 0 2px 10px rgba(#000, 0.4);
    background: $background-secondary;
    transition: right 0.3s ease-in-out;
    z-index: 99;
    padding: 10px 10px 30px 30px;
    display: flex;
    flex-direction: column;

    &.active {
        right: 0;
    }
}

.side-filters__content {
    flex: 1;
}

.side-filters__actions {
    margin-bottom: 10px;
}

.side-filters__overlay {
    z-index: 98;
    background: rgba(#000, 0.6);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

@include media-query-max($breakpoint-sm) {
    .side-filters {
        padding: 10px 10px 15px 15px;
    }
}
