.link {
    cursor: pointer;
}

.links-group {
    display: flex;
    flex-wrap: wrap;
    margin: -1 * $links-group-margin;

    & > * {
        margin: $links-group-margin;
    }
}

.links-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        border-top: $links-list-item-border;
        padding: $links-list-item-padding;

        &:first-child {
            border-top: none;
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.links-group--right {
    justify-content: flex-end;
}

.links-group--center {
    justify-content: center;
}

.links-group--small {
    margin: -1 * $links-group-small-margin;

    & > * {
        margin: $links-group-small-margin;
    }
}

.links-group--large {
    margin: -1 * $links-group-large-margin;

    & > * {
        margin: $links-group-large-margin;
    }
}

a {
    text-decoration: none;
    color: $link-default-color;
    transition: $link-transition;
    display: inline-block;

    &:hover {
        color: $link-default-color-hover;
    }

    &.disabled {
        pointer-events: none;
        opacity: $link-disabled-opacity;
    }

    @include styleFontIcon() {
        &::before {
            display: inline-block;
        }
    }
}

@each $color-name, $color-base, $color-contrast, $color-hover, $color-disabled in get-colors($buttons-colors) {
    @include link-default($color-name, $color-base, $color-hover);
}

.link--small {
    font-size: $link-small-factor-size;
}

.link--large {
    font-size: $link-large-factor-size;
}

.link--icon-only {
    @include styleFontIcon() {
        &::before {
            display: inline-block;
            font-size: $link-icon-size;
        }
    }
}

.link--icon-start {
    padding-left: #{$link-icon-size + $link-icon-start-margin};
    position: relative;

    @include styleFontIcon() {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;

        &::before {
            display: block;
            font-size: $link-icon-size;
        }
    }
}

.link--icon-start-small {
    padding-left: #{$link-icon-size-small + $link-icon-end-margin};

    @include styleFontIcon() {
        &::before {
            font-size: $link-icon-size-small;
        }
    }
}

.link--icon-end {
    position: relative;
    padding-right: #{$link-icon-size + $link-icon-end-margin};

    @include styleFontIcon() {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;

        &::before {
            display: block;
            font-size: $link-icon-size;
        }
    }
}

.link--icon-end-small {
    padding-right: #{$link-icon-size-small + $link-icon-end-margin};

    @include styleFontIcon() {
        &::before {
            font-size: $link-icon-size-small;
        }
    }
}

.link-stretched-wrapper {
    position: relative;
}

.link--stretched::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: '';
    z-index: 1;
}
