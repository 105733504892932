sbm-form-actions-row {
    display: block;
    margin-top: $forms-actions-margin-top;
}

.form-header {
    width: calc(100% + 20px);
    font-size: 14px;
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    margin: 45px -10px 15px;
    text-transform: uppercase;
}

.form-header--first {
    margin-top: 0;
}

.form-header-hint {
    text-transform: none;
    display: block;
    color: $color-tertiary;
    margin: -8px auto 15px;
    font-size: 11px;
    padding-left: 20px;
    position: relative;
    line-height: 15px;

    @include styleFontIcon() {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 15px;
    }
}

.form-row {
    margin-top: $forms-row-margin-top;

    &:first-child:not(.ordered-rows__end):not(.form-row--first) {
        margin-top: 0;
    }
}

.form-row--summary {
    margin-top: $forms-row-summary-margin-top;

    &:first-child {
        margin-top: 0;
    }
}

.form-row__hint {
    color: $color-tertiary;
    display: block;
    font: {
        size: 12px;
        weight: 300;
    }
    margin-top: 2px;
}

.field-with-hint, .field-with-icon {
    display: flex;

    & > * {
        flex: 1;
    }

    .hint-icon, .field-icon {
        display: flex;
        flex: none;
        width: 40px;
        height: 44px;
        padding-left: 10px;
        align-items: center;
    }

    @include styleFontIcon() {
        font-size: 2.4rem;
        color: $color-tertiary;
    }
}

.form-actions {
    margin-top: $forms-actions-margin-top;

    hr:first-child {
        margin-top: 0;
    }

    &:first-child {
        margin-top: 0;
    }
}

.form-actions-hint {
    margin-top: $forms-actions-margin-top;
    position: relative;
    color: $forms-actions-hint-color;
    margin-bottom: -0.5 * $forms-actions-margin-top;

    @include styleFontIcon {
        font: {
            size: $forms-actions-hint-icon-size;
        }
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        & + .form-actions-hint__content {
            padding: $forms-actions-hint-icon-padding;
        }
    }

    sbm-icon + .form-actions-hint__content {
        padding: $forms-actions-hint-icon-padding;
    }
}

.form-actions-hint__content {
    padding: $forms-actions-hint-padding;
}

.form-label {
    display: flex;
    font: {
        weight: $forms-label-weight;
    }
    color: $color-tertiary;
    justify-content: flex-start;
    line-height: $forms-label-line-height;
    text-align: left;

    br {
        width: 100%;
    }

    .form-horizontal & {
        height: 100%;
        justify-content: flex-end;
        text-align: right;
        min-height: $forms-text-input-height;
        padding-top: $forms-label-padding-top;
    }

    .form-row--summary & {
        color: $forms-label-summary-color;
        min-height: $forms-label-line-height;
        padding-top: 0;
    }

    & + ul {
        margin-top: 0;
    }
}

.form-static {
    align-self: center;
}

.form-error,
.form-error-msg,
.errorlist {
    font: {
        size: $forms-error-msg-size;
    }
    color: $forms-error-msg-color;
    line-height: ceil(1.3 * $forms-error-msg-size);
    display: block;

    [margin-top] > & {
        margin-top: $forms-row-margin-top;
    }

    &.hide {
        display: none;
    }

    .form-checkbox &,
    .form-radio &,
    .form-checkboxes > & {
        margin-top: 0.5em;
    }
}

ul.errorlist {
    margin: 0;
    padding: 0;
    list-style: none;
}
