.timeline {
    padding: $timeline-item-margin 0 $timeline-item-margin $timeline-padding-left;
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 2px;
        background: $timeline-line-color;
        height: 100%;
        position: absolute;
        top: 2px;
        left: ($timeline-padding-left - $timeline-border-width) / 2;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: $timeline-padding-left / 2 - $timeline-arrow-width;
        @include makeArrow(up, $timeline-line-color, $timeline-arrow-width, $timeline-arrow-width);
    }
}

.timeline__item {
    margin: $timeline-item-margin 0;
    padding: $timeline-item-padding;
    border: $timeline-border-width solid $timeline-border-color;
    border-radius: 6px;
    background: $timeline-item-background;
    position: relative;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: -10px;
        width: 16px;
        height: 16px;
        background: $timeline-item-background;
        border: $timeline-border-width solid $timeline-border-color;
        border-right: none;
        border-bottom: none;
        transform: translateY(-50%) rotate(-45deg);
    }

    &:before {
        content: '';
        display: block;
        width: $timeline-circle-width;
        height: $timeline-circle-width;
        border: $timeline-border-width solid $timeline-line-color;
        background: $timeline-border-color;
        border-radius: 50%;
        position: absolute;
        left: -1 *
            (
                ($timeline-padding-left + $timeline-circle-width + 2 * $timeline-border-width) / 2 +
                    $timeline-border-width
            );
        top: 50%;
        transform: translateY(-50%);
    }
}
