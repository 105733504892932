@import '../icons/variables';

$use-css-variables: true !default;
$use-admin-layout: true !default;

/// @group Colors
/// @type Color
$color-primary: #4f81bb !default;
/// @group Colors
/// @type Color
$color-secondary: #464e57 !default;
/// @group Colors
/// @type Color
$color-tertiary: #778593 !default;
/// @group Colors
/// @type Color
$color-lime: #B6CD2A !default;
/// @group Colors
/// @type Color
$color-brand: #435a78 !default;
/// @group Colors
/// @type Color
$color-border: #d8d8d8 !default;
/// @group Colors
/// @type Color
$color-default: #222 !default;
/// @group Colors
/// @type Color
$color-success: #5fa200 !default;
/// @group Colors
/// @type Color
$color-warning: #f49317 !default;
/// @group Colors
/// @type Color
$color-danger: #d10000 !default;
/// @group Colors
/// @type Color
$color-info: #4f81bb;
$color-inverse: color-inverse($color-primary);

$color-lime: #b6cd2a;

$color-body: $color-secondary !default;

/// @group Colors
/// @type Color
$background-primary: #fff !default;
/// @group Colors
/// @type Color
$background-secondary: #f0f3f6 !default;
/// @group Colors
/// @type Color
$background-tertiary: #778593 !default;
/// @group Colors
/// @type Color
$background-quaternary: #e0f1f8 !default;
/// @group Colors
/// @type Color
$background-gradient-start: #313a43 !default;
/// @group Colors
/// @type Color
$background-gradient-end: #778583 !default;
/// @group Colors
/// @type Color
$background-hover: #f5f7f9 !default;
/// @group Colors
/// @type Color
$background-body: $background-secondary !default;

$body-min-width: 320px !default;

/// list of colors with variants
/// @group Colors
$colors: (
    'primary': (
        base: $color-primary,
        contrast: color-contrast($color-primary),
        hover: darken($color-primary, 10%),
        disabled: color-disabled($color-primary),
        rgb: get-rgb($color-primary),
    ),
    'secondary': (
        base: $color-secondary,
        contrast: color-contrast($color-secondary),
        hover: color-hover($color-secondary),
        disabled: color-disabled($color-secondary),
        rgb: get-rgb($color-secondary),
    ),
    'tertiary': (
        base: $color-tertiary,
        contrast: color-contrast($color-tertiary),
        hover: color-hover($color-tertiary),
        disabled: color-disabled($color-tertiary),
        rgb: get-rgb($color-tertiary),
    ),
    'lime': (
        base: $color-lime,
        contrast: color-contrast($color-lime),
        hover: color-hover($color-lime),
        disabled: color-disabled($color-lime),
        rgb: get-rgb($color-lime),
    ),
    // other is backward compatibility for tertiary
    'other':
        (
            base: $color-tertiary,
            contrast: color-contrast($color-tertiary),
            hover: color-hover($color-tertiary),
            disabled: color-disabled($color-tertiary),
            rgb: get-rgb($color-tertiary),
        ),
    'success': (
        base: $color-success,
        contrast: color-contrast($color-success),
        hover: color-hover($color-success),
        disabled: color-disabled($color-success),
        rgb: get-rgb($color-success),
    ),
    'info': (
        base: $color-info,
        contrast: color-contrast($color-info),
        hover: color-hover($color-info),
        disabled: color-disabled($color-info),
        rgb: get-rgb($color-info),
    ),
    'warning': (
        base: $color-warning,
        contrast: color-contrast($color-warning),
        hover: color-hover($color-warning),
        disabled: color-disabled($color-warning),
        rgb: get-rgb($color-warning),
    ),
    'danger': (
        base: $color-danger,
        contrast: color-contrast($color-danger),
        hover: color-hover($color-danger),
        disabled: color-disabled($color-danger),
        rgb: get-rgb($color-danger),
    ),
    'inverse': (
        base: $color-inverse,
        contrast: color-contrast($color-inverse),
        hover: color-hover($color-inverse),
        disabled: color-disabled($color-inverse),
        rgb: get-rgb($color-inverse),
    ),
);

$background-colors: (
    'primary': (
        base: $background-primary,
        contrast: color-contrast($background-primary),
    ),
    'secondary': (
        base: $background-secondary,
        contrast: color-contrast($background-secondary),
    ),
    'tertiary': (
        base: $background-tertiary,
        contrast: color-contrast($background-tertiary),
    ),
    // other is backward compatibility for tertiary
    'other':
        (
            base: $background-tertiary,
            contrast: color-contrast($background-tertiary),
        ),
    'quaternary': (
        base: $background-quaternary,
        contrast: color-contrast($background-quaternary),
    ),
    'success': (
        base: $color-success,
        contrast: color-contrast($color-success),
    ),
    'info': (
        base: $color-info,
        contrast: color-contrast($color-info),
    ),
    'warning': (
        base: $color-warning,
        contrast: color-contrast($color-warning),
    ),
    'danger': (
        base: $color-danger,
        contrast: color-contrast($color-danger),
    ),
);

$foreground-colors: (
    'default': $color-default,
    'primary': $color-primary,
    'secondary': $color-secondary,
    'tertiary': $color-tertiary,
    'lime': $color-lime,
    // other is backward compatibility for tertiary
    'other': $color-tertiary,
    'danger': $color-danger,
    'info': $color-info,
    'success': $color-success,
    'warning': $color-warning,
);

/// list of colors available for buttons
/// @group Colors
$buttons-colors: primary, secondary, tertiary, other, danger, info, success, warning, inverse !default;

/// @group Core
$font-family: 'Open Sans', sans-serif !default;
/// @group Core
$font-family-secondary: 'Montserrat', sans-serif !default;
/// @group Core
$font-size: 1.4rem !default;
/// @group Core
$default-line-height: 1.285714 !default;

$font-icon-prefix: $font-prefix;
$font-icon-name: $font-icon;

// FLEXBOXGRID
$grid-columns: 12 !default;
$gutter-width: 30px !default;
$outer-margin: 15px !default;

$breakpoint-xs: 479px;
$breakpoint-sm: 767px;
$breakpoint-md: 993px;
$breakpoint-lg: 1199px;
$breakpoint-xl: 1439px;

$breakpoints: (
    xs $breakpoint-xs 480px,
    sm $breakpoint-sm 768px,
    md $breakpoint-md 994px,
    lg $breakpoint-lg 1200px
) !default;
$flexboxgrid-max-width: 1700px !default;

$app-subheader-background: #3b444e !default;

$mobile-breakpoint: $breakpoint-md !default;

@import 'components/boxes/boxes.variables';
