$badge-font-size: 0.9em !default;
$badge-font-weight: 300 !default;
$badge-border: 2px solid transparent !default;
$badge-padding: 0 8px !default;
$badge-border-radius: 6px !default;
$badge-margin: 0 5px !default;

$badge-icon-size: 24px !default;
$badge-icon-padding: 11px !default;
$badge-icon-border-color: #fff !default;
$badge-icon-top: -9px !default;
$badge-icon-font-size: 1rem !default;
$badge-icon-font-weight: 600 !default;
$badge-icon-font-family: $font-family !default;
