.builder-field {
    display: flex;
}

.field-order {
    align-self: center;
    width: 30px;

    @include styleFontIcon() {
        cursor: move;
    }
}

.field-data {
    flex: 1;
}

.field-actions {
    width: 40px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    & > * {
        margin: 10px 0 0;

        &:first-child {
            margin-top: 0;
        }
    }
}
