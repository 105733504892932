$popover-background: #fff !default;
$popover-border-radius: 4px !default;
$popover-shadow: 0 0 5px rgba(#000, 0.2) !default;
$popover-width: 200px !default;

$popover-header-background: $background-secondary !default;
$popover-header-border: 1px solid $color-border !default;
$popover-header-font-size: 1.2rem !default;
$popover-header-padding: 5px 15px !default;

$popover-body-padding: 10px 15px !default;
$popover-body-font-size: 1.4rem !default;

$popover-footer-background: $background-secondary !default;
$popover-footer-border: 1px solid $color-border !default;
$popover-footer-font-size: 1.2rem !default;
$popover-footer-padding: 5px 15px !default;
